import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { LoyaltyProgramsEffects } from './loyalty-programs.effects';
import { loyaltyProgramsReducer } from './loyalty-programs.reducer';

@NgModule({
    imports: [CommonModule, StoreModule.forFeature('loyaltyPrograms', loyaltyProgramsReducer), EffectsModule.forFeature([LoyaltyProgramsEffects])],
})
export class LoyaltyProgramsStateModule {}
