import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';

import * as actions from './virtual-locations.actions';
import * as Services from '@shared/core/services';

import { Observable } from 'rxjs';
import { mergeMap, catchError, map } from 'rxjs/operators';

@Injectable()
export class VirtualLocationsEffects {
    public requestVirtualLocations$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.VirtualLocationsRequest),
            mergeMap((action) =>
                this._virtualLocationsService.getVirtualLocations(action.params).pipe(
                    map((payload) => actions.VirtualLocationsSuccessRequest({ params: action.params, payload })),
                    catchError((ex) => {
                        console.error('requestVirtualLocations$', ex);

                        return [actions.VirtualLocationsErrorRequest(action.params, ex)];
                    }),
                ),
            ),
        ),
    );

    constructor(private _actions$: Actions, private _virtualLocationsService: Services.VirtualLocationsService) {}
}
