import { createSelector } from '@ngrx/store';

import { onlineMenuState } from '../root';

export const getOnlineMenuItemById = (id: number) =>
    createSelector(onlineMenuState, (onlineMenu) => {
        if (!onlineMenu.data) return null;

        let product: Nullable<OLO.DTO.OnlineMenuProductResponseModel> = null;

        for (let i = 0, j = onlineMenu.data.Pages.length; i < j; i++) {
            if (product) break;

            const page = onlineMenu.data.Pages[i];

            for (let k = 0, m = page.Products.length; k < m; k++) {
                if (page.Products[k].ProductId === id || page.Products[k].MenuFlowId === id) {
                    product = page.Products[k];
                    break;
                }
            }
        }

        return product;
    });
