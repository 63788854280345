import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as Services from '@shared/core/services';

import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError, switchMap, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class DietaryTagsImagesEffects {
    public onOnlineMenuSuccessRequest$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.OnlineMenuPagesSuccessRequest, actions.LoadDietaryImagesForLocation),
            withLatestFrom(this._store.select('dietaryTagsImages')),
            switchMap(([action, images]) => {
                const dietaryTags: Set<OLO.DTO.TagModel> = new Set();

                action.payload.Pages.forEach((page) => {
                    page.Products.forEach((product) => {
                        if (product.DietaryTags) {
                            product.DietaryTags.forEach((tag) => {
                                const existingTag = Array.from(dietaryTags).find((t) => t.Id === tag.Id);
                                if (!existingTag) {
                                    dietaryTags.add(tag);
                                }
                            });
                        }
                    });
                });

                /* Filter existing */
                images.forEach((image) =>
                    dietaryTags.forEach((tag) => {
                        if (tag.Id === image.Id) {
                            dietaryTags.delete(tag);
                        }
                    }),
                );

                return of(actions.DietaryTagsImagesRequest({ params: { width: 150, height: 150 }, dietaryTags: Array.from(dietaryTags) }));
            }),
        ),
    );

    public onRequestProductImages$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.DietaryTagsImagesRequest),
            mergeMap(({ params, dietaryTags }) => {
                if (dietaryTags.length === 0) return [actions.DietaryTagsImagesSuccessRequest({ payload: [], dietaryTags: [] })];
                const dietaryTagIds = dietaryTags.map((tag) => tag.Id);

                return this._imagesService.getDietaryTagsImages(params, ...dietaryTagIds).pipe(
                    map((images: OLO.DTO.ImageUrlModel[]) => actions.DietaryTagsImagesSuccessRequest({ payload: images, dietaryTags })),
                    catchError((ex) => of(actions.DietaryTagsImagesErrorRequest({ ex, dietaryTags }))),
                );
            }),
        ),
    );

    constructor(private _actions$: Actions, private _imagesService: Services.ImagesService, private _store: Store<OLO.State>) {}
}
