import { createReducer, on, Action } from '@ngrx/store';
import * as Utils from '@shared/core/utils';
import * as actions from './locations.actions';

const initialState: OLO.State.Locations = {
    isDownloading: false,
    hasSucceeded: false,
    hasFailed: false,
    data: [],
};

export const locationsReducerFn = createReducer(
    initialState,
    on(actions.LocationsRequest, (state) => ({
        ...state,
        isDownloading: true,
        hasSucceeded: false,
        hasFailed: false,
    })),
    on(actions.LocationsSuccessRequest, (state, action) => ({
        ...state,
        isDownloading: false,
        hasSucceeded: true,
        hasFailed: false,
        data: [...action.payload],
    })),
    on(actions.LocationsErrorRequest, (state) => ({
        ...state,
        isDownloading: false,
        hasSucceeded: false,
        hasFailed: true,
    })),
    on(actions.LocationRequest, (state) => ({
        ...state,
        isDownloading: true,
        hasSucceeded: false,
        hasFailed: false,
    })),
    on(actions.HiddenLocationsRequest, (state) => ({
        ...state,
        HiddenLocations: {
            ...state.HiddenLocations,
            isDownloading: true,
            hasSucceeded: false,
            hasFailed: false,
        },
    })),
    on(actions.HiddenLocationsSuccessRequest, (state, action) => ({
        ...state,
        HiddenLocations: {
            ...state.HiddenLocations,
            isDownloading: false,
            hasSucceeded: true,
            hasFailed: false,
            listOfHiddenLocations: action.payload,
        },
    })),
    on(actions.HiddenLocationsErrorRequest, (state) => ({
        ...state,
        HiddenLocations: {
            ...state.HiddenLocations,
            isDownloading: false,
            hasSucceeded: false,
            hasFailed: true,
        },
    })),
    on(actions.LocationSuccessRequest, (state, action) => ({
        ...state,
        isDownloading: false,
        hasSucceeded: true,
        hasFailed: false,
        data: state.data.map((locationData) => {
            if (action.params.locationNos.includes(locationData.LocationNo)) {
                const newData = action.payload.find((location) => action.params.locationNos.includes(location.LocationNo));

                return {
                    ...locationData,
                    OperatingTimeInfo: Utils.Arrays.dedupeByProps([...locationData.OperatingTimeInfo, ...newData.OperatingTimeInfo], 'Date'),
                    OrderingTimeInfo: Utils.Arrays.dedupeByProps([...locationData.OrderingTimeInfo, ...newData.OrderingTimeInfo], 'Date'),
                    OrderTypes: locationData.OrderTypes.map((orderType) => {
                        const newDataOrderType = newData.OrderTypes.find((_orderType) => _orderType.Id === orderType.Id);

                        return {
                            ...orderType,
                            OrderingTimeInfo: Utils.Arrays.dedupeByProps([...orderType.OrderingTimeInfo, ...newDataOrderType.OrderingTimeInfo], 'Date'),
                        };
                    }),
                };
            }

            return locationData;
        }),
    })),
    on(actions.LocationErrorRequest, (state) => ({
        ...state,
        isDownloading: false,
        hasSucceeded: false,
        hasFailed: true,
    })),
);

export function locationsReducer(state: OLO.State.Locations | undefined, action: Action) {
    return locationsReducerFn(state, action);
}
