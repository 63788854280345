export class FatZebraPaymentProviderMapper {
    public static mapGetSettingsForLocation(fatZebraSettings: APIv3.FatZebraSettingsResponse): OLO.DTO.FatZebraSettingsResponse {
        return fatZebraSettings
            ? {
                  DirectPostUrl: fatZebraSettings.DirectPostUrl,
                  ReturnPath: fatZebraSettings.ReturnPath,
                  Verification: fatZebraSettings.Verification?.toLocaleLowerCase(),
              }
            : null;
    }

    public static mapVerificationRequestDetails(data: OLO.DTO.FatZebraVerificationRequestModel): APIv3.CustomPaymentRequest {
        return data
            ? {
                  LocationNo: data.locationNo,
                  PaymentIntent: {
                      PaymentAccountId: data.paymentAccountId ?? null,
                      Amount: data.amount,
                      Reference: data.saleName,
                  },
              }
            : null;
    }

    public static mapVerificationResponse(data: APIv3.FatZebraOAuthResponse): OLO.DTO.FatZebraOAuthResponse {
        return data
            ? {
                  sessionToken: data.SessionToken ?? null,
                  merchantName: data.MerchantName ?? null,
                  verification: data.Verification ?? null,
                  cardToken: data.CardToken ?? null,
              }
            : null;
    }

    public static map3DSVerificationDetails(data: FatZebra.SuccessCardPaymentIntentDetails): Nullable<OLO.DTO.FatZebra3DSTokenDetails> {
        return data
            ? {
                  cavv: data.cavv,
                  directory_server_txn_id: data.directoryServerTxnId,
                  par: data.par,
                  sli: data.sli,
                  threeds_version: data.threedsVersion,
                  ver: data.ver,
                  xid: data.xid,
              }
            : null;
    }
}
