export class Hash {
    public static hash32(str: string, asString: boolean = true, seed?: number) {
        let i!: number;
        let l!: number;
        let hval: number = seed === undefined ? 0x811c9dc5 : seed;
        for (i = 0, l = str.length; i < l; i++) {
            // eslint-disable-next-line no-bitwise
            hval ^= str.charCodeAt(i);
            // eslint-disable-next-line no-bitwise
            hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
        }
        if (asString) {
            // eslint-disable-next-line no-bitwise
            return ('0000000' + (hval >>> 0).toString(16)).substr(-8);
        }

        // eslint-disable-next-line no-bitwise
        return hval >>> 0;
    }

    public static hash64(str: string) {
        let h1 = Hash.hash32(str, true, 1);

        return `${h1}${Hash.hash32(h1 + str, true, 1)}`;
    }
}
