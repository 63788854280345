import * as Statics from '@shared/core/statics';
import { PAYMENT_PROVIDERS_REQUIRED_BILLING_DETAILS } from '@shared/core/consts';

type BillingDetailsRequireArray = (typeof PAYMENT_PROVIDERS_REQUIRED_BILLING_DETAILS)[number];

export class BillingDetails {
    public static isBillingDetailsSupported(): boolean {
        const config = new Statics.ConfigStatic().current;
        const paymentProvider = config?.payments?.baseProvider;

        return PAYMENT_PROVIDERS_REQUIRED_BILLING_DETAILS.includes(paymentProvider as BillingDetailsRequireArray);
    }
}
