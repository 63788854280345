import { Injectable, Inject } from '@angular/core';
import { Store, select, Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';

import * as Tokens from '@shared/core/tokens';
import * as Services from '@shared/core/services';
import * as Utils from '@shared/core/utils';

import { Observable, of } from 'rxjs';
import { switchMap, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class StateEffects {
    public saveState$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.StateSave),
            withLatestFrom(this._store.pipe(select(selectors.getState))),
            switchMap(([, state]) => {
                /* Fixing date objects - convert to timestamp */
                if (Utils.Storage.set(OLO.Enums.SESSION_STORAGE.STATE, this._cryptoService.encrypt(JSON.stringify(state)), 'sessionStorage')) {
                    return of(actions.StateSaveSuccess());
                }

                return of(actions.StateSaveError());
            }),
        ),
    );

    public restoreSavedState$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.StateRestore),
            switchMap(({ setProps }) => {
                const stateEncrypted: string = Utils.Storage.getItem(OLO.Enums.SESSION_STORAGE.STATE, 'sessionStorage');

                const exit = (error: string) => {
                    Utils.Storage.remove(OLO.Enums.SESSION_STORAGE.STATE, 'sessionStorage');
                    console.error(error);

                    return [];
                };

                if (!stateEncrypted) {
                    return exit("#1 Unable to restore app state! Please check if www to non-www redirects are set properly both on domain and in provider's settings");
                }

                const stateDecrypted: string = this._cryptoService.decrypt(stateEncrypted);
                if (!stateDecrypted) {
                    return exit('#2 `Unable to read app state! There was a problem decrypting state: ' + stateEncrypted);
                }

                Utils.Storage.remove(OLO.Enums.SESSION_STORAGE.STATE, 'sessionStorage');

                try {
                    const state: OLO.State = JSON.parse(stateDecrypted);
                    if (!state) {
                        throw new Error('JSON invalid');
                    }

                    /* Fix dates */
                    if (state.cart.pickupTime) {
                        state.cart.pickupTime.Date = new Date(state.cart.pickupTime.Date);
                        state.cart.pickupTime.PlaceOrderTimeout = new Date(state.cart.pickupTime.PlaceOrderTimeout);
                    }

                    if (state.currentLocation.pickupTime) {
                        state.currentLocation.pickupTime.Date = new Date(state.currentLocation.pickupTime.Date);
                        state.currentLocation.pickupTime.PlaceOrderTimeout = new Date(state.currentLocation.pickupTime.PlaceOrderTimeout);
                    }

                    if (state.availablePickups.length) {
                        state.availablePickups = state.availablePickups.map((obj) => ({
                            ...obj,
                            data: obj?.data.map((pickup) => ({
                                ...pickup,
                                Date: new Date(pickup.Date),
                                PlaceOrderTimeout: new Date(pickup.PlaceOrderTimeout),
                            })),
                        }));
                    }

                    if (setProps !== null) {
                        Object.keys(setProps).forEach((key) => {
                            state[key] = setProps[key];
                        });
                    }

                    return of(actions.StateRestored({ state }));
                } catch (ex) {
                    console.error(ex);

                    return exit('Unable to rebuild app state!');
                }
            }),
        ),
    );

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: OLO.Config,
        private _actions$: Actions,
        private _store: Store<OLO.State>,
        private _cryptoService: Services.CryptoService,
    ) {}
}
