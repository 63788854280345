import { createSelector } from '@ngrx/store';

import { membersState } from '../root';

export const getMemberUpdateStatus = createSelector(membersState, (state) => {
    const {
        update: { isUpdating, updateHasFailed },
    } = state;

    return (updateHasFailed ? 'error' : isUpdating ? 'loading' : null) as Nullable<OLO.Types.REQUEST_STATUS>;
});
