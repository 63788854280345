import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { OnlineOrdersEffects } from './online-order.effects';
import { onlineOrdersReducer } from './online-order.reducer';

@NgModule({
    declarations: [],
    imports: [CommonModule, StoreModule.forFeature('onlineOrder', onlineOrdersReducer), EffectsModule.forFeature([OnlineOrdersEffects])],
    exports: [],
    providers: [],
})
export class OnlineOrderStateModule {}
