import { createSelector } from '@ngrx/store';
import { getOrderTypeId } from '../../../collectionType/selectors';
import { getOnlineMenuVirtualLocationsFiltered } from '../getOnlineMenuVirtualLocationsFiltered';

export const getOnlineMenuVirtualLocationsPagesFiltered = createSelector(
    getOnlineMenuVirtualLocationsFiltered,
    getOrderTypeId,
    (onlineMenuVirtualLocationsFiltered, orderTypeId) =>
        onlineMenuVirtualLocationsFiltered
            ?.filter((virtualLocation) => virtualLocation.orderTypeId === orderTypeId)
            .reduce((allPages, virtualLocation) => {
                if (virtualLocation.data?.Pages) {
                    const extendProductsPages = virtualLocation.data.Pages.map((page) => ({
                        ...page,
                        Products: page.Products.map((product) => ({ ...product, VirtualLocationNo: virtualLocation.virtualLocationNo })),
                    }));

                    return [...allPages, ...extendProductsPages];
                }

                return allPages;
            }, [] as OLO.DTO.OnlineMenuPageResponseModel[]) || null,
);
