import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './cart.actions';
import { CartSimpleItemChangeQuantity } from './cart.actions';

const initialState: OLO.State.Cart = {
    locationNo: null,
    orderTypeId: null,
    pickupTime: null,
    onlineMenu: null,
    onlineMenuVirtualLocations: null,
    itemsMenuFlow: [],
    itemsSimple: [],
    activatedVouchers: [],
    deliveryAddress: null,
};

export const cartReducerFn = createReducer(
    initialState,

    on(actions.CartLoad, (state, action) => {
        let json = action.cart;
        if (!json)
            return {
                ...state,
            };
        /* Convert string dates to object dates */
        if (json.pickupTime && json.pickupTime.Date && json.pickupTime.PlaceOrderTimeout) {
            json = {
                ...json,
                pickupTime: {
                    ...json.pickupTime,
                    Date: new Date(json.pickupTime.Date),
                    PlaceOrderTimeout: new Date(json.pickupTime.PlaceOrderTimeout),
                },
            };
        }

        return { ...json };
    }),
    on(actions.CartSetOnlineMenu, (state, action) => ({
        ...state,
        onlineMenu: JSON.parse(JSON.stringify(action.onlineMenu)),
        onlineMenuVirtualLocations: action.onlineMenuVirtualLocations ? JSON.parse(JSON.stringify(action.onlineMenuVirtualLocations)) : null,
    })),
    on(actions.CartSetPickupTime, (state, action) => ({
        ...state,
        pickupTime: {
            ...action.pickupTime,
        },
    })),
    on(actions.CartSetLocationNo, (state, action) => ({
        ...state,
        locationNo: action.locationNo,
    })),
    on(actions.CartSetOrderTypeId, (state, action) => ({
        ...state,
        orderTypeId: action.orderTypeId,
    })),
    on(actions.CartReset, (state, _action) => ({
        ...state,
        itemsMenuFlow: [],
        itemsSimple: [],
        orderTypeId: null,
        pickupTime: null,
        locationNo: null,
        onlineMenu: null,
        onlineMenuVirtualLocations: null,
    })),
    on(actions.CartRemoveAllItems, (state, _action) => ({
        ...state,
        itemsMenuFlow: [],
        itemsSimple: [],
    })),
    on(actions.CartSimpleItemAdd, (state, action) => {
        let found: boolean = false;
        const newItemsArray = state.itemsSimple.map((obj: OLO.State.Cart.CartSimpleItemExtended) => {
            if (
                obj.Plu === action.item.Plu &&
                obj.UnitPrice === action.item.UnitPrice &&
                obj.SpecialInstructions === action.item.SpecialInstructions &&
                action.item.VirtualLocationNo === obj.VirtualLocationNo
            ) {
                found = true;

                return {
                    ...obj,
                    Quantity: obj.Quantity + action.item.Quantity,
                };
            }

            return obj;
        });

        if (!found) {
            newItemsArray.push({
                ...action.item,
            });
        }

        return {
            ...state,
            itemsSimple: newItemsArray,
        };
    }),
    on(actions.CartSimpleItemUpdate, (state, action) => ({
        ...state,
        itemsSimple: state.itemsSimple.map((item) => {
            if (item._Id === action.item._Id) {
                return {
                    ...item,
                    ...action.item,
                };
            }

            return item;
        }),
    })),
    on(actions.CartSimpleItemRemove, (state, action) => ({
        ...state,
        itemsSimple: state.itemsSimple.filter((item) => item._Id !== action.item._Id),
    })),
    on(actions.CartSimpleItemDecrement, (state, action) => ({
        ...state,
        itemsSimple: state.itemsSimple.map((item) => {
            if (item._Id === action.item._Id) {
                return {
                    ...item,
                    Quantity: item.Quantity - action.changeValue,
                };
            }

            return item;
        }),
    })),
    on(actions.CartSimpleItemIncrement, (state, action) => ({
        ...state,
        itemsSimple: state.itemsSimple.map((item) => {
            if (item._Id === action.item._Id) {
                return {
                    ...item,
                    Quantity: item.Quantity + action.changeValue,
                };
            }

            return item;
        }),
    })),
    on(actions.CartSimpleItemChangeQuantity, (state, action) => ({
        ...state,
        itemsSimple: state.itemsSimple.map((item) => {
            if (item._Id === action.item._Id) {
                return {
                    ...item,
                    Quantity: action.changeValue,
                };
            }

            return item;
        }),
    })),
    on(actions.CartMenuFlowAdd, (state, action) => ({
        ...state,
        itemsMenuFlow: [
            ...state.itemsMenuFlow,
            {
                ...action.item,
            },
        ],
    })),
    on(actions.CartMenuFlowUpdate, (state, action) => ({
        ...state,
        itemsMenuFlow: state.itemsMenuFlow.map((item) => {
            if (item._Id === action.item._Id) {
                return {
                    ...item,
                    ...action.item,
                };
            }

            return item;
        }),
    })),
    on(actions.CartMenuFlowRemove, (state, action) => ({
        ...state,
        itemsMenuFlow: state.itemsMenuFlow.filter((item) => item._Id !== action.item._Id && item.UpsellInitiatorId !== action.item._Id),
    })),
    on(actions.CartMenuFlowDecrement, (state, action) => ({
        ...state,
        itemsMenuFlow: state.itemsMenuFlow.map((item) => {
            if (item._Id === action.item._Id) {
                return {
                    ...item,
                    Quantity: item.Quantity - action.changeValue,
                };
            }

            return item;
        }),
    })),
    on(actions.CartMenuFlowIncrement, (state, action) => ({
        ...state,
        itemsMenuFlow: state.itemsMenuFlow.map((item) => {
            if (item._Id === action.item._Id) {
                return {
                    ...item,
                    Quantity: item.Quantity + action.changeValue,
                };
            }

            return item;
        }),
    })),
    on(actions.CartMenuFlowChangeQuantity, (state, action) => ({
        ...state,
        itemsMenuFlow: state.itemsMenuFlow.map((item) => {
            if (item._Id === action.item._Id) {
                return {
                    ...item,
                    Quantity: action.changeValue,
                };
            }

            return item;
        }),
    })),
    on(actions.CartAddActivatedVoucher, (state, action) => ({
        ...state,
        activatedVouchers: action.payload.ActivatedVouchers,
    })),
    on(actions.CartRemoveActivatedVoucher, (state, _action) => ({
        ...state,
        activatedVouchers: [],
    })),
    on(actions.CartSetDeliveryAddress, (state, action) => ({
        ...state,
        deliveryAddress: action.address,
    })),
);

export function cartReducer(state: OLO.State.Cart | undefined, action: Action) {
    return cartReducerFn(state, action);
}
