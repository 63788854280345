import { OrderPaymentMethod, OrderPaymentDetails } from './order-payment-method';

export class OrderPaymentWithAdyen extends OrderPaymentMethod {
    public async generatePaymentDetails(details: OrderPaymentDetails) {
        const { paymentProvider, cardState, paymentMethod } = details;
        const cardDetails = this._getCardDetails(cardState);

        if (!this._isVendorPayment(paymentMethod) && paymentProvider === OLO.Enums.PAYMENT_PROVIDER.ADYEN) {
            this._extendExecutePaymentModel(details);

            this._executePaymentModel.CardData = {
                EncryptedCardNumber: cardDetails?.AdyenPaymentData?.encryptedCardNumber || null,
                EncryptedExpiryMonth: cardDetails?.AdyenPaymentData?.encryptedExpiryMonth || null,
                EncryptedExpiryYear: cardDetails?.AdyenPaymentData?.encryptedExpiryYear || null,
                EncryptedSecurityCode: cardDetails?.AdyenPaymentData?.encryptedSecurityCode || null,
                SaveForFuture: cardDetails?.SaveAwait || false,
            };

            if (cardState.activeCardToken) {
                this._executePaymentModel.Token = null;
                this._executePaymentModel.PaymentAccountId = null;
            }

            if (cardState.activeCardId) {
                this._executePaymentModel.CardData = {
                    EncryptedSecurityCode: cardDetails?.AdyenPaymentData?.encryptedSecurityCode || null,
                };
            }

            return this._executePaymentModel;
        }

        if (this._next) {
            return this._next.generatePaymentDetails(details);
        }

        return this._executePaymentModel;
    }
}
