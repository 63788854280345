import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './google.actions';

const initialState: OLO.State.Google = {
    hasInit: false,
    places: {
        isDownloading: false,
        hasSucceeded: false,
        hasFailed: false,
        search: null,
        downloadedDate: null,
        data: null,
    },
    geocoder: {
        isDownloading: false,
        hasSucceeded: false,
        hasFailed: false,
        location: null,
        downloadedDate: null,
        data: null,
    },
};

export const googleReducerFn = createReducer<OLO.State.Google>(
    initialState,
    on(actions.GooglePlaceSearchRequest, (state, action) => ({
        ...state,
        places: {
            ...state.places,
            isDownloading: true,
            hasSucceeded: false,
            hasFailed: false,
            search: action.search,
        },
    })),
    on(actions.GooglePlaceSearchSuccessRequest, (state, action) => ({
        ...state,
        places: {
            ...state.places,
            isDownloading: false,
            hasSucceeded: true,
            hasFailed: false,
            downloadedDate: Date.now(),
            data: action.payload.map((place) => ({
                isDownloading: false,
                hasSucceeded: false,
                downloadedDate: null,
                hasFailed: false,
                data: place,
            })),
        },
    })),
    on(actions.GooglePlaceSearchErrorRequest, (state, _action) => ({
        ...state,
        places: {
            ...state.places,
            isDownloading: false,
            hasSucceeded: false,
            hasFailed: true,
        },
    })),
    on(actions.GooglePlaceDetailsRequest, (state, action) => ({
        ...state,
        places: {
            ...state.places,
            data: state.places.data.map((place) => {
                if (action.placeId === place.data.place_id) {
                    return {
                        ...place,
                        isDownloading: true,
                        hasSucceeded: false,
                        hasFailed: false,
                    };
                }

                return place;
            }),
        },
    })),
    on(actions.GooglePlaceDetailsSuccessRequest, (state, action) => ({
        ...state,
        places: {
            ...state.places,
            data: state.places.data.map((place) => {
                if (action.placeId === place.data.place_id) {
                    return {
                        ...place,
                        isDownloading: false,
                        hasSucceeded: true,
                        hasFailed: false,
                        downloadedDate: Date.now(),
                        data: {
                            Id: place.data.place_id,
                            Name: place.data.description,
                            ...place.data,
                            details: action.payload,
                            geometry: action.payload?.geometry?.location?.toJSON() || null,
                        } as APICommon.GooglePlaceDetails,
                    };
                }

                return place;
            }),
        },
    })),
    on(actions.GooglePlaceDetailsErrorRequest, (state, action) => ({
        ...state,
        places: {
            ...state.places,
            data: state.places.data.map((place) => {
                if (action.placeId === place.data.place_id) {
                    return {
                        ...place,
                        Id: place.data.place_id,
                        Name: place.data.description,
                        isDownloading: false,
                        hasSucceeded: false,
                        hasFailed: true,
                    };
                }

                return place;
            }),
        },
    })),
    on(actions.GoogleGeocoderRequest, (state, action) => ({
        ...state,
        geocoder: {
            ...state.geocoder,
            isDownloading: true,
            hasSucceeded: false,
            hasFailed: false,
            location: action.location,
        },
    })),
    on(actions.GoogleGeocoderSuccessRequest, (state, action) => ({
        ...state,
        geocoder: {
            ...state.geocoder,
            isDownloading: false,
            hasSucceeded: true,
            hasFailed: false,
            downloadedDate: Date.now(),
            data: action.payload,
        },
    })),
    on(actions.GoogleGeocoderErrorRequest, (state, _action) => ({
        ...state,
        geocoder: {
            ...state.geocoder,
            isDownloading: false,
            hasSucceeded: false,
            hasFailed: true,
        },
    })),
    on(actions.GoogleInitSuccess, (state, _action) => ({
        ...state,
        hasInit: true,
    })),
);

export function googleReducer(state: OLO.State.Google | undefined, action: Action) {
    return googleReducerFn(state, action);
}
