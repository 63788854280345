import { ConfigStatic } from '@shared/core/statics';

export class DynamicPages {
    private static _setMissingPropsFromDefaults<T extends number, K extends Unpacked<OLO.Config.IterablePageConfigsUnion>>(page: T, defaultPage: K): T | K {
        if (!page) {
            // Unlock object and drop references to original config object
            return JSON.parse(JSON.stringify(defaultPage));
        }

        const editablePage = JSON.parse(JSON.stringify(page));

        Object.keys(defaultPage).forEach((pageProp) => {
            const pagePropExist = editablePage.hasOwnProperty(pageProp);
            const isDefault = !pagePropExist || editablePage[pageProp] === -1;
            if (isDefault) {
                editablePage[pageProp] = defaultPage[pageProp];
            }
        });

        return editablePage;
    }

    public static getCurrentPageSetup<T extends OLO.Config.IterablePageConfigsUnion>(pagesConfig: T & Array<any>, orderTypeId: number): Unpacked<T> {
        const pageByOrderTypeId = pagesConfig.find((page) => page.orderTypeIds.includes(orderTypeId));
        const defaultPage = pagesConfig.find((page) => !page.orderTypeIds.length);

        return DynamicPages._setMissingPropsFromDefaults(pageByOrderTypeId, defaultPage);
    }

    public static customPageSetup<T extends keyof OLO.Config.IterableNonArrayPageConfigs, K extends OLO.Config.IterableNonArrayPageConfigs[T]>(
        pageName: T,
        orderTypeId: Nullable<number> = null,
    ) {
        const config = new ConfigStatic().current[pageName];

        return DynamicPages.getCurrentPageSetup(config, orderTypeId) as K;
    }
}
