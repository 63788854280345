import { createSelector } from '@ngrx/store';

import { getCartLocationFriendlyName } from '../../cart';
import { getCurrentLocationDetails } from '../getCurrentLocationDetails';
import { routeIsLocationDetailsPage } from '../../routes';

export const getActiveLocationFriendlyNameFromCartOrCurrentLocation = () =>
    createSelector(getCartLocationFriendlyName, getCurrentLocationDetails, routeIsLocationDetailsPage(), (nameFromCart, currentLocation, isLocationDetailsPage) => {
        if (nameFromCart) return nameFromCart;
        if (!isLocationDetailsPage || !currentLocation) return null;

        return currentLocation.LocationFriendlyName || null;
    });
