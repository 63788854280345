import { createAction, props } from '@ngrx/store';
import { CollectionTypeParams } from './collection-type.models';

export const SetCollectionType = createAction('[Collection type] Set data', props<CollectionTypeParams & { previousOrderTypeId?: Nullable<number> }>());

export const PatchOrderTypeIdCollectionTypeValue = createAction('[Collection type] Patch order type id data', props<{ orderTypeId?: number }>());

export const PatchAddressCollectionTypeValue = createAction('[Collection type] Patch address data', props<{ address?: APICommon.GooglePlaceDetails }>());

export const PatchTableNoCollectionTypeValue = createAction('[Collection type] Patch table no data', props<{ tableNo?: string }>());
