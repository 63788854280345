import { createAction, props } from '@ngrx/store';

export const LocationsRequest = createAction('[Locations] Request locations', (params: APICommon.TerminalsOnlineOrderingLocationsGetParams = {}) => ({ params }));

export const HiddenLocationsRequest = createAction('[Locations] Request hidden locations');

export const HiddenLocationsSuccessRequest = createAction('[Locations] Success reueqst hidden locations', props<{ payload: OLO.DTO.HiddenLocationModel[] }>());

export const HiddenLocationsErrorRequest = createAction('[Locations] Success reueqst hidden locations', props<{ ex: any }>());

export const LocationsSuccessRequest = createAction(
    '[Locations] Success request locations',
    props<{ params: APICommon.TerminalsOnlineOrderingLocationsGetParams; payload: OLO.DTO.LocationBusinessModel[] }>(),
);

export const LocationsErrorRequest = createAction('[Locations] Error request locations', (params: APICommon.TerminalsOnlineOrderingLocationsGetParams, ex: any = null) => ({
    ex,
    params,
}));

export const LocationRequest = createAction('[Locations] Request one location', (params: APICommon.TerminalsOnlineOrderingLocationsGetParams = {}) => ({ params }));

export const LocationSuccessRequest = createAction(
    '[Locations] Success request one location',
    props<{ params: APICommon.TerminalsOnlineOrderingLocationsGetParams; payload: OLO.DTO.OnlineOrderingLocationBusinessModel[] }>(),
);

export const LocationErrorRequest = createAction('[Locations] Error request one location', (params: APICommon.TerminalsOnlineOrderingLocationsGetParams, ex: any = null) => ({
    ex,
    params,
}));
