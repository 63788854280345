import { Pickups } from '../pickups.utils';
import { Items } from '../items.utils';
import { Dates } from '../dates.utils';
import { ConfigStatic } from '@shared/core/statics';

import { CollectionTypeHelper } from '../collection-type-helper.utils';
import { LocationPickups } from '../locations';

export class OnlineOrders {
    public static hasOrderTypePristineDisclaimerFields(orderType: APICommon.OrderTypeExtended, disclaimers: boolean = false): boolean {
        if (!orderType) return false;
        const totalDisclaimers = orderType.Disclaimers?.length || 0;
        const hasDisclaimers = totalDisclaimers > 0;
        const pristineOptionalDisclaimers = orderType.Disclaimers?.filter((obj) => obj.IsRequired !== true && typeof obj._Value !== 'boolean');

        return disclaimers && hasDisclaimers && pristineOptionalDisclaimers?.length > 0;
    }

    public static hasOrderTypeVisibleFields(orderType: APICommon.OrderTypeExtended, disclaimers: boolean = false): boolean {
        if (!orderType) return false;
        const totalDisclaimers = orderType.Disclaimers?.length || 0;
        const hasDisclaimers = totalDisclaimers > 0;

        return disclaimers && hasDisclaimers;
    }

    public static hasOrderTypePristineDetailsFields(orderType: APICommon.OrderTypeExtended): boolean {
        if (!orderType) return false;

        const pristineDetails = orderType.Details?.filter((obj) => !obj.hasOwnProperty('_Value'));

        return pristineDetails?.length > 0;
    }

    public static isOrderTypeDisclaimerValid(orderType: APICommon.OrderTypeExtended, disclaimers: boolean = false): boolean {
        const totalDisclaimers = orderType.Disclaimers?.length || 0;
        const hasDisclaimers = totalDisclaimers > 0;
        const requiredDisclaimers = orderType.Disclaimers?.filter((obj) => obj.IsRequired === true);
        const optionalDisclaimers = orderType.Disclaimers?.filter((obj) => obj.IsRequired !== true);
        const pristineDisclaimers = orderType.Disclaimers?.filter((obj) => obj._Value === null);
        const allDisclaimersAreUnset = hasDisclaimers ? pristineDisclaimers.length === totalDisclaimers : false;
        const hasPristineRequiredDisclaimers = requiredDisclaimers?.length ? requiredDisclaimers.find((obj) => obj._Value !== true) : false;
        const hasOnlyOptionalDisclaimersAndAllUnset = hasDisclaimers && optionalDisclaimers?.length === totalDisclaimers && allDisclaimersAreUnset ? true : false;
        if (!disclaimers || !hasDisclaimers || hasOnlyOptionalDisclaimersAndAllUnset) return true;

        if (allDisclaimersAreUnset || hasPristineRequiredDisclaimers) return false;

        return true;
    }

    public static detectOrderCollectionTypeGroup(order: OLO.DTO.OnlineOrderDetailedBusinessModel): OLO.Enums.COLLECTION_TYPE {
        const config = new ConfigStatic().current;

        if (!order) return null;
        const collectionTypeConfig = new CollectionTypeHelper(config.collectionTypes);
        const isDineIn =
            order.OrderTypeId === collectionTypeConfig.getDineInCollectionTypeConfig()?.dineInBuzzer?.orderTypeId ||
            order.OrderTypeId === collectionTypeConfig.getDineInCollectionTypeConfig()?.dineInTable?.orderTypeId;

        if (isDineIn) return OLO.Enums.COLLECTION_TYPE.DINE_IN;

        const isDelivery = collectionTypeConfig.getDeliveryCollectionTypeConfig()?.orderTypeIds.includes(order.OrderTypeId);
        if (isDelivery) return OLO.Enums.COLLECTION_TYPE.DELIVERY;

        const isCatering = collectionTypeConfig.getCateringCollectionTypeConfig()?.orderTypeIds.includes(order.OrderTypeId);
        if (isCatering) return OLO.Enums.COLLECTION_TYPE.CATERING;

        return OLO.Enums.COLLECTION_TYPE.PICKUP;
    }

    /**
     * Checks the group of provided order id
     *
     * @param {number} orderTypeId to check
     * @param {OLO.Config} config to get info about order type ids
     * @returns {OLO.Enums.COLLECTION_TYPE} collection type id
     */
    public static detectOrderTypeIdCollectionTypeGroup(orderTypeId: number, config: OLO.Config): OLO.Enums.COLLECTION_TYPE {
        if (!orderTypeId) return null;

        const collectionTypeConfig = new CollectionTypeHelper(config.collectionTypes);
        const isDineIn =
            orderTypeId === collectionTypeConfig.getDineInCollectionTypeConfig()?.dineInBuzzer?.orderTypeId ||
            orderTypeId === collectionTypeConfig.getDineInCollectionTypeConfig()?.dineInTable?.orderTypeId;
        if (isDineIn) return OLO.Enums.COLLECTION_TYPE.DINE_IN;

        const isDelivery = collectionTypeConfig.getDeliveryCollectionTypeConfig()?.orderTypeIds.includes(orderTypeId);
        if (isDelivery) return OLO.Enums.COLLECTION_TYPE.DELIVERY;

        const isCatering = collectionTypeConfig.getCateringCollectionTypeConfig()?.orderTypeIds.includes(orderTypeId);
        if (isCatering) return OLO.Enums.COLLECTION_TYPE.CATERING;

        return OLO.Enums.COLLECTION_TYPE.PICKUP;
    }

    /**
     * Check if GROUP of two orderTypeIds is the same i.e. based on the config, check if both are DINE IN types or PICKUP TYPES
     *
     * @param {number} orderTypeId
     * @param {number} orderTypeId2
     * @returns {boolean}
     */
    public static orderTypesGroupMatch(orderTypeId: number, orderTypeId2: number, config: OLO.Config): boolean {
        return OnlineOrders.detectOrderTypeIdCollectionTypeGroup(orderTypeId, config) === OnlineOrders.detectOrderTypeIdCollectionTypeGroup(orderTypeId2, config);
    }

    public static extendWithOrderTypeDisclaimers(
        onlineOrder: OLO.DTO.OnlineOrderDetailedBusinessModel,
        orderType: APICommon.OrderTypeExtended,
    ): OLO.DTO.OnlineOrderDetailedBusinessModel {
        const baseModel: OLO.DTO.OnlineOrderDetailedBusinessModel = {
            OrderTypeId: orderType?.Id || onlineOrder.OrderTypeId || null,
            OrderTypeDisclaimers: orderType?.Disclaimers?.reduce(
                (acc, obj) => [
                    ...acc,
                    {
                        Id: null,
                        OrderId: null,
                        OrderDisclaimerDefinitionId: obj.Id,
                        IsAccepted: obj._Value,
                        DisplayIndex: obj.DisplayIndex,
                        CustomerFriendlyName: obj.CustomerFriendlyName,
                        CustomerFriendlyDescription: obj.CustomerFriendlyDescription,
                    },
                ],
                [],
            ),
            OrderTypeDetails:
                orderType?.Details?.reduce((acc, obj) => {
                    if (!obj._Value) return acc;

                    return [
                        ...acc,
                        {
                            DetailsDefinitionId: obj.Id,
                            ValueProvided: obj._Value || null,
                        },
                    ];
                }, []) || null,
            Items: [...(onlineOrder?.Items || [])],
            Medias: [...(onlineOrder?.Medias || [])],
        };

        return baseModel;
    }

    public static extendOnlineOrderWithVirtualLocations(
        cart: OLO.State.Cart,
        virtualLocations: OLO.DTO.VirtualLocationBusinessModel[],
    ): APIv3_SNAPSHOT.OnlineOrderVirtualLocationModel[] {
        const onlineOrderVirtualLocations = [...cart.itemsSimple, ...cart.itemsMenuFlow].reduce((onlineLocations, cartItem) => {
            if (cartItem.VirtualLocationNo == null) {
                return onlineLocations;
            }
            const virtualLocation = virtualLocations.find((v) => v.LocationNo === cartItem.VirtualLocationNo);
            const isOnlineLocation = virtualLocation?.LocationClassification === OLO.Enums.LOCATION_CLASSIFICATION.ONLINE_LOCATION;

            if (isOnlineLocation) {
                /* For online locations there will be always one item array */
                if (onlineLocations.length === 0) {
                    const onlineOrderVirtualLocation: APIv3_SNAPSHOT.OnlineOrderVirtualLocationModel = {
                        LocationNo: virtualLocation.LocationNo,
                        LocationClassification: virtualLocation.LocationClassification,
                    };

                    return [onlineOrderVirtualLocation];
                }
            }

            return onlineLocations;
        }, []);

        return onlineOrderVirtualLocations.length ? onlineOrderVirtualLocations : null;
    }

    public static onlineOrderModelFix(order: OLO.DTO.OnlineOrderDetailedBusinessModel): OLO.DTO.OnlineOrderDetailedBusinessModel {
        order.Items = order.Items.filter((item) => {
            const foundMenuFlowActivation = order.MenuFlowActivations.some((menuFlow) => menuFlow.MenuFlowItems.some((menuFlowItem) => menuFlowItem.OnlineOrderItemId === item.Id));

            const foundSurcharge = order.Surcharges.some((surcharge) => surcharge.PLU === item.PLU);

            return !foundMenuFlowActivation && !foundSurcharge;
        });

        return order;
    }

    public static isOrderActive(status: OLO.Enums.ONLINE_ORDER_STATUS): boolean {
        return status >= OLO.Enums.ONLINE_ORDER_STATUS.VALIDATED && status < OLO.Enums.ONLINE_ORDER_STATUS.FINALIZED;
    }

    public static isOrderFinalized(status: OLO.Enums.ONLINE_ORDER_STATUS): boolean {
        return status === OLO.Enums.ONLINE_ORDER_STATUS.FINALIZED;
    }

    public static canOrderFromVirtualLocation(cart: OLO.State.Cart, cartActionVirtualLocations: OLO.DTO.VirtualLocationBusinessModel[], virtualLocationNo: number): boolean {
        const virtualLocation = cartActionVirtualLocations.find((v) => v.LocationNo === virtualLocationNo);
        const isOnlineLocation = virtualLocation?.LocationClassification === OLO.Enums.LOCATION_CLASSIFICATION.ONLINE_LOCATION;

        if (isOnlineLocation) {
            const checkSameLocation = (item: OLO.State.Cart.CartMenuFlow | OLO.State.Cart.CartSimpleItem) =>
                !item.VirtualLocationNo || item.VirtualLocationNo === virtualLocation.LocationNo;
            const hasItemsInCartFromSameOnlineLocation = [...cart.itemsSimple, ...cart.itemsMenuFlow].every(checkSameLocation);

            return hasItemsInCartFromSameOnlineLocation;
        }

        return true;
    }

    public static canOrder(
        location: OLO.DTO.OnlineOrderingLocationBusinessModel,
        isScheduledOrderingEnabled: boolean,
        asapPickupMins: number,
        openingHours: OLO.DTO.OpeningHoursModel[],
        orderTimeoutBufferMins: number,
        startBufferMins: number,
        orderTypeId: number,
    ): boolean {
        const pickupForToday: OLO.Ordering.PickupForLocation = Pickups.calcInitialTimesObj({
            locationNo: location.LocationNo,
            asapPickupMins,
            openingHours,
            orderTimeoutBufferMins,
            startBufferMins,
        });

        const pickupTimeList = LocationPickups.getAvailablePickupTimesWithFutureForLocation({
            location,
            orderTypeId: orderTypeId,
            futureOrders: isScheduledOrderingEnabled,
        });

        if (pickupForToday === null || pickupTimeList?.length === 0) {
            if (isScheduledOrderingEnabled && openingHours && openingHours.length > 0) {
                return true;
            }

            return false;
        }

        return pickupForToday.MinimumPickupTime >= pickupForToday.OpeningTime && pickupForToday.MaximumPickupTime <= pickupForToday.ClosingTime;
    }

    public static unduplicateOrderItems(order: OLO.DTO.OnlineOrderDetailedBusinessModel): OLO.DTO.OnlineOrderDetailedBusinessModel {
        if (!order) return null;
        const newOrder: OLO.DTO.OnlineOrderDetailedBusinessModel = JSON.parse(JSON.stringify(order));
        const menuFlowProducts: number[] = [];

        newOrder.MenuFlowActivations.forEach((MenuFlow) => MenuFlow.MenuFlowItems.forEach((item) => menuFlowProducts.push(item.OnlineOrderItemId)));
        newOrder.Items = newOrder.Items.filter((item) => !menuFlowProducts.includes(item.Id));

        return newOrder;
    }

    public static generateDescriptionForMenuFlowActivationItem(menuFlowActivation: OLO.DTO.OnlineOrderMenuFlowActivationModel): string {
        return menuFlowActivation.MenuFlowItems.reduce((acc, item) => {
            if (item.IsHiddenFromUser) return acc;

            let qty = '';
            let prefix = '';
            if (acc) {
                prefix = ', ';
            }
            if (item.Quantity > 1) {
                qty = `${item.Quantity}x `;
            }

            let modifiersDescription: string = item.IngredientsChanges.IngredientsModified.reduce(
                (accM, itemM, indexM) => (accM += `${indexM && accM ? ', ' : ''}${itemM.ModifierName}`),
                '',
            );

            return (acc += `${prefix}${qty}${item.DisplayName}${modifiersDescription ? `(${modifiersDescription})` : ''}`);
        }, '').replace(/\s{1,},/g, ',');
    }

    public static generateDescriptionForTransactionMenuFlowActivationItem(menuFlowActivation: OLO.DTO.LoyaltyAppTransactionMenuFlow): string {
        return menuFlowActivation.MenuFlowProducts.reduce((acc, item) => {
            let qty = '';
            let prefix = '';
            if (acc) {
                prefix = ', ';
            }
            if (item.Quantity > 1) {
                qty = `${item.Quantity}x `;
            }

            let modifiersDescription: string = '';

            return (acc += `${prefix}${qty}${item.POSDisplay}${modifiersDescription ? `(${modifiersDescription})` : ''}`);
        }, '').replace(/\s{1,},/g, ',');
    }

    public static generateDescriptionFromTransaction(transaction: OLO.DTO.LoyaltyAppTransactionModel): string {
        if (!transaction) return null;

        let description = '';

        const descBuilder = (obj: OLO.DTO.LoyaltyAppTransactionMenuFlow & OLO.DTO.LoyaltyAppTransactionProduct) => {
            if (obj.ProductID === -99999) return;
            let prefix = '';
            let qty = '';
            if (!description) {
                prefix = '';
            } else {
                prefix = ', ';
            }
            if (obj.Quantity > 1) {
                qty = `${(obj as any).Quantity}x `;
            }

            description += `${prefix}${qty}${obj.CustomerFriendlyName || obj.MenuFlowDescription || obj.POSDisplay}`;
        };

        transaction.TransactionMenuFlows.forEach(descBuilder);
        transaction.TransactionProducts.forEach(descBuilder);

        return description.replace(/\s{1,},/g, ',');
    }

    public static generateDescriptionFromOrder(order: OLO.DTO.OnlineOrderDetailedBusinessModel): string {
        if (!order) return null;

        const fixedOrder = OnlineOrders.unduplicateOrderItems(order);
        let description = '';

        const descBuilder = (obj) => {
            let prefix = '';
            let qty = '';
            if (!description) {
                prefix = '';
            } else {
                prefix = ', ';
            }
            if (obj.Quantity > 1) {
                qty = `${obj.Quantity}x `;
            }

            description += `${prefix}${qty}${obj.DisplayName}`;
        };

        fixedOrder.MenuFlowActivations.forEach(descBuilder);
        fixedOrder.Items.forEach(descBuilder);

        return description.replace(/\s{1,},/g, ',');
    }

    private static _extendIngredients(
        ingredients: APICommon.ProductLocationIngredientExtended[],
        ingredient: OLO.DTO.OnlineOrderItemIngredientModificationModel,
        errors: OLO.Errors.Order2CartConvertErrors,
        menuFlowActivation: OLO.DTO.OnlineOrderMenuFlowActivationModel,
        menuFlowProduct: OLO.DTO.OnlineOrderMenuFlowItemModel,
        baseMenuFlowProduct: OLO.DTO.MenuFlowProduct,
        order: OLO.DTO.OnlineOrderDetailedBusinessModel,
    ): APICommon.IngredientModifierExtended {
        const freashIngredient: APICommon.ProductLocationIngredientExtended = ingredients.find((obj) => obj.PLU === menuFlowProduct.PLU);

        if (!freashIngredient) {
            errors.menuFlows.push({
                errorId: new Date().getTime() + Math.floor(Math.random() * 1000000),
                menuFlowId: menuFlowActivation.Id,
                error: `Unable to find coresponding ingredient ${ingredient.IngredientPLU} for product ${menuFlowProduct.Id}`,
            });
        }

        let modifier: APICommon.IngredientModifierExtended;
        if (freashIngredient) {
            modifier = freashIngredient.Ingredients[0].Modifiers.find((obj) => obj.ModifierID === ingredient.ModifierID);

            if (!modifier) {
                errors.menuFlows.push({
                    errorId: new Date().getTime() + Math.floor(Math.random() * 1000000),
                    menuFlowId: menuFlowActivation.Id,
                    error: `Unable to find coresponding ingredient modifier ${ingredient.ID} ${ingredient.ModifierName} for product ${menuFlowProduct.Id}`,
                });
            }
        }

        return {
            ...ingredient,
            ...modifier,
            _IsOptional: modifier ? modifier._IsOptional : null,
            _ProductId: baseMenuFlowProduct.ProductId,
            _LocationNo: order.PickupLocation,
            _ProductPLU: baseMenuFlowProduct.Plu,
            ModifierName: modifier ? modifier.ModifierName : null,
        };
    }

    private static _dealWithMenuFlow(
        menuFlowActivations: OLO.DTO.OnlineOrderMenuFlowActivationModel[],
        errors: OLO.Errors.Order2CartConvertErrors,
        allOnlineMenu: OLO.DTO.OnlineMenuResponseModel[],
        menuFlowsDetails: OLO.DTO.MenuFlowDetailsModel[],
        order: OLO.DTO.OnlineOrderDetailedBusinessModel,
        ingredients: APICommon.ProductLocationIngredientExtended[],
        fixMenuFlowActivationsDescriptions: boolean,
    ): OLO.State.Cart.CartMenuFlow[] {
        return menuFlowActivations.map((menuFlowActivation) => {
            /* Get relevant online menu page and menu flow details for current menu flow activation */
            const onlineMenu = allOnlineMenu?.find((menu) => {
                const menuFlowVirtualLocation = menuFlowActivation.VirtualLocations;
                const menuVirtualLocationNo = menu._VirtualLocationNo;
                const itemNotVirtualLocation = !menuFlowVirtualLocation || (menuFlowVirtualLocation?.length === 0 && !menuVirtualLocationNo);
                const itemVirtualLocation = !!menuFlowVirtualLocation?.length && menuFlowVirtualLocation?.[0]?.LocationNo === menuVirtualLocationNo;
                const wholeOrderHasAssignedVirtualLocation = !!menuVirtualLocationNo && order.VirtualLocations?.[0]?.LocationNo === menuVirtualLocationNo;

                return itemNotVirtualLocation || itemVirtualLocation || wholeOrderHasAssignedVirtualLocation;
            });
            const baseOnlineMenuPage: OLO.DTO.OnlineMenuProductResponseModel = onlineMenu?.Pages.reduce<OLO.DTO.OnlineMenuProductResponseModel>((acc, page) => {
                if (acc) return acc;

                const foundCorespondingMenuFlow = page.Products.find((obj) => obj.MenuFlowId === menuFlowActivation.MenuFlowId);
                if (foundCorespondingMenuFlow) {
                    return foundCorespondingMenuFlow;
                }

                return acc;
            }, null);

            const baseMenuFlowDetails: OLO.DTO.MenuFlowDetailsModel = menuFlowsDetails.find((obj) => obj.MenuFlowId === menuFlowActivation.MenuFlowId && obj.IsActive === true);

            const isMenuFlowUpsell: boolean = menuFlowActivation.IsUpsell === true && baseOnlineMenuPage === null;
            if (!isMenuFlowUpsell) {
                if (!baseOnlineMenuPage) {
                    errors.menuFlows.push({
                        errorId: new Date().getTime() + Math.floor(Math.random() * 1000000),
                        menuFlowId: menuFlowActivation.Id,
                        error: 'unable to find coresponding online menu page for menu flow activation item',
                    });
                }

                if (baseOnlineMenuPage && baseOnlineMenuPage.State !== 0) {
                    errors.menuFlows.push({
                        errorId: new Date().getTime() + Math.floor(Math.random() * 1000000),
                        menuFlowId: menuFlowActivation.Id,
                        error: `menuFlow (${baseOnlineMenuPage.MenuFlowId}) is currently unavailable - state property is not 0 (${baseOnlineMenuPage.State})`,
                    });
                }
            }

            if (!baseMenuFlowDetails) {
                errors.menuFlows.push({
                    errorId: new Date().getTime() + Math.floor(Math.random() * 1000000),
                    menuFlowId: menuFlowActivation.Id,
                    error: 'unable to find coresponding menu flow in online menu',
                });
            }

            /* Restore pages container */
            const generatedPages: OLO.State.Cart.CartMenuFlowPage[] = baseMenuFlowDetails
                ? Items.createMenuFlowItemPagesFromMenuFlowDetailsModel<OLO.State.Cart.CartMenuFlowPageProduct>(baseMenuFlowDetails)
                : [];

            menuFlowActivation.MenuFlowItems.forEach((menuFlowProduct) => {
                const basePageForProduct: OLO.DTO.MenuFlowPageModel = baseMenuFlowDetails?.Pages.find((obj) => obj.PageIdentifier === menuFlowProduct.MenuFlowPageId);
                /* Validate page params */
                if (!basePageForProduct) {
                    errors.menuFlows.push({
                        errorId: new Date().getTime() + Math.floor(Math.random() * 1000000),
                        menuFlowId: menuFlowActivation.Id,
                        error: `unable to find coresponding menu flow page (${menuFlowProduct.MenuFlowPageId}) in menu flow details`,
                    });

                    return;
                }

                const baseMenuFlowProduct: OLO.DTO.MenuFlowProduct = basePageForProduct?.Products.find((product) => product.Plu === menuFlowProduct.PLU);
                /* Validate product params */
                if (!baseMenuFlowProduct) {
                    errors.menuFlows.push({
                        errorId: new Date().getTime() + Math.floor(Math.random() * 1000000),
                        menuFlowId: menuFlowActivation.Id,
                        error: `unable to find coresponding menu flow product (${menuFlowProduct.PLU}) in menu flow details`,
                    });

                    return;
                }

                if (baseMenuFlowProduct && baseMenuFlowProduct.State !== 0) {
                    errors.menuFlows.push({
                        errorId: new Date().getTime() + Math.floor(Math.random() * 1000000),
                        menuFlowId: menuFlowActivation.Id,
                        error: `product (${menuFlowProduct.PLU}) is currently unavailable - state property is not 0 (${baseMenuFlowProduct.State})`,
                    });

                    return;
                }

                /* Fetch page */
                const pageToFetch = generatedPages.find((obj) => obj.PageIdentifier === basePageForProduct.PageIdentifier);
                if (!pageToFetch) {
                    errors.menuFlows.push({
                        errorId: new Date().getTime() + Math.floor(Math.random() * 1000000),
                        menuFlowId: menuFlowActivation.Id,
                        error: `Unable to find page in generated pages - looking for ${basePageForProduct.PageIdentifier}
                        in menuFlow ${menuFlowProduct.OnlineOrderMenuFlowActivationId}
                        for product ${menuFlowProduct.DisplayName} with PLU ${menuFlowProduct.PLU}`,
                    });

                    return;
                }

                const productForPage = {
                    ...baseMenuFlowProduct,
                    ...(menuFlowProduct && (menuFlowProduct as any)),
                    IngredientsChanges: {
                        ...(menuFlowProduct.IngredientsChanges as OLO.Ordering.MenuFlowItemPageProductIngredientChanges),
                        IngredientsModified: menuFlowProduct.IngredientsChanges.IngredientsModified.map((ingredient) =>
                            this._extendIngredients(ingredients, ingredient, errors, menuFlowActivation, menuFlowProduct, baseMenuFlowProduct, order),
                        ),
                        IngredientsAdded: menuFlowProduct.IngredientsChanges.IngredientsAdded.map((ingredient) =>
                            this._extendIngredients(ingredients, ingredient, errors, menuFlowActivation, menuFlowProduct, baseMenuFlowProduct, order),
                        ),
                    },
                };

                pageToFetch.Products.push(productForPage);
            });

            const newMenuFlowActivation: OLO.State.Cart.CartMenuFlowExtended = Items.createMenuFlowItemFromOnlineOrder(menuFlowActivation, baseMenuFlowDetails, {
                LocationNo: order.PickupLocation,
                _IsDisabled: !!errors.menuFlows.length && errors.menuFlows.some((error) => error.menuFlowId === menuFlowActivation.Id),
                _Id: menuFlowActivation.Id,
                IsUpsell: menuFlowActivation.IsUpsell || false,
                Pages: generatedPages,
                DisplayDescription: fixMenuFlowActivationsDescriptions ? OnlineOrders.menuFlowActivationItemsDescription(menuFlowActivation).DisplayDescription : null,
                VirtualLocationNo: menuFlowActivation.VirtualLocations?.[0]?.LocationNo || order.VirtualLocations?.[0]?.LocationNo,
            });

            return baseMenuFlowDetails ? Items.updateMenuFlowItemPrices(baseMenuFlowDetails, newMenuFlowActivation) : newMenuFlowActivation;
        });
    }

    private static _dealWithSimpleProduct(
        order: OLO.DTO.OnlineOrderDetailedBusinessModel,
        allOnlineMenu: OLO.DTO.OnlineMenuResponseModel[],
        errors: OLO.Errors.Order2CartConvertErrors,
    ): OLO.State.Cart.CartSimpleItem[] {
        return order.Items.reduce((acc, item) => {
            let baseProduct: OLO.DTO.OnlineMenuProductResponseModel;
            const onlineMenu = allOnlineMenu.find((menu) => {
                const itemVirtualLocationInfo = item.VirtualLocations;
                const menuVirtualLocationNo = menu._VirtualLocationNo;
                const itemNotVirtualLocation = !itemVirtualLocationInfo || (itemVirtualLocationInfo?.length === 0 && !menuVirtualLocationNo);
                const itemVirtualLocation = !!itemVirtualLocationInfo?.length && itemVirtualLocationInfo?.[0]?.LocationNo === menuVirtualLocationNo;
                const wholeOrderHasAssignedVirtualLocation = order.VirtualLocations?.[0]?.LocationNo === menuVirtualLocationNo;

                return itemNotVirtualLocation || itemVirtualLocation || wholeOrderHasAssignedVirtualLocation;
            });
            const baseOnlineMenuPage: OLO.DTO.OnlineMenuPageResponseModel = onlineMenu?.Pages.reduce<OLO.DTO.OnlineMenuPageResponseModel>((innerAcc, page) => {
                if (innerAcc) return innerAcc;
                baseProduct = page.Products.find((obj) => obj.Plu === item.PLU);
                if (baseProduct) {
                    innerAcc = page;
                }

                return innerAcc;
            }, null);
            if (!baseOnlineMenuPage) {
                errors.simpleItems.push({
                    errorId: new Date().getTime() + Math.floor(Math.random() * 1000000),
                    error: `Unable to find BASE PAGE for simple product ${item.DisplayName} with PLU ${item.PLU}`,
                    productId: item.PLU,
                });
            }

            if (!baseProduct) {
                errors.simpleItems.push({
                    errorId: new Date().getTime() + Math.floor(Math.random() * 1000000),
                    error: `Unable to find BASE PRODUCT for simple product ${item.DisplayName} with PLU ${item.PLU}`,
                    productId: item.PLU,
                });
            }

            if (baseProduct && baseProduct.State !== 0) {
                errors.simpleItems.push({
                    errorId: new Date().getTime() + Math.floor(Math.random() * 1000000),
                    error: `Product  ${item.DisplayName} with PLU ${item.PLU} is currently unavailable - state property is not 0 (${baseProduct.State})`,
                    productId: item.PLU,
                });
            }

            const hasError = !!errors.simpleItems.length && errors.simpleItems.some((err) => err.productId === item.PLU);

            return [
                ...acc,
                Items.createSimpleItemFromOnlineOrderItemModel(item, {
                    _IsDisabled: hasError,
                    LocationNo: order.PickupLocation,
                    DietaryTags: baseProduct ? baseProduct.DietaryTags : null,
                    VirtualLocationNo: item.VirtualLocations?.[0]?.LocationNo || order.VirtualLocations?.[0]?.LocationNo,
                }),
            ];
        }, []);
    }

    private static _checkOrderPickupTimeInOnlineMenuRange(
        order: OLO.DTO.OnlineOrderDetailedBusinessModel,
        onlineMenu: OLO.DTO.OnlineMenuResponseModel,
        errors: OLO.Errors.Order2CartConvertErrors,
    ): void {
        const startTime = Dates.createHoursIntFromDate(onlineMenu.StartTime);
        const endTime = Dates.createHoursIntFromDate(onlineMenu.EndTime);
        const orderPickupTime = Dates.createHoursIntFromDate(order.PickUpDate);

        if (orderPickupTime < startTime || orderPickupTime > endTime) {
            errors.general.push({
                errorId: new Date().getTime() + Math.floor(Math.random() * 1000000),
                error: 'Order pickupTime not in range of online menu StartTime and EndTime',
            });
        }
    }

    public static covertOrderToCart(
        onlineOrder: OLO.DTO.OnlineOrderDetailedBusinessModel,
        allOnlineMenu: OLO.DTO.OnlineMenuResponseModel[],
        menuFlowsDetails: OLO.DTO.MenuFlowDetailsModel[],
        ingredients: APICommon.ProductLocationIngredientExtended[],
        pickupTime: OLO.Ordering.PickupTime,
        fixMenuFlowActivationsDescriptions: boolean = true,
    ): OLO.Ordering.OnlineOrder2CartConverter {
        const cart: OLO.State.Cart = {
            locationNo: onlineOrder.PickupLocation,
            onlineMenu: allOnlineMenu.find((onlineMenu) => !onlineMenu._VirtualLocationNo),
            onlineMenuVirtualLocations: allOnlineMenu.filter((onlineMenu) => Boolean(onlineMenu._VirtualLocationNo)),
            pickupTime: { ...pickupTime },
            itemsMenuFlow: null,
            itemsSimple: null,
            orderTypeId: onlineOrder.OrderTypeId,
        };
        const errors: OLO.Errors.Order2CartConvertErrors = {
            general: [],
            menuFlows: [],
            simpleItems: [],
        };
        const order: OLO.DTO.OnlineOrderDetailedBusinessModel = OnlineOrders.unduplicateOrderItems(onlineOrder);

        /* #1 deal with menuflows */
        const itemsMenuFlow: OLO.State.Cart.CartMenuFlow[] = this._dealWithMenuFlow(
            order.MenuFlowActivations,
            errors,
            allOnlineMenu,
            menuFlowsDetails,
            order,
            ingredients,
            fixMenuFlowActivationsDescriptions,
        );

        /* #2 deal with products */
        const itemsSimple: OLO.State.Cart.CartSimpleItem[] = this._dealWithSimpleProduct(order, allOnlineMenu, errors);

        /* #3 check if order pickupTime is in current online menu range, if physical online menu is not available then check for online location */
        const relevantOnlineMenuForCheckingPickupTime = allOnlineMenu.find(
            (onlineMenu) => !onlineMenu._VirtualLocationNo || order?.VirtualLocations?.[0]?.LocationNo === onlineMenu._VirtualLocationNo,
        );
        if (relevantOnlineMenuForCheckingPickupTime) {
            this._checkOrderPickupTimeInOnlineMenuRange(order, relevantOnlineMenuForCheckingPickupTime, errors);
        }

        cart.itemsMenuFlow = itemsMenuFlow.filter((item) => item !== null && item !== undefined);
        cart.itemsSimple = itemsSimple;

        return {
            cart,
            errors,
        };
    }

    public static convertCart(
        saleName: string,
        cart: OLO.State.Cart,
        PickupObj: OLO.Ordering.PickupTime,
        extraParams: APICommon.CartToOrderConvertExtraParams = {},
        virtualLocations: OLO.DTO.VirtualLocationBusinessModel[],
    ): OLO.DTO.OnlineOrderDetailedBusinessModel {
        if (!PickupObj) return null;
        // Doing https://lh3.googleusercontent.com/V8ehXTGa-NIpxHJjZKxOtajAUlhIZE483BpEPH49nJRCxJfX4ifC8d8WU9bXr0n9uQqwLtTdivfuog=w270-h130-rw-no stuff...
        const locationNo: number = cart.locationNo;
        /* Brejnfak, normalize hours */
        const createdDate = Dates.getLocalISOFormatDate(new Date(), true);
        const pickupTime = PickupObj.DateLocalISO + 'Z'; /* Z is required in our sick api */

        return {
            SaleName: `${saleName} ${createdDate.replace(/\.\d{3}Z?/i, '')}`,
            MemberId: extraParams.MemberId || null,
            PartialMember: extraParams.PartialMember || null,
            OrderTypeId: extraParams.OrderTypeId || cart.orderTypeId || null,
            PickupLocation: locationNo,
            PickUpDate: pickupTime,
            OrderedDate: createdDate,
            Status: OLO.Enums.ONLINE_ORDER_STATUS.CREATED,
            SendToKMS: true,
            OnlineDiscounts: [],
            Medias: [
                /* Boldman says 'NO MEDIA UGHhhh' */
            ],
            Items: cart.itemsSimple.map((product) => ({
                ...product,
                PLU: product.Plu,
                Value: product.UnitPrice * product.Quantity,
                Modifiers: [],
                IngredientsChanges: {
                    IngredientsModified: [],
                    IngredientsRemoved: [],
                    IngredientsAdded: [],
                    IngredientsSwapped: [],
                },
                VirtualLocations:
                    extraParams.VirtualLocations === null && product.VirtualLocationNo
                        ? [
                              {
                                  LocationNo: product.VirtualLocationNo,
                                  LocationClassification: virtualLocations.find((virtualLocation) => virtualLocation.LocationNo === product.VirtualLocationNo)
                                      ?.LocationClassification,
                              },
                          ]
                        : null,
            })),

            MenuFlowActivations: [
                /* MenuFlowActivations start */
                ...cart.itemsMenuFlow.map((menuFlow) => ({
                    Id: null,
                    IsUpsell: menuFlow.IsUpsell || false,
                    MenuFlowId: menuFlow.MenuFlowId,
                    Quantity: menuFlow.Quantity,
                    UnitPrice: menuFlow.UnitPrice,
                    SpecialInstructions: menuFlow.SpecialInstructions || null,
                    DisplayName: menuFlow.CustomerFriendlyName,
                    DisplayDescription: menuFlow.CustomerFriendlyDescription,
                    Value: menuFlow.UnitPrice * menuFlow.Quantity /* ( Value including UnitPrice and total menu flows products value ) * Quantity */,
                    MenuFlowItems: [
                        ...menuFlow.Pages.reduce(
                            (acc, page) =>
                                acc.concat(
                                    page.Products.map((product) => ({
                                        Id: null,
                                        OnlineOrderItemId: null,
                                        Type: null,
                                        MenuFlowPageId: page.PageIdentifier,
                                        PLU: product.Plu,
                                        Quantity: product.Quantity,
                                        DisplayName: product.ProductName,
                                        DisplayDescription: product.ProductDescription,

                                        /* These values should be CALCULATED using condition for Page */
                                        UnitPrice: product.UnitPrice,
                                        Value: product.UnitPrice * product.Quantity,

                                        IngredientsChanges: {
                                            IngredientsModified: extraParams.RemoveModifiers
                                                ? []
                                                : OnlineOrders.modifierPropsTrimmer<OLO.DTO.OnlineOrderItemIngredientModificationModel>(product, 'IngredientsModified'),
                                            IngredientsRemoved: extraParams.RemoveModifiers
                                                ? []
                                                : OnlineOrders.modifierPropsTrimmer<OLO.DTO.OnlineOrderItemIngredientRemovalModel>(product, 'IngredientsRemoved'),
                                            IngredientsAdded: extraParams.RemoveModifiers
                                                ? []
                                                : OnlineOrders.modifierPropsTrimmer<OLO.DTO.OnlineOrderItemIngredientAdditionModel>(product, 'IngredientsAdded'),
                                            IngredientsSwapped: extraParams.RemoveModifiers
                                                ? []
                                                : OnlineOrders.modifierPropsTrimmer<OLO.DTO.OnlineOrderItemIngredientSwapModel>(product, 'IngredientsSwapped'),
                                        },
                                    })),
                                ),
                            [],
                        ),
                    ],
                    VirtualLocations:
                        extraParams.VirtualLocations === null && menuFlow.VirtualLocationNo
                            ? [
                                  {
                                      LocationNo: menuFlow.VirtualLocationNo,
                                      LocationClassification: virtualLocations.find((virtualLocation) => virtualLocation.LocationNo === menuFlow.VirtualLocationNo)
                                          ?.LocationClassification,
                                  },
                              ]
                            : null,
                })),
                /* MenuFlowActivations end */
            ],
            VirtualLocations: extraParams.VirtualLocations,
        };
    }

    public static modifierPropsTrimmer<T>(product: OLO.State.Wizzard.WizzardMenuFlowItem, propName: string): T[] {
        if (!product.IngredientsChanges || !product.IngredientsChanges[propName]) return [];

        const trimmedArray: T[] = [];

        product.IngredientsChanges[propName].forEach((modifier) => {
            const newModifierObj: T = {} as T;

            Object.keys(modifier).forEach((key) => {
                if (key.charAt(0) !== '_') {
                    newModifierObj[key] = modifier[key];
                }
            });

            trimmedArray.push(newModifierObj);
        });

        return trimmedArray;
    }

    public static mapOnlineOrderProducts(
        order: OLO.DTO.OnlineOrderDetailedBusinessModel,
        fixMenuFlowDescriptions: boolean = false,
        onlineProducts: OLO.DTO.OnlineMenuProductResponseModel[] = null,
    ): OLO.Ordering.OnlineOrderMappedProducts {
        const distinctMenuFlowProducts: number[] = [];

        const obj: OLO.Ordering.OnlineOrderMappedProducts = {
            itemsSimple: [],
            itemsMenuFlow: [],
        };

        order.MenuFlowActivations.forEach((menuFlowActivation) => {
            menuFlowActivation.MenuFlowItems.forEach((item) => distinctMenuFlowProducts.push(item.OnlineOrderItemId));
        });

        obj.itemsMenuFlow = [
            ...order.MenuFlowActivations.map((menuFlow) => ({
                ...menuFlow,
                VirtualLocations: (menuFlow.VirtualLocations?.length && menuFlow.VirtualLocations) || (order.VirtualLocations?.length && order.VirtualLocations) || null,
            })),
        ];

        if (onlineProducts) {
            const filteredItems = [...order.Items].filter((item) => !distinctMenuFlowProducts.includes(item.Id));
            filteredItems.forEach((item) => {
                const product = onlineProducts.find((prod) => prod.Plu === item.PLU);
                obj.itemsSimple.push({
                    ...item,
                    UnitPrice: product?.Price || null,
                });
            });
        } else {
            obj.itemsSimple = [...order.Items].filter((item) => !distinctMenuFlowProducts.includes(item.Id));
        }

        obj.itemsSimple = obj.itemsSimple.map((item) => ({
            ...item,
            VirtualLocations: (item.VirtualLocations?.length && item.VirtualLocations) || (order.VirtualLocations?.length && order.VirtualLocations) || null,
        }));

        if (fixMenuFlowDescriptions) {
            obj.itemsMenuFlow = obj.itemsMenuFlow.map(OnlineOrders.menuFlowActivationItemsDescription);
        }

        return obj;
    }

    public static menuFlowActivationItemsDescription(menuFlowActivation: OLO.DTO.OnlineOrderMenuFlowActivationModel): OLO.DTO.OnlineOrderMenuFlowActivationModel {
        return {
            ...menuFlowActivation,
            DisplayDescription: OnlineOrders.generateDescriptionForMenuFlowActivationItem(menuFlowActivation),
        };
    }
}
