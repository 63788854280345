import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { LoyaltyProductProgramsEffects } from './loyalty-product-programs.effects';
import { loyaltyProductProgramsReducer } from './loyalty-product-programs.reducer';

@NgModule({
    declarations: [],
    imports: [CommonModule, StoreModule.forFeature('loyaltyProductPrograms', loyaltyProductProgramsReducer), EffectsModule.forFeature([LoyaltyProductProgramsEffects])],
    exports: [],
    providers: [],
})
export class LoyaltyProductProgramsStateModule {}
