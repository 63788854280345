import { createSelector } from '@ngrx/store';

import { getWizzardMenuFlowPageSelectedProducts } from '../getWizzardMenuFlowPageSelectedProducts';

export const canShowNextPriceForWizzardTile = (pageIdentifier: number, product: OLO.DTO.MenuFlowProduct) =>
    createSelector(getWizzardMenuFlowPageSelectedProducts(pageIdentifier), (selectedProducts) => {
        const isSelected = selectedProducts?.find((obj) => obj.ProductId === product.ProductId);
        if (!isSelected) {
            return false;
        }
        const hasExtraPrice =
            (isSelected.IngredientsChanges?.IngredientsModified && isSelected.IngredientsChanges?.IngredientsModified[0]?.ExtraPrice) ||
            (isSelected.IngredientsChanges?.IngredientsAdded && isSelected.IngredientsChanges?.IngredientsAdded[0]?.ExtraPrice);

        return Boolean(isSelected.UnitPrice || hasExtraPrice);
    });
