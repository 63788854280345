import { DynamicPages } from './dynamic-pages.utils';

export class OrderConfirmationUrl {
    private _pageConfig: Nullable<OLO.Config.OrderConfirmationPage> = null;
    private _baseUrl: Nullable<string> = null;
    private _queryComponent: Nullable<string> = null;
    private _relativeUrl: Nullable<string> = null;
    private _absoluteUrl: Nullable<string> = null;
    private _isLiveViewConfigured: boolean = false;

    constructor(private _orderTypeId: number, private _encryptComponent: string) {
        this._pageConfig = DynamicPages.customPageSetup('orderConfirmationPage', this._orderTypeId);
        if (this._pageConfig) {
            this._isLiveViewConfigured = this._pageConfig.orderStatusSection.isLiveViewEnabled === true;
            this._baseUrl = this._isLiveViewConfigured ? '/order-confirmation' : '/order-confirmation-static';
            this._queryComponent = `order=${this._encryptComponent}`;
            this._relativeUrl = `${this._baseUrl}?${this._queryComponent}`;
            this._absoluteUrl = window.location.origin + this._relativeUrl;
        }
    }

    public getAbsoluteUrl(): Nullable<string> {
        return this._absoluteUrl;
    }

    public getRelativeUrl(): Nullable<string> {
        return this._relativeUrl;
    }

    public getBaseUrl(): Nullable<string> {
        return this._baseUrl;
    }

    public getSerializedQueryParams(): Nullable<Record<string, string>> {
        if (!this._queryComponent) {
            return null;
        }

        const queryParams = {};
        const splitted = this._queryComponent.split('=');
        queryParams[splitted[0]] = splitted[1];

        return queryParams;
    }

    public isLiveViewEnabled(): boolean {
        return this._isLiveViewConfigured;
    }
}
