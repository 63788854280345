import { createSelector } from '@ngrx/store';

import { getOnlineMenu } from '@shared/state/onlineMenu/selectors';
import { getWizzardSimpleItem, getWizzardMenuFlow } from '@shared/state/wizzard/selectors';
import { getImagesForOnlineMenuPages, isDownloadingAnyOnlineMenuPageImages } from '@shared/state/onlineMenuProductsImages/selectors';
import { getCart } from '@shared/state/cart/selectors';
import { getOnlineMenuVirtualLocations } from '../../../onlineMenuVirtualLocations/selectors';

export const getImageForWizzardItem = (isMenuFlow: boolean) =>
    createSelector(
        getOnlineMenu,
        getOnlineMenuVirtualLocations,
        getWizzardSimpleItem,
        getWizzardMenuFlow,
        getImagesForOnlineMenuPages,
        isDownloadingAnyOnlineMenuPageImages,
        getCart,
        (onlineMenu, onlineMenuVirtualLocations, singleProduct, menuFlow, images, isDownloadingAnything, cart) => {
            if (isDownloadingAnything || !images || (isMenuFlow && !menuFlow) || (!isMenuFlow && !singleProduct)) {
                return null;
            }
            const cartOnlineMenu = cart.onlineMenu ?? cart.onlineMenuVirtualLocations?.[0];
            const relevantOnlineMenu = onlineMenu ?? onlineMenuVirtualLocations?.[0];
            const itemId: number = isMenuFlow ? menuFlow.MenuFlowId : singleProduct.ProductId;
            const isEditing: boolean = isMenuFlow ? !!menuFlow._Id : !!singleProduct._Id;
            const onlineMenuSelection: OLO.DTO.OnlineMenuResponseModel = isEditing && cartOnlineMenu ? cartOnlineMenu : relevantOnlineMenu.data;
            if (!onlineMenuSelection) return null;

            let foundInOnlineMenuItemId: number;
            onlineMenuSelection.Pages.forEach((page) => {
                page.Products.forEach((product) => {
                    if (foundInOnlineMenuItemId) return;

                    if (isMenuFlow) {
                        if (product.MenuFlowId === itemId) {
                            foundInOnlineMenuItemId = product.Id;
                        }

                        return;
                    }

                    if (product.ProductId === itemId) {
                        foundInOnlineMenuItemId = product.Id;
                    }
                });
            });

            let foundImage: Nullable<string> = null;
            for (let i = 0, j = images.length; i < j; i++) {
                if (foundImage) break;

                let page = images[i];

                if (page.data) {
                    for (let k = 0, l = page.data.length; k < l; k++) {
                        if (foundImage) break;

                        let img = page.data[k];

                        if (img.ParentId === foundInOnlineMenuItemId && img.ImageUrl) {
                            foundImage = img.ImageUrl;
                        }
                    }
                }
            }

            return foundImage;
        },
    );
