import { createAction, props } from '@ngrx/store';

export const MenuFlowsDetailsRequest = createAction(
    '[Menu flows] Request details by MenuFlowId and LocationNo',
    props<{ menuFlowId: number; locationNo: number; virtualLocationNo?: number }>(),
);

export const MenuFlowsDetailsSuccessRequest = createAction(
    '[Menu flows] Success request details by MenuFlowId and LocationNo',
    props<{ menuFlowId: number; locationNo: number; payload: OLO.DTO.MenuFlowDetailsModel; virtualLocationNo?: number }>(),
);

export const MenuFlowsDetailsErrorRequest = createAction(
    '[Menu flows] Error request details by MenuFlowId and LocationNo',
    props<{ menuFlowId: number; locationNo: number; ex?: any; virtualLocationNo?: number }>(),
);
