import * as io from 'io-ts';
import { nullable, arrayOfStrings, boolean, nullableString, validString } from '@shared/core/types/common';
import { checkoutStepEnum, orderTypeIdsArray } from '@shared/core/types/consts';

const dynamiCorderTypeDetailsCTADefinition: io.MixedObject<OLO.Config.DynamicOrderTypeDetailsCTA> = {
    icon: validString,
    text: validString,
};

const dynamiCorderTypeDetailsModalDefinition: io.MixedObject<OLO.Config.DynamicOrderTypeDetailsModal> = {
    preTitle: validString,
    title: arrayOfStrings,
    description: arrayOfStrings,
};

const dynamiCorderTypeDetailsDefinition: io.MixedObject<OLO.Config.DynamicOrderTypeDetails> = {
    associateWithPaymentStep: checkoutStepEnum,
    addDetailsButtonText: validString,
    dataCollected: arrayOfStrings,
    callToAction: io.type(dynamiCorderTypeDetailsCTADefinition),
    modal: nullable(io.type(dynamiCorderTypeDetailsModalDefinition)),
};

const rootDefinition: io.MixedObject<OLO.Config.CheckoutPage> = {
    orderTypeIds: orderTypeIdsArray,
    orderTypesDisclaimers: boolean,
    allowGuestsOrders: boolean,
    mediaSectionHeader: io.union([nullableString, io.literal(-1)]),
    isMemberInterfaceEnabled: boolean,
    dynamicOrderTypeDetails: io.union([nullable(io.type(dynamiCorderTypeDetailsDefinition)), io.literal(-1)]),
};

export const checkoutPageSchema = io.array(io.type(rootDefinition));
