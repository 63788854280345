import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './geolocation.actions';

const initialState: OLO.State.Geolocation = {
    isRequesting: false,
    hasSucceeded: false,
    hasFailed: false,
    data: null,
};

export function geolocationReducer(state: OLO.State.Geolocation | undefined, action: Action) {
    return createReducer(
        initialState,
        on(actions.geolocationPositionRequest, (currState, _action) => ({
            ...currState,
            isRequesting: true,
            hasSucceeded: false,
            hasFailed: false,
        })),
        on(actions.geolocationPositionSuccessRequest, (currState, _action) => ({
            ...currState,
            isRequesting: false,
            hasSucceeded: true,
            hasFailed: false,
            data: _action.payload,
        })),
        on(actions.geolocationPositionErrorRequest, (currState, _action) => ({
            ...currState,
            isRequesting: false,
            hasSucceeded: false,
            hasFailed: true,
        })),
    )(state, action);
}
