export class OnlineOrderLiveViewStatuses {
    private static _isPickupFilterValid(pickupDate: string) {
        return new Date().getTime() >= new Date(pickupDate).getTime();
    }

    public static isDone(
        orderData: OLO.DTO.OnlineOrderDetailedBusinessModel,
        liveViewSection: OLO.Config.OrderConfirmationPage.LiveViewItem,
        liveViewItems: OLO.Config.OrderConfirmationPage.LiveViewItem[],
    ): boolean {
        if (liveViewSection.pickupDateFilter) {
            const pickupDateFilterValid = OnlineOrderLiveViewStatuses._isPickupFilterValid(orderData.PickUpDate);

            return (
                pickupDateFilterValid &&
                (liveViewSection.statusFilter === null ||
                    liveViewItems.some((item) => OnlineOrderLiveViewStatuses.isActive(orderData, item) && item.displayIndex >= liveViewSection.displayIndex))
            );
        }

        return (
            liveViewSection.statusFilter === null ||
            liveViewItems.some((item) => OnlineOrderLiveViewStatuses.isActive(orderData, item) && item.displayIndex >= liveViewSection.displayIndex)
        );
    }

    public static isActive(orderData: OLO.DTO.OnlineOrderDetailedBusinessModel, liveViewSection: OLO.Config.OrderConfirmationPage.LiveViewItem): boolean {
        if (liveViewSection.pickupDateFilter) {
            const pickupDateFilterValid = OnlineOrderLiveViewStatuses._isPickupFilterValid(orderData.PickUpDate);

            return pickupDateFilterValid && (liveViewSection.statusFilter === null || liveViewSection.statusFilter.some((statusItem) => orderData.Status === statusItem));
        }

        return liveViewSection.statusFilter === null || liveViewSection.statusFilter.some((statusItem) => orderData.Status === statusItem);
    }

    public static isCompleted(
        orderData: OLO.DTO.OnlineOrderDetailedBusinessModel,
        liveViewSection: OLO.Config.OrderConfirmationPage.LiveViewItem,
        liveViewItems: OLO.Config.OrderConfirmationPage.LiveViewItem[],
    ): boolean {
        if (liveViewSection.pickupDateFilter) {
            const pickupDateFilterValid = OnlineOrderLiveViewStatuses._isPickupFilterValid(orderData.PickUpDate);

            return (
                pickupDateFilterValid &&
                (liveViewSection.statusFilter === null ||
                    liveViewItems.some((item) => OnlineOrderLiveViewStatuses.isActive(orderData, item) && item.displayIndex >= liveViewSection.displayIndex))
            );
        }

        return (
            liveViewSection.statusFilter === null ||
            liveViewItems.some((item) => OnlineOrderLiveViewStatuses.isActive(orderData, item) && item.displayIndex >= liveViewSection.displayIndex)
        );
    }

    public static isCancelled(orderData: OLO.DTO.OnlineOrderDetailedBusinessModel, liveViewSections: OLO.Config.OrderConfirmationPage.LiveViewItem[]): boolean {
        return liveViewSections.some(
            (liveViewSection) => liveViewSection.isFinal && liveViewSection.isSuccess === false && liveViewSection.statusFilter?.includes(orderData.Status),
        );
    }

    /**
     * Create list of all actual statuses for current live view item element
     * @param order online order;
     * @param liveViewItem single live view item
     * @param liveViewItems all relevant live view items / context
     * @returns list of all relevant statuses
     */
    public static getSectionStatuses(
        order: OLO.DTO.OnlineOrderDetailedBusinessModel,
        liveViewItem: OLO.Config.OrderConfirmationPage.LiveViewItem,
        liveViewItems: OLO.Config.OrderConfirmationPage.LiveViewItem[],
    ): OLO.State.HistoryOrders.OrderLiveViewStatuses {
        return {
            isDone:
                OnlineOrderLiveViewStatuses.isDone(order, liveViewItem, liveViewItems) &&
                !liveViewItems.some((item) => OnlineOrderLiveViewStatuses.isDone(order, item, liveViewItems) && item.isFinal),
            isActive: OnlineOrderLiveViewStatuses.isActive(order, liveViewItem),
            isCompleted: OnlineOrderLiveViewStatuses.isCompleted(order, liveViewItem, liveViewItems),
            isCancelled: OnlineOrderLiveViewStatuses.isCancelled(order, liveViewItems),
            isFinal: liveViewItem.isFinal,
        };
    }
}
