import { createAction, props } from '@ngrx/store';

export const VirtualLocationsRequest = createAction('[Virtual Locations] Request virtual locations', (params: APICommon.VirtualLocationsGetParams = {}) => ({ params }));

export const VirtualLocationsSuccessRequest = createAction(
    '[Virtual Locations] Success request virtual locations',
    props<{ params: APICommon.VirtualLocationsGetParams; payload: OLO.DTO.VirtualLocationBusinessModel[] }>(),
);

export const VirtualLocationsErrorRequest = createAction('[Virtual Locations] Error request virtual locations', (params: APICommon.VirtualLocationsGetParams, ex: any = null) => ({
    ex,
    params,
}));
