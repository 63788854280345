type LangCode = 'en' | 'es';

export class Translations {
    public static readonly DEFAULT_LANG: LangCode = 'en';
    public static readonly AVAILABLE_LANGS: LangCode[] = ['en', 'es'];

    /**
     * Provides language code based on config or from browser if config was not provided
     * @param {OLO.Types.COUNTRIES_CODE=} configLocale locale extracted from application config
     * @returns {LangCode} LangCode
     */
    public static getLanguage(configLocale?: OLO.Types.COUNTRIES_CODE): LangCode {
        const configLang = configLocale.slice(0, 2) as LangCode;
        const userLang = navigator.language.slice(0, 2) as LangCode;

        if (configLang) {
            return configLang;
        } else if (Translations.AVAILABLE_LANGS.includes(userLang as LangCode)) {
            return userLang;
        }

        return Translations.DEFAULT_LANG;
    }

    public static setTextTranslationProxy(translations: T.StaticTexts, textToSet: string) {
        const handler = {
            get(target, prop) {
                const nextValue = target[prop];
                if (nextValue === undefined) {
                    return textToSet;
                }
                if (nextValue.constructor === Object) {
                    const nestedProxy = new Proxy(nextValue, handler);

                    return nestedProxy;
                }

                return nextValue;
            },
        };

        return new Proxy(translations, handler);
    }
}
