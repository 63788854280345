import { createSelector } from '@ngrx/store';
import { cartState } from '../root';

export const getCartMenuFlowQuantityByMenuFlowId = (menuFlowId: number, virtualLocationNo: Nullable<number> = null) =>
    createSelector(cartState, (cart) =>
        cart.itemsMenuFlow.reduce((acc, menuFlow) => {
            if (menuFlow.MenuFlowId === menuFlowId && menuFlow.VirtualLocationNo === virtualLocationNo) {
                return (acc += menuFlow.Quantity);
            }

            return acc;
        }, 0),
    );
