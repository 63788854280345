import * as io from 'io-ts';
import { nullableNumber, boolean, validString } from '@shared/core/types/common';
import { imageFileExtensionsCardsType } from '@shared/core/types/consts';

const memberCardDefinition: io.MixedObject<OLO.Config.Membership.MemberCard> = {
    minLength: nullableNumber,
    maxLength: nullableNumber,
};
const memberProfilePictureDefinition: io.MixedObject<OLO.Config.Membership.ProfilePicture> = {
    title: validString,
    description: io.array(validString),
    previewDescription: io.array(validString),
    allowDeviceCamera: boolean,
    allowedExtensions: imageFileExtensionsCardsType,
    maxFileSize: io.number,
};

const rootDefinition: io.MixedObject<OLO.Config.Membership> = {
    birthdayRewards: boolean,
    enableQuickSignInForReturningMembers: boolean,
    membersCanDeleteAccounts: boolean,
    defaultMemberGroupId: nullableNumber,
    minimumAgeToSignUp: nullableNumber,
    maximumAgeToSignUp: nullableNumber,
    memberCard: io.type(memberCardDefinition),
    passwordRules: io.undefined,
    profilePicture: io.union([io.type(memberProfilePictureDefinition), io.undefined]),
};

export const membershipSchema = io.type(rootDefinition);
