import { createAction, props } from '@ngrx/store';

/* General */
export const TopBarSetStickyFlag = createAction('[topBar] set sticky flag', props<{ isSticky: boolean }>());

export const TopBarSetCartIconVisibility = createAction('[topBar] hide / show cart icon', props<{ isVisible: boolean }>());

export const TopBarSetBackToStoreIconVisibility = createAction('[topBar] hide / show back to store button', props<{ isVisible: boolean }>());

export const TopBarSetBackToStoresIconVisibility = createAction('[topBar] hide / show back to stores - home/landing page button', props<{ isVisible: boolean }>());

export const TopBarSetBackToLandingIconVisibility = createAction('[topBar] hide / show back to landing button', props<{ isVisible: boolean }>());
