/* https://github.com/gcanti/io-ts/issues/322#issuecomment-584658211 */
import * as io from 'io-ts';

import { either, Either, isRight, left, right, Right } from 'fp-ts/lib/Either';

const getIsCodec =
    <T extends io.Any>(tag: string) =>
    (codec: io.Any): codec is T =>
        (codec as any)._tag === tag;
const isInterfaceCodec = getIsCodec<io.InterfaceType<io.Props>>('InterfaceType');
const isPartialCodec = getIsCodec<io.PartialType<io.Props>>('PartialType');

const getProps = (codec: io.HasProps): io.Props => {
    switch (codec._tag) {
        case 'RefinementType':
        case 'ReadonlyType':
            return getProps(codec.type);
        case 'InterfaceType':
        case 'StrictType':
        case 'PartialType':
            return codec.props;
        case 'IntersectionType':
            return codec.types.reduce<io.Props>((props, type) => Object.assign(props, getProps(type)), {});
    }
};

const getNameFromProps = (props: io.Props): string =>
    Object.keys(props)
        .map((k) => `${k}: ${props[k].name}`)
        .join(', ');

const getPartialTypeName = (inner: string): string => `Partial<${inner}>`;

const getExcessTypeName = (codec: io.Any): string => {
    if (isInterfaceCodec(codec)) {
        return `{| ${getNameFromProps(codec.props)} |}`;
    }
    if (isPartialCodec(codec)) {
        return getPartialTypeName(`{| ${getNameFromProps(codec.props)} |}`);
    }

    return `Excess<${codec.name}>`;
};

const stripKeys = <T = any>(o: T, props: io.Props): Either<Array<string>, T> => {
    const extraKeys: string[] = [];

    const checkObject = (obj: any, schema: io.Props, prefix: string = '') => {
        const objKeys = Object.keys(obj);
        const schemaKeys = Object.keys(schema);

        objKeys.forEach((key) => {
            if (!schemaKeys.includes(key)) {
                extraKeys.push(prefix + key);
            } else if (typeof obj[key] === 'object' && obj[key] !== null && schema[key] instanceof io.InterfaceType) {
                checkObject(obj[key], (schema[key] as io.InterfaceType<any>).props, `${prefix}${key}.`);
            }
        });
    };

    checkObject(o, props);

    return extraKeys.length ? left(extraKeys) : right(o);
};

const createContext = (parentContext: io.Context, key: string): io.Context => [{ ...parentContext[0], key }];

export class ExcessType<C extends io.Any, A = C['_A'], O = A, I = unknown> extends io.Type<A, O, I> {
    constructor(name: string, is: ExcessType<C, A, O, I>['is'], validate: ExcessType<C, A, O, I>['validate'], encode: ExcessType<C, A, O, I>['encode'], public readonly type: C) {
        super(name, is, validate, encode);
    }
}

export const excess = <C extends io.Any>(codec: C, name: string = getExcessTypeName(codec)): ExcessType<C> => {
    if (!('props' in codec) || !('_tag' in codec)) {
        throw new Error("Codec must have 'props' and '_tag' property");
    }

    const props: io.Props = getProps(codec as io.HasProps);

    return new ExcessType<C>(
        name,
        (u): u is C => isRight(stripKeys(u, props)) && codec.is(u),
        (u, c) =>
            either.chain(io.UnknownRecord.validate(u, c), () =>
                either.chain(codec.validate(u, c), (a) =>
                    either.mapLeft(stripKeys<C>(a, props), (keys) =>
                        keys.map((k) => ({
                            value: k,
                            context: createContext(c, k),
                            message: `excess key "${k}" found`,
                        })),
                    ),
                ),
            ),
        (a) => codec.encode((stripKeys(a, props) as Right<any>).right),
        codec,
    );
};
