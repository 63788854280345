import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './wizzard.actions';

const initialState: OLO.State.Wizzard = {
    errors: [],
    recalculatePrices: {
        isCalculating: false,
        hasSucceeded: false,
        hasFailed: false,
    },
    itemsMenuFlow: null,
    itemsSimple: null,
};

export const wizzardReducerFn = createReducer(
    initialState,
    on(actions.WizzardStepMenuFlowInit, (state) => ({
        ...state,
        errors: [],
        recalculatePrices: {
            isCalculating: false,
            hasSucceeded: false,
            hasFailed: false,
        },
        itemsSimple: null,
        itemsMenuFlow: null,
    })),
    on(actions.WizzardStepMenuFlowMount, (state, action) => ({
        ...state,
        recalculatePrices: {
            isCalculating: false,
            hasSucceeded: false,
            hasFailed: false,
        },
        itemsMenuFlow: {
            ...action.item,
        },
    })),
    on(actions.WizzardValidate, (state, action) => ({
        ...state,
        errors: action.errors,
    })),
    on(actions.WizzardUnmountAll, (state) => ({
        ...state,
        recalculatePrices: {
            isCalculating: false,
            hasSucceeded: false,
            hasFailed: false,
        },
        itemsSimple: null,
        itemsMenuFlow: null,
        errors: [],
    })),
    on(actions.WizzardStepSimpleItemMount, (state, action) => ({
        ...state,
        recalculatePrices: {
            isCalculating: false,
            hasSucceeded: false,
            hasFailed: false,
        },
        itemsSimple: { ...action.item },
    })),
    on(actions.WizzardMenuFlowUpsellSetupRequest, (state) => ({
        ...state,
        recalculatePrices: {
            isCalculating: false,
            hasSucceeded: false,
            hasFailed: false,
        },
    })),
    on(actions.WizzardMountMenuFlowUpsell, (state, action) => ({
        ...state,
        recalculatePrices: {
            isCalculating: false,
            hasSucceeded: false,
            hasFailed: false,
        },
        itemsMenuFlow: { ...action.item },
    })),
    on(actions.WizzardSimpleItemChangeQuantity, (state, action) => ({
        ...state,
        itemsSimple: {
            ...state.itemsSimple,
            Quantity: action.changeValue,
        },
    })),
    on(actions.WizzardSimpleItemDecrement, (state, action) => ({
        ...state,
        itemsSimple: {
            ...state.itemsSimple,
            Quantity: (state.itemsSimple?.Quantity ?? 0) - action.changeValue,
        },
    })),
    on(actions.WizzardSimpleItemIncrement, (state, action) => ({
        ...state,
        itemsSimple: {
            ...state.itemsSimple,
            Quantity: (state.itemsSimple?.Quantity ?? 0) + action.changeValue,
        },
    })),
    on(actions.WizzardSimpleItemSpecialInstructions, (state, action) => ({
        ...state,
        itemsSimple: {
            ...state.itemsSimple,
            SpecialInstructions: action.specialInstruction,
        },
    })),
    on(actions.WizzardMenuFlowReplaceAllWithNew, (state, action) => ({
        ...state,
        recalculatePrices: {
            isCalculating: false,
            hasSucceeded: false,
            hasFailed: false,
        },
        itemsMenuFlow: {
            ...state.itemsMenuFlow!,
            Pages: state.itemsMenuFlow?.Pages?.map((Page) => {
                if (Page.PageIdentifier === action.pageIdentifier) {
                    return {
                        ...Page,
                        Products: [
                            {
                                ...action.product,
                                Quantity: action.product.Quantity ? action.product.Quantity : 1,
                            },
                        ],
                    };
                }

                return Page;
            }),
        },
    })),
    on(actions.WizzardRecalculatePricesForMenuFlowRequest, (state) => ({
        ...state,
        recalculatePrices: {
            isCalculating: true,
            hasSucceeded: false,
            hasFailed: false,
        },
    })),
    on(actions.WizzardRecalculatePricesForMenuFlowSuccessRequest, (state, action) => {
        const priceObj = action.priceObj;

        //
        //  Because pickupTimeValidator removes unsets everything on some actions taken, we need to
        //  check if state is still set or it's null.
        //
        return {
            ...state,
            recalculatePrices: {
                isCalculating: false,
                hasSucceeded: true,
                hasFailed: false,
            },
            itemsMenuFlow:
                state.itemsMenuFlow === null
                    ? null
                    : {
                          ...state.itemsMenuFlow,
                          UnitPrice: priceObj.UnitPrice,
                          UnitTotalValue: priceObj.UnitTotalValue,
                          Pages: state.itemsMenuFlow?.Pages?.map((Page) => {
                              const pageFromPriceObj = priceObj.Pages.find((obj) => obj.PageIdentifier === Page.PageIdentifier);

                              if (pageFromPriceObj) {
                                  return {
                                      ...Page,
                                      Products:
                                          Page?.Products?.map((Product) => {
                                              const productFromPriceObj = pageFromPriceObj.Products.find(
                                                  (obj) => obj.ProductId === Product.ProductId && obj.PageProductIdentifier === Product.PageProductIdentifier,
                                              );

                                              if (productFromPriceObj) {
                                                  return {
                                                      ...Product,
                                                      UnitPrice: productFromPriceObj.UnitPrice,
                                                      TotalValue: productFromPriceObj.TotalValue,
                                                  };
                                              }

                                              return Product;
                                          }) || [],
                                  };
                              }

                              return Page;
                          }),
                      },
        };
    }),
    on(actions.WizzardRecalculatePricesForMenuFlowErrorRequest, (state) => ({
        ...state,
        recalculatePrices: {
            isCalculating: false,
            hasSucceeded: false,
            hasFailed: true,
        },
    })),
    on(actions.WizzardMenuFlowAddProduct, (state, action) => ({
        ...state,
        itemsMenuFlow: {
            ...state.itemsMenuFlow!,
            Pages: state.itemsMenuFlow?.Pages?.map((page) => {
                if (page.PageIdentifier === action.pageIdentifier) {
                    return {
                        ...page,
                        Products: [
                            ...(page.Products || []),
                            {
                                ...action.product,
                                Quantity: action.product.Quantity ? action.product.Quantity : 1,
                            },
                        ],
                    };
                }

                return page;
            }),
        },
    })),
    on(actions.WizzardMenuFlowRemoveProduct, (state, action) => ({
        ...state,
        itemsMenuFlow: {
            ...state.itemsMenuFlow!,
            Pages:
                state.itemsMenuFlow?.Pages?.map((page) => {
                    if (page.PageIdentifier === action.pageIdentifier) {
                        return {
                            ...page,
                            Products: page?.Products?.filter((product) => product.ProductId !== action.productId) || [],
                        };
                    }

                    return page;
                }) || [],
        },
    })),
    on(actions.WizzardMenuFlowProductDecrement, (state, action) => ({
        ...state,
        itemsMenuFlow: {
            ...state.itemsMenuFlow!,
            Pages:
                state.itemsMenuFlow?.Pages?.map((page) => {
                    if (page.PageIdentifier === action.pageIdentifier) {
                        return {
                            ...page,
                            Products:
                                page?.Products?.map((product) => {
                                    if (product.ProductId === action.productId) {
                                        return {
                                            ...product,
                                            Quantity: (product?.Quantity || 0) - action.changeValue,
                                        };
                                    }

                                    return product;
                                }) || [],
                        };
                    }

                    return page;
                }) || [],
        },
    })),
    on(actions.WizzardMenuFlowProductIncrement, (state, action) => ({
        ...state,
        itemsMenuFlow: {
            ...state.itemsMenuFlow!,
            Pages:
                state.itemsMenuFlow?.Pages?.map((page) => {
                    if (page.PageIdentifier === action.pageIdentifier) {
                        return {
                            ...page,
                            Products:
                                page.Products?.map((product) => {
                                    if (product.ProductId === action.productId) {
                                        return {
                                            ...product,
                                            Quantity: (product?.Quantity || 0) + action.changeValue,
                                        };
                                    }

                                    return product;
                                }) || [],
                        };
                    }

                    return page;
                }) || [],
        },
    })),
    on(actions.WizzardMenuFlowProductChangeQuantity, (state, action) => ({
        ...state,
        itemsMenuFlow: {
            ...state.itemsMenuFlow!,
            Pages:
                state.itemsMenuFlow?.Pages?.map((page) => {
                    if (page.PageIdentifier === action.pageIdentifier) {
                        return {
                            ...page,
                            Products:
                                page.Products?.map((product) => {
                                    if (product.ProductId === action.productId) {
                                        return {
                                            ...product,
                                            Quantity: action.changeValue,
                                        };
                                    }

                                    return product;
                                }) || [],
                        };
                    }

                    return page;
                }) || [],
        },
    })),
    on(actions.WizzardMenuFlowSelectModifier, (state, action) => ({
        ...state,
        itemsMenuFlow: {
            ...state.itemsMenuFlow!,
            Pages:
                state.itemsMenuFlow?.Pages?.map((Page) => {
                    if (Page.PageIdentifier === action.pageIdentifier) {
                        return {
                            ...Page,
                            Products:
                                Page?.Products?.map((Product) => {
                                    if (Product.ProductId === action.productId) {
                                        const modifier = action.modifier;

                                        // Optional items put into IngredientsAdded array, mandatory IngredientsModified
                                        // for this project we only allow one modifier, but we keep it in array (same as online order)
                                        // remember to put this modifier into NEW array

                                        if (modifier._IsOptional) {
                                            const ingrExists = Product?.IngredientsChanges?.IngredientsAdded?.find((mod) => mod.ModifierID === modifier.ModifierID);

                                            if (ingrExists) {
                                                return Product;
                                            }

                                            return {
                                                ...Product,
                                                IngredientsChanges: {
                                                    ...Product.IngredientsChanges,
                                                    IngredientsAdded: [{ ...modifier }],
                                                },
                                            };
                                        }

                                        const ingredientExists = Product?.IngredientsChanges?.IngredientsModified?.find((mod) => mod.ModifierID === modifier.ModifierID);

                                        if (ingredientExists) {
                                            return Product;
                                        }

                                        return {
                                            ...Product,
                                            IngredientsChanges: {
                                                ...Product.IngredientsChanges,
                                                IngredientsModified: [{ ...modifier }],
                                            },
                                        };
                                    }

                                    return Product;
                                }) || [],
                        };
                    }

                    return Page;
                }) || [],
        },
    })),
    on(actions.WizzardMenuFlowDecrement, (state, action) => ({
        ...state,
        itemsMenuFlow: {
            ...state.itemsMenuFlow!,
            Quantity: (state.itemsMenuFlow?.Quantity || 0) - action.changeValue,
        },
    })),
    on(actions.WizzardMenuFlowIncrement, (state, action) => ({
        ...state,
        itemsMenuFlow: {
            ...state.itemsMenuFlow!,
            Quantity: (state?.itemsMenuFlow?.Quantity || 0) + action.changeValue,
        },
    })),
    on(actions.WizzardMenuFlowChangeQuantity, (state, action) => ({
        ...state,
        itemsMenuFlow: {
            ...state.itemsMenuFlow!,
            Quantity: action.changeValue,
        },
    })),
    on(actions.WizzardMenuFlowSpecialInstructions, (state, action) => ({
        ...state,
        itemsMenuFlow: {
            ...state.itemsMenuFlow!,
            SpecialInstructions: action.specialInstructions,
        },
    })),
);

export function wizzardReducer(state: OLO.State.Wizzard | undefined, action: Action) {
    return wizzardReducerFn(state, action);
}
