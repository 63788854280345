import { createSelector } from '@ngrx/store';

import * as Utils from '@shared/core/utils';

import { getPaymentErrors } from '../getPaymentErrors';
import { getPaymentState } from '../getPaymentState';

export const getPaymentErrorsMapped = createSelector(getPaymentErrors, getPaymentState, (errors, payment) => {
    if (payment.hasSucceeded || payment.paymentStepStatus === 'complete' || (payment.data !== null && payment.data.Status === OLO.Enums.PAYMENT_STATUS.SUCCESS)) {
        return Utils.Messages.mapErrors({ previousTransaction: 'Previous transaction available' })[0];
    }

    if (!errors.length) return null;

    /* Currently we handle only one error */
    return Utils.Messages.mapErrors({ processingPayment: "We're having trouble processing payment. Please try again" })[0];
});
