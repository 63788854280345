import * as io from 'io-ts';

/**
 * Checks if all items in the array are vaid
 * @param allowedTypes
 */
export const looseTupleType = <C extends io.Mixed>(allowedTypes: io.Mixed[]) => {
    const validate = (inputArr: Array<C>): inputArr is io.Branded<Array<C>, C> => {
        const registry = new Map<C, boolean>();

        inputArr.forEach((obj) => {
            if (!registry.has(obj)) {
                registry.set(obj, false);
            }

            allowedTypes.forEach((brand) => {
                if (registry.get(obj)) {
                    return;
                }

                if (brand.decode(obj)._tag === 'Right') {
                    registry.set(obj, true);
                }
            });
        });

        const values = Array.from(registry.values());
        values.forEach((isValid, index) => {
            if (!isValid) {
                const obj = inputArr[index];
                console.error('Invalid object type:', obj, ' in ', inputArr);
            }
        });

        return values.includes(false) ? false : true;
    };

    return new io.Type('looseMixedTuple', validate, (input: Array<C>, context) => (validate(input) ? io.success(input) : io.failure(input, context)), io.identity);
};
