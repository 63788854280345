import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';

import { onlineMenuFiltersReducer } from './online-menu-filters.reducer';

@NgModule({
    imports: [CommonModule, StoreModule.forFeature('onlineMenuFilters', onlineMenuFiltersReducer)],
})
export class OnlineMenuFiltersStateModule {}
