export class LocationOpenStatus {
    private readonly _DIFF_MINUTES: number = 5;
    private _dateString: string;
    private _openingDate: Date;
    private _closingDate: Date;

    constructor(private _orderingTimeInfo: OLO.DTO.LocationOrderingTimeInfoModel) {
        if (!_orderingTimeInfo) return;
        this._init();
    }

    private _init(): void {
        this._extractDateString();

        this._openingDate = new Date(`${this._dateString}T${this._orderingTimeInfo.OpeningTime}.000`);
        this._closingDate = new Date(`${this._dateString}T${this._orderingTimeInfo.ClosingTime}.000`);
    }

    private _extractDateString(): void {
        this._dateString = this._orderingTimeInfo.Date.split('T')[0];
    }

    private _getDiffInMiliseconds(): number {
        return this._DIFF_MINUTES * 60 * 1000;
    }

    public isOpen(): boolean {
        if (!this._orderingTimeInfo) return false;
        const open: number = this._openingDate.getTime();
        const closed: number = this._closingDate.getTime();

        return closed - open > this._getDiffInMiliseconds();
    }

    public isOpenForProvidedDate(date: string, minimumPickupTime: number = 0): boolean {
        if (!this._orderingTimeInfo || !date) return false;
        const minPickupTimeMiliseconds = minimumPickupTime * 1000 * 60;
        const startDate = new Date(this._orderingTimeInfo.Date.split('T')[0] + 'T' + this._orderingTimeInfo.OpeningTime + '.000').getTime();
        const endDate = new Date(this._orderingTimeInfo.Date.split('T')[0] + 'T' + this._orderingTimeInfo.ClosingTime + '.000').getTime() - minPickupTimeMiliseconds;
        const dateToCheck = new Date(date.replace('Z', '')).getTime();

        return dateToCheck >= startDate && dateToCheck < endDate;
    }
}
