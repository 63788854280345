import { Action, ActionReducerMap } from '@ngrx/store';

/**
 * Every reducer module's default export is the reducer function itself. In
 * addition, each module should export a type or interface that describes
 * the state of the reducer plus any selector functions. The `* as`
 * notation packages up all of the exports into a single object.
 */

import { InjectionToken } from '@angular/core';

/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */

/* Get reducers */

export const ROOT_REDUCERS = new InjectionToken<ActionReducerMap<OLO.State, Action>>('Root reducers token', {
    factory: () => ({}),
});
