import { Injectable, Inject } from '@angular/core';
import { Store, select, Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';

import * as Services from '@shared/core/services';
import * as Tokens from '@shared/core/tokens';

import { Observable, of } from 'rxjs';
import { switchMap, catchError, map, withLatestFrom, mergeMap } from 'rxjs/operators';

@Injectable()
export class LoyaltyProductProgramsEffects {
    public resetLoyaltyProgramOnSignOut$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberSignOut),
            switchMap(() => of(actions.LoyaltyProductProgramsReset())),
        ),
    );

    public requestLoyaltyInfoForCurrentMemberOnAppInit$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.AppSettingsSuccessRequest, actions.MemberJwtDataResponseSuccess),
            withLatestFrom(this._store.pipe(select(selectors.isMemberAuthorizedJWT)), this._store.pipe(select(selectors.hasRequestedLoyaltyProductPrograms))),
            switchMap(([, isAuthorized, hasRequestedPrograms]) => {
                if (this._config.appMode !== OLO.Enums.APP_MODE.ORDERING_ONLY && isAuthorized && !hasRequestedPrograms) {
                    return [actions.LoyaltyProductProgramsRequest({ requestParams: {} })];
                }

                return [];
            }),
        ),
    );

    public requestLoyaltyProductPrograms$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.LoyaltyProductProgramsRequest),
            mergeMap(({ requestParams }) =>
                this._loyaltyAppService.apiGetLoyaltyProductPrograms().pipe(
                    map((payload) => actions.LoyaltyProductProgramsSuccessRequest({ requestParams, payload })),
                    catchError((ex) => {
                        console.error('apiGetLoyaltyProductPrograms', ex);

                        return of(actions.LoyaltyProductProgramsErrorRequest({ requestParams, ex }));
                    }),
                ),
            ),
        ),
    );

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: OLO.Config,
        private _actions$: Actions,
        private _store: Store<OLO.State>,
        private _loyaltyAppService: Services.LoyaltyAppService,
    ) {}
}
