import { createSelector } from '@ngrx/store';

import { getLocationRedemptionRate } from '@shared/state/payment/selectors/getRedemptionRate';
import { isMemberAuthorized } from '@shared/state/members/selectors/isMemberAuthorized';
import { getMemberRedeemableePoints } from '@shared/state/members/selectors/getMemberRedeemablePointsTotal';

export const canPayWithPoints = createSelector(
    getLocationRedemptionRate,
    isMemberAuthorized,
    getMemberRedeemableePoints,
    (redemptionRate, memberAuthorized, redeemablePoints) => memberAuthorized && redemptionRate?.rate > 0 && redeemablePoints > 0,
);
