export class PaymentsMapper {
    public static mapPayWithAccountChargePOSTRequest(payWithAccount: OLO.DTO.ExecuteOnlineOrderAccountChargeModel): APIv3.AccountChargeRequest {
        return payWithAccount
            ? {
                  Amount: payWithAccount.Amount,
                  SetOrderAsValidatedOnSuccess: payWithAccount.SetOrderAsValidatedOnSuccess,
              }
            : null;
    }

    public static mapPayWithAccountChargePOSTResponse(payWithAccount: APIv3.AccountChargeResponse): OLO.DTO.ExecuteAccountChargeResponse {
        return payWithAccount
            ? {
                  Status: payWithAccount.Status,
              }
            : null;
    }

    public static mapPayWithPointsPOSTResponse(payWithPoints: APIv3.PointsRedeemedModel): OLO.DTO.PointsRedeemedModel {
        return payWithPoints;
    }

    public static mapPayPOSTRequest(pay: OLO.DTO.ExecutePaymentModel): APIv3.ExecutePaymentModel {
        return pay
            ? {
                  Amount: pay.Amount,
                  PaymentAccountId: pay.PaymentAccountId != null ? +pay.PaymentAccountId : null,
                  SetOrderAsValidatedOnSuccess: pay.SetOrderAsValidatedOnSuccess,
                  PaymentProvider: pay.PaymentProvider as APIv3.ExecutePaymentModel['PaymentProvider'],
                  Token: pay.Token,
                  ExpirationDate: pay.ExpirationDate,
                  StripePaymentDetails: pay.StripePaymentDetails
                      ? {
                            CardType: pay.StripePaymentDetails.CardType,
                            ExpirationDate: pay.StripePaymentDetails.ExpirationDate,
                            IsDefault: pay.StripePaymentDetails.IsDefault,
                            Last4Digits: pay.StripePaymentDetails.Last4Digits,
                            SaveForLaterUse: pay.StripePaymentDetails.SaveForLaterUse,
                        }
                      : null,
                  ApplePayToken: pay.ApplePayToken
                      ? {
                            PaymentData: pay.ApplePayToken.PaymentData,
                            TransactionIdentifier: pay.ApplePayToken.TransactionIdentifier,
                        }
                      : null,
                  GooglePayToken: pay.GooglePayToken
                      ? {
                            PaymentData: pay.GooglePayToken.PaymentData,
                        }
                      : null,
                  FatZebraToken: pay.FatZebraToken
                      ? {
                            r: pay.FatZebraToken.r,
                            v: pay.FatZebraToken.v,
                        }
                      : null,
                  FatZebraExtra: pay.FatZebraExtra
                      ? {
                            cavv: pay.FatZebraExtra.cavv,
                            directory_server_txn_id: pay.FatZebraExtra.directory_server_txn_id,
                            par: pay.FatZebraExtra.par,
                            sli: pay.FatZebraExtra.sli,
                            threeds_version: pay.FatZebraExtra.threeds_version,
                            ver: pay.FatZebraExtra.ver,
                            xid: pay.FatZebraExtra.xid,
                        }
                      : null,
                  CardData: pay.CardData
                      ? {
                            EncryptedCardNumber: pay.CardData.EncryptedCardNumber,
                            EncryptedExpiryMonth: pay.CardData.EncryptedExpiryMonth,
                            EncryptedExpiryYear: pay.CardData.EncryptedExpiryYear,
                            EncryptedSecurityCode: pay.CardData.EncryptedSecurityCode,
                            SaveForFuture: pay.CardData.SaveForFuture,
                        }
                      : null,
                  BillingAddress: pay.BillingAddress
                      ? {
                            CountryIso2Code: pay.BillingAddress.CountryIso2Code,
                            City: pay.BillingAddress.City,
                            HouseNumber: pay.BillingAddress.HouseNumber,
                            PostalCode: pay.BillingAddress.PostalCode,
                            Street: pay.BillingAddress.Street,
                            StateOrProvince: pay.BillingAddress.StateOrProvince,
                        }
                      : null,
                  SaveBillingAddress: pay.SaveBillingAddress ?? false,
              }
            : null;
    }

    public static mapPayPOSTResponse(pay: APIv3.ExecuteTransactionResponse): OLO.DTO.ExecutePaymentResponse {
        return pay
            ? {
                  TransactionId: pay.TransactionId,
              }
            : null;
    }

    public static maPaymentStatusGETResponse(paymentStatus: APIv3.GetTransactionResponse): OLO.DTO.GetTransactionResponse {
        return paymentStatus
            ? {
                  TransactionId: paymentStatus.TransactionId,
                  Status: paymentStatus.Status,
              }
            : null;
    }
}
