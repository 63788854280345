import { Inject, Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as Services from '@shared/core/services';
import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';

import * as actions from '../actions';
import * as selectors from '../selectors';

import { combineLatest, never, Observable, of } from 'rxjs';
import { auditTime, delay, filter, map, switchMap, takeWhile, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class ModalEffects {
    private _animationTimeout: number = this._config && (this._config.modals || this._config.modals.animateTimeout) ? this._config.modals.animateTimeout : 500;

    public closeAllModalsWithExeptionTypes$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.ModalCloseAll),
            withLatestFrom(this._store.pipe(select(selectors.getAllModals))),
            switchMap(([action, modals]) =>
                modals
                    .filter((modal) => !action.typesExcludedFromClosing.includes(modal.type))
                    .map((modal) => (action.animation === OLO.Components.Modals.MODAL_ANIMATION.OUT ? actions.ModalRequestClose(modal.id) : actions.ModalClose({ id: modal.id }))),
            ),
        ),
    );

    public onModalAnyAction$: Observable<never> = createEffect(
        () =>
            this._actions$.pipe(
                ofType(actions.ModalOpen, actions.ModalRequestClose, actions.ModalBackgroundClicked, actions.ModalAnimate, actions.ModalClose),
                takeWhile(() => this._config.modals && this._config.modals.preventGlobalScroll),
                auditTime(0),
                withLatestFrom(this._store.select(selectors.getAllModals), this._modalsSharedService.modalContainer$),
                switchMap(([, modals, container]) => {
                    const isModalOpen: boolean = modals.length > 0;
                    const isScrollVisible: boolean = document.body.clientHeight > window.innerHeight;

                    if (isModalOpen) {
                        /* When open */
                        /* TODO! FIX TOPBAR tick WHEN OPENING AND CLOSING DUE TO POSITION:FIXED! */
                        if (isScrollVisible && container) {
                            container.nativeElement.style.overflowY = 'scroll';
                            /* If any difference because scrollbar */
                            const bodyWidth: number = document.body.clientWidth;
                            const windowInnerWidth: number = window.innerWidth || window.outerWidth;
                            const diff: number = windowInnerWidth - bodyWidth;

                            if (diff) {
                                document.body.style.paddingRight = `${diff}px`;
                            }

                            document.body.style.overflowY = 'hidden';
                        }
                    } else {
                        /* When closed */
                        if (container) {
                            container.nativeElement.style.overflowY = null;
                        }
                        /* IE FIX - AOLO-208 */
                        document.body.removeAttribute('style');
                    }

                    return never();
                }),
            ),
        { dispatch: false },
    );

    public onModalCloseRequest$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.ModalRequestClose),
            map((action) => actions.ModalAnimate(action.modalId, OLO.Components.Modals.MODAL_ANIMATION.OUT)),
        ),
    );

    public onModalAnimateOut$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.ModalAnimate),
            filter((action) => action.animation === OLO.Components.Modals.MODAL_ANIMATION.OUT),
            delay(this._animationTimeout),
            map((action) => actions.ModalClose({ id: action.id })),
        ),
    );

    public onModalBackgroundClick$: Observable<Action | never> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.ModalBackgroundClicked),
            switchMap(() => {
                if (this._config.modals && this._config.modals.bgClickClose) {
                    return of(actions.ModalRequestClose());
                }

                return never();
            }),
        ),
    );

    public showFirstLoadIntroModalOnLocationOrCollectionTypeChange$: Observable<void> = createEffect(
        () =>
            combineLatest({
                currentLocationSet: this._actions$.pipe(ofType(actions.CurrentLocationSet)),
                setCollectionType: this._actions$.pipe(ofType(actions.SetCollectionType)),
            }).pipe(
                withLatestFrom(
                    this._store.pipe(select(selectors.getAllModals)),
                    this._store.pipe(select(selectors.getCurrentLocationNo)),
                    this._store.pipe(select(selectors.routeIsLocationDetailsPage())),
                    this._store.pipe(select(selectors.getOrderTypeId)),
                    this._store.pipe(select(selectors.isCartEmpty)),
                    this._store.pipe(select(selectors.getCartPickupTime)),
                    this._store.pipe(select(selectors.getCurrentPickupTime)),
                    this._store.pipe(select(selectors.getCartLocationNo)),
                ),
                filter(([, , currentLocationNo, isLocationDetailsPage, orderTypeId, isCartEmpty, cartPickupTime, currentPickupTime, cartLocationNo]) => {
                    const currentLocationDetailsPage = Utils.DynamicPages.getCurrentPageSetup(this._config.locationDetailsPage, orderTypeId);
                    const locationIsNumber = typeof currentLocationNo === 'number' && isLocationDetailsPage && isCartEmpty;
                    const locationNoChange = currentLocationNo !== cartLocationNo;
                    const pickupTimeNotChanged = cartPickupTime && currentPickupTime && cartPickupTime.Id === currentPickupTime.Id;

                    return !!currentLocationDetailsPage?.firstLoadIntroductionModal && ((locationIsNumber && !pickupTimeNotChanged) || locationNoChange);
                }),
                switchMap(([, , currentLocationNo]) => {
                    this._modalsSharedService.show({
                        type: 'first-load-intro',
                        locationNo: currentLocationNo,
                    });

                    return [];
                }),
            ),
        { dispatch: false },
    );

    public showDeliveryModalOnLocationOrCollectionTypeChange$: Observable<void> = createEffect(
        () =>
            combineLatest({
                currentLocationSet: this._actions$.pipe(ofType(actions.CurrentLocationSet)),
                setCollectionType: this._actions$.pipe(ofType(actions.SetCollectionType)),
            }).pipe(
                withLatestFrom(
                    this._store.pipe(select(selectors.getAllModals)),
                    this._store.pipe(select(selectors.getCurrentLocationNo)),
                    this._store.pipe(select(selectors.routeIsLocationDetailsPage())),
                    this._store.pipe(select(selectors.getOrderTypeId)),
                    this._store.pipe(select(selectors.getCartDeliveryAddress)),
                ),
                filter(([, , currentLocationNo, isLocationDetailsPage, orderTypeId, cartDeliveryAddress]) => {
                    const selectedCollectionType = new Utils.CollectionTypeHelper(this._config.collectionTypes).getCollectionTypeByOrderTypeId(orderTypeId);

                    return (
                        typeof currentLocationNo === 'number' &&
                        isLocationDetailsPage &&
                        !cartDeliveryAddress &&
                        selectedCollectionType &&
                        'deliveryAddressModal' in selectedCollectionType
                    );
                }),
                switchMap(([, , currentLocationNo]) => {
                    this._modalsSharedService.show({
                        type: 'delivery-address',
                        locationNo: currentLocationNo,
                    });

                    return [];
                }),
            ),
        { dispatch: false },
    );

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: OLO.Config,
        private _actions$: Actions,
        private _store: Store<OLO.State>,
        private _modalsSharedService: Services.ModalsService,
    ) {}
}
