import { Observable } from 'rxjs';

export class Images {
    public static toImageTypeString(imageType: OLO.Enums.IMAGE_TYPE): keyof OLO.State.LocationsImages {
        switch (true) {
            case imageType === 2:
                return 'forList';
            case imageType === 3:
                return 'forDetails';
            case imageType === 4:
                return 'forWeb';
            case imageType === 5:
                return 'forMobile';
            case imageType === 6:
                return 'forPushNotifications';
            case imageType === 7:
                return 'forLogo';
            case imageType === 400:
                return 'forWebThumb';
            default:
                return 'default';
        }
    }

    public static preDownload(urls: string[], headers?: { [key: string]: string }): Observable<string[]> {
        //
        //  https://github.com/pmiatkowski/imgworker/blob/master/img.worker.js
        //  Predownload images in the back instead of inserting them into [src] property and waiting to download
        //
        return new Observable((observer) => {
            if (!urls.length) {
                observer.next([]);
                observer.complete();

                return;
            }

            let error: boolean = false;
            let toDownload: number = urls.length;
            let downloadingRequests: XMLHttpRequest[] = [];
            let downloaded: string[] = [];

            urls.forEach((url) => {
                if (error || toDownload === downloaded.length) return;
                let xhr = new XMLHttpRequest();
                downloadingRequests.push(xhr);
                xhr.open('GET', url, true);
                if (headers && Object.keys(headers).length) {
                    Object.keys(headers).forEach((key) => {
                        xhr.setRequestHeader(key, headers[key]);
                    });
                }
                xhr.responseType = 'arraybuffer';

                xhr.onerror = (ex) => {
                    error = true;

                    observer.error(ex);
                };
                xhr.onreadystatechange = () => {
                    if (xhr.readyState === 4) {
                        if (xhr.status >= 200 && xhr.status < 300) {
                            console.warn('got response', xhr.response);
                            downloaded.push('data:' + xhr.getResponseHeader('Content-Type') + ';base64,' + btoa(String.fromCharCode.apply(null, new Uint8Array(xhr.response))));

                            if (downloaded.length === toDownload) {
                                observer.next(downloaded);
                                observer.complete();
                            }
                        } else {
                            error = true;
                            observer.error(false);
                        }
                    }
                };
                xhr.send(null);
            });

            return () => {
                downloadingRequests.forEach((request) => {
                    try {
                        request.abort();
                    } catch (ex) {}
                });
            };
        });
    }
}
