import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { modalReducer } from './modal.reducer';
import { ModalEffects } from './modal.effects';

@NgModule({
    declarations: [],
    imports: [CommonModule, StoreModule.forFeature('modals', modalReducer), EffectsModule.forFeature([ModalEffects])],
    exports: [],
    providers: [],
})
export class ModalsStateModule {}
