import { Injectable } from '@angular/core';
import { Action, Store, select } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';
import * as Services from '@shared/core/services';

import { Observable, of } from 'rxjs';
import { map, catchError, mergeMap, delay, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class OnlineMenuPagesImagesEffects {
    public requestOnlineMenuPagesImages$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.OnlineMenuPagesImagesRequest),
            withLatestFrom(this._store.pipe(select(selectors.getOnlineMenuPagesImages))),
            mergeMap(([action]) =>
                // const imageType: OLO.Enums.IMAGE_TYPE = OLO.Enums.IMAGE_TYPE.ForWeb;
                // const imageTypeName: string = Utils.Images.toImageTypeString(imageType);
                // const unduplicatedPageIds = action.pageIds.reduce((acc, pageId) => {
                //     const foundImg = images[imageTypeName].reduce((imgFound, type) => {
                //         if (imgFound) return imgFound;

                //         return type[imageType]?.find(obj => obj.pageId === pageId) || null;
                //     }, null);

                //     const shouldDownload = !foundImg || foundImg.hasSucceeded === false && foundImg.isDownloading === false;
                //     if (shouldDownload) {
                //         return [
                //             ...acc,
                //             pageId,
                //         ];
                //     }

                //     return acc;

                // }, [] as Array<number>);

                // if(!unduplicatedPageIds.length) {
                //     return [];
                // }

                this._imagesService.getImagesForOnlineMenuPages(action.params, action.imageType, action.pageIds).pipe(
                    delay(1000),
                    map((payload) => actions.OnlineMenuPagesImagesSuccessRequest({ pageIds: action.pageIds, imageType: action.imageType, payload })),
                    catchError((ex) => {
                        console.error('Error downloading online menu pages images', ex);

                        return of(actions.OnlineMenuPagesImagesErrorRequest({ pageIds: action.pageIds, imageType: action.imageType, ex }));
                    }),
                ),
            ),
        ),
    );

    constructor(private _actions$: Actions, private _imagesService: Services.ImagesService, private _store: Store<OLO.State>) {}
}
