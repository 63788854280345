import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './product-restrictions.actions';

const initialState: OLO.State.ProductRestrictions = {
    isDownloading: false,
    hasSucceeded: false,
    hasFailed: false,
    LocationNo: null,
    DateToCheck: null,
    data: null,
    productsWithRestrictions: null,
};

export const productRestrictionsReducerFn = createReducer(
    initialState,
    on(actions.ProductRestrictionsRequest, (state, action) => ({
        ...state,
        isDownloading: true,
        hasSucceeded: false,
        hasFailed: false,
        LocationNo: action.params.locationNo,
        DateToCheck: action.params.dateToCheck,
    })),
    on(actions.ProductRestrictionsSuccessRequest, (state, action) => ({
        ...state,
        isDownloading: false,
        hasSucceeded: true,
        hasFailed: false,
        data: action.payload,
    })),

    on(actions.ProductRestrictionsErrorRequest, (state, action) => ({
        ...state,
        isDownloading: false,
        hasSucceeded: true,
        hasFailed: false,
        LocationNo: null,
        DateToCheck: null,
        data: null,
    })),

    on(actions.ProductAddRestrictions, (state, action) => ({
        ...state,
        productsWithRestrictions: action.payload,
    })),
);

export function productRestrictionsReducer(state: OLO.State.ProductRestrictions | undefined, action: Action) {
    return productRestrictionsReducerFn(state, action);
}
