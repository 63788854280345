import { createSelector } from '@ngrx/store';

import * as Utils from '@shared/core/utils';
import * as Statics from '@shared/core/statics';

import { isDownloadingAnyOrderTypes, getOrderTypesForLocation } from '@shared/state/orderTypes/selectors';
import { getCollectionType, getSelectedCollectionType } from '@shared/state/collectionType/selectors';
import { getLocationDetails } from '@shared/state/locations/selectors';

export const getDefaultOrderTypeForLocation = (locationNo: number) =>
    createSelector(
        isDownloadingAnyOrderTypes,
        getOrderTypesForLocation(locationNo),
        getCollectionType,
        getSelectedCollectionType,
        getLocationDetails(locationNo),
        (isDownloading, orderTypes, collectionType, selectedCollectionType, location) => {
            const config = new Statics.ConfigStatic().current;

            const collectionTypeId = selectedCollectionType?.collectionTypeId;
            if (isDownloading || !orderTypes?.data || !collectionType || collectionTypeId == null || !location) return null;

            let foundOrderType: OLO.DTO.OrderTypeModel;

            if (collectionType.orderTypeId) {
                foundOrderType = orderTypes.data.find((obj) => obj.Id === collectionType.orderTypeId);
            }

            if (foundOrderType) return foundOrderType;
            const orderingTimeInfo = new Utils.LocationOrderingTimeInfo(location).getOrderingTimeInfo();
            const isLocationOpen = new Utils.LocationOpenStatus(orderingTimeInfo[0]).isOpenForProvidedDate(Utils.Dates.getLocalISOFormatDate(new Date()));
            let firstAvailableOrderType: OLO.DTO.OrderTypeModel;

            orderTypes.data.forEach((orderType) => {
                if (firstAvailableOrderType) return;
                const orderTypeCheck = new Utils.CollectionTypeGroupDetector(orderType.Id, config);
                if (orderTypeCheck.isDineIn() && !isLocationOpen) {
                    return;
                }

                firstAvailableOrderType = orderType;
            });

            foundOrderType = orderTypes.data.find((obj) => new Utils.CollectionTypeGroupDetector(obj.Id, config).getCollectionType() === collectionTypeId);

            return foundOrderType || firstAvailableOrderType || null;
        },
    );
