import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule, FullRouterStateSerializer } from '@ngrx/router-store';

import { stateMetaReducers } from '@shared/state';
import { ROOT_REDUCERS } from '@shared/core/tokens/root-reducers.tokens';
import { StateSharedModule } from '@shared/state/state.shared.module';
import { RootRoutesModule } from './routes-root.module';
import { SharedProvidersModule } from '@shared/shared-providers.module';

import { AppComponent } from './app.component';

import { environment } from '../environments/environment';
import { buildEnvironment } from '../environments/environment.build';
import { extModules } from '../build';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserAnimationsModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        RootRoutesModule,
        StoreModule.forRoot(ROOT_REDUCERS, {
            metaReducers: [...stateMetaReducers],
            runtimeChecks: {
                strictStateImmutability: false,
                strictActionImmutability: false /* Throws an error from meta.decorator */,
                strictStateSerializability: false,
                strictActionSerializability: false /* Thorw an error from action */,
            },
        }),
        EffectsModule.forRoot([]),
        StoreRouterConnectingModule.forRoot({ serializer: FullRouterStateSerializer }),
        StateSharedModule,

        SharedProvidersModule.forRoot({
            environment,
            buildEnvironment,
        }),
        extModules,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
