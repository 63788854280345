import { OrderPaymentMethod, OrderPaymentDetails } from './order-payment-method';

export class OrderPaymentWithConverge extends OrderPaymentMethod {
    public async generatePaymentDetails(details: OrderPaymentDetails) {
        const { paymentProvider, paymentMethod } = details;

        if (!this._isVendorPayment(paymentMethod) && paymentProvider === OLO.Enums.PAYMENT_PROVIDER.CONVERGE) {
            this._extendExecutePaymentModel(details);

            return this._executePaymentModel;
        }

        if (this._next) {
            return this._next.generatePaymentDetails(details);
        }

        return this._executePaymentModel;
    }
}
