import { createSelector } from '@ngrx/store';

import { getMenuFlows } from '../getMenuFlows';

export const isMenuFlowRequestingDetails = (menuFlowId: number, locationNo: number) =>
    createSelector(getMenuFlows, (menuFlows) => {
        if (!menuFlowId || !locationNo) return false;

        return menuFlows?.some((obj) => obj.MenuFlowId === menuFlowId && obj.LocationNo === locationNo && obj.isDownloading === true);
    });
