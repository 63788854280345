import { createAction, props } from '@ngrx/store';

export const IngredientsRequest = createAction('[Ingredients] Request ingredients by locationNo & productIds', props<{ locationNo: number; productIds: number[] }>());

export const IngredientsSuccessRequest = createAction(
    '[Ingredients] Success request ingredients by locationNo & productIds',
    props<{ payload: APICommon.ProductLocationIngredientExtended[]; locationNo: number; productIds: number[] }>(),
);

export const IngredientsErrorRequest = createAction(
    '[Ingredients] Error request ingredients by locationNo & productIds',
    props<{ locationNo: number; productIds: number[]; ex?: any }>(),
);
