import { Injectable, Inject } from '@angular/core';
import { Store } from '@ngrx/store';

import * as Tokens from '@shared/core/tokens';

import * as actions from '@shared/state/actions';

@Injectable({
    providedIn: 'root',
})
export class VenueImagesService {
    public venueId: number;

    constructor(@Inject(Tokens.CONFIG_TOKEN) public config: OLO.Config, public store: Store<OLO.State>) {
        this.venueId = this.config.venue ? this.config.venue.id : null;
    }

    public requestVenueImage(imageType: OLO.Enums.IMAGE_TYPE = OLO.Enums.IMAGE_TYPE.ForWeb): void {
        if (!this.venueId) return;
        setTimeout(() => {
            this.store.dispatch(actions.VenueImagesRequest([this.venueId], imageType, window.innerWidth, window.innerHeight));
        }, 50);
    }
}
