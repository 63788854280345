import { createSelector } from '@ngrx/store';

import { getMemberState, isMemberLoading } from '@shared/state/members/selectors';
import { getCardsState } from '@shared/state/creditCards/selectors';
import { isCartZeroPrice } from '@shared/state/cart/selectors/isCartZeroPrice';

export const isPaymentDisabledForMemberWithCreditCard = createSelector(
    getMemberState,
    isMemberLoading,
    getCardsState,
    isCartZeroPrice,
    (membersState, memberLoading, cardsState, isNoPriceCart) => {
        const noGuestData = membersState.isGuestModeEnabled && !membersState.guestData;
        const noRequiredCardData = !cardsState.activeCardToken && !cardsState.activeCardId && !isNoPriceCart;
        const noMemberData = !membersState.isGuestModeEnabled && !membersState.data;

        return memberLoading || noGuestData || noRequiredCardData || noMemberData;
    },
);
