import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';

import { Observable, throwError } from 'rxjs';

import { map, catchError, switchMap } from 'rxjs/operators';
import { CardConnectPaymentProviderMapper } from '@shared/core/mappers/paymentProviders/card-connect.payment-provider.shared.mapper';

@Injectable({
    providedIn: 'root',
})
export class CardConnectPaymentProviderService {
    private _cvvForLatestCard: Map<string, string> = new Map();
    constructor(@Inject(Tokens.CONFIG_TOKEN) public config: OLO.Config, public httpClient: HttpClient) {}

    public getCardConnectSettings$(locationNo: number): Observable<OLO.DTO.CardConnectSettingsResponse> {
        return this.httpClient
            .get<APIv3.CardConnectSettingsResponse>(`${Utils.HTTP.switchApi(this.config.api.base)}/Payments/cardConnect/settings/${locationNo}`)
            .pipe(map((response: APIv3.CardConnectSettingsResponse) => CardConnectPaymentProviderMapper.mapGetCardConnectSettings(response)));
    }

    /**
     * @deprecated For compliance reasons use requestCardToken2 method instead to handle exp date and cvv code.
     * @param cardNumber
     * @param locationNo
     * @returns
     */
    public requestCardToken$(cardNumber: string, locationNo: Nullable<number> = null): Observable<PPCardConnect.SecureResponse> {
        return this.getCardConnectSettings$(locationNo).pipe(
            switchMap((settings: APIv3.CardConnectSettingsResponse) => this._cardConnectSecureCard$(settings.ApiUrl, cardNumber)),
            map((response) => {
                if (response.cardsecure.action === 'ER') throw response;

                return response;
            }),
            catchError((ex) => throwError(ex)),
        );
    }

    /**
     * @deprecated For compliance reasons use _cardConnectSecureCard2 method instead to handle exp date and cvv code.
     * @param apiUrl
     * @param cardNo
     * @returns
     */

    /* eslint-disable @typescript-eslint/naming-convention, quote-props*/
    protected _cardConnectSecureCard$(apiUrl: string, cardNo: number | string): Observable<PPCardConnect.SecureResponse> {
        return this.httpClient
            .get(`${apiUrl}/cardsecure/cs?action=CE&data=${Utils.CreditCards.processCardNumber(cardNo)}&type=xml`, {
                responseType: 'text',
                headers: new HttpHeaders({
                    'Content-Type': 'text/xml',
                    Accept: 'text/xml',
                }),
            })
            .pipe(map((response: string) => CardConnectPaymentProviderMapper.mapCardConnectSecureCard(response)));
    }

    public requestCardToken2$(cardDetails: OLO.CreditCards.CreditCardDetails, locationNo: Nullable<number> = null): Observable<OLO.DTO.CardConnectTokenResponse> {
        if (cardDetails.cvv) {
            this._cvvForLatestCard.set(cardDetails.cardNumber.substring(cardDetails.cardNumber.length - 4), cardDetails.cvv);
        }

        return this.getCardConnectSettings$(locationNo).pipe(
            switchMap((settings: APIv3.CardConnectSettingsResponse) => this._cardConnectSecureCard2$(settings.ApiUrl, cardDetails)),
            map((response) => {
                if (response.errorcode !== 0) throw response;

                return response;
            }),
            catchError((ex) => throwError(ex)),
        );
    }

    public getLatestCvv(cardNumber: string): string {
        return this._cvvForLatestCard.get(cardNumber);
    }

    protected _cardConnectSecureCard2$(apiUrl: string, cardDetails: OLO.CreditCards.CreditCardDetails): Observable<OLO.DTO.CardConnectTokenResponse> {
        const requestDetails = CardConnectPaymentProviderMapper.mapCardDetails(cardDetails);

        return this.httpClient
            .post<PPCardConnect.ResponseToken>(`${apiUrl}/cardsecure/api/v1/ccn/tokenize`, requestDetails)
            .pipe(map((response) => CardConnectPaymentProviderMapper.mapSecuredTokenResponse(response)));
    }
    /* eslint-enable @typescript-eslint/naming-convention, quote-props*/
}
