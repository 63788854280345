import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';

import * as Tokens from '@shared/core/tokens';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MenuFlowsMapper } from '@shared/core/mappers/menu-flows.shared.mapper';

@Injectable({
    providedIn: 'root',
})
export class MenuFlowsService {
    constructor(@Inject(Tokens.CONFIG_TOKEN) public config: OLO.Config, public httpClient: HttpClient, public store: Store<OLO.State>) {}

    public getMenuFlowDetailsForLocation(menuFlowId: number, locationNo: number): Observable<OLO.DTO.MenuFlowDetailsModel> {
        return this.httpClient
            .get<APIv3.MenuFlowDetailsModel>(`${this.config.api.base}/menuFlows/${menuFlowId}/details/${locationNo}`)
            .pipe(map((menuFlow: APIv3.MenuFlowDetailsModel) => MenuFlowsMapper.mapGetMenuFlowDetailsForLocation(menuFlow)));
    }

    public getFlatProductsFromMenuFlow(menuFlow: OLO.DTO.MenuFlowDetailsModel): OLO.DTO.MenuFlowProduct[] {
        return menuFlow.Pages.reduce((prevPage, nextPage) => prevPage.concat(nextPage.Products), []);
    }

    public getDefaultActivation(menuFlowId: number): Observable<OLO.DTO.MenuFlowDefaultActivation> {
        return this.httpClient
            .get<APIv3.MenuFlowDefaultActivation>(`${this.config.api.base}/menuFlows/${menuFlowId}/defaultActivation`)
            .pipe(map((menuFlow: APIv3.MenuFlowDefaultActivation) => MenuFlowsMapper.mapGetDefaultActivation(menuFlow)));
    }
}
