import { createSelector } from '@ngrx/store';

import * as Utils from '@shared/core/utils';

import { locationsImagesState } from '../root';

export const getLocationImagesForIds = (imageType: OLO.Enums.IMAGE_TYPE, locationNos: number[]) =>
    createSelector(locationsImagesState, (locationsImages) => {
        const targetImageType = Utils.Images.toImageTypeString(imageType);

        return locationsImages[targetImageType]?.filter((obj: OLO.State.LocationsImages.ImageStateObj) => locationNos.includes(obj.Id) || null);
    });
