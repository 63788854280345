import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './app-settings.actions';

const initialState: OLO.State.AppSettings = {
    isOnline: true,
    hasInitialized: false,
    locationMode: null,
    deviceType: null,
    apiSettings: {
        isDownloading: false,
        hasSucceeded: false,
        hasFailed: false,
        downloadedDate: null,
        data: null,
    },
};

export function appSettingsReducer(s: OLO.State.AppSettings | undefined, a: Action) {
    return createReducer(
        initialState,
        on(actions.AppSettingsSetOnlineStatus, (state, action) => ({
            ...state,
            isOnline: action.online,
        })),
        on(actions.AppSettingsRequest, (state) => ({
            ...state,
            apiSettings: {
                ...state.apiSettings,
                isDownloading: true,
                hasSucceeded: false,
                hasFailed: false,
            },
        })),
        on(actions.AppSettingsSuccessRequest, (state, action) => ({
            ...state,
            apiSettings: {
                ...state.apiSettings,
                isDownloading: false,
                hasSucceeded: true,
                hasFailed: false,
                downloadedDate: new Date().getTime(),
                data: action.payload,
            },
        })),
        on(actions.AppSettingsErrorRequest, (state) => ({
            ...state,
            apiSettings: {
                ...state.apiSettings,
                isDownloading: false,
                hasSucceeded: false,
                hasFailed: true,
            },
        })),
        on(actions.SetApplicationInitFlag, (state) => ({
            ...state,
            hasInitialized: true,
        })),
        on(actions.SetApplicationMode, (state, action) => ({
            ...state,
            locationMode: action.mode,
        })),
        on(actions.SetDeviceType, (state, { deviceType }) => ({
            ...state,
            deviceType,
        })),
    )(s, a);
}
