import * as Statics from '@shared/core/statics';
import { PAYMENT_PROVIDERS_WITH_CVV_VALIDATION } from '@shared/core/consts';

type CVVValidationArray = (typeof PAYMENT_PROVIDERS_WITH_CVV_VALIDATION)[number];

export class CVV {
    public static isCVVValidationRequired(): boolean {
        const config = new Statics.ConfigStatic().current;
        const paymentProvider = config.payments.baseProvider;

        return PAYMENT_PROVIDERS_WITH_CVV_VALIDATION.includes(paymentProvider as CVVValidationArray);
    }
}
