import { NgModule, Inject } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes, Router, RouteConfigLoadEnd, Route } from '@angular/router';

import { AppModeOrderingGuard } from '@shared/core/guards/app-mode-ordering.shared.guard';
import { UniqueCodeGuard } from '@shared/core/guards/unique-code.shared.guard';
import { DynamicPathsGuard } from '@shared/core/guards/dynamic-paths.shared.guard';
import { SelectivePreloadingStrategyService } from '@shared/core/resolvers/selective-preloading-strategy.shared.service';
import * as Tokens from '@shared/core/tokens';
import { ConfirmationGuard } from '@shared/core/guards/confirmation.shared.guard';

export const routes: Routes = [
    {
        path: 'order-confirmation-static',
        loadChildren: () => import('./views/OrderConfirmation/types/OrderConfirmationStatic/order-confirmation-static.module').then((m) => m.OrderConfirmationStaticModule),
        canActivate: [AppModeOrderingGuard, ConfirmationGuard],
        data: { isLiveViewType: false },
    },
    {
        path: 'payment-gateway',
        loadChildren: () => import('./views/PaymentGateway/payment-gateway.module').then((m) => m.PaymentGatewayModule),
        canActivate: [AppModeOrderingGuard],
    },
    {
        path: 'sign-up',
        loadChildren: () => import('./views/SignUp/sign-up.module').then((m) => m.SignUpModule),
        canActivate: [UniqueCodeGuard],
    },
    {
        path: '',
        loadChildren: () => import('./views/views.module').then((m) => m.ViewsModule),
    },
    {
        path: '**',
        loadChildren: () => import('./views/Error404/error404.module').then((m) => m.Error404Module),
        canActivate: [DynamicPathsGuard],
    },
];

@NgModule({
    imports: [
        BrowserAnimationsModule,
        RouterModule.forRoot(routes, {
            // preloadingStrategy: QuicklinkStrategy, // Resolvers.SelectivePreloadingStrategyService,
            useHash: Boolean(history.pushState) === false,
            /*
             * This feature still does not work reliably,
             * especially the part about restoring the previous scroll position when navigating.
             */
            scrollPositionRestoration: 'enabled',
        }),
    ],
    exports: [RouterModule],
    providers: [SelectivePreloadingStrategyService],
})
export class RootRoutesModule {
    private _init: boolean = false;
    constructor(@Inject(Tokens.CONFIG_TOKEN) private _config: OLO.Config, private _router: Router, private _dynamicPathsGuard: DynamicPathsGuard) {
        const venueName = this._config?.venue?.name;
        const foundCollectionTypeUrl = this._config.collectionTypes.some((collectionType) => !!collectionType.url);

        if (venueName || foundCollectionTypeUrl) {
            this._router.events.subscribe(async (routerEvent) => {
                if (routerEvent instanceof RouteConfigLoadEnd) {
                    // OLD: Trigger change detection so _loadedConfig is available in router
                    // NEW: I can't find "_loadedConfig", but probably it has been removed in Angular 14+, so refactored to use "_loadedRoutes"
                    setTimeout(() => {
                        let routesHasBeenLoaded = null;
                        this._router.config.forEach((root) => {
                            if (root.path === '' && (root as any)._loadedRoutes) {
                                routesHasBeenLoaded = false;
                                const foundChild: Route = (root as any)._loadedRoutes?.[0] ?? null;
                                if (!foundChild) return;
                                if (venueName) {
                                    try {
                                        const foundMainChild = foundChild.children?.find((obj) => obj.path === 'VENUE_DYNAMIC_URL') ?? null;

                                        if (foundMainChild) {
                                            foundMainChild.path = venueName;
                                            foundMainChild.data = { ...foundMainChild.data, isDynamic: true };
                                            routesHasBeenLoaded = true;
                                        }
                                    } catch (ex) {
                                        console.warn('Unable to set locations dynamic routes.  Try refresh app', ex, foundChild);
                                    }
                                }
                                if (foundCollectionTypeUrl) {
                                    try {
                                        const foundCollectionTypeMainChild = foundChild.children?.find((obj) => obj.path === 'COLLECTION_TYPE_DYNAMIC_URL') ?? null;

                                        if (foundCollectionTypeMainChild) {
                                            const collectionTypeUrls = this._config.collectionTypes.reduce((arrUrls, collectionType) => {
                                                if (collectionType.url) {
                                                    return [...arrUrls, collectionType.url];
                                                }

                                                return arrUrls;
                                            }, []);
                                            collectionTypeUrls.forEach((collectionTypeUrl) => {
                                                foundCollectionTypeMainChild.path = collectionTypeUrl;
                                                foundCollectionTypeMainChild.data = { ...foundCollectionTypeMainChild.data, isDynamic: true, isCollectionTypeUrl: true };
                                            });
                                            routesHasBeenLoaded = true;
                                        }
                                    } catch (ex) {
                                        console.warn('Unable to set locations dynamic routes.  Try refresh app', ex, foundChild);
                                    }
                                }
                            }
                        });
                        this._dynamicPathsGuard.routeUpdate$.next(routesHasBeenLoaded);
                    }, 1000);
                }
            });
        } else {
            this._dynamicPathsGuard.routeUpdate$.next(false);
        }
    }
}
