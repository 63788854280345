import { createSelector } from '@ngrx/store';

import { membersState } from '../root';

export const getMemberPhoneNo = createSelector(membersState, (members) => {
    let mobilePhone: Nullable<string> = null;
    switch (true) {
        case members.authorizationStep === OLO.Enums.AUTH_STEP.BEFORE_UPDATE_VERIFY_PHONE && members.update.data?.MobilePhone != null:
            mobilePhone = members.update.data.MobilePhone;
            break;
        case members.addData?.MobilePhone != null:
            mobilePhone = members.addData.MobilePhone;
            break;

        case members.data?.MobilePhone != null:
            mobilePhone = members.data.MobilePhone;
            break;

        default:
            return null;
    }

    return mobilePhone;
});
