import { createSelector } from '@ngrx/store';
import { cartState } from '../root';
import * as Utils from '@shared/core/utils';
import { ConfigStatic } from '@shared/core/statics';

export const isCartCollectionTypeDelivery = createSelector(cartState, (cart) => {
    if (!cart?.orderTypeId) return false;

    const config = new ConfigStatic().current;

    return new Utils.CollectionTypeGroupDetector(cart?.orderTypeId, config).isDelivery();
});
