import { createSelector } from '@ngrx/store';
import * as Statics from '@shared/core/statics';
import { getVirtualLocations } from '../getVirtualLocations/get-virtual-locations.selector';

export const getVirtualLocationsForDropdown = createSelector(getVirtualLocations, (virtualLocationsData) => {
    const text = new Statics.TextsStatic().current;
    const allItems: OLO.Components.DropDown.DropDownOption[] = [
        {
            Id: OLO.Enums.VIRTUAL_LOCATION_DROPDOWN.ALL,
            Name: text.common.all,
        },
    ];

    return allItems.concat(
        virtualLocationsData.map((virtualLocation) => ({
            Id: virtualLocation.LocationNo,
            Name: virtualLocation.LocationDescription,
        })),
    );
});
