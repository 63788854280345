import { Injectable, Inject } from '@angular/core';
import { Store } from '@ngrx/store';

import * as actions from '@shared/state/actions';

import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';

import { LoyaltyAppService } from './loyalty-app.shared.service';

import { Subscription } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AppSettingsService {
    private _internetConnectionListener$: Subscription;

    constructor(@Inject(Tokens.CONFIG_TOKEN) public config: OLO.Config, public store: Store<OLO.State>, public loyaltyAppService: LoyaltyAppService) {}

    public init(): void {
        this._internetConnectionListener$ = Utils.HTTP.isOnline$().subscribe((online) => this.store.dispatch(actions.AppSettingsSetOnlineStatus({ online })));

        this.loyaltyAppService.requestLoyaltyAppInfo();

        // this._setupCurrentCollectionTypes(this.config);
        this._setVenueOrLocationMode(
            this.config.venue && this.config.venue.id && this.config.venue.name ? OLO.Enums.APP_LOCATION_MODE.VENUE : OLO.Enums.APP_LOCATION_MODE.LOCATION,
        );
        this._setDeviceType(Utils.Devices.isMobile() ? OLO.Enums.DEVICE_TYPE.MOBILE : OLO.Enums.DEVICE_TYPE.DESKTOP);
    }

    private _setVenueOrLocationMode(mode: OLO.Enums.APP_LOCATION_MODE): void {
        this.store.dispatch(actions.SetApplicationMode({ mode }));
    }

    private _setDeviceType(deviceType: OLO.Enums.DEVICE_TYPE): void {
        this.store.dispatch(actions.SetDeviceType({ deviceType }));
    }

    public setInitAppInitFlag(): void {
        this.store.dispatch(actions.SetApplicationInitFlag());
    }
}
