export class TagSettingsUtils {
    constructor(tags: OLO.DTO.TagModel[], appSettingsTags: OLO.DTO.LoyaltyAppTagSettingsModel) {
        this._tags = tags;
        this._appSettingsTags = appSettingsTags;
    }
    private _appSettingsTags: OLO.DTO.LoyaltyAppTagSettingsModel = null;
    private _tags: OLO.DTO.TagModel[] = null;

    public get isStandardMenuFlow(): boolean {
        return !this.isEnchancedMenuItem && !this.isStepThroughMenuItem && !this.isCreateYourOwnMenuItem && !this.isCombinedPagesMenuItem;
    }

    public get isEnchancedMenuItem(): boolean {
        return this._tags?.some((tag) => tag.Id === this._appSettingsTags?.EnhancedMenuItemTagId);
    }

    public get isStepThroughMenuItem(): boolean {
        return this._tags?.some((tag) => tag.Id === this._appSettingsTags?.StepThroughMenuItemTagId);
    }

    public get isCreateYourOwnMenuItem(): boolean {
        return this._tags?.some((tag) => tag.Id === this._appSettingsTags?.CreateYourOwnMenuItemTagId);
    }

    public get isCombinedPagesMenuItem(): boolean {
        return this._tags?.some((tag) => tag.Id === this._appSettingsTags?.CombinedPagesMenuItemTagId);
    }

    public get isFeaturedMenuItem(): boolean {
        return this._tags?.some((tag) => tag.Id === this._appSettingsTags?.FeaturedMenuItemTagId);
    }

    public getModalType(): OLO.Components.Modals.MODAL_TYPE {
        let modalType: OLO.Components.Modals.MODAL_TYPE = 'standard-menu-flow'; /* no tag */

        if (this._tags) {
            switch (true) {
                case this.isEnchancedMenuItem:
                    modalType = 'enhanced-menu-flow';
                    break;
                case this.isStepThroughMenuItem:
                    modalType = 'step-through-menu-flow';
                    break;
                case this.isCombinedPagesMenuItem:
                    modalType = 'combined-pages-menu-flow';
                    break;
            }
        }

        return modalType;
    }
}
