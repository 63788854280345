import { Hash } from '@shared/core/utils/hash.utils';

type FlushFn = () => void;

interface ComparerFunctionResult {
    (prevArgs: Nullable<any>, nextArgs: Nullable<any>): boolean;
    flush: FlushFn;
}

interface MemoizedFunction<T extends (...args: Parameters<T>) => any> {
    (...args: Parameters<T>): any;
    flush: FlushFn;
}

interface MemoizedPropertyDescriptor extends PropertyDescriptor {
    $$memoizing?: boolean;
    $$isMemoized?: boolean;
    value?: PropertyDescriptor['value'] & { $$originalValue?: any; $$flush?: FlushFn };
}

type Params = {
    /** Defines time in miliseconds after which memoized result will be cleared. `null` is off */
    timeout: number;
    /**
     * Provide custom arguments comparer function. This is optional - if not provided, JSON.stringify method will be used to compare previous and the current argument.
     * This might not be the best option if we want to handle more complex scenarios with object like params.
     */
    compareFactory?: (ctx?: any) => ComparerFunctionResult;
};

export class Memoization {
    public static readonly defaultComparerFactory = (): ComparerFunctionResult => {
        const registry: Record<string, { args: any; created: number }> = {};

        const comparerFunc = (_, next) => {
            let hasOccured: boolean = true;
            const regKey = Hash.hash64(JSON.stringify(next));
            if (!(regKey in registry)) {
                registry[regKey] = {
                    args: next,
                    created: Date.now(),
                };

                hasOccured = false;
            }

            return hasOccured;
        };

        comparerFunc.flush = () => {
            Object.keys(registry).forEach((key) => delete registry[key]);
        };

        return comparerFunc;
    };

    /**
     * Allows to memoize any function call. This should be used for heavy operations only.
     * @param {Function} originalMethod
     * @param {Params} params
     * @returns {Function} memoized function
     */
    public static memoizeFunctionFactory<T extends (...args: Parameters<T>) => any>(originalMethod: T, params: Partial<Params> = {}): MemoizedFunction<T> {
        const opts: Params = {
            timeout: null,
            ...params,
        };

        const comparer = typeof opts.compareFactory === 'function' ? opts.compareFactory(this) : Memoization.defaultComparerFactory();
        const memoizedResults = {};
        let clearTimeout: Nullable<number> = null;
        let previousArgs: Nullable<Parameters<typeof originalMethod>> = null;
        let currentArgs = previousArgs;

        const fn = (...args: Parameters<T>) => {
            previousArgs = currentArgs;
            currentArgs = args;

            const hashCurr = Hash.hash64(JSON.stringify(currentArgs));

            if (!comparer(previousArgs, currentArgs)) {
                memoizedResults[hashCurr] = originalMethod.apply(this, args);
            }

            const isTimeBased = opts.timeout != null && clearTimeout === null;
            if (isTimeBased) {
                clearTimeout = window.setTimeout(() => {
                    clearTimeout = null;
                    if (typeof comparer.flush === 'function') {
                        comparer.flush();
                    }
                }, opts.timeout);
            }

            return memoizedResults[hashCurr];
        };

        fn.flush =
            typeof comparer.flush === 'function'
                ? comparer.flush
                : () => {
                      console.warn('Memoization comparer function should contain flush method');
                  };

        return fn;
    }

    /**
     * `Decorator` that can be used directly on the class method
     * @param {Params} params
     * @returns {Function} memoized method
     */
    public static memoMethod(params: Partial<Params> = {}) {
        return function (target: object, propertyKey: string, descriptor: MemoizedPropertyDescriptor): MemoizedPropertyDescriptor {
            const originalMethod = descriptor.value;

            if (typeof originalMethod !== 'function') {
                console.warn('Memoization should be applied to class methods. Error in ', target, `Property: ${propertyKey}`);

                return descriptor;
            }

            const opts: Params = {
                timeout: null,
                ...params,
            };

            descriptor.value = Memoization.memoizeFunctionFactory(originalMethod, opts);
            descriptor.value.$$originalValue = originalMethod;

            return descriptor;
        };
    }
}
