type Args = string[];

type Params = {
    target?: HTMLElement;
};

type MessageTypes = 'error';

/** Creates HTML logs */
export class Log {
    private _messages: Map<HTMLElement, Args> = new Map();
    private _target: HTMLElement;
    private _container: HTMLElement;

    constructor(params: Params = {}) {
        this._target = params.target || document.body;
    }

    public error(...messages: Args): void {
        this._setupContainer()._registerNewMessage('error', ...messages);
    }

    private _setupContainer(): Log {
        if (!this._container) {
            this._container = document.createElement('div');
            this._container.style.position = 'fixed';
            this._container.style.zIndex = '100';
            this._container.style.top = '50%';
            this._container.style.left = '50%';
            this._container.style.maxWidth = '80vw';
            this._container.style.fontSize = '12px';
            this._container.style.fontFamily = 'Arial, Helvetica';
            this._container.style.fontWeight = 'normal';
            this._container.style.lineHeight = '1.2em';
            this._container.style.textAlign = 'center';
            this._container.style.color = 'white';
            this._container.style.transform = 'translate(-50%, -50%)';
        }

        this._target.append(this._container);

        return this;
    }

    private _registerNewMessage(type: MessageTypes, ...messages: Args): Log {
        let backgroundColor: Nullable<string> = null;
        let color: Nullable<string> = null;

        switch (type) {
            default:
                backgroundColor = 'red';
        }

        const item = document.createElement('div');
        item.style.backgroundColor = backgroundColor;
        item.style.color = color;
        item.style.padding = '20px';

        messages.forEach((msg) => {
            const line = document.createElement('div');
            line.innerText = msg;

            item.appendChild(line);
        });

        this._messages.set(item, messages);

        this._container.append(item);

        return this;
    }
}
