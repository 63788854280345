import * as io from 'io-ts';

type AllowedTypes = boolean | string | number | null;

/** Checks against defined values */
export const matchValues = (...values: AllowedTypes[]) => {
    const includes = (input: unknown): input is AllowedTypes => values.includes(input as AllowedTypes);

    return new io.Type('includes', includes, (input, context) => (includes(input) ? io.success(input) : io.failure(input, context)), io.identity);
};
