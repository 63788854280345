export class MemberSignupPolicyMapper {
    public static mapGetMemberSignupPolicy(memberSignupPolicyModel: APIv3.MemberSignUpPolicyModel): OLO.DTO.MemberSignUpPolicyModel {
        const mapFormFields = (memberFormField: APIv3.MemberFormFieldsModel) => ({
            Id: memberFormField.Id,
            TemplateId: memberFormField.TemplateId,
            Type: memberFormField.Type,
            CustomerFriendlyName: memberFormField.CustomerFriendlyName,
            IsSystemField: memberFormField.IsSystemField,
            IsRequired: memberFormField.IsRequired,
            DisplayIndex: memberFormField.DisplayIndex,
        });

        return memberSignupPolicyModel
            ? {
                  Id: memberSignupPolicyModel.Id,
                  IsActive: memberSignupPolicyModel.IsActive,
                  Name: memberSignupPolicyModel.Name,
                  AllowOverrideForDefaultTier: memberSignupPolicyModel.AllowOverrideForDefaultTier,
                  IsSystemDefault: memberSignupPolicyModel.IsSystemDefault,
                  AllowOverrideForDefaultGroup: memberSignupPolicyModel.AllowOverrideForDefaultGroup,
                  LastChangedByClerkId: memberSignupPolicyModel.LastChangedByClerkId,
                  DefaultGroupId: memberSignupPolicyModel.DefaultGroupId,
                  DefaultTierId: memberSignupPolicyModel.DefaultTierId,
                  LastChangedDate: memberSignupPolicyModel.LastChangedDate,
                  IsValid: memberSignupPolicyModel.IsValid,
                  IsTermsAndConditionsAcceptanceRequired: memberSignupPolicyModel.IsTermsAndConditionsAcceptanceRequired,
                  IsPrivacyPolicyAcceptanceRequired: memberSignupPolicyModel.IsPrivacyPolicyAcceptanceRequired,
                  IsUserAgreementAcceptanceRequired: memberSignupPolicyModel.IsUserAgreementAcceptanceRequired,
                  SignupFormTemplateId: memberSignupPolicyModel.SignupFormTemplateId,
                  UpdatePersonalDetailsFormTemplateId: memberSignupPolicyModel.UpdatePersonalDetailsFormTemplateId,
                  SignupFormFields: memberSignupPolicyModel.SignupFormFields ? memberSignupPolicyModel.SignupFormFields.map(mapFormFields) : null,
                  UpdateDetailsFormFields: memberSignupPolicyModel.UpdateDetailsFormFields ? memberSignupPolicyModel.UpdateDetailsFormFields.map(mapFormFields) : null,
                  AllowOverrideForMemberCardNumber: memberSignupPolicyModel.AllowOverrideForMemberCardNumber,
                  PasswordPolicy: memberSignupPolicyModel.PasswordPolicy
                      ? {
                            MinLength: memberSignupPolicyModel.PasswordPolicy.MinLength,
                            MaxLength: memberSignupPolicyModel.PasswordPolicy.MaxLength,
                            UpperCaseCount: memberSignupPolicyModel.PasswordPolicy.UpperCaseCount,
                            LowerCaseCount: memberSignupPolicyModel.PasswordPolicy.LowerCaseCount,
                            SpecialCharacterCount: memberSignupPolicyModel.PasswordPolicy.SpecialCharacterCount,
                            NumeralCount: memberSignupPolicyModel.PasswordPolicy.NumeralCount,
                            AllowSpaces: memberSignupPolicyModel.PasswordPolicy.AllowSpaces,
                        }
                      : null,
              }
            : null;
    }
}
