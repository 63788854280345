import { createAction, props } from '@ngrx/store';

export const OnlineMenuProductsImagesRequest = createAction(
    '[Online menu products images] Request images for online menu page products',
    props<{ params: APICommon.ImagesGetParams; pageId: number }>(),
);

export const OnlineMenuProductsImagesSuccessRequest = createAction(
    '[Online menu products images] Success request images for online menu page products',
    props<{ pageId: number; payload: OLO.DTO.ImageUrlModel[] }>(),
);

export const OnlineMenuProductsImagesErrorRequest = createAction(
    '[Online menu products images] Error request images for online menu page products',
    props<{ pageId: number; ex?: any }>(),
);
