import { Inject, Injectable } from '@angular/core';
import { Action, select, Store } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';

import * as Services from '@shared/core/services';
import * as Utils from '@shared/core/utils';
import * as Tokens from '@shared/core/tokens';

import { combineLatest, Observable } from 'rxjs';
import { catchError, switchMap, map, mergeMap, filter, auditTime, take, tap } from 'rxjs/operators';

@Injectable()
export class OnlineMenuVirtualLocationsEffects {
    public onPickupTimeSetGetOnlineMenuForVirtualLocations$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.CurrentLocationSet, actions.SetCollectionType, actions.PatchOrderTypeIdCollectionTypeValue),
            auditTime(100),
            switchMap(() =>
                combineLatest(
                    this._store.pipe(
                        select(selectors.getOrderTypeId),
                        filter((orderTypeId) => !!orderTypeId),
                    ),
                    this._store.pipe(select(selectors.routeIsLocationDetailsPage())),
                    this._store.pipe(select(selectors.isCurrentRouteCheckoutPage)),
                    this._store.pipe(select(selectors.getCurrentLocationNo)),
                    this._store.pipe(
                        select(selectors.getCurrentPickupTime),
                        filter((pickupTime) => !!pickupTime),
                    ),
                    this._store.pipe(select(selectors.getCartOrderTypeId)),
                    this._store.pipe(select(selectors.getCartLocationNo)),
                    this._store.pipe(select(selectors.getVirtualLocationsForCurrentLocation)),
                    this._store.pipe(
                        select(selectors.getOnlineMenuVirtualLocations),
                        filter((onlineMenuVirtualLocations) => onlineMenuVirtualLocations?.every((onlineMenu) => !onlineMenu.isDownloading)),
                    ),
                ).pipe(take(1)),
            ),
            switchMap(
                ([
                    orderTypeId,
                    isOnLocationDetailsPage,
                    isCurrentRouteCheckoutPage,
                    locationNo,
                    pickupTime,
                    cartOrderTypeId,
                    cartLocationNo,
                    virtualLocationsForCurrentLocation,
                    onlineMenuVirtualLocations,
                ]) => {
                    const locationHasVirtualLocations = !!virtualLocationsForCurrentLocation && virtualLocationsForCurrentLocation.length > 0;
                    const orderTypeHasVirtualLocations =
                        locationHasVirtualLocations && virtualLocationsForCurrentLocation.some((vl) => vl.OrderTypes.some((ot) => ot.Id === orderTypeId));
                    if (!this._config.virtualLocations.enabled) return [];
                    const onlineMenuVirtualLocationExistForCurrentLocation = onlineMenuVirtualLocations.some((onlineMenu) => onlineMenu.locationNo === locationNo);
                    if (
                        !orderTypeHasVirtualLocations ||
                        (onlineMenuVirtualLocations.length > 0 && !onlineMenuVirtualLocationExistForCurrentLocation) ||
                        !locationHasVirtualLocations
                    ) {
                        this._store.dispatch(actions.OnlineMenuPagesVirtualLocationReset());
                    }

                    return (
                        virtualLocationsForCurrentLocation
                            ?.map((virtualLocation) => {
                                const onlineMenuVirtualLocation = onlineMenuVirtualLocations.find((onlineMenu) => onlineMenu.virtualLocationNo === virtualLocation.LocationNo);
                                const pickupTimeIsSet: boolean = !!pickupTime;
                                const pickupTimeMatchesOnlineMenuRange: boolean =
                                    onlineMenuVirtualLocation?.data !== null &&
                                    Utils.Dates.isHourInHoursRange(pickupTime?.Hour, onlineMenuVirtualLocation?.data.StartTime, onlineMenuVirtualLocation?.data.EndTime);
                                const isSameDay: boolean = Utils.Dates.isSameDay(pickupTime?.DateLocalISO, onlineMenuVirtualLocation?.pickupDate);
                                const locationHasChanged: boolean = locationNo !== onlineMenuVirtualLocation?.locationNo;
                                const virtualLocationHasOrderType = virtualLocation.OrderTypes.some((orderType) => orderType.Id === orderTypeId);
                                const menuHasDownloaded: boolean =
                                    onlineMenuVirtualLocation?.data !== null && cartLocationNo === locationNo && locationHasChanged
                                        ? onlineMenuVirtualLocation?.orderTypeId === cartOrderTypeId
                                        : onlineMenuVirtualLocation?.orderTypeId === orderTypeId;

                                const shouldRequestVirtualLocationOnlineMenu: boolean =
                                    (isOnLocationDetailsPage || isCurrentRouteCheckoutPage) &&
                                    virtualLocationHasOrderType &&
                                    (locationHasChanged || !menuHasDownloaded || !pickupTimeMatchesOnlineMenuRange || !pickupTimeIsSet || !isSameDay);

                                if (!shouldRequestVirtualLocationOnlineMenu) return null;

                                return actions.OnlineMenuPagesVirtualLocationRequest(
                                    virtualLocation.DisplayIndex,
                                    virtualLocation.LocationDescription,
                                    virtualLocation.AccentColour,
                                    locationNo,
                                    virtualLocation.LocationNo,
                                    orderTypeId,
                                    Utils.Dates.getLocalISOFormatDate(pickupTime.Date, true),
                                );
                            })
                            .reduce((actionsToDispatch, action) => {
                                if (!action) return actionsToDispatch;

                                return [...actionsToDispatch, action];
                            }, []) || []
                    );
                },
            ),
        ),
    );

    public requestOnlineMenuForVirtualLocation$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.OnlineMenuPagesVirtualLocationRequest),
            mergeMap(({ locationNo, virtualLocationNo, orderTypeId, pickupDate }) =>
                this._onlineMenuService
                    .getMenuPages({
                        locationNo: locationNo,
                        virtualLocationNo,
                        orderTypeId,
                        menuDate: pickupDate,
                    })
                    .pipe(
                        tap((response) => this._store.dispatch(actions.LoadDietaryImagesForLocation(locationNo, orderTypeId, pickupDate, response))),
                        map((response) => {
                            /* Sort first by DisplayIndex */
                            response.Pages = response.Pages.sort((a, b) => {
                                switch (true) {
                                    case a.DisplayIndex < b.DisplayIndex:
                                        return -1;
                                    case a.DisplayIndex > b.DisplayIndex:
                                        return 1;
                                    default:
                                        return 0;
                                }
                            });

                            return actions.OnlineMenuPagesVirtualLocationSuccessRequest(locationNo, virtualLocationNo, orderTypeId, pickupDate, response);
                        }),
                        catchError((ex) => {
                            console.error(ex);

                            return [actions.OnlineMenuPagesVirtualLocationErrorRequest(locationNo, virtualLocationNo, orderTypeId, pickupDate, ex)];
                        }),
                    ),
            ),
        ),
    );

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: OLO.Config,
        private _actions$: Actions,
        private _onlineMenuService: Services.OnlineMenuService,
        private _store: Store<OLO.State>,
    ) {}
}
