import { createSelector } from '@ngrx/store';

import { membersState } from '../root';
import { isMemberProfilePictureValid } from '../isMemberProfilePictureValid';

export const isMemberDataValid = createSelector(
    membersState,
    isMemberProfilePictureValid,
    (members, isProfilePictureValid) => members.data !== null || (members.guestData !== null && isProfilePictureValid),
);
