import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';

import * as actions from '@shared/state/actions';

import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';
import { LocationsMapper } from '@shared/core/mappers/locations.shared.mapper';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class LocationsService {
    constructor(@Inject(Tokens.CONFIG_TOKEN) public config: OLO.Config, public httpClient: HttpClient, public store: Store<OLO.State>) {}

    public getLocationsWithOnlineOrdering(customParams: APICommon.TerminalsOnlineOrderingLocationsGetParams = {}): Observable<OLO.DTO.OnlineOrderingLocationBusinessModel[]> {
        const defaultParams: APICommon.TerminalsOnlineOrderingLocationsGetParams = {
            duration: APICommon.ORDERING_TIME_INFO_DURATION.DAY,
        };

        const params: HttpParams = new HttpParams({
            fromObject: { ...defaultParams, ...customParams } as any,
        });

        return this.httpClient.get<APIv3.TerminalsGetOnlineOrderingLocations.Responses.$200>(`${this.config.api.base}/terminals/onlineOrderingLocations`, { params }).pipe(
            map((response: APIv3.TerminalsGetOnlineOrderingLocations.Responses.$200) => LocationsMapper.mapGetLocationsWithOnlineOrdering(response)),
            map((locations) => {
                let filteredLocations = this._filterUnconfiguredLocations(locations);

                if (this.config.virtualLocations?.enabled) {
                    filteredLocations = this._filterRevenueAgregatorTypeLocations(filteredLocations);
                    this._handlePotentialMixedLocation(locations);
                }

                return filteredLocations;
            }),
        );
    }

    public getHiddenLocations(): Observable<OLO.DTO.HiddenLocationModel[]> {
        return this.httpClient.get<APIv3.LoyaltyAppGetHiddenLocations.Responses.$200>(`${this.config.api.base}/loyaltyapp/hiddenLocations`);
    }

    public requestLocations(): void {
        this.store.dispatch(
            actions.LocationsRequest({
                venueNo: this.config.venue ? this.config.venue.id : null,
                dateToCheck: Utils.Dates.getLocalISOFormatDate(new Date(), false) /* Without Z - will not return TODAY if true */,
                duration: APICommon.ORDERING_TIME_INFO_DURATION.MONTH,
            }),
        );
    }

    public requestHiddenLocations(): void {
        this.store.dispatch(actions.HiddenLocationsRequest());
    }

    private _filterRevenueAgregatorTypeLocations(locations: OLO.DTO.OnlineOrderingLocationBusinessModel[]): OLO.DTO.OnlineOrderingLocationBusinessModel[] {
        return locations.map((location) => ({
            ...location,
            VirtualLocations: Utils.LocationClassification.filterOutRevenue(location.VirtualLocations),
        }));
    }

    private _filterUnconfiguredLocations(locations: OLO.DTO.OnlineOrderingLocationBusinessModel[]): OLO.DTO.OnlineOrderingLocationBusinessModel[] {
        return locations.filter((location) => {
            const collectionTypesInfo = new Utils.LocationCollectionTypesChecker(location, this.config);
            if (!collectionTypesInfo.hasAnyTypes()) {
                this._throwNoCofiguredOrderTypeWarn(location.LocationNo);

                return false;
            }

            return true;
        });
    }

    private _throwNoCofiguredOrderTypeWarn(LocationNo: number): void {
        console.warn(`WARNING! The config.js collection types list haven't configured any order type id corresponding to order type id in location no ${LocationNo}.`);
    }

    private _handlePotentialMixedLocation(locations: OLO.DTO.OnlineOrderingLocationBusinessModel[]) {
        locations.forEach((location) => {
            if (this._hasMixedLocationClassification(location)) {
                this._throwMixedVirtualLocationsClassificationWarn(location.LocationNo);
            }
        });
    }

    private _hasMixedLocationClassification({ VirtualLocations }: OLO.DTO.OnlineOrderingLocationBusinessModel): boolean {
        if (!VirtualLocations || VirtualLocations.length <= 0) return false;

        return VirtualLocations.some((vl, i, locations) => vl.LocationClassification !== locations[0].LocationClassification);
    }

    private _throwMixedVirtualLocationsClassificationWarn(LocationNo): void {
        console.warn(`WARNING! The location no ${LocationNo} has mixed virtual locations classification, online locations can not be mixed with brand locations.`);
    }
}
