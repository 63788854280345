import { createAction, props } from '@ngrx/store';

export const MemberStateReset = createAction('[Member] STATE RESET');

export const MemberUnsetAddData = createAction('[Member] Unset Add data for sign up process');

export const MemberUnsetAccountCredentials = createAction('[Member] Unset account credentials');

export const MemberUnsetUpdateAndValidation = createAction('[Member] Just clear out all flags that were set to update or verify account - make things clear to avoid collisions');

export const MemberSignOut = createAction('[Member] Sign out', props<{ redirect: boolean | string }>());

/* Quick login with email verification ommited */

export const MemberQuickLoginRequest = createAction('[Member] Request - Quick login', props<{ login: string; password: string; authorizationType: OLO.Enums.LOGIN_TYPE }>());

export const MemberQuickLoginSuccessRequest = createAction(
    '[Member] Success request - Quick login',
    props<{ login: string; password: string; authorizationType: OLO.Enums.LOGIN_TYPE; sessionKey: string }>(),
);

export const MemberGetRedeemablePointsRequest = createAction('[Member] request - Request get redeemable points');

export const MemberGetRedeemablePointsRequestSuccess = createAction(
    '[Member] Success request - Request get redeemable points',
    props<{ payload: OLO.DTO.MemberRedeemablePoints }>(),
);

export const MemberGetRedeemablePointsRequestError = createAction('[Member] Error request - Request get redeemable points', props<{ ex?: any }>());

export const MemberQuickLoginErrorRequest = createAction(
    '[Member] Error request - Quick login',
    props<{ login: string; password: string; authorizationType: OLO.Enums.LOGIN_TYPE; ex?: any }>(),
);

/* Get user data */
export const MemberDataRequest = createAction('[Member] Request - get user data by ID');

export const MemberDataSuccessRequest = createAction('[Member] Success request - get user data by ID', props<{ memberId: string | number; payload: OLO.DTO.MemberModel }>());

export const MemberDataErrorRequest = createAction('[Member] Error request - get user data by ID', props<{ memberId: string | number; payload: OLO.DTO.MemberModel; ex?: any }>());

/* Authorization actions */
export const MemberValidateLoginRequest = createAction('[Member] Request - validate login', props<{ login: string; loginType: OLO.Enums.LOGIN_TYPE }>());

export const MemberValidateLoginSuccessRequest = createAction(
    '[Member] Success request - validate login',
    props<{ login: string; loginType: OLO.Enums.LOGIN_TYPE; payload: OLO.DTO.MemberModel }>(),
);

export const MemberValidateLoginErrorRequest = createAction('[Member] Error request - validate login', props<{ login: string; loginType: OLO.Enums.LOGIN_TYPE; ex?: any }>());

export const MemberSignInDataRequest = createAction('[Member] Request - sign in/login', props<{ login?: string; password: string }>());

export const MemberSignInDataResponseSuccess = createAction(
    '[Member] Request - sign in/login success',
    props<{ password: string; data: { sessionKey: string; jwt: OLO.DTO.JWTokenObject }; withChangeState: boolean }>(),
);

export const MemberSignInDataResponseError = createAction('[Member] Request - sign in/login error', props<{ password: string; ex?: any }>());

export const MemberAuthorizationInit = createAction('[Member] Init authorization flow', props<{ authorizationType: OLO.Enums.LOGIN_TYPE; accountLogin: string }>());

export const MemberAuthorizationSetFlag = createAction('[Member] Set authorization flag', props<{ flag: boolean }>());

export const MemberAuthorizationSetStep = createAction('[Member] Set authorization step', (p: { step: Nullable<OLO.Enums.AUTH_STEP> }) => p);

export const MemberAuthorizationCalculateNextStep = createAction('[Member] Calculate next authorization step', (p: { currentStep?: OLO.Enums.AUTH_STEP }) => p);

/* Guest Mode */
export const MemberGuestModeSet = createAction('[Member] Guest mode set', props<{ flag: boolean }>());

export const MemberGuestDataRevalidateAndSet = createAction('[Member] Guest data set revalidate and set', props<{ guestData: APICommon.OnlineOrderPartialMember }>());

export const MemberGuestDataSet = createAction('[Member] Guest data set', props<{ guestData: APICommon.OnlineOrderPartialMember }>());

export const MemberGuestDataReset = createAction('[Member] Guest data reset');

export const MemberDataReset = createAction('[Member] Member data reset');

/* JWT */
export const MemberJwtSetData = createAction('[Member] Set Jwt Data', props<{ jwt: OLO.DTO.JWTokenObject }>());

export const MemberJwtDataRequest = createAction('[Member] Request - sign in/login jwt', props<{ email: string; password: string }>());

export const MemberJwtDataResponseSuccess = createAction('[Member] Success request - sign in/login jwt', props<{ email: string; password: string }>());

export const MemberJwtDataResponseError = createAction('[Member] Error request - sign in/login jwt', props<{ email: string; password: string; ex?: any }>());

/* PHONE and EMAIL Validation */
export const MemberValidatePhoneRequest = createAction(
    '[Member] Request - validate phone number',
    props<{
        phone: string;
        memberPhoneIdPriority: boolean; // If 'true' MemberID priority is set to Phone
    }>(),
);

export const MemberValidatePhoneSuccessRequest = createAction('[Member] Success request - validate phone number', props<{ phone: string; memberId: number }>());

export const MemberValidatePhoneDataResponseError = createAction('[Member] Error request - validate phone number', props<{ phone: string; ex?: any }>());

export const MemberValidateEmailDataRequest = createAction(
    '[Member] Request - validate email',
    props<{
        email: string;
        memberPhoneIdPriority: boolean; // If 'true' MemberID priority is set to Phone
    }>(),
);

export const MemberValidateEmailDataResponseSuccess = createAction('[Member] Success request - validate email', props<{ email: string; memberId: number }>());

export const MemberValidateEmailDataResponseError = createAction('[Member] Error request - validate email', props<{ email: string; ex?: any }>());

/* Session */
export const CreateMemberSession = createAction('[Member] Creating session data', props<{ sessionKey: string; accountId: string; authorizationType: OLO.Enums.LOGIN_TYPE }>());

export const MemberSessionRequest = createAction('[Member] Check session data');

export const MemberSessionSuccessRequest = createAction(
    '[Member] Success request session data',
    props<{ sessionKey: string; accountId: string; authorizationType: OLO.Enums.LOGIN_TYPE }>(),
);

export const MemberSessionErrorRequest = createAction(
    '[Member] Error request session data',
    props<{ sessionKey: string; accountId: string; authorizationType: OLO.Enums.LOGIN_TYPE; ex?: any }>(),
);

// /* Partial member complete register */
// export const MemberPartialMemberSignUpProcessInit = createAction(
//     '[Member] Initialize sign up of partial member member',
//     props<{ memberId: number; memberData: IMemberModel; }>(),
// );

/* Register member */
export const MemberSignUpProcessInit = createAction('[Member] Initialize sign up of new member', props<{ memberData: OLO.DTO.MemberModel }>());

export const MemberSignUpRequest = createAction(
    '[Member] Request - sign up/register new member',
    props<{ memberData: OLO.DTO.MemberModel; existingMember: OLO.DTO.MemberModel }>(),
);

export const MemberSignUpSuccessRequest = createAction('[Member] Success request - sign up/register new member', props<{ memberData: OLO.DTO.MemberModel }>());

export const MemberSignUpErrorRequest = createAction(
    '[Member] Error request - sign up/register new member',
    props<{ memberData: OLO.DTO.MemberModel; existingMember: OLO.DTO.MemberModel; ex?: any }>(),
);

export const MemberDetachedFlowSignUpRequest = createAction('[Member] Request - sign up/register new member - flow detached', props<{ memberData: OLO.DTO.MemberModel }>());

export const MemberDetachedFlowSignUpSuccessRequest = createAction(
    '[Member] Success request - sign up/register new member - flow detached',
    props<{ memberData: OLO.DTO.MemberModel }>(),
);

export const MemberDetachedFlowSignUpErrorRequest = createAction(
    '[Member] Error request - sign up/register new member - flow detached',
    props<{ memberData: OLO.DTO.MemberModel }>(),
);

/* Updating member */
export const MemberProfileUpdateInit = createAction('[Member] Update profile init', props<{ userModel: OLO.DTO.MemberModel; modalId: number }>());

export const MemberProfileUpdateRequest = createAction(
    "[Member] Request update signed in user's profile data",
    (userModel: OLO.DTO.MemberModel, modalId: Nullable<number> = null) => ({
        userModel,
        modalId,
    }),
);

export const MemberProfileUpdateSuccessRequest = createAction(
    "[Member] Success request update signed in user's profile data",
    props<{ userModel: OLO.DTO.MemberModel; payload: OLO.DTO.MemberModel; modalId?: number }>(),
);

export const MemberProfileUpdateErrorRequest = createAction(
    "[Member] Error request update signed in user's profile data",
    props<{ userModel: OLO.DTO.MemberModel; ex?: any; modalId?: number }>(),
);

export const MemberProfileUpdateResetRequest = createAction('[Member] Reset member update request');

/* THESE ACTIONS ARE SICK - USED IN DIFFERENT UPDATE TYPES - NOT FOR JUST UPDATING USER DATA IN ACCOUNT PROFILE VIEW..... 'so generic' */
export const MemberUpdateRequest = createAction('[Member] Request - update user data', props<{ userModel: OLO.DTO.MemberModel }>());

export const MemberUpdateSuccessRequest = createAction('[Member] Request success - update user data', props<{ userModel: OLO.DTO.MemberModel; payload: OLO.DTO.MemberModel }>());

export const MemberUpdateErrorRequest = createAction('[Member] Request error - update user data', props<{ userModel: OLO.DTO.MemberModel; ex?: any }>());

export const MemberRestoreUpdateFlags = createAction('[Member] Restore flags - update user data');

/* Change password */
export const MemberPasswordChangeRequest = createAction('[Member] Request - update user password', props<{ OldPassword?: string; NewPassword?: string; MemberId: number }>());

export const MemberPasswordChangeSuccessRequest = createAction(
    '[Member] Request success - update user password',
    props<{ OldPassword: string; NewPassword: string; MemberId: number }>(),
);

export const MemberPasswordChangeErrorRequest = createAction(
    '[Member] Request error - update user password',
    props<{ OldPassword: string; NewPassword: string; MemberId: number; ex?: any }>(),
);

export const MemberPasswordChangeRestoreFlags = createAction('[Member] Restore flags - update user password');

/* Forgot Password */
export const MemberForgotPasswordDataRequest = createAction('[Member] Request - forgot password', props<{ email: string }>());

export const MemberForgotPasswordDataResponseSuccess = createAction('[Member] Request - forgot password response success', props<{ email: string }>());

export const MemberForgotPasswordDataResponseError = createAction('[Member] Request - forgot password response error', props<{ email: string; ex?: any }>());

export const MemberForgotPasswordRestoreFlags = createAction('[Member] Restore flags - forgot password');

/* Verify Phone Number */
export const MemberSendPhoneVerificationCodeDataRequest = createAction(
    '[Member] Request - send verification code for phone',
    props<{ phoneNo: string; mobilePhoneCountryId: number }>(),
);

export const MemberSendPhoneVerificationCodeDataResponseSuccess = createAction('[Member] Success request - send verification code for phone', props<{ phoneNo: string }>());

export const MemberSendPhoneVerificationCodeDataResponseError = createAction('[Member] Error request - send verification code for phone', props<{ phoneNo: string; ex?: any }>());

export const MemberVerifyPhoneDataRequest = createAction('[Member] Request - verify phone', props<{ phoneNo: string; token: string; countryPrefix: number }>());

export const MemberVerifyPhoneDataSuccessRequest = createAction('[Member] Success request - verify phone', props<{ phoneNo: string; token: string }>());

export const MemberVerifyPhoneDataErrorRequest = createAction('[Member] Error request - verify phone', props<{ phoneNo: string; token: string; ex?: any }>());

export const MemberVerifyPhoneRestoreFlags = createAction('[Member] Restore flags - verify phone');

/* Verify Email */
export const MemberSendEmailVeryficationRequest = createAction('[Member] Request - send verification email for email', props<{ email: string }>());

export const MemberSendEmailVeryficationSuccessRequest = createAction('[Member] Success request - send verification email for email', props<{ email: string }>());

export const MemberSendEmailVeryficationDataResponseError = createAction('[Member] Error request - send verification email for email', props<{ email: string; ex?: any }>());

export const MemberVerifyEmailRestoreFlags = createAction('[Member] Restore flags - verify email');

/* Validate password reset token */
export const MemberValidatePasswordResetTokenRequest = createAction('[Member] GUID validate request', props<{ token: string }>());

export const MemberValidatePasswordResetTokenSuccessRequest = createAction('[Member] GUID validate success request', props<{ token: string; payload: boolean }>());

export const MemberValidatePasswordResetTokenErrorRequest = createAction('[Member] GUID validate error request', props<{ token: string; ex?: any }>());

export const MemberValidatePasswordResetTokenReset = createAction('[Member] GUID validate reset');

/* Reset forgotten password */
export const MemberForgottenPasswordResetRequest = createAction('[Member] Request forgotten password reset', props<{ model: OLO.DTO.ResetMemberForgottenPasswordCommand }>());

export const MemberForgottenPasswordResetSuccessRequest = createAction(
    '[Member] Success request forgotten password reset',
    props<{ model: OLO.DTO.ResetMemberForgottenPasswordCommand; payload: boolean }>(),
);

export const MemberForgottenPasswordResetErrorRequest = createAction(
    '[Member] Error request forgotten password reset',
    props<{ model: OLO.DTO.ResetMemberForgottenPasswordCommand; ex?: any }>(),
);

/* Confirm email */
export const MemberConfirmEmailTokenRequest = createAction('[Member] Request confirm token for email address', props<{ token: string }>());

export const MemberConfirmEmailTokenSuccessRequest = createAction('[Member] Success request confirm token for email address', props<{ token: string; payload: boolean }>());

export const MemberConfirmEmailTokenErrorRequest = createAction('[Member] Error request confirm token for email address', props<{ token: string; ex?: any }>());

export const MemberConfirmEmailRequest = createAction('[Member] Request confirm email address', props<{ token: string }>());

export const MemberConfirmEmailSuccessRequest = createAction('[Member] Success request confirm email address', props<{ token: string; payload: boolean }>());

export const MemberConfirmEmailErrorRequest = createAction('[Member] Error request confirm email address', props<{ token: string; ex?: any }>());

export const MemberConfirmEmailReset = createAction('[Member] Confirm email state reset');

export const MemberCompleteAccountSetupGuestCheck = createAction('[Member] Get data to check if guest user can complete account setup', props<{ orderId: number }>());

/* Simulating email verification */
export const MemberVerifyEmailRequest = createAction('[Member] Request verify email - for simulating requests');

export const MemberVerifyEmailSuccessRequest = createAction('[Member] Success request verify email - for simulating requests');

export const MemberVerifyEmailErrorRequest = createAction('[Member] Error request verify email - for simulating requests');

export const MemberVerifyEmaiResetFlags = createAction('[Member] Reset verify email - for simulating requests');

export const MemberPartialSignUpRequest = createAction('[Member] Request partial member sign up', props<{ memberData: OLO.DTO.MemberModel }>());

export const MemberPartialSignUpSuccessRequest = createAction(
    '[Member] Success request partial member sign up',
    props<{ memberId: number; memberData: OLO.DTO.MemberModel; payload: OLO.DTO.MemberModel }>(),
);

export const MemberPartialSignUpErrorRequest = createAction(
    '[Member] Error request partial member sign up',
    props<{ memberId: number; memberData: OLO.DTO.MemberModel; ex?: any }>(),
);

export const MemberPartialSignUpResetState = createAction('[Member] Partial member sign up state reset');

export const MemberPasswordSetRequest = createAction('[Member] Request password set for user', props<{ memberId: number; password: string }>());

export const MemberPasswordSetSuccessRequest = createAction('[Member] Success request password set for user', props<{ memberId: number; password: string }>());

export const MemberPasswordSetErrorRequest = createAction('[Member] Error request password set for user', props<{ memberId: number; password: string; ex?: any }>());

export const MemberPasswordSetResetState = createAction('[Member] Password set state reset');

/* Free products */
export const MemberFreeProductsRequest = createAction('[Member] Request free products', props<{ memberId: number }>());

export const MemberFreeProductsSuccessRequest = createAction('[Member] Success request free products', props<{ payload: OLO.DTO.MemberFreeProductModel[] }>());

export const MemberFreeProductsErrorRequest = createAction('[Member] Error request free products', props<{ memberId: number; ex?: any }>());

/* Free products visited*/
export const MemberFreeProductsRequestVisited = createAction('[Member] Request free products visited', props<{ freeProductId: number }>());

export const MemberFreeProductsVisitedSuccessRequest = createAction('[Member] Success request free products visited', props<{ freeProductId: number }>());

export const MemberFreeProductsVisitedErrorRequest = createAction('[Member] Error request free products visited', props<{ ex?: any }>());

/* Loyalty products */
export const MemberLoyaltyProductsRequest = createAction('[Member] Request loyalty products', props<{ memberId: number }>());

export const MemberLoyaltyProductsSuccessRequest = createAction(
    '[Member] Success Request loyalty products',
    props<{ memberId: number; payload: OLO.DTO.GetLoyaltyProductProgramTrackingBusinessModel[] }>(),
);

export const MemberLoyaltyProductsErrorRequest = createAction('[Member] Error request loyalty products', props<{ memberId: number; ex?: any }>());

/* Unique registration code */
export const MemberUniqueCodeCheckRequest = createAction('[Member] Request unique code check', props<{ memberCode: string }>());

export const MemberUniqueCodeCheckSuccessRequest = createAction('[Member] Success request unique code check', props<{ memberCode: string; payload: OLO.DTO.MemberModel }>());

export const MemberUniqueCodeCheckErrorRequest = createAction('[Member] Error request unique code check', props<{ memberCode: string; ex?: any }>());

export const MemberUniqueCodeCheckReset = createAction('[Member] Reset unique code check');

/* Account balance */
export const MemberAccountBalanceRequest = createAction('[Member] Request account balance', props<Record<string, unknown>>());

export const MemberAccountBalanceSuccessRequest = createAction('[Member] Success request account balance', props<{ payload: OLO.DTO.MemberAccountBalanceModel }>());

export const MemberAccountBalanceErrorRequest = createAction('[Member] Error request account balance', props<{ ex: any }>());

export const MemberAccountBalanceReset = createAction('[Member] Account balance reset');

/* Delete Member Account */
export const MemberDeleteProfileRequest = createAction('[Member] Request delete account');

export const MemberDeleteProfileSuccessRequest = createAction('[Member] Success request delete account');

export const MemberDeleteProfileErrorRequest = createAction('[Member] Error request delete account', props<{ ex?: any }>());

/* Link Rewards account */
export const MemberLinkRewardsAccountVerifyRequest = createAction(
    '[Member] Request link rewards account verify',
    props<{ params: APICommon.MemberMemberCardWithEmailValidateParams }>(),
);

export const MemberLinkRewardsAccountVerifySuccessRequest = createAction(
    '[Member] Success request link rewards account verify',
    props<{ params: APICommon.MemberMemberCardWithEmailValidateParams; payload: OLO.DTO.ValidateMemberLoginResponse }>(),
);

export const MemberLinkRewardsAccountVerifyErrorRequest = createAction(
    '[Member] Error request link rewards account verify',
    props<{ params: APICommon.MemberMemberCardWithEmailValidateParams; ex?: any }>(),
);

export const MemberLinkRewardsAccountVerifyReset = createAction('[Member] Reset link rewards account verify');

/* Link account */
export const MemberLinkRewardsAccountRequest = createAction('[Member] Request Link Rewards Member Account Init', props<{ memberData: OLO.DTO.MemberModel }>());

export const MemberLinkRewardsAccountSuccessRequest = createAction('[Member] Success Request Link Rewards Member Account Init', props<{ payload: OLO.DTO.MemberModel }>());

export const MemberLinkRewardsAccountErrorRequest = createAction('[Member] Error Request Link Rewards Member Account Init', props<{ ex?: any }>());

/**
 * Validate guest details
 */
export const MemberGuestDataRequest = createAction('[Member] Guest data details request', props<{ login: string; loginType: OLO.Enums.LOGIN_TYPE }>());

export const MemberGuestDataSuccessRequest = createAction(
    '[Member] Guest data details success request',
    props<{ login: string; loginType: OLO.Enums.LOGIN_TYPE; payload: OLO.DTO.MemberModel }>(),
);

export const MemberGuestDataErrorRequest = createAction('[Member] Guest data details error request', props<{ login: string; loginType: OLO.Enums.LOGIN_TYPE; ex?: any }>());

/**
 * Get member points
 */

export const MemberGetPointsRequest = createAction('[Member] Request get member points');

export const MemberGetPointsRequestSuccessRequest = createAction('[Member] Success request get member points', props<{ points: number }>());

export const MemberGetPointsRequestErrorRequest = createAction('[Member] Error request get member points', props<{ ex?: any }>());

export const MemberGetSignupFormFieldsRequest = createAction('[Member] Request get signup form fields');

export const MemberGetSignupFormFieldsSuccessRequest = createAction(
    '[Member] Success request get signup form fields',
    props<{ formFields: OLO.State.Members.MemberFormFieldModel[] }>(),
);

export const MemberGetSignupFormFieldsErrorRequest = createAction('[Member] Error request get signup form fields', props<{ ex?: any }>());

export const MemberGetUpdateMemberFormFieldsRequest = createAction('[Member] Request get update member form fields');

export const MemberGetUpdateMemberFormFieldsSuccessRequest = createAction(
    '[Member] Success request get update member form fields',
    props<{ formFields: OLO.State.Members.MemberFormFieldModel[] }>(),
);

export const MemberGetUpdateMemberFormFieldsErrorRequest = createAction('[Member] Error request get update member form fields', props<{ ex?: any }>());

export const MemberGetMemberInterfaceRequest = createAction('[Member] Request get member interface', props<{ locationNo: number }>());

export const MemberGetMemberInterfaceSuccessRequest = createAction(
    '[Member] Success - Request get member interface',
    props<{ locationNo: number; payload: OLO.DTO.LoyaltyAppMemberInterfaceModel }>(),
);

export const MemberGetMemberInterfaceErrorRequest = createAction('[Member] Error - Request get member interface', props<{ locationNo: number; ex?: any }>());

export const MemberGetMemberInterfaceUserDataRequest = createAction('[Member] Request get member interface user data');

export const MemberGetMemberInterfaceUserDataSuccessRequest = createAction(
    '[Member] Success - Request get member interface user data',
    props<{ payload: OLO.DTO.MemberInterfaceLoginLightweightModel[] }>(),
);

export const MemberGetMemberInterfaceUserDataErrorRequest = createAction('[Member] Error - Request get member interface user data', props<{ ex?: any }>());

export const MemberValidateMemberInterfaceRequest = createAction('[Member] Request validate member interface', props<{ data: APIv3.TerminalMemberInterfaceModel }>());

export const MemberValidateMemberInterfaceSuccessRequest = createAction(
    '[Member] Success - Request validate member interface',
    props<{ status: OLO.Enums.MEMBER_INTERFACE_VERIFICATION_STATUS }>(),
);

export const MemberValidateMemberInterfaceErrorRequest = createAction('[Member] Error - Request validate member interface', props<{ ex?: any }>());
