import { createAction, props } from '@ngrx/store';

export const HistoryOrdersReset = createAction('[History order] Reset data');

/* Bulks */
export const HistoryOrdersRequest = createAction('[History order] Request bulk online orders data', (params: APICommon.OnlineOrdersGetOrdersParams) => ({ params }));

export const HistoryOrdersSuccessRequest = createAction(
    '[History order] Success request bulk online orders data',
    props<{ params: APICommon.OnlineOrdersGetOrdersParams; payload: OLO.DTO.OnlineOrderDetailedBusinessModel[] }>(),
);

export const HistoryOrdersErrorRequest = createAction(
    '[History order] Error request bulk online orders data',
    props<{ params: APICommon.OnlineOrdersGetOrdersParams; ex?: any }>(),
);

/* Bulks */
export const HistoryOrdersLoadMoreRequest = createAction('[History order] Request bulk more online orders data', (params: APICommon.OnlineOrdersGetOrdersParams) => ({ params }));

export const HistoryOrdersLoadMoreSuccessRequest = createAction(
    '[History order] Success request bulk more online orders data',
    props<{ params: APICommon.OnlineOrdersGetOrdersParams; payload: OLO.DTO.OnlineOrderDetailedBusinessModel[] }>(),
);

export const HistoryOrdersLoadMoreErrorRequest = createAction(
    '[History order] Error request bulk more online orders data',
    props<{ params: APICommon.OnlineOrdersGetOrdersParams; ex?: any }>(),
);

/* Single */
export const HistoryOrderRequest = createAction('[History order] Request single online order data', props<{ orderId: number }>());

export const HistoryOrderSuccessRequest = createAction(
    '[History order] Success request single online order data',
    props<{ orderId: number; payload: OLO.DTO.OnlineOrderDetailedBusinessModel }>(),
);

export const HistoryOrderErrorRequest = createAction('[History order] Error request single online order data', props<{ orderId: number; ex?: any }>());

export const HistoryOrderStatusRequest = createAction('[History order] Request online order status data', props<{ orderId: number; includeFinalized: boolean }>());

export const HistoryOrderStatusSuccessRequest = createAction('[History order] Success request online order status data', props<{ orderId: number; payload: number }>());

export const HistoryOrderStatusErrorRequest = createAction('[History order] Error request online order status data', props<{ orderId: number; ex?: any }>());

export const HistoryOrderLoadFromCache = createAction('[History order] Load history order from browser storage', props<{ order: OLO.DTO.OnlineOrderDetailedBusinessModel }>());
