import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';

import * as actions from '@shared/state/actions';

import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { VirtualLocationsMapper } from '../mappers/virtual-locations.shared.mapper';

@Injectable({
    providedIn: 'root',
})
export class VirtualLocationsService {
    constructor(@Inject(Tokens.CONFIG_TOKEN) public config: OLO.Config, public httpClient: HttpClient, public store: Store<OLO.State>) {}

    public getVirtualLocations(props: APICommon.VirtualLocationsGetParams = {}): Observable<OLO.DTO.VirtualLocationBusinessModel[]> {
        return this.httpClient.get<APIv3.VirtualLocationBusinessModel[]>(`${this.config.api.base}/terminals/VirtualLocations${Utils.HTTP.object2string(props)}`).pipe(
            map(this._filterRevenueAgregatorTypeLocations),
            map((response: APIv3.VirtualLocationBusinessModel[]) => VirtualLocationsMapper.mapGetVirtualLocations(response)),
        );
    }

    public requestVirtualLocations(): void {
        this.store.dispatch(actions.VirtualLocationsRequest());
    }

    private _filterRevenueAgregatorTypeLocations(locations: APIv3.VirtualLocationBusinessModel[]): APIv3.VirtualLocationBusinessModel[] {
        return Utils.LocationClassification.filterOutRevenue(locations);
    }
}
