import { createSelector } from '@ngrx/store';
import { OnlineOrders } from '@shared/core/utils';
import { historyOrdersState } from '../root';
import { hasDownloadedHistoryOrders } from '../hasDownloadedHistoryOrders';

export const canShowHistoryOrders = (active: boolean = false, ...locationNos: number[]) =>
    createSelector(historyOrdersState, hasDownloadedHistoryOrders, (stateHistory, hasDownloaded) =>
        hasDownloaded
            ? stateHistory.orders.some((order) => {
                  const obj = order?.data;
                  const foundLocation = locationNos.includes(obj?.PickupLocation);

                  return OnlineOrders.isOrderActive(obj?.Status) === active && foundLocation;
              })
            : true,
    );
