import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { LatestTransactionsEffects } from './latest-transactions.effects';
import { latestTransactionsReducer } from './latest-transactions.reducer';

@NgModule({
    declarations: [],
    imports: [CommonModule, StoreModule.forFeature('latestTransactions', latestTransactionsReducer), EffectsModule.forFeature([LatestTransactionsEffects])],
    exports: [],
    providers: [],
})
export class LatestTransactionsStateModule {}
