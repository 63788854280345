import * as uuid from 'uuid';

export class RestrictionItem implements OLO.Ordering.RestrictionCategorySummary<OLO.State.Cart.CartSimpleItemExtended, OLO.State.Cart.CartMenuFlowPageProduct> {
    public id: string = uuid.v4();
    public isExceeded!: boolean;
    public totalQty: number;

    constructor(
        public customerFriendlyName: string,
        public customerFriendlyDescription: string,
        public categoryIds: number[],
        public familyIds: number[],
        public limit: number,
        public products: OLO.State.Cart.CartSimpleItemExtended[],
        public menuFlowProducts: OLO.State.Cart.CartMenuFlowPageProduct[],
    ) {
        this.totalQty = [...products, ...menuFlowProducts].reduce((acc, product) => acc + product.Quantity, 0);

        this.isExceeded = this.totalQty > limit;
    }
}
