import * as io from 'io-ts';
import { boolean, nullableString, nullableNumber, nullable } from '@shared/core/types/common';
import { paymentProviderEnum, googleSupportedCardsType, googleAllowedCardAuthMethodType, appleMerchantCapabilityType, appleNetworkTypesType } from '@shared/core/types/consts';

const accountChargeDefinition: io.MixedObject<OLO.Config.Payments.AccountCharge> = {
    enabled: boolean,
    balanceDisplayThreshold: nullableNumber,
};

const googlePayDefinition: io.MixedObject<OLO.Config.Payments.GooglePay> = {
    enabled: boolean,
    apiVersion: nullableNumber,
    apiVersionMinor: nullableNumber,
    gateway: nullableString,
    gatewayMerchantId: nullableString,
    merchantName: nullableString,
    allowedCardNetworks: io.array(googleSupportedCardsType),
    allowedCardAuthMethods: io.array(googleAllowedCardAuthMethodType),
};

const applePayDefinition: io.MixedObject<OLO.Config.Payments.ApplePay> = {
    enabled: boolean,
    merchantId: nullableString,
    initiativeContext: nullableString,
    displayName: nullableString,
    apiVersion: nullableNumber,
    supportedNetworks: appleNetworkTypesType,
    merchantCapability: appleMerchantCapabilityType,
};

const payByPOintsDefinition = {
    enabled: boolean,
};

const rootDefinition: io.MixedObject<OLO.Config.Payments> = {
    accountCharge: io.type(accountChargeDefinition),
    payInStore: boolean,
    testMode: boolean,
    baseProvider: nullable(paymentProviderEnum),
    redirectType: boolean,
    googlePay: io.type(googlePayDefinition),
    applePay: io.type(applePayDefinition),
    payByPoints: io.type(payByPOintsDefinition),
};

export const paymentsSchema = io.type(rootDefinition);
