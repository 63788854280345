import * as io from 'io-ts';
import { logger } from './logger';

type Params = {
    /** Array of validation types */
    types: io.Mixed[];
    /** Custom logger function */
    logger: typeof logger;
};

/**
 * Combine multiple types into one type, i.e. when checking array against size and types
 * @param {Params=} params base parameters
 */
export const combinedTypes = <C = any>(params: Partial<Params> = {}) => {
    const opts: Params = {
        types: [],
        logger,
        ...params,
    };

    const contextRegistry: io.ValidationError[] = [];

    const validate = (input: unknown): input is C => {
        const result = opts.types.reduce((acc, nextType) => {
            const current = nextType.decode(input);
            if (current._tag === 'Left') {
                const last = current.left.length - 1;
                contextRegistry.push(current.left[last]);
                acc.push(current.left[last]);
            }

            return acc;
        }, [] as io.ValidationError[]);

        if (result.length) {
            return false;
        }

        return true;
    };

    return new io.Type(
        'combinedTypes',
        validate,
        (input, context) => {
            const isValid = validate(input);

            if (isValid) {
                return io.success(input);
            }

            const ctx = Array.from(context);

            const messages: string[] = [];
            const remappedContexts = contextRegistry.reduce((acc, nextType) => {
                nextType.context.forEach((subContext) => {
                    const overridedContext = {
                        ...subContext,
                        key: subContext.key || '__THIS',
                    };

                    acc.push(overridedContext);
                });

                if (nextType.message) {
                    messages.push(nextType.message);
                }

                return acc;
            }, [] as io.ContextEntry[]);

            return io.failure(input, [...ctx, ...remappedContexts], messages.join(' / '));
        },
        io.identity,
    );
};
