import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';

import { TaggingAnalyticsEffects } from './tag-analytics.effects';

@NgModule({
    imports: [CommonModule, EffectsModule.forFeature([TaggingAnalyticsEffects])],
})
export class TagAnalyticsStateModule {}
