import { Inject, Injectable } from '@angular/core';
import * as Tokens from '@shared/core/tokens';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as actions from '@shared/state/actions';
import { map } from 'rxjs/operators';
import { MemberSignupPolicyMapper } from '@shared/core/mappers/member-signup-policy.shared.mapper';

@Injectable({
    providedIn: 'root',
})
export class MemberSignupPolicyService {
    constructor(@Inject(Tokens.CONFIG_TOKEN) public config: OLO.Config, public httpClient: HttpClient, public store: Store<OLO.State>) {}

    public getMemberSignupPolicy(): Observable<OLO.DTO.MemberSignUpPolicyModel> {
        return this.httpClient
            .get<APIv3.MemberSignUpPolicyModel>(`${this.config.api.base}/loyaltyapp/membersignuppolicy`)
            .pipe(map((response: APIv3.MemberSignUpPolicyModel) => MemberSignupPolicyMapper.mapGetMemberSignupPolicy(response)));
    }

    public requestMemberSignupPolicy(): void {
        this.store.dispatch(actions.MemberSignupPolicyRequest());
    }
}
