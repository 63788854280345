import { Injectable } from '@angular/core';
import { Action, Store, select } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';

import * as Services from '@shared/core/services';

import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError, switchMap, withLatestFrom, skipWhile, take } from 'rxjs/operators';

@Injectable()
export class ProductImagesEffects {
    public requestProductsImagesForMountedMenuFlow$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MenuFlowsDetailsSuccessRequest),
            switchMap((action) =>
                this._store.pipe(
                    select(selectors.getMenuFlow(action.menuFlowId, action.locationNo)),
                    skipWhile(
                        (menuFlow) => menuFlow === undefined || menuFlow === null || menuFlow.isDownloading === true || menuFlow.data === null || menuFlow.hasFailed === true,
                    ),
                    take(1),
                    withLatestFrom(this._store.select('productImages')),
                    switchMap(([menuFlow, images]) => {
                        let productIds: number[] = menuFlow.data.Pages.reduce((acc, page) => acc.concat(page.Products.map((product) => product.ProductId)), []);

                        const filtered: number[] = productIds.filter(
                            (productId) => !images.find((image) => image.ProductId === productId && (image.isDownloading || image.hasSucceeded)),
                        );

                        return of(actions.ProductImagesRequest({ params: { width: 100, height: 100 }, productIds: filtered }));
                    }),
                ),
            ),
        ),
    );

    public onRequestProductImages$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.ProductImagesRequest),
            mergeMap(({ params, productIds }) => {
                if (productIds.length === 0) return [actions.ProductImagesSuccessRequest({ payload: [], productIds: [] })];

                return this._imagesService.getProductImages(params, ...productIds).pipe(
                    map((payload: OLO.DTO.ImageUrlModel[]) => actions.ProductImagesSuccessRequest({ payload, productIds })),
                    catchError((ex) => of(actions.ProductImagesErrorRequest({ ex, productIds }))),
                );
            }),
        ),
    );

    constructor(private _actions$: Actions, private _imagesService: Services.ImagesService, private _store: Store<OLO.State>) {}
}
