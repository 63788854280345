import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store, select } from '@ngrx/store';

import * as selectors from '@shared/state/selectors';
import * as actions from '@shared/state/actions';

import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';

import { Observable, throwError, forkJoin, combineLatest, of } from 'rxjs';
import { catchError, delay, map, take, withLatestFrom, tap, combineLatest as combineLatestOp, switchMap } from 'rxjs/operators';
import { MembersMapper } from '../mappers/members.shared.mapper';

@Injectable({
    providedIn: 'root',
})
export class MembersService /*  implements Resolve<Models.IMember> */ {
    /* https://stackoverflow.com/questions/39777220/cannot-read-property-type-of-undefined-ngrx */

    constructor(@Inject(Tokens.CONFIG_TOKEN) public config: OLO.Config, public httpClient: HttpClient, public store: Store<OLO.State>) {}

    public getUserData(): Observable<OLO.DTO.MemberAuthData> {
        return this.httpClient.get<APIv3.AuthGetCurrentMember.Responses.$200>(`${this.config.api.base}/auth/member/currentUser`).pipe(
            map((response: APIv3.AuthGetCurrentMember.Responses.$200) => MembersMapper.mapUserDataGETResponse(response)),
            withLatestFrom(this.store.pipe(select(selectors.getLoyaltyAppSettings))),
            map(([member, { data }]) => this._currentUserConverter(member, data)),
            delay(1000),
            catchError((ex) => throwError(ex)),
        );
    }

    // TODO - Because in db MobilePhone can be in string format or (prefix + string) format we have to find user country,
    //  countryCodeId and Prefix before we upgrade each partner's DBs. After this we remove this part of code.
    private _currentUserConverter(member: OLO.DTO.MemberAuthData, data: OLO.DTO.LoyaltyAppModel): OLO.DTO.MemberModel {
        if (member.MobilePhone.match(/\+/i)) {
            const country = data?.CountryAssignments.filter((c) => member.MobilePhone.includes(c.PhonePrefix))[0];
            if (country) {
                member.MobilePhonePrefix = country.PhonePrefix;
                member.MobilePhone = member.MobilePhone.split(country.PhonePrefix)[1];
                member.MobilePhoneCountryId = country.Id;
            }
        }

        /* Making sure that no other bugs pop out due to lack of MemberId prop etc. */
        return {
            ...member,
            PostCode: member.Postcode,
            FavoriteLocationId: member.FavoriteLocationNo,
            MemberId: member.UserId,
        };
    }

    public checkMemberUniqueCode(memberCode: string): Observable<OLO.DTO.MemberModel> {
        return this.httpClient
            .get<APIv3.MemberModel>(`${this.config.api.base}/members/uniqueCode/${window.encodeURIComponent(memberCode)}`)
            .pipe(map((response: APIv3.MemberModel) => MembersMapper.mapCheckMemberUniqueCodeGETResponse(response)));
    }

    public getMembers(props: APICommon.MembersGetPaginatedList = {}): Observable<OLO.DTO.MemberModel> {
        return this.httpClient
            .get<APIv3.MemberModel>(`${this.config.api.base}/members${Utils.HTTP.object2string(props)}`)
            .pipe(map((response: APIv3.MemberModel) => MembersMapper.mapMembersGETResponse(response)));
    }

    public validateUserNewProfileData(member: OLO.DTO.MemberModel): Observable<boolean> {
        /* When updating user profile details, verify its email and phone no */
        return forkJoin(
            this.validateMemberByProperty(member.MobilePhone, member.MobilePhoneCountryId, OLO.Enums.LOGIN_TYPE.MOBILE_PHONE_BASED_LOGIN, member.UserId),
            this.validateMemberByProperty(member.Email, member.MobilePhoneCountryId, OLO.Enums.LOGIN_TYPE.EMAIL_BASED_LOGIN, member.UserId),
        ).pipe(map(([{ isValid: byPhone }, { isValid: byEmail }]) => !(byPhone || byEmail)));
    }

    public updateUser(memberModel: OLO.DTO.MemberModel): Observable<boolean> {
        const mappedMember: APIv3.MemberProfileUpdateCommand = MembersMapper.mapMembersPUTRequest(memberModel);

        return this.httpClient.put<APIv3.MembersUpdateMemberPersonalData.Responses.$200>(`${this.config.api.base}/members/my/personalData`, mappedMember).pipe(
            map((response: APIv3.MembersUpdateMemberPersonalData.Responses.$200) => MembersMapper.mapMembersPUTResponse(response)),
            tap(() => this.store.dispatch(actions.MemberDataRequest())),
            catchError((ex) => throwError(ex)),
        );
    }

    public updatePasswordRequest(passwordModel: OLO.DTO.MemberChangePasswordCommand): Observable<boolean> {
        const mapedPasswordModel: APIv3.MembersChangeMemberPassword.Parameters.Model = MembersMapper.mapUpdatePasswordPUTRequest(passwordModel);

        return this.httpClient.put<APIv3.MembersChangeMemberPassword.Responses.$200>(`${this.config.api.base}/members/my/changePassword`, mapedPasswordModel).pipe(
            map((response: APIv3.MembersChangeMemberPassword.Responses.$200) => MembersMapper.mapUpdatePasswordPUTResponse(response)),
            catchError((ex) => throwError(ex)),
        );
    }

    public resetForgottenPassword(model: OLO.DTO.ResetMemberForgottenPasswordCommand): Observable<boolean> {
        const mappedModel: APIv3.AuthForgotPasswordReset.Parameters.Model = MembersMapper.mapResetForgottenPasswordPUTRequest(model);

        return this.httpClient.put<APIv3.AuthForgotPasswordReset.Responses.$200>(`${this.config.api.base}/auth/member/resetForgottenPassword`, mappedModel).pipe(
            map((response: APIv3.AuthForgotPasswordReset.Responses.$200) => MembersMapper.mapResetForgottenPasswordPUTResponse(response)),
            catchError((ex) => throwError(ex)),
        );
    }

    public confirmEmailAddress(Token: string): Observable<boolean> {
        const mappedModel: APIv3.AuthConfirmMemberEmail.Parameters.Model = MembersMapper.mapConfirmEmailAddressPUTRequest(Token);

        return this.httpClient.put<boolean>(`${this.config.api.base}/auth/member/confirmMemberEmail`, mappedModel).pipe(
            map((response: APIv3.AuthConfirmMemberEmail.Responses.$200) => MembersMapper.mapConfirmEmailAddressPUTResponse(response)),
            catchError((ex) => throwError(ex)),
        );
    }

    public validateMemberPasswordResetToken(Token: string): Observable<boolean> {
        const mappedModel: APIv3.AuthValidateResetForgottenPasswordToken.Parameters.Model = MembersMapper.mapValidateMemberPasswordResetTokenPOSTRequest(Token);

        return this.httpClient.post<boolean>(`${this.config.api.base}/auth/member/validateForgotPasswordToken`, mappedModel).pipe(
            map((response: APIv3.AuthValidateResetForgottenPasswordToken.Responses.$200) => MembersMapper.mapValidateMemberPasswordResetTokenPOSTResponse(response)),
            catchError((ex) => throwError(ex)),
        );
    }

    public validateMemberConfirmEmailToken(Token: string): Observable<boolean> {
        const mappedModel: APIv3.AuthValidateEmailConfirmationToken.Parameters.Model = MembersMapper.mapValidateMemberConfirmEmailTokenPOSTRequest(Token);

        return this.httpClient.post<boolean>(`${this.config.api.base}/auth/member/validateEmailConfirmationToken`, mappedModel).pipe(
            map((response: APIv3.AuthValidateEmailConfirmationToken.Responses.$200) => MembersMapper.mapValidateMemberConfirmEmailTokenPOSTResponse(response)),
            catchError((ex) => throwError(ex)),
        );
    }

    public validateMemberByMemberCard(value: string): Observable<boolean> {
        return this.validateLogin(value, OLO.Enums.LOGIN_TYPE.MEMBER_CARD_NUMBER_BASED_LOGIN).pipe(
            map((res) => {
                if (res) {
                    if (res.MemberId) {
                        return res.IsOnlineRegistered === false;
                    } else {
                        return null;
                    }
                }

                return null;
            }),
        );
    }

    public validateMemberByProperty(
        login: string,
        MobilePhoneCountryId: number,
        type: OLO.Enums.LOGIN_TYPE = OLO.Enums.LOGIN_TYPE.MOBILE_PHONE_BASED_LOGIN,
        memberId: Nullable<number> = null,
    ): Observable<OLO.DTO.ValidateMemberResponse> {
        /* Can user sign up/in */

        let validatedProperty: string;

        switch (type) {
            case OLO.Enums.LOGIN_TYPE.MOBILE_PHONE_BASED_LOGIN:
                validatedProperty = 'IsMobileValidated';
                break;
            case OLO.Enums.LOGIN_TYPE.EMAIL_BASED_LOGIN:
                validatedProperty = 'IsEmailValidated';
                break;
            default:
                validatedProperty = 'IsMobileValidated';
        }

        const postModel: APIv3.ValidateMemberLoginRequest = MembersMapper.mapValidateMemberByPropertyPOSTRequest({
            Login: login,
            LoginType: type,
            MobilePhoneCountryId,
        });

        return this.httpClient
            .post<APIv3.ValidateMemberLoginResponse>(`${this.config.api.base}/auth/member/validateLogin`, postModel)
            .pipe(map((response) => MembersMapper.mapValidateMemberByPropertyPOSTResponse(response, validatedProperty, memberId)));
    }

    public isMobileNumberRegistered(Login: string, MobilePhoneCountryId: number): Observable<boolean> {
        const postModel: APIv3.ValidateMemberLoginRequest = MembersMapper.mapIsMobileNumberRegisteredPOSTRequest({
            Login,
            LoginType: OLO.Enums.LOGIN_TYPE.MOBILE_PHONE_BASED_LOGIN,
            MobilePhoneCountryId,
        });

        return this.httpClient
            .post<APIv3.ValidateMemberLoginResponse>(`${this.config.api.base}/auth/member/validateLogin`, postModel)
            .pipe(map((response: APIv3.ValidateMemberLoginResponse) => MembersMapper.mapIsMobileNumberRegisteredPOSTResponse(response)));
    }

    public changeMemberPassword(password: string): Observable<boolean> {
        //
        //  resetPassword ? so clear!!
        //  ... and the best one - password string needs to be double quoted https://media.giphy.com/media/LObjDkMUNFU2oRnXve/giphy.gif
        //
        return this.httpClient.put<boolean>(`${this.config.api.base}/members/my/resetPassword`, `"${password}"`);
    }

    public resetPassword(MemberEmail: string): Observable<boolean> {
        const mappedModel: APIv3.AuthResendForgotPassword.Parameters.Model = MembersMapper.mapResetPasswordPOSTRequest(MemberEmail);

        return this.httpClient.post<boolean>(`${this.config.api.base}/auth/member/resendForgotPasswordEmail`, mappedModel).pipe(
            map((response: APIv3.AuthResendForgotPassword.Responses.$200) => MembersMapper.mapResetPasswordPOSTResponse(response)),
            catchError((ex) => throwError(ex)),
        );
    }

    public resendEmailConfirmation(model: OLO.DTO.SendMemberActivationEmailCommand, LoyaltyAppId: Nullable<number> = null): Observable<boolean> {
        const mappedModel: APIv3.AuthResendMemberEmailConfirmation.Parameters.Model = MembersMapper.mapResendEmailConfirmationPOSTRequest(model, LoyaltyAppId);

        return this.httpClient
            .post<APIv3.AuthResendMemberEmailConfirmation.Responses.$200>(`${this.config.api.base}/auth/member/resendEmailConfirmation`, mappedModel)
            .pipe(map((response: APIv3.AuthResendMemberEmailConfirmation.Responses.$200) => MembersMapper.mapResendEmailConfirmationPOSTResponse(response)));
    }

    public resendForgotPasswordConfirmation(MemberEmail: string, LoyaltyAppId: Nullable<number> = null): Observable<boolean> {
        const mappedModel: APIv3.AuthResendForgotPassword.Parameters.Model = MembersMapper.mapResendForgotPasswordConfirmationPOSTRequest(MemberEmail, LoyaltyAppId);

        return this.httpClient
            .post<boolean>(`${this.config.api.base}/auth/member/resendForgotPasswordEmail`, mappedModel)
            .pipe(map((response: APIv3.AuthResendForgotPassword.Responses.$200) => MembersMapper.mapResendForgotPasswordConfirmationPOSTResponse(response)));
    }

    public validateLogin(login: string, loginType: OLO.Enums.LOGIN_TYPE): Observable<OLO.DTO.MemberModel> {
        let postModel: OLO.DTO.ValidateMemberLoginRequest = {
            Login: login,
            LoginType: loginType,
        };

        if (loginType === OLO.Enums.LOGIN_TYPE.MOBILE_PHONE_BASED_LOGIN) {
            const [countryId, phone] = login.split(':');
            postModel.Login = phone;
            postModel.MobilePhoneCountryId = parseInt(countryId, 10);
        }

        const mappedModel: APIv3.AuthValidateMemberLogin.Parameters.Request = MembersMapper.mapValidateLoginPOSTRequest(postModel);

        return this.httpClient.post<APIv3.AuthValidateMemberLogin.Responses.$200>(`${this.config.api.base}/auth/member/validateLogin`, mappedModel).pipe(
            map((validateMember: APIv3.AuthValidateMemberLogin.Responses.$200) => MembersMapper.mapValidateLoginPOSTResponse(validateMember, login, loginType)),
            catchError((ex) => {
                console.error('Error validating member login', ex);

                return throwError(ex);
            }),
        );
    }

    public apiGetFreeProductsForMemberRequest(redeemed: boolean = false): Observable<OLO.DTO.MemberFreeProductModel[]> {
        return this.httpClient
            .get<APIv3.MembersGetMemberFreeProducts.Responses.$200>(`${this.config.api.base}/members/my/FreeProducts?returnRedeemedProducts=${redeemed}`)
            .pipe(map((response: APIv3.MembersGetMemberFreeProducts.Responses.$200) => MembersMapper.mapFreeProductsForMemberGETResponse(response)));
    }

    public apiPostFreeProductsVisitedRequest(freeProductId: number): Observable<number> {
        return this.httpClient.post<boolean>(`${this.config.api.base}/members/my/FreeProducts/${freeProductId}/visited`, {}).pipe(
            map((response: boolean) => {
                if (response) return freeProductId;

                return null;
            }),
        );
    }

    public apiGetLoyaltyProductsForMemberRequest(): Observable<OLO.DTO.GetLoyaltyProductProgramTrackingBusinessModel[]> {
        return this.httpClient
            .get<APIv3.MembersGetLoyaltyProductProgramTrackings.Responses.$200>(`${this.config.api.base}/members/my/LoyaltyProducts`)
            .pipe(map((response: APIv3.MembersGetLoyaltyProductProgramTrackings.Responses.$200) => MembersMapper.mapGetLoyaltyProductsForMemberRequest(response)));
    }

    public apiGetMemberAccountBalance(): Observable<OLO.DTO.MemberAccountBalanceModel> {
        return this.httpClient
            .get<APIv3.MembersGetMemberAccountBalance.Responses.$200>(`${Utils.HTTP.switchApi(this.config.api.base)}/members/my/accountBalance`)
            .pipe(map((response: APIv3.MembersGetMemberAccountBalance.Responses.$200) => MembersMapper.mapMemberAccountBalanceGETResponse(response)));
    }

    public apiGetMemberRedeemablePoints(): Observable<OLO.DTO.MemberRedeemablePoints> {
        return this.httpClient
            .get<APIv3.MembersGetMemberRedeemablePoints.Responses.$200>(`${Utils.HTTP.switchApi(this.config.api.base)}/members/my/RedeemablePoints`)
            .pipe(map((response: APIv3.MembersGetMemberRedeemablePoints.Responses.$200) => MembersMapper.mapMemberRedeemablePointsGETResponse(response)));
    }

    public async resendTemporaryVerificationCode(): Promise<boolean> {
        return new Promise((resolve) => {
            combineLatest([this.store.pipe(select(selectors.getMemberPhoneNo)), this.store.pipe(select(selectors.getMobilePhoneCountryId))])
                .pipe(take(1))
                .subscribe(([phoneNo, mobilePhoneCountryId]) => {
                    if (!phoneNo) return resolve(false);

                    this.store.dispatch(actions.MemberVerifyPhoneRestoreFlags());

                    this.store.dispatch(actions.MemberSendPhoneVerificationCodeDataRequest({ phoneNo, mobilePhoneCountryId }));

                    resolve(true);
                });
        });
    }

    public getHistoryTransactions(pageSize: number = 10, pageNo: number = 1): Observable<OLO.DTO.TransactionBusinessModel[]> {
        return this.httpClient
            .get<APIv3.MembersGetMemberTransactions.Responses.$200>(`${this.config.api.base}/members/my/transactions?pagingArgs.pageSize=${pageSize}&pagingArgs.pageNo=${pageNo}`)
            .pipe(map((response: APIv3.MembersGetMemberTransactions.Responses.$200) => MembersMapper.mapHistoryTransactionsGETResponse(response)));
    }

    public getLatestTransactions(
        params: APICommon.MemberLatestTransactionsRequestParam = {
            pageNo: 1,
            pageSize: 10,
        },
    ): Observable<OLO.DTO.LoyaltyAppTransactionModel[]> {
        const p = Utils.HTTP.object2string(params);

        return this.httpClient
            .get<APIv3.MembersGetMemberNewestTransactions.Responses.$200>(`${this.config.api.base}/members/my/latestTransactions${p}`)
            .pipe(map((response: APIv3.MembersGetMemberNewestTransactions.Responses.$200) => MembersMapper.mapLatestTransactionsGETResponse(response)));
    }

    public apiGetMemberFormFields(formType: OLO.Enums.MEMBER_FORM_FIELDS_TEMPLATE_TYPE): Observable<OLO.State.Members.MemberFormFieldModel[]> {
        return this.httpClient
            .get<APIv3.LoyaltyAppGetMemberFormFields.Responses.$200>(`${this.config.api.base}/loyaltyapp/memberFormFields?formType=${formType}`)
            .pipe(map((response: APIv3.LoyaltyAppGetMemberFormFields.Responses.$200) => MembersMapper.mapApiGetMemberFormFields(response)));
    }

    public apiGetPoints(): Observable<number> {
        return this.httpClient
            .get<APIv3.MembersGetMemberPoints.Responses.$200>(`${this.config.api.base}/members/my/points`)
            .pipe(map((response: APIv3.MembersGetMemberPoints.Responses.$200) => MembersMapper.mapApiGetPoints(response)));
    }

    public apiValidateMemberInterface(data: APIv3.TerminalMemberInterfaceModel): Observable<APIv3.MembersSendTerminalMemberInterface.Responses.$200> {
        return this.httpClient
            .post<APIv3.MembersSendTerminalMemberInterface.Responses.$200>(`${this.config.api.base}/members/my/terminalMemberInterface/validate`, data)
            .pipe(map((response: APIv3.MembersSendTerminalMemberInterface.Responses.$200) => MembersMapper.mapApiValidateMemberInterface(response)));
    }

    public apiGetMemberInterfaceUserData(): Observable<OLO.DTO.MemberInterfaceLoginLightweightModel[]> {
        return this.httpClient
            .get<APIv3.MembersGetTerminalMemberInterfaces.Responses.$200>(`${this.config.api.base}/members/my/terminalMemberInterface`)
            .pipe(map((response: APIv3.MembersGetTerminalMemberInterfaces.Responses.$200) => MembersMapper.mapApiGetMemberInterfaceUserData(response)));
    }
}
