import { Injectable, Inject, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store, select } from '@ngrx/store';

import * as selectors from '@shared/state/selectors';
import * as actions from '@shared/state/actions';

import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';

import { PickupsService } from './pickups.shared.service';

import { Observable, of, throwError } from 'rxjs';
import { map, take, switchMap, filter, withLatestFrom, catchError, auditTime, combineLatest, tap } from 'rxjs/operators';
import { OnlineOrdersMapper } from '@shared/core/mappers/online-orders.shared.mapper';
import { RouteService } from './route.shared.service';
import { CryptoService } from './crypto.shared.service';

@Injectable({
    providedIn: 'root',
})
export class OnlineOrdersService {
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) public config: OLO.Config,
        public httpClient: HttpClient,
        public store: Store<OLO.State>,
        @Optional() public pickupsService: PickupsService,
        private _routeService: RouteService,
        @Optional() public cryptoService: CryptoService,
    ) {}
    /* eslint-disable @typescript-eslint/naming-convention, quote-props*/
    public createNewOnlineOrder(model: OLO.DTO.OnlineOrderDetailedBusinessModel): Observable<OLO.DTO.OnlineOrderDetailedBusinessModel> {
        const { discounts, sendAutoReceiptEmail } = this.config.onlineOrders;

        if (discounts) {
            model = {
                ...model,
                OnlineDiscounts: [
                    {
                        Id: null,
                        OnlineOrderId: model.Id || null,
                        Value: discounts,
                    },
                ],
            };
        }

        if (!sendAutoReceiptEmail) {
            model = {
                ...model,
                ReceiptNotificationEmailAdresses: null,
            };
        }

        const mapedPostmodel: APIv3.OnlineOrderDetailedBusinessModel = OnlineOrdersMapper.mapCreateNewOnlineOrderPOSTRequest(model);

        return this.httpClient
            .post<APIv3.OnlineOrderDetailedBusinessModel>(`${this.config.api.base}/OnlineOrders`, mapedPostmodel)
            .pipe(map((response: APIv3.OnlineOrderDetailedBusinessModel) => OnlineOrdersMapper.mapCreateNewOnlineOrderPOSTResponse(response)));
    }

    public redeemOrderByPoints(orderModel: APIv3_SNAPSHOT.OnlineOrderBusinessModel): Observable<APIv3.OnlineOrderDetailedBusinessModel> {
        const mapedPostModel: APIv3.RequestPointsPaymentCommand = {
            Order: orderModel,
            RequestedPointsPaymentType: 0,
        };

        return this.httpClient
            .post<APIv3.OnlineOrderDetailedBusinessModel>(`${this.config.api.base}/OnlineOrders/requestPointsRedemption`, mapedPostModel)
            .pipe(map((response: APIv3.OnlineOrderDetailedBusinessModel) => OnlineOrdersMapper.mapCreateNewOnlineOrderPOSTResponse(response)));
    }

    public updateOnlineOrder(model: OLO.DTO.OnlineOrderDetailedBusinessModel): Observable<OLO.DTO.OnlineOrderDetailedBusinessModel> {
        const mappedPutmodel: APIv3.OnlineOrderDetailedBusinessModel = OnlineOrdersMapper.mapCreateNewOnlineOrderPOSTRequest(model);

        return this.httpClient
            .put<APIv3.OnlineOrderDetailedBusinessModel>(`${this.config.api.base}/OnlineOrders`, mappedPutmodel)
            .pipe(map((response: APIv3.OnlineOrderDetailedBusinessModel) => OnlineOrdersMapper.mapCreateNewOnlineOrderPOSTResponse(response)));
    }

    public cancelOnlineOrder(orderId: number): Observable<boolean> {
        return this.httpClient.put<boolean>(`${this.config.api.base}/OnlineOrders/${orderId}/Cancel`, null);
    }

    public sendEmailWithOrderConfirmation(orderId: number): Observable<boolean> {
        const mapedPostmodel: APIv3.OnlineOrderEmailConfirmationQuery = OnlineOrdersMapper.mapSendEmailWithOrderConfirmationPOSTRequest({
            OnlineOrderId: orderId,
            LoyaltyMobileAppId: null,
        });

        return this.httpClient
            .post<APIv3.OnlineOrdersSendOnlineOrderConfirmationEmail.Responses.$200>(`${this.config.api.base}/OnlineOrders/sendOrderConfirmationEmail`, mapedPostmodel)
            .pipe(map((response: APIv3.OnlineOrdersSendOnlineOrderConfirmationEmail.Responses.$200) => OnlineOrdersMapper.mapSendEmailWithOrderConfirmationPOSTResponse(response)));
    }

    public recalculateOnlineOrder(model: OLO.DTO.OnlineOrderDetailedBusinessModel): Observable<OLO.DTO.OnlineOrderDetailedBusinessModel> {
        const { discounts } = this.config.onlineOrders;
        if (discounts) {
            model = {
                ...model,
                OnlineDiscounts: [
                    {
                        Id: null,
                        OnlineOrderId: model.Id || null,
                        Value: discounts,
                    },
                ],
            };
        }

        const mapedPostmodel: APIv3.OnlineOrderDetailedBusinessModel = OnlineOrdersMapper.mapRecalculateOnlineOrderPOSTRequest(model);
        const params = {
            shouldCalculatePoints: this.config.payments.payByPoints.enabled,
        };

        return this.httpClient
            .post<APIv3.OnlineOrderDetailedBusinessModel>(`${this.config.api.base}/OnlineOrders/Recalculate`, mapedPostmodel, { params })
            .pipe(map((response: APIv3.OnlineOrderDetailedBusinessModel) => OnlineOrdersMapper.mapRecalculateOnlineOrderPOSTResponse(response)));
    }

    public addVoucherOnlineOrder(model: OLO.DTO.ActivateVoucherCommand): Observable<OLO.DTO.OnlineOrderBusinessModel> {
        const { discounts } = this.config.onlineOrders;
        if (discounts) {
            model.Order = {
                ...model.Order,
                OnlineDiscounts: [
                    {
                        Id: null,
                        OnlineOrderId: model.Order.Id || null,
                        Value: discounts,
                    },
                ],
            };
        }

        const mapedPostmodel: APIv3.ActivateVoucherCommand = OnlineOrdersMapper.mapAddVoucherOnlineOrderPOSTRequest(model);

        return this.httpClient
            .post<APIv3.OnlineOrderBusinessModel>(`${this.config.api.base}/OnlineOrders/activateVoucher`, mapedPostmodel)
            .pipe(map((response: APIv3.OnlineOrderBusinessModel) => OnlineOrdersMapper.mapAddVoucherOnlineOrderPOSTResponse(response)));
    }

    public removeVoucherOnlineOrder(model: OLO.DTO.DeactivateVoucherCommand): Observable<OLO.DTO.OnlineOrderBusinessModel> {
        const { discounts } = this.config.onlineOrders;
        if (discounts) {
            model.Order = {
                ...model.Order,
                OnlineDiscounts: [
                    {
                        Id: null,
                        OnlineOrderId: model.Order.Id || null,
                        Value: discounts,
                    },
                ],
            };
        }

        const mapedPostmodel: APIv3.DeactivateVoucherCommand = OnlineOrdersMapper.mapRemoveVoucherOnlineOrderPOSTRequest(model);

        return this.httpClient
            .post<APIv3.OnlineOrderBusinessModel>(`${this.config.api.base}/OnlineOrders/deactivateVoucher`, mapedPostmodel)
            .pipe(map((response: APIv3.OnlineOrderBusinessModel) => OnlineOrdersMapper.mapRemoveVoucherOnlineOrderPOSTResponse(response)));
    }

    public getOnlineOrders(p: APICommon.OnlineOrdersGetOrdersParams = {}): Observable<OLO.DTO.PaginatedListOnlineOrderDetailedBusinessModel> {
        return this.httpClient
            .get<APIv3.PaginatedListOnlineOrderDetailedBusinessModel>(`${this.config.api.base}/members/my/onlineOrders${Utils.HTTP.object2string(p)}`)
            .pipe(map((response: APIv3.PaginatedListOnlineOrderDetailedBusinessModel) => OnlineOrdersMapper.mapOnlineOrdersGETResponse(response)));
    }

    public getOnlineOrder(orderId: number): Observable<OLO.DTO.OnlineOrderDetailedBusinessModel> {
        return this.httpClient
            .get<APIv3.OnlineOrderDetailedBusinessModel>(`${this.config.api.base}/OnlineOrders/${orderId}`)
            .pipe(map((response: APIv3.OnlineOrderDetailedBusinessModel) => OnlineOrdersMapper.mapOnlineOrderGETResponse(response)));
    }

    public getOnlineOrderStatus(orderId: number, includeFinalized: boolean): Observable<number> {
        return this.httpClient.get<number>(`${this.config.api.base}/OnlineOrders/${orderId}/status?includeFinalized=${includeFinalized}`);
    }

    public requestActiveOrders(params: APICommon.OnlineOrdersGetOrdersParams = {}): void {
        this.store.dispatch(
            actions.HistoryOrdersRequest({
                'pagingArgs.pageNo': 1,
                statuses: [
                    OLO.Enums.ONLINE_ORDER_STATUS.VALIDATED,
                    OLO.Enums.ONLINE_ORDER_STATUS.RECIVED_AT_SITE,
                    OLO.Enums.ONLINE_ORDER_STATUS.SENT_TO_KITCHEN,
                    OLO.Enums.ONLINE_ORDER_STATUS.SENT_TO_SITE,
                ],
                ...params,
            }),
        );
    }

    public requestOrders(params: APICommon.OnlineOrdersGetOrdersParams = {}, pageNo: number): void {
        if (pageNo > 1) {
            this.store.dispatch(
                actions.HistoryOrdersLoadMoreRequest({
                    'pagingArgs.pageNo': pageNo,
                    'pagingArgs.pageSize': this.config.historyOrdersPage.ordersLimit,
                    statuses: [OLO.Enums.ONLINE_ORDER_STATUS.FINALIZED],
                    ...params,
                }),
            );
        } else {
            this.store.dispatch(
                actions.HistoryOrdersRequest({
                    'pagingArgs.pageNo': pageNo,
                    'pagingArgs.pageSize': this.config.historyOrdersPage.ordersLimit,
                    statuses: [
                        OLO.Enums.ONLINE_ORDER_STATUS.VALIDATED,
                        OLO.Enums.ONLINE_ORDER_STATUS.RECIVED_AT_SITE,
                        OLO.Enums.ONLINE_ORDER_STATUS.SENT_TO_KITCHEN,
                        OLO.Enums.ONLINE_ORDER_STATUS.SENT_TO_SITE,
                        OLO.Enums.ONLINE_ORDER_STATUS.FINALIZED,
                    ],
                    ...params,
                }),
            );
        }
    }

    public requestPreviousOrdersForReorder(params: APICommon.OnlineOrdersGetOrdersParams = {}, _pageSize: number = 4): void {
        this.store.pipe(select(selectors.getCurrentLocationNo), take(1)).subscribe((locNo) => {
            let locationNo: number = locNo;
            this.store.dispatch(
                actions.HistoryOrdersRequest({
                    'pagingArgs.pageNo': 1,
                    'pagingArgs.pageSize': 4,
                    includeFinalized: true,
                    locationNo,
                    statuses: [OLO.Enums.ONLINE_ORDER_STATUS.FINALIZED],
                    ...params,
                }),
            );
        });
    }

    public cleanHistoryOrderForGuest(): void {
        this.store.pipe(select(selectors.isGuestModeEnabled), take(1)).subscribe((guestMode) => {
            if (!guestMode) return;

            this.store.dispatch(actions.HistoryOrdersReset());
        });
    }

    public recalculateOrderAction(): void {
        this.store.dispatch(actions.OnlineOrderRecalculateRequest());
    }

    private _createSummaryPayment(recalcOrder: OLO.State.OnlineOrder['recalculateRequest']): Observable<OLO.Ordering.PaymentSummary> {
        return this.store.pipe(
            select(selectors.getPaymentStepsStatus),
            auditTime(200),
            filter((status) => status === 'complete' || status === 'failed'),
            withLatestFrom(this.store.pipe(select(selectors.getPaymentState))),
            take(1),
            map(([status, paymentState]) => ({
                status: status === 'failed' ? OLO.Enums.PAYMENT_STATUS.FAILED : OLO.Enums.PAYMENT_STATUS.SUCCESS,
                orderId: paymentState.orderId,
                orderTypeId: recalcOrder.data.OrderTypeId,
                locationNo: recalcOrder.data.PickupLocation,
            })),
        );
    }

    public placeOrderWithRedirectPaymentProvider(): void {
        this.pickupsService
            .validateCartWithPopup()
            .pipe(take(1))
            .subscribe((isCartValid) => {
                if (isCartValid) {
                    this.store.dispatch(actions.PaymentInitWithRedirect());
                }
            });
    }

    public placeOrderWithPaymentProvider(paymentConfig?: OLO.Ordering.PaymentConfig): void {
        this.placeOrder(paymentConfig)
            .then((summary) => this._routeService.saveConfirmationUrlAndNavigateToOrderConfirmation(summary.orderId, summary.locationNo, summary.orderTypeId))
            .catch((ex) => {
                console.error('placeOrderWithPaymentProvider', ex);

                return false;
            });
    }

    // public async placeOrder(creditCard: State.IPaymentCreditCardData = null, paymentMethod: State.IPaymentMethod = null): Promise<OLO.Ordering.IPaymentSummary> {
    public async placeOrder(paymentConfig: OLO.Ordering.PaymentConfig = {}): Promise<OLO.Ordering.PaymentSummary> {
        return new Promise((resolve, reject) => {
            if (!this.pickupsService) {
                return reject('pickupsService not provided');
            }
            this.pickupsService
                .validateCartWithPopup()
                .pipe(
                    take(1),
                    withLatestFrom(this.store.pipe(select(selectors.getOnlineOrderRecalcData))),
                    switchMap(([isCartValid, recalcOrder]) => {
                        if (isCartValid) {
                            if (paymentConfig?.paymentMethod) {
                                this.store.dispatch(actions.PaymentInitWithPaymentMethod(paymentConfig.paymentMethod));
                            } else {
                                this.store.dispatch(actions.PaymentInit());
                            }
                        } else {
                            return of(null);
                        }

                        return this._createSummaryPayment(recalcOrder);
                    }),
                    catchError((ex) => {
                        console.error('Unable to make payment', ex);

                        return throwError(ex);
                    }),
                )
                .subscribe(
                    (summary: OLO.Ordering.PaymentSummary) => {
                        if (!summary || summary.status !== OLO.Enums.PAYMENT_STATUS.SUCCESS || !summary.locationNo || !summary.orderId) {
                            console.error('Invalid payment summary', summary);

                            return reject(summary);
                        }
                        resolve(summary);
                    },
                    () => reject(),
                );
        });
    }

    public sendOnlineOrderReceipt(orderId: number): Observable<boolean> {
        const mapedPostmodel: APIv3.OnlineOrderEmailReceiptQuery = OnlineOrdersMapper.mapSendOnlineOrderReceiptPOSTRequest({
            LoyaltyMobileAppId: null,
            OnlineOrderId: orderId,
        });

        return this.httpClient
            .post<APIv3.OnlineOrdersSendOnlineOrderReceiptEmail.Responses.$200>(`${this.config.api.base}/OnlineOrders/sendOnlineOrderReceiptEmail`, mapedPostmodel)
            .pipe(map((response: APIv3.OnlineOrdersSendOnlineOrderReceiptEmail.Responses.$200) => OnlineOrdersMapper.mapSendOnlineOrderReceiptPOSTResponse(response)));
    }

    public requestHistoryOrder(orderId: number): void {
        this.store
            .pipe(
                select(selectors.getHistoryOrderObjectByOrderId(orderId)),
                take(1),
                filter((obj) => Boolean(obj?.isDownloading) === false && Boolean(obj?.data) === false),
            )
            .subscribe(() => this.store.dispatch(actions.HistoryOrderRequest({ orderId })));
    }

    public getHistoryOrderFromStorage(orderId: number): Nullable<OLO.DTO.OnlineOrderDetailedBusinessModel> {
        const historyOrdersData: string = Utils.Storage.getItem(OLO.Enums.HISTORY_ORDERS_STORAGE.DATA);
        let historyOrdersDataObject: OLO.DTO.OnlineOrderDetailedBusinessModel[] = [];

        if (historyOrdersData) {
            // repairs possibly corrupted history storage data
            try {
                historyOrdersDataObject = JSON.parse(this.cryptoService.decrypt(historyOrdersData));
            } catch (error) {
                Utils.Storage.set(OLO.Enums.HISTORY_ORDERS_STORAGE.DATA, this.cryptoService.encrypt('[]'));
                console.error('Error parsing history orders, it has been reset:', error);
            }

            const historyOrder = historyOrdersDataObject?.find((order) => order.Id === orderId) || null;

            if (historyOrder) {
                this.store.dispatch(actions.HistoryOrderLoadFromCache({ order: historyOrder }));
            }

            return historyOrder;
        }
    }

    public requestOrderStatus(orderId: number, includeFinalized: boolean): void {
        this.store.dispatch(actions.HistoryOrderStatusRequest({ orderId, includeFinalized: includeFinalized }));
    }

    public reorderSetup(orderId: number, locationNo: number, modalId: Nullable<number> = null): void {
        this.store.dispatch(actions.ReorderSetup({ orderId, locationNo, modalId }));
    }

    public reorderInitCalculations(orderId: number): void {
        this.store
            .pipe(
                select(selectors.getHistoryOrderByOrderId(orderId)),
                filter((order) => order !== null && order !== undefined && order.isDownloading !== true),
                combineLatest(
                    this.store.pipe(
                        select(selectors.getCurrentPickupTime),
                        filter((pickupTime) => pickupTime !== null),
                        take(1),
                    ),
                ),
                take(1),
            )
            .subscribe(([order, pickupTime]) => {
                if (!order.data) {
                    console.warn(`Invalid order data for order ${orderId}:`, order);

                    return;
                }

                this.store.dispatch(
                    actions.ReorderCalculateRequest({
                        orderId,
                        locationNo: order.data.PickupLocation,
                        pickupTime,
                    }),
                );
            });
    }

    public reorderToggleItemSelected(orderId: number, item: OLO.State.Cart.CartMenuFlowExtended | OLO.State.Cart.CartSimpleItemExtended): void {
        if (item._IsSelected) {
            return this.store.dispatch(actions.ReorderDeselectItem({ orderId, item }));
        }
        this.store.dispatch(actions.ReorderSelectItem({ orderId, item }));
    }

    public reorderAccept(orderId: number, locationNo: number, modalId: Nullable<number> = null): void {
        this.store
            .pipe(
                select(selectors.getCurrentPickupTime),
                switchMap((pickupTime) => this.store.pipe(select(selectors.getReorder(orderId, locationNo, pickupTime)))),
                take(1),
            )
            .subscribe((reorder) => {
                const menuFlows = reorder.data.cart.itemsMenuFlow.filter((menuFlow) => menuFlow && menuFlow._IsSelected && !menuFlow._IsDisabled);

                const simpleItems = reorder.data.cart.itemsSimple.filter((simpleItem) => simpleItem && simpleItem._IsSelected && !simpleItem._IsDisabled);

                this.store.dispatch(actions.CartSetupWithMultipleItems(modalId, locationNo, menuFlows, simpleItems));
            });
    }

    public insertOnlineOrderUrl(model: OLO.DTO.OnlineOrderUrlModel): Observable<OLO.DTO.OnlineOrderUrlModel> {
        const mapedPostmodel: APIv3.OnlineOrderUrlModel = OnlineOrdersMapper.mapInsertOnlineOrderUrlPOSTRequest(model);

        return this.httpClient
            .post<APIv3.OnlineOrderUrlModel>(`${this.config.api.base}/OnlineOrders/${mapedPostmodel.OrderId}/InsertOnlineOrderUrl`, mapedPostmodel)
            .pipe(map((response: APIv3.OnlineOrderUrlModel) => OnlineOrdersMapper.mapInsertOnlineOrderUrlPOSTResponse(response)));
    }

    public createNewOnlineOrderWithBaseInfoSave(model: OLO.DTO.OnlineOrderDetailedBusinessModel): void {
        this.createNewOnlineOrder(model)
            .pipe(take(1))
            .subscribe((payload) => {
                this.saveOrderBaseInfo({
                    OrderedDate: payload.OrderedDate,
                    TotalGrossValue: payload.TotalGrossValue,
                    Id: payload.Id,
                    MemberId: payload.MemberId,
                });
                this.store.dispatch(actions.OnlineOrderCreateSuccessRequest({ payload }));
            });
    }

    public saveOrderBaseInfo(orderInfo: OLO.DTO.OnlineOrdersPreviousOrder): void {
        const encryptedData = this.cryptoService.encrypt(JSON.stringify(orderInfo));

        Utils.Storage.set(OLO.Enums.CART_STORAGE.ORDER, encryptedData);
    }

    public saveOrderPaymentId(TransactionId: OLO.DTO.OnlineOrdersPreviousOrder['TransactionId']) {
        const orderInfo = JSON.parse(this.cryptoService.decrypt(Utils.Storage.getItem(OLO.Enums.CART_STORAGE.ORDER)));
        const encryptedData = this.cryptoService.encrypt(JSON.stringify({ ...orderInfo, TransactionId }));

        Utils.Storage.set(OLO.Enums.CART_STORAGE.ORDER, encryptedData);
    }

    public clearSavedOrderInfo() {
        Utils.Storage.remove(OLO.Enums.CART_STORAGE.ORDER);
    }

    /* eslint-enable @typescript-eslint/naming-convention, quote-props */
}
