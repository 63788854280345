import { Action } from '@ngrx/store';

export const LOADER_SHOW = '[Loader] Show';
export class LoaderShow implements Action {
    public type: string = LOADER_SHOW;
    constructor() {}
}

export const LOADER_HIDE = '[Loader] Hide';
export class LoaderHide implements Action {
    public type: string = LOADER_HIDE;
    constructor() {}
}
