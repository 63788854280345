import * as io from 'io-ts';

/** Check if string has min/max length */
export const stringLength = (min: number = 1, max?: number) => {
    const isValidStringLength = (input: unknown): input is string => {
        const isString = typeof input === 'string';
        if (!isString) {
            return false;
        }

        const length = (typeof input === 'string' && input.length) || null;
        if (min != null && length < min) {
            return false;
        }

        if (max != null && length > max) {
            return false;
        }

        return true;
    };

    return new io.Type(
        'isValidStringLength',
        isValidStringLength,
        (input, context) =>
            isValidStringLength(input) ? io.success(input) : io.failure(input, context, `Provided value ${input} has invalid length. Min: ${min}, max: ${max || 'infinite'}`),
        io.identity,
    );
};
