import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { MenuFlowImagesEffects } from './menu-flow-images.effects';
import { menuFlowImagesReducer } from './menu-flow-images.reducer';

@NgModule({
    declarations: [],
    imports: [CommonModule, StoreModule.forFeature('menuFlowImages', menuFlowImagesReducer), EffectsModule.forFeature([MenuFlowImagesEffects])],
    exports: [],
    providers: [],
})
export class MenuFlowImagesStateModule {}
