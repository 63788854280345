import { createSelector } from '@ngrx/store';

import * as Utils from '@shared/core/utils';

import { getSelectedOrderType } from '../getSelectedOrderType';

export const hasOrderTypeVisibleFields = createSelector(getSelectedOrderType, (orderType) => {
    const pageConfig = Utils.DynamicPages.customPageSetup('checkoutPage', orderType?.Id);

    return Utils.OnlineOrders.hasOrderTypeVisibleFields(orderType, pageConfig?.orderTypesDisclaimers);
});
