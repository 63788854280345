import { Inject, Injectable } from '@angular/core';
import { GoogleTags } from './implementation-classes/google-ecommerce.service';
import * as Tokens from '@shared/core/tokens';
import { EcommerceTracking } from './ecommerce-tracker.interface';

@Injectable({
    providedIn: 'root',
})
export class EcommerceTrackingService implements EcommerceTracking {
    private ecommerceTrackingComponents: EcommerceTracking[] = [];

    constructor(@Inject(Tokens.CONFIG_TOKEN) private _config: OLO.Config, private _googleTags: GoogleTags) {
        if (this._config.ecommerceTracking?.googleTags?.enabled) {
            this.addTrackingComponent(this._googleTags);
        }
    }

    public addTrackingComponent(component: EcommerceTracking): void {
        this.ecommerceTrackingComponents.push(component);
    }

    public recordAddToCartSimpleItem(item: OLO.State.Cart.CartSimpleItem & OLO.State.Cart.CartSimpleItemExtended): void {
        this.ecommerceTrackingComponents.forEach((component) => {
            component.recordAddToCartSimpleItem(item);
        });
    }

    public recordAddToCartMenuFlowItem(item: OLO.State.Cart.CartMenuFlowExtended): void {
        this.ecommerceTrackingComponents.forEach((component) => {
            component.recordAddToCartMenuFlowItem(item);
        });
    }

    public recordRemoveFromCart(item: GA.CartItem, quantity: number): void {
        this.ecommerceTrackingComponents.forEach((component) => {
            component.recordRemoveFromCart(item, quantity);
        });
    }

    public recordViewItem(item: OLO.DTO.OnlineMenuProductResponseModel): void {
        this.ecommerceTrackingComponents.forEach((component) => {
            component.recordViewItem(item);
        });
    }

    public recordViewCart(): void {
        this.ecommerceTrackingComponents.forEach((component) => {
            component.recordViewCart();
        });
    }

    public recordBeginCheckout(): void {
        this.ecommerceTrackingComponents.forEach((component) => {
            component.recordBeginCheckout();
        });
    }

    public recordPurchase(data: {
        orderId: number;
        order: APIv3_SNAPSHOT.OnlineOrderDetailedBusinessModel;
        simpleItems: OLO.Ordering.SimpleItem[];
        menuFlowItems: OLO.State.Cart.CartMenuFlow[];
        onlineMenuPages: OLO.DTO.OnlineMenuPageResponseModel[];
    }): void {
        this.ecommerceTrackingComponents.forEach((component) => {
            component.recordPurchase(data);
        });
    }

    public recordAddPaymentInfo(value: number, paymentMethod: GA.PAYMENT_METHOD): void {
        this.ecommerceTrackingComponents.forEach((component) => {
            component.recordAddPaymentInfo(value, paymentMethod);
        });
    }

    public recordAddShippingInfo(item: any): void {
        this.ecommerceTrackingComponents.forEach((component) => {
            component.recordAddShippingInfo(item);
        });
    }

    public recordSearch(searchTerm: string): void {
        this.ecommerceTrackingComponents.forEach((component) => {
            component.recordSearch(searchTerm);
        });
    }

    public recordSelectContent(contentType: GA.CONTENT_TYPE, item: string): void {
        this.ecommerceTrackingComponents.forEach((component) => {
            component.recordSelectContent(contentType, item);
        });
    }

    public recordSelectItemMenuFlow(product: OLO.State.Cart.CartMenuFlowExtended): void {
        this.ecommerceTrackingComponents.forEach((component) => {
            component.recordSelectItemMenuFlow(product);
        });
    }

    public recordSelectItemSimple(product: OLO.Ordering.SimpleItem): void {
        this.ecommerceTrackingComponents.forEach((component) => {
            component.recordSelectItemSimple(product);
        });
    }

    public recordSpendVirtualCurrency(value: number): void {
        this.ecommerceTrackingComponents.forEach((component) => {
            component.recordSpendVirtualCurrency(value);
        });
    }

    public recordViewItemList(page: OLO.DTO.OnlineMenuPageResponseModel): void {
        this.ecommerceTrackingComponents.forEach((component) => {
            component.recordViewItemList(page);
        });
    }
}
