import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { CollectionTypesEffects } from './collection-type.effects';
import { collectionTypesReducer } from './collection-type.reducer';

@NgModule({
    declarations: [],
    imports: [CommonModule, StoreModule.forFeature('collectionType', collectionTypesReducer), EffectsModule.forFeature([CollectionTypesEffects])],
})
export class CollectionTypeStateModule {}
