import { createSelector } from '@ngrx/store';

import { getCartTotalQuantity } from '@shared/state/cart/selectors/getCartTotalQuantity';
import { getCartTotalValue } from '@shared/state/cart/selectors/getCartTotalValue';
import { getMemberState } from '@shared/state/members/selectors';

export const isZeroPaymentsDisabled = createSelector(getCartTotalQuantity, getCartTotalValue, getMemberState, (cartTotalQuantity, cartTotalValue, membersState) => {
    if (cartTotalQuantity && !cartTotalValue) {
        return (membersState.isGuestModeEnabled && !membersState.guestData) || (!membersState.isGuestModeEnabled && !membersState.data);
    }

    return null;
});
