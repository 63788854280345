import { Injectable, Inject } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';

import * as actions from './locations.actions';
import * as Services from '@shared/core/services';
import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';

import { Observable } from 'rxjs';
import { mergeMap, catchError, map } from 'rxjs/operators';

@Injectable()
export class LocationsEffects {
    public requestLocations$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.LocationsRequest),
            mergeMap((action) =>
                this._locationsService.getLocationsWithOnlineOrdering(action.params).pipe(
                    map((payload) => {
                        payload.forEach((location) => {
                            const isConfgiruedForOrdering = !!location.OrderTypes?.length;
                            if (!isConfgiruedForOrdering) {
                                console.warn(`
                                Location ${location.LocationFriendlyName} has no order type configured.
                                Please configure orderTypes for this specific location to turn it on.
                                `);
                            } else {
                                /* Validate orderTypeIds futureMax/Min days. Needs to be updated after TOLO-1699 */
                                const collectionTypePickupConfig = new Utils.CollectionTypeHelper(this._config.collectionTypes).getPickupCollectionTypeConfig();
                                const collectionTypeDineInConfig = new Utils.CollectionTypeHelper(this._config.collectionTypes).getDineInCollectionTypeConfig();

                                const pickupsAllowedIds = collectionTypePickupConfig?.orderTypeIds || [];
                                const dineInAllowedIds = [
                                    collectionTypeDineInConfig?.dineInBuzzer?.orderTypeId || null,
                                    collectionTypeDineInConfig?.dineInTable?.orderTypeId || null,
                                ].filter((obj) => obj != null);

                                const reporter = (orderTypes: OLO.DTO.TerminalLocationOrderTypeModel[]): void => {
                                    if (!orderTypes.length) return;

                                    orderTypes.reduce((prevOrderType, next) => {
                                        const isEnabledMatch = prevOrderType.IsEnabledForFutureOrdering === next.IsEnabledForFutureOrdering;
                                        const isMinMatch = prevOrderType.FutureOrderingMinDaysAhead === next.FutureOrderingMinDaysAhead;
                                        const isMaxMatch = prevOrderType.FutureOrderingMaxDaysAhead === next.FutureOrderingMaxDaysAhead;
                                        if (isEnabledMatch && isMinMatch && isMaxMatch) {
                                            return next;
                                        }

                                        console.warn(
                                            `Setup issue - invalid orderTypId configuration in ${location.LocationFriendlyName}.`,
                                            'All orderTypeIds assigned to single collectionType group should match future order setup. TOLO-1709',
                                            `
                                            Invalid orderTypes:

                                            `,
                                            prevOrderType,
                                            next,
                                        );

                                        return next;
                                    }, orderTypes[0]);
                                };

                                const locationPickupOrderTypes = location.OrderTypes.filter((obj) => pickupsAllowedIds.includes(obj.Id));
                                const locationDineInOrderTypes = location.OrderTypes.filter((obj) => dineInAllowedIds.includes(obj.Id));

                                reporter(locationPickupOrderTypes);
                                reporter(locationDineInOrderTypes);
                            }
                            if (
                                (location.FutureOrderingMinDaysAhead === null && location.FutureOrderingMaxDaysAhead !== null && location.FutureOrderingMaxDaysAhead >= 0) ||
                                (location.FutureOrderingMinDaysAhead !== null && location.FutureOrderingMinDaysAhead >= 0 && location.FutureOrderingMaxDaysAhead === null)
                            ) {
                                console.warn(`invalid configuration for futureOrderingMinDaysAhead or futureOrderingMaxDaysAhead at locationNo: ${location.LocationNo}`);
                            }
                            const maxDaysAheadValue = Utils.LocationFutureOrdering.getMaxDaysAheadValue(location.FutureOrderingMaxDaysAhead, location.OrderTypes);
                            if (location.FutureOrderingMinDaysAhead !== null && maxDaysAheadValue > 30) {
                                let nextMonthDate = Utils.Dates.addDays(new Date(), 30);

                                this._store.dispatch(
                                    actions.LocationRequest({
                                        venueNo: this._config.venue ? this._config.venue.id : null,
                                        dateToCheck: Utils.Dates.getLocalISOFormatDate(nextMonthDate, false) /* Without Z - will not return TODAY if true */,
                                        duration: APICommon.ORDERING_TIME_INFO_DURATION.MONTH,
                                        locationNos: [location.LocationNo],
                                    }),
                                );
                            }
                        });

                        return actions.LocationsSuccessRequest({ params: action.params, payload });
                    }),
                    catchError((ex) => {
                        console.error('requestLocations$', ex);

                        return [actions.LocationsErrorRequest(action.params, ex)];
                    }),
                ),
            ),
        ),
    );

    public requestHiddenLocations$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.HiddenLocationsRequest),
            mergeMap(() =>
                this._locationsService.getHiddenLocations().pipe(
                    map((hiddenLocations) => actions.HiddenLocationsSuccessRequest({ payload: hiddenLocations })),
                    catchError((ex) => {
                        console.error(ex);

                        return [actions.HiddenLocationsErrorRequest({ ex })];
                    }),
                ),
            ),
        ),
    );

    public requestLocation$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.LocationRequest),
            mergeMap((action) =>
                this._locationsService.getLocationsWithOnlineOrdering(action.params).pipe(
                    map((payload) => {
                        payload.forEach((location) => {
                            const previousDate = action.params.dateToCheck;
                            const daysDifference = Utils.Dates.datesDiffInDays(previousDate, new Date());
                            const maxDaysAheadValue = Utils.LocationFutureOrdering.getMaxDaysAheadValue(location.FutureOrderingMaxDaysAhead, location.OrderTypes);

                            /**
                             * February fix. Month is shorter than 30 days so we need to compensate the difference in calculations.
                             * Otherwise there will be a 2-3 days gap in dates list in March
                             */
                            const dateStr = Utils.Dates.getChunk(previousDate).split('-');
                            const daysInMonth = Utils.Dates.getDaysInMonth(+dateStr[1], +dateStr[0]);
                            const nextMonthStartingPoint = 30 - (30 - daysInMonth);

                            const differenceIsBiggerThanMonth = maxDaysAheadValue > daysDifference + nextMonthStartingPoint;
                            if (differenceIsBiggerThanMonth) {
                                /** Without Z - will not return TODAY if true */
                                const nextMonthDate = Utils.Dates.getLocalISOFormatDate(Utils.Dates.addDays(previousDate, nextMonthStartingPoint), false);

                                this._store.dispatch(
                                    actions.LocationRequest({
                                        venueNo: this._config.venue ? this._config.venue.id : null,
                                        dateToCheck: nextMonthDate,
                                        duration: APICommon.ORDERING_TIME_INFO_DURATION.MONTH,
                                        locationNos: [location.LocationNo],
                                    }),
                                );
                            }
                        });

                        return actions.LocationSuccessRequest({ params: action.params, payload });
                    }),
                    catchError((ex) => {
                        console.error('requestLocations$', ex);

                        return [actions.LocationErrorRequest(action.params, ex)];
                    }),
                ),
            ),
        ),
    );

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: OLO.Config,
        private _actions$: Actions,
        private _locationsService: Services.LocationsService,
        private _store: Store<OLO.State>,
    ) {}
}
