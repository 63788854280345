import { createSelector } from '@ngrx/store';
import * as Utils from '@shared/core/utils';
import { ConfigStatic } from '@shared/core/statics';
import { getOrderTypeId } from '@shared/state/collectionType/selectors';

export const getLandingPageSearchMode = createSelector(getOrderTypeId, (orderTypeId) => {
    const config = new ConfigStatic().current;

    return Utils.DynamicPages.getCurrentPageSetup(config.landingPage, orderTypeId)?.locationMode.searchMode;
});
