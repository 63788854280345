import { ErrorHandler, NgZone, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class ErrorsHandlerSharedService implements ErrorHandler {
    constructor(public zone: NgZone) {}

    public handleError(error: OLO.Common.PromiseError | Error | Response | HttpErrorResponse | GeolocationPositionError): void {
        const chunkFailedMessage: RegExp = /Loading chunk [\d]+ failed/;
        if (chunkFailedMessage.test((error as Error)?.message)) {
            return window.location.reload();
        }
        this.logError(error);
        const constructorName: string = error.constructor.name;
        const obj: string = Object.prototype.toString.call(error);

        switch (true) {
            case error instanceof HttpErrorResponse || constructorName === 'Response':
                this._httpErrorsHandler(error as Response | HttpErrorResponse);
                break;

            case constructorName === 'GeolocationPositionError':
                this._geolocationErrorsHandler(error as GeolocationPositionError);
                break;
            case obj === '[object Error]' && constructorName === 'Error' && error.hasOwnProperty('rejection'):
                this._httpErrorsHandler((error as OLO.Common.PromiseError).rejection);
                break;
            default:
                this.unknownError(error);
        }
    }

    private _geolocationErrorsHandler(error: GeolocationPositionError) {
        let code: number = (error as GeolocationPositionError).code || 0;
        let statusText: string = (error as GeolocationPositionError).message || 'Unknown status';
        let errorCode: OLO.Enums.ERROR_CODES;
        let title: string = 'Geolocation Error';

        if (code === 1 || code === 2) {
            title = errorCode = `${code}` as OLO.Enums.ERROR_CODES;
        }

        this.showError(title, `${statusText}`, 'We were unable to get your coodrinates. Some features on this website might not be available for you.', code, errorCode);
    }

    private _httpErrorsHandler(error: Response | HttpErrorResponse): void {
        let code: number = (error as HttpErrorResponse).status || 0;
        let statusText: string = (error as HttpErrorResponse).statusText || 'Unknown status';
        let errorCode: OLO.Enums.ERROR_CODES;
        let message: string = '';
        let title: string = 'Http Error';

        if ((error as Response).json && typeof (error as Response).json === 'function') {
            try {
                const json: any = (error as Response).json();
                if (json.Message) {
                    message = json.Message;
                }
            } catch (ex) {
                if (!message) {
                    message = 'Unable to get error.json().Message. Please check the console for more detailed error info';
                }
            }
        }

        if (!navigator.onLine || (error.url !== undefined && error.url === null) || code === 0) {
            message = 'Check Internet Connection';
        }

        /* Assign error codes */
        if (typeof code === 'number') {
            title = errorCode = `${code}` as OLO.Enums.ERROR_CODES;
        }

        if (!message) {
            if (error.hasOwnProperty('message') && error['message']) {
                message = error['message'];
            }
        }

        this.showError(title, `${statusText}`, `${message}`, code, errorCode);
    }

    public unknownError(...args: any[]) {
        this.showError('Unknown Error', '???', 'Please check the console for more detailed error info', null);
    }

    public logError(error: any): void {
        console.error(error.constructor.name, error);
    }

    public showError(errorType: string, title: string, description: string, statusCode: number, errorCode?: OLO.Enums.ERROR_CODES): any {
        this.zone.run(() => {});
    }
}
