import { Objects } from './objects.utils';

type PropToOverride = {
    path: string;
    overrideValue?;
};

export class DevmodeUtils {
    private static _propsToOverride: PropToOverride[] = [{ path: 'AppSettings.GoogleMapsApiKey' }];

    public static overrideAppSettingsPayload(payload: OLO.DTO.LoyaltyAppModel): OLO.DTO.LoyaltyAppModel {
        this._propsToOverride.forEach((prop) => {
            payload = prop.overrideValue ? Objects.overrideNestedProp(payload, prop.path, prop.overrideValue) : Objects.deleteNestedProp(payload, prop.path);
        });

        return payload;
    }
}
