import { OrderPaymentMethod, OrderPaymentDetails } from './order-payment-method';
import { FatZebra3DSPaymentProviderService } from '../paymentProviders/fat-zebra-3ds.payment-provider.shared.service';

export class OrderPaymentWithFatZebra extends OrderPaymentMethod {
    constructor(protected _fatZebra3DSPaymentProviderService: FatZebra3DSPaymentProviderService) {
        super();
    }

    public async generatePaymentDetails(details: OrderPaymentDetails) {
        const { paymentProvider, cardState, paymentMethod, onlineOrder, currency, member, locationNo } = details;

        if (!this._isVendorPayment(paymentMethod) && paymentProvider === OLO.Enums.PAYMENT_PROVIDER.FAT_ZEBRA) {
            this._extendExecutePaymentModel(details);

            const card = cardState.data.find((obj) => (cardState.activeCardId ? obj.Id === cardState.activeCardId : obj.Token === cardState.activeCardToken)) ?? null;

            const fatZebraExtra = await this._fatZebra3DSPaymentProviderService.verifyCard({
                card,
                cardToken: cardState.activeCardToken,
                order: onlineOrder,
                currency,
                member,
                locationNo,
                paymentAccountId: cardState.activeCardId,
            });

            this._executePaymentModel.FatZebraExtra = fatZebraExtra;

            this._executePaymentModel.FatZebraToken = {
                r: cardState.fatZebra.r,
                v: cardState.fatZebra.v,
            };

            return this._executePaymentModel;
        }

        if (this._next) {
            return this._next.generatePaymentDetails(details);
        }

        return this._executePaymentModel;
    }
}
