import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';

import { alertMessagesReducer } from './alert-messages.reducer';

@NgModule({
    declarations: [],
    imports: [CommonModule, StoreModule.forFeature('alertMessages', alertMessagesReducer)],
    exports: [],
    providers: [],
})
export class AlertMessagesStateModule {}
