import { createAction, props } from '@ngrx/store';

export const ModalRequestClose = createAction('[Modal] Request close modal', (modalId: Nullable<number> = null) => ({ modalId }));

export const ModalOpen = createAction('[Modal] Open', (modal: OLO.State.Modals.Modal) => ({
    modal: {
        ...modal,
        id: modal.id || Math.floor(Math.random() * 10000) + new Date().getTime(),
    },
}));

export const ModalClose = createAction('[Modal] Close', props<{ id: number }>());

export const ModalCloseAll = createAction(
    '[Modal] Close all',
    (typesExcludedFromClosing: OLO.Components.Modals.MODAL_TYPE[] = [], animation: OLO.Components.Modals.MODAL_ANIMATION = OLO.Components.Modals.MODAL_ANIMATION.OUT) => ({
        typesExcludedFromClosing,
        animation,
    }),
);

export const ModalAnimate = createAction('[Modal] Animate', (id: number, animation: OLO.Components.Modals.MODAL_ANIMATION = OLO.Components.Modals.MODAL_ANIMATION.IN) => ({
    id,
    animation,
}));

/* Can be handeled on app level */
export const ModalBackgroundClicked = createAction('[Modal] Background clicked');

export const ModalSetLoading = createAction('[Modal] Set loading', (id: number, isLoading: boolean = false) => ({ id, isLoading }));

export const ModalSwap = createAction('[Modal] Swap', (modalId: number, details: OLO.State.Modals.ModalSwapModel = {}) => ({ modalId, details }));
