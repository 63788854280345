export class Colors {
    public static isValidHex(hex: string): boolean {
        return /^#([A-Fa-f0-9]{3,4}){1,2}$/.test(hex);
    }

    public static getChunksFromString(hex: string): RegExpMatchArray {
        const hexWithoutHash: string = hex.slice(1);
        const chunkSize: number = Math.floor((hex.length - 1) / 3);

        return hexWithoutHash.match(new RegExp(`.{${chunkSize}}`, 'g'));
    }

    public static convertHexUnitTo256(hexStr: string): number {
        return parseInt(hexStr, 16);
        // return parseInt(hexStr.repeat(2 / hexStr.length), 16);
    }

    public static getAlphafloat(a: number, alpha: number = undefined) {
        if (typeof a !== 'undefined') {
            return a / 256;
        }

        if (typeof alpha !== 'undefined') {
            if (1 < alpha && alpha <= 100) {
                return alpha / 100;
            }

            if (0 <= alpha && alpha <= 1) {
                return alpha;
            }
        }

        return 1;
    }

    public static hexToRGBA(hex: string, alpha: number = undefined): string {
        // hex should have alpha value on begining, eg. #ff111111
        try {
            if (!Colors.isValidHex(hex)) throw new Error('Invalid HEX');

            const hexArr: RegExpMatchArray = Colors.getChunksFromString(hex);
            const [a, r, g, b]: number[] = hexArr.map(Colors.convertHexUnitTo256);

            return `rgba(${r}, ${g}, ${b}, ${Colors.getAlphafloat(a, alpha)})`;
        } catch (ex) {
            console.error('hexToRGBA', ex);

            return hex;
        }
    }

    public static hexToRGB(hex: string): OLO.Common.RGB {
        hex = hex.trim();
        const isHexValid = /^#([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/gi.test(hex);

        if (isHexValid) {
            const r = hex.slice(1, 3);
            const g = hex.slice(3, 5);
            const b = hex.slice(5, 7);

            return {
                r: parseInt(r, 16),
                g: parseInt(g, 16),
                b: parseInt(b, 16),
            };
        } else {
            return {
                r: 230,
                g: 163,
                b: 56,
            };
        }
    }

    public static setBrandAccentRGBvariable(): void {
        let root = document.documentElement;
        const hexColor = getComputedStyle(document.documentElement).getPropertyValue('--color-brand-accent');
        const rgbColor = this.hexToRGB(hexColor);
        root.style.setProperty('--color-brand-accent-rgb', `${rgbColor['r']}, ${rgbColor['g']}, ${rgbColor['b']}`);
    }
}
