import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

type ValueObj = Nullable<{
    country: Nullable<OLO.DTO.LoyaltyAppCountryAssignmentModel>;
    phoneNo: Nullable<string>;
}>;

@Injectable({
    providedIn: 'root',
})
export class CellVerifyService {
    public nextValue = new BehaviorSubject<ValueObj>(null);

    /** Provides animation info to cell verification popup */
    public animate$: BehaviorSubject<OLO.Animations.IN | OLO.Animations.OUT> = new BehaviorSubject('in');
    /** Should show verification form or not */
    public isCellVerifyFormVisible$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    /** Provides current verification status */
    public verificationStatus$: BehaviorSubject<OLO.Components.CellVerify.CellVerificationStatus> = new BehaviorSubject(null);
    /** Provides token send to user to validator */
    public verificationToken$: Subject<string> = new Subject();
    /** Unpauses verification process inside validator */
    public verificationProcess$: BehaviorSubject<Nullable<number>> = new BehaviorSubject(null);
    /** Provides partial member id */
    public partialMemberId$: BehaviorSubject<Nullable<number>> = new BehaviorSubject(null);

    /**
     * Integrates outer services and allows triggering verification process inside cell-verify validator or other outher components.
     * It forces to send 4 digit code to user's mobile phone
     * @returns {this} current instance
     */
    public invokeVerificationProcess(): CellVerifyService {
        this.verificationProcess$.next(Date.now());

        return this;
    }

    /**
     * Restores values to the defaults
     */
    public reset(): CellVerifyService {
        this.animate$.next('in');
        this.verificationProcess$.next(null);
        this.verificationStatus$.next(null);
        this.isCellVerifyFormVisible$.next(false);
        this.verificationToken$.next('');
        this.partialMemberId$.next(null);

        return this;
    }

    /**
     * Updates verification token value - in cell validator this will unpause verification process
     * @param {string} token - token provided by the user inside  cell verify form
     */
    public setVerificationToken(token: string): CellVerifyService {
        this.verificationToken$.next(token);

        return this;
    }

    /**
     * Sets verification form flag to true
     * @param {number} delay when to completly show cell verification form
     * @param {number} animateTimeout animation change will be triggered after **(delay+animationTimeout)**
     */
    public showVerificationForm(delay: number = 0, animateDelay: number = 0): CellVerifyService {
        setTimeout(() => this.animate$.next('in'), delay + animateDelay);
        setTimeout(() => this.isCellVerifyFormVisible$.next(true), delay);

        return this;
    }

    /**
     * Sets verification form flag to false
     * @param {number} delay when to completly hide cell verification form
     * @param {number} animateTimeout animation change will be triggered after **(delay-animationTimeout)**
     */
    public hideVerificationForm(delay: number = 2000, animateTimeout: number = 500): CellVerifyService {
        setTimeout(() => this.animate$.next('out'), delay - animateTimeout);
        setTimeout(() => this.isCellVerifyFormVisible$.next(false), delay);

        return this;
    }

    /**
     * Sets verification status
     * @param status verifications tatus
     */
    public setVerificationStatus(status: OLO.Components.CellVerify.CellVerificationStatus): CellVerifyService {
        this.verificationStatus$.next(status);

        return this;
    }

    /**
     * Sets partial member id for sign up process
     * @param partialMemberId user's id
     */
    public setPartialMemberId(partialMemberId: number): CellVerifyService {
        this.partialMemberId$.next(partialMemberId);

        return this;
    }
}
