import { createSelector } from '@ngrx/store';

import * as Utils from '@shared/core/utils';

import { getFieldsDetails } from '../getFieldsDetails';
import { isMemberAuthorizedJWT } from '../isMemberAuthorizedJWT';
import { getCurrentMember } from '../getCurrentMember';

/**
 * Checks if user's profile picture is set if required by policies. For guests validation is off.
 */
export const isMemberProfilePictureValid = createSelector(isMemberAuthorizedJWT, getCurrentMember, getFieldsDetails, (isAuthorized, currentMember, fields) => {
    if (!isAuthorized) {
        return true;
    }

    if (!currentMember || !fields?.length) {
        return false;
    }

    const profilePic = new Utils.MemberPhotoChecker(currentMember, fields);

    return profilePic.isValid;
});
