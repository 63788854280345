import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as Services from '@shared/core/services';

import { Observable, of } from 'rxjs';
import { switchMap, catchError, map, mergeMap } from 'rxjs/operators';

@Injectable()
export class FreeProductsImagesEffects {
    public onFreeProductsSuccessGetImages$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberFreeProductsSuccessRequest),
            switchMap(({ payload }) => payload.map((product) => actions.FreeProductImageRequest({ productId: product.ProductId }))),
        ),
    );

    public requestFreeProductImage$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.FreeProductImageRequest),
            mergeMap(({ productId }) => {
                const params = {
                    width: window.innerWidth,
                    height: window.innerHeight,
                };

                if (window.innerWidth > 768) {
                    params.width = Math.floor(window.innerWidth / 3);
                    params.height = Math.floor(window.innerHeight / 3);
                }

                return this._imagesService.getImageForMemberFreeProduct(productId, params).pipe(
                    map((payload) => actions.FreeProductImageSuccessRequest({ productId, payload })),
                    catchError((ex) => {
                        console.error(ex);

                        return of(actions.FreeProductImageErrorRequest({ productId, ex }));
                    }),
                );
            }),
        ),
    );

    constructor(private _actions$: Actions, private _imagesService: Services.ImagesService) {}
}
