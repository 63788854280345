import { createSelector } from '@ngrx/store';

import { membersState } from '../root';

export const isMemberLoading = createSelector(
    membersState,
    (members) =>
        members.uniqueCode.isDownloading ||
        members.signUp.isSigningUp ||
        members.jwt.isDownloading ||
        members.changePassword.isChanging ||
        members.forgotPassword.isSending ||
        members.signIn.isSigningIn ||
        members.tokenForEmail.isSending ||
        members.tokenForPhone.isSending ||
        members.validateLogin.isValidating ||
        members.verifyPhone.isVerifying ||
        members.validateEmail.isValidating ||
        members.validatePhone.isValidating ||
        members.isDownloading ||
        members.setPassword.isSetting ||
        members.signUpPartialMember.isSigningUp ||
        members.update.isUpdating ||
        members.deleteProfile.isDeleting ||
        members.verifyLinkRewardsAccount.isVerifying ||
        members.linkRewardsAccount.isLinking,
);
