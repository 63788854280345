import { createSelector } from '@ngrx/store';
import { getCardsState } from '../../../creditCards/selectors/getCardsState';
import { isLoadingCards } from '../../../creditCards/selectors/isLoadingCards';
import { isActiveIdCardlessType } from '../../../creditCards/selectors/isActiveIdCardlessType';
import { isCardBillingDetailsValid } from '../../../creditCards/selectors/isCardBillingDetailsValid';
import { isCartZeroPrice } from '../../../cart/selectors/isCartZeroPrice';

export const isPaymentDisabledForCards = createSelector(
    getCardsState,
    isLoadingCards,
    isCardBillingDetailsValid,
    isActiveIdCardlessType,
    isCartZeroPrice,
    (cardsState, cardsLoading, isBillingDetailsValid, isCardlessType, isNoPriceCart) => {
        const isDataEmpty = Boolean(cardsState.data?.length) === false;
        const noBillingDataPresent = !isBillingDetailsValid || cardsState.showAddCardForm === true || isDataEmpty;
        const noCardsInfoPresent = cardsState.data == null || cardsLoading;

        return (!isCardlessType && !isNoPriceCart && noBillingDataPresent) || noCardsInfoPresent;
    },
);
