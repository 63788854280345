import { createSelector } from '@ngrx/store';

import * as Models from '@shared/core/models';
import * as Utils from '@shared/core/utils';

import { getWizzardSimpleItem, getWizzardMenuFlow } from '@shared/state/wizzard/selectors';
import { getDietaryTagImages } from '@shared/state/dietaryTagsImages/selectors';
import { getOnlineMenuPages } from '@shared/state/onlineMenu/selectors';
import { getOnlineMenuVirtualLocationsPagesFiltered } from '../../onlineMenuVirtualLocations';

export const getStatsForWizzardItem = (isMenuFlow: boolean, label: string) =>
    createSelector(
        getOnlineMenuPages,
        getOnlineMenuVirtualLocationsPagesFiltered,
        getWizzardSimpleItem,
        getWizzardMenuFlow,
        getDietaryTagImages,
        (onlineMenuPages, onlineMenuVirtualLocationsPagesFiltered, singleProduct, menuFlow, images) => {
            if ((!onlineMenuPages && !onlineMenuVirtualLocationsPagesFiltered) || (isMenuFlow && !menuFlow) || (!isMenuFlow && !singleProduct) || !images) return null;

            const allOnlineMenuPages = Utils.Arrays.safeConcat(onlineMenuPages, onlineMenuVirtualLocationsPagesFiltered);

            let stats: Nullable<Models.Stats> = allOnlineMenuPages.reduce((statsForProduct, page) => {
                if (statsForProduct) return statsForProduct;

                const matchedProduct = page.Products.find((product) => {
                    if (isMenuFlow) {
                        return product.MenuFlowId === menuFlow.MenuFlowId;
                    } else {
                        return product.ProductId === singleProduct.ProductId;
                    }
                });

                if (matchedProduct) {
                    return new Models.Stats({
                        label: isMenuFlow ? (matchedProduct.Price || matchedProduct.DisplayMinimumPrice ? label : null) : null,
                        price: isMenuFlow ? (matchedProduct.DisplayMinimumPrice ? matchedProduct.MinimumPrice : matchedProduct.Price) : matchedProduct.Price,
                        kilojoules: matchedProduct.Kilojoules,
                        displayMinimumPrice: matchedProduct.DisplayMinimumPrice,
                    });
                }

                return statsForProduct;
            }, null as Nullable<Models.Stats>);

            return (stats?.toJson() as OLO.Components.StatsComponentInput) || null;
        },
    );
