import { createSelector } from '@ngrx/store';

import { wizzardState } from '../root';

export const isWizzardPageQuantityMaxedOut = (pageDetails: OLO.DTO.MenuFlowPageModel) =>
    createSelector(wizzardState, (wizzard) => {
        const page = wizzard?.itemsMenuFlow?.Pages?.find((p) => p.PageIdentifier === pageDetails.PageIdentifier);
        if (!page || !page.Products) return null;

        const totalProducts = page.Products.reduce((acc, product) => (acc += product.Quantity), 0);

        return totalProducts === pageDetails.PageMaxQuantity;
    });
