import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { AppSettingsEffects } from './app-settings.effects';
import { appSettingsReducer } from './app-settings.reducer';

@NgModule({
    declarations: [],
    imports: [CommonModule, StoreModule.forFeature('appSettings', appSettingsReducer), EffectsModule.forFeature([AppSettingsEffects])],
    exports: [],
    providers: [],
})
export class AppSettingsStateModule {}
