import * as io from 'io-ts';
import { nullable, validString } from '@shared/core/types/common';
import { orderTypeIdsArray } from '@shared/core/types/consts';

const locationListPageCommonDefinition: io.MixedObject<OLO.Config.LocationListPage.Common> = {
    listResultsCountText: validString,
    listResultsCountTextPlural: validString,
};

const rootDefinition: io.MixedObject<OLO.Config.LocationListPage> = {
    orderTypeIds: orderTypeIdsArray,
    locationMode: io.union([nullable(io.type(locationListPageCommonDefinition)), io.literal(-1)]),
    venueMode: io.union([nullable(io.type(locationListPageCommonDefinition)), io.literal(-1)]),
};

export const locationListPageSchema = io.array(io.type(rootDefinition));
