import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { OnlineMenuEffects } from './online-menu.effects';
import { onlineMenuReducer } from './online-menu.reducer';

@NgModule({
    declarations: [],
    imports: [CommonModule, StoreModule.forFeature('onlineMenu', onlineMenuReducer), EffectsModule.forFeature([OnlineMenuEffects])],
    exports: [],
    providers: [],
})
export class OnlineMenuStateModule {}
