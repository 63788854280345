import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { PaymentsEffects } from './payment.effects';
import { paymentsReducer } from './payment.reducer';

@NgModule({
    imports: [StoreModule.forFeature('payment', paymentsReducer), EffectsModule.forFeature([PaymentsEffects])],
})
export class PaymentStateModule {}
