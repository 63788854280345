//
//  Will convert 09:00:00 to 9:00am, or 21:30:00 to 9:30pm
//

export class ShortHours {
    private _initalValue: string;
    private _initialMeridemiSeparator: string;
    private _transformedHour: string;
    private _text: T.StaticTexts;

    constructor(longTime: string, text: T.StaticTexts, meridemiSeparator: string = '') {
        this._initalValue = longTime;
        this._initialMeridemiSeparator = meridemiSeparator;
        this._text = text;

        this._transform();
    }

    private _transform(): void {
        if (!this._initalValue || typeof this._initalValue !== 'string') {
            this._transformedHour = this._initalValue;

            return;
        }

        const split: string[] = this._initalValue
            .replace(/^\d{4}-\d{2}-\d{2}T/, '')
            .replace(/\.\d{3}Z?$/, '')
            .split(':');
        const hour: number = +split[0];
        const meridiem: string = hour < 12 ? this._text.pickup.am : this._text.pickup.pm;
        const newHour: string = hour > 12 ? `${hour - 12}` : `${hour}`;

        this._transformedHour = `${newHour === '0' || newHour === '00' ? '12' : newHour}:${split[1]}${this._initialMeridemiSeparator}${meridiem}`;

        return;
    }

    public getTransformedHour(): string {
        return this._transformedHour;
    }
}
