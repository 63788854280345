import { Injectable, Compiler, Injector, ViewContainerRef, NgModuleFactory, Inject, Type } from '@angular/core';
import { LAZY_MODULES } from '@shared/core/tokens';
import { lazyModulesRegistry, ModulesNames } from './registry';

export interface LoadModuleWithComponentConfig {
    /**  Container for holding module's component */
    viewRefContainer: ViewContainerRef;
    /** Optional name [[ModulesNames]] for container - if container has different local name [[ModulesNames]] than module in registry [[lazyModulesRegistry]] */
    name?: ModulesNames;
    /** Should container be cleared before injecting module's component. Default is ```true``` */
    clearContainer?: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class LazyLoaderService {
    private _availableModules: ModulesNames[] = [];

    constructor(private _injector: Injector, private _compiler: Compiler, @Inject(LAZY_MODULES) private _lazyModulesRegistry: typeof lazyModulesRegistry) {
        this._extractRegistryNames();
    }

    public async loadModuleWithComponent(config: LoadModuleWithComponentConfig) {
        const opts: LoadModuleWithComponentConfig = {
            viewRefContainer: null,
            name: null,
            clearContainer: true,
            ...config,
        };

        try {
            const name = this._getName(opts.viewRefContainer, opts.name);
            if (!name) {
                throw new Error(`Unable to find ViewContainerRef's name. Provided value: ${opts.name}, calculated result: ${name}`);
            }
            const ngModuleOrNgModuleFactory = await this._lazyModulesRegistry[name]();

            let moduleFactory;
            if (ngModuleOrNgModuleFactory instanceof NgModuleFactory) {
                moduleFactory = ngModuleOrNgModuleFactory;
            } else {
                moduleFactory = await this._compiler.compileModuleAsync(ngModuleOrNgModuleFactory);
            }

            const entryComponent = (<any>moduleFactory.moduleType).entry;
            if (!entryComponent) {
                throw new Error(`Entry component not defined for module:${name}`);
            }
            const moduleRef = moduleFactory.create(this._injector);

            if (opts.clearContainer) {
                opts.viewRefContainer.clear();
            }
            const compFactory = moduleRef.componentFactoryResolver.resolveComponentFactory(entryComponent);
            const comp = opts.viewRefContainer.createComponent(compFactory);

            return comp;
        } catch (error) {
            console.error(opts.name, error);

            return null;
        }
    }

    public async loadModule(path: () => Promise<NgModuleFactory<any> | Type<any>>) {
        return path()
            .then((elementModuleOrFactory) => {
                /**
                 * Handle view engine
                 */
                if (elementModuleOrFactory instanceof NgModuleFactory) {
                    return elementModuleOrFactory;
                }

                /**
                 * Handle ivy engine
                 */
                return this._compiler.compileModuleAsync(elementModuleOrFactory);
            })
            .then((moduleFactory) => {
                const elementModuleRef = moduleFactory.create(this._injector);
                const moduleInstance = elementModuleRef.instance;

                return moduleInstance;
            })
            .catch((ex) => {
                console.error('EXEPTION', ex);

                return null;
            });
    }

    private _extractRegistryNames(): void {
        this._availableModules = Object.keys(this._lazyModulesRegistry) as ModulesNames[];
    }

    private _getName(container: ViewContainerRef, name?: any): ModulesNames {
        if (!name && (container as any)?._hostTNode?.localNames) {
            name = Array.from<ModulesNames>((container as any)?._hostTNode?.localNames).find((o) => this._availableModules.includes(o));
            if (!name) {
                throw new Error('Unable to find containerRef for dynamic module - please provide name of ViewChild container as a second argument of load method');
            }

            return name;
        }

        return name;
    }
}
