import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { LoyaltyIntroductionPagesImagesEffects } from './loyalty-introduction-pages-images.effects';
import { loyaltyIntroductionPagesImagesReducer } from './loyalty-introduction-pages-images.reducer';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature('loyaltyIntroductionPagesImages', loyaltyIntroductionPagesImagesReducer),
        EffectsModule.forFeature([LoyaltyIntroductionPagesImagesEffects]),
    ],
    exports: [],
    providers: [],
})
export class LoyaltyIntroductionPagesImagesStateModule {}
