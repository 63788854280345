import { Injectable, Inject } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';

import { Observable, throwError, of } from 'rxjs';
import { catchError, take, map } from 'rxjs/operators';
import { FatZebraPaymentProviderMapper } from '@shared/core/mappers/paymentProviders/fat-zebra.payment-provider.shared.mapper';

@Injectable({
    providedIn: 'root',
})
export class FatZebraPaymentProviderService {
    constructor(@Inject(Tokens.CONFIG_TOKEN) public config: OLO.Config, public httpClient: HttpClient) {}

    public requestCardToken(locationNo: Nullable<number> = null, defaultSettings: OLO.DTO.FatZebraSettingsResponse = null): Observable<OLO.DTO.FatZebraSettingsResponse> {
        if (!locationNo) {
            if (!defaultSettings) return throwError('No default settings provided for FatZebra payment provider');

            return of(defaultSettings).pipe(
                take(1),
                map((response) => ({
                    ...response,
                    Verification: response.Verification.toLocaleLowerCase(),
                })),
            );
        }

        return this._getSettingsForLocation(locationNo);
    }

    protected _getSettingsForLocation(locationNo: number): Observable<OLO.DTO.FatZebraSettingsResponse> {
        return this.httpClient.get<APIv3.FatZebraSettingsResponse>(`${Utils.HTTP.switchApi(this.config.api.base)}/Payments/fatZebra/settings/${locationNo}`).pipe(
            map((response: APIv3.FatZebraSettingsResponse) => FatZebraPaymentProviderMapper.mapGetSettingsForLocation(response)),
            catchError((ex) => {
                console.error('LocationNo not provided', ex);

                return throwError(ex);
            }),
        );
    }

    public tokenizeCard(data: { redirectUrl: string; verificationToken: string; returnUrlAfterRedirect: string; card: OLO.CreditCards.CreditCardDetails }): void {
        const { card, returnUrlAfterRedirect, verificationToken, redirectUrl } = data;

        const virtualForm = document.createElement('form');
        virtualForm.method = 'POST';
        virtualForm.action = redirectUrl;

        const [month, year] = Utils.CreditCards.dateToCustomFormat(card.expiryDate, 'MM-YY').split('-');

        const addInput = (name: string, value: any, isHidden: boolean = false) => {
            const input = document.createElement('input');
            input.type = isHidden ? 'hidden' : 'text';
            input.name = name;
            input.value = value;

            virtualForm.appendChild(input);
        };
        virtualForm.method = 'GET';
        virtualForm.action = redirectUrl.replace('.json', '');

        addInput('card_holder', card.cardHolderName);
        addInput('card_number', card.cardNumber);
        addInput('cvv', card.cvv);
        addInput('verification', verificationToken, true);
        addInput('return_path', returnUrlAfterRedirect || `${window.location.origin}/payment-gateway`, true);
        addInput('expiry_month', +month);
        addInput('expiry_year', `20${year}`);

        document.body.appendChild(virtualForm);
        virtualForm.submit();
    }
}
