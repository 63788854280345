import { createSelector } from '@ngrx/store';
import { getOnlineOrderRecalcData, getOrderSummary } from '../../../onlineOrder/selectors';
import { getMemberRedeemableePoints } from '../../../members/selectors';
import { isPayByPointsSelected } from '../../../creditCards/selectors';
import { getLocationRedemptionRate } from '../../../payment/selectors';

export const canFulfillPaymentWithPointsOnly = createSelector(
    getOrderSummary,
    isPayByPointsSelected,
    getMemberRedeemableePoints,
    getLocationRedemptionRate,
    getOnlineOrderRecalcData,
    (orderSummary, payByPointsSelected, points, locationRedemptionRate, recalculate) => {
        const total = orderSummary?.Total || 0;
        const availableBalance = points / locationRedemptionRate?.rate;
        const areAllProductsRedeemable = recalculate?.data?.Items?.every((item) => item.PointsCost !== null);

        return payByPointsSelected && areAllProductsRedeemable && availableBalance >= total;
    },
);
