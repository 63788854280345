import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './payment.actions';

const initialState: OLO.State.Payment = {
    orderId: null,
    errors: [],
    redirect: {
        vendorPayment: false,
    },
    paymentStepStatus: null,

    paymentMethod: null,
    isPaying: false,
    hasSucceeded: false,
    hasFailed: false,
    data: null,
    redemptionRate: {
        rate: null,
        isDownloading: false,
        hasSucceeded: false,
        hasFailed: false,
    },
};

export const paymentsReducerFn = createReducer(
    initialState,
    on(actions.PaymentClearErrors, (state) => ({
        ...state,
        errors: [],
    })),
    on(actions.PaymentInitWithRedirect, (state, action) => ({
        ...state,
        orderId: null,
        errors: [],
        paymentStepStatus: OLO.State.Payment.PAYMENT_STEP_STATUS.INIT,
        redirect: {
            ...state.redirect,
            vendorPayment: true,
        },
        paymentMethod: null,
        isPaying: true,
        hasSucceeded: false,
        hasFailed: false,
        data: null,
    })),
    on(actions.PaymentInitWithPaymentMethod, (state, action) => ({
        ...state,
        redirect: {
            ...state.redirect,
            vendorPayment: false,
        },
        orderId: null,
        errors: [],
        paymentStepStatus: OLO.State.Payment.PAYMENT_STEP_STATUS.INIT,

        paymentMethod: action.paymentMethod,
        isPaying: true,
        hasSucceeded: false,
        hasFailed: false,
        data: null,
    })),
    on(actions.PaymentForceStep, (state, action) => ({
        ...state,
        paymentStepStatus: action.step,
    })),
    on(actions.PaymentInit, (state, action) => ({
        ...state,
        orderId: null,
        redirect: {
            ...state.redirect,
            vendorPayment: false,
        },
        errors: [],
        paymentStepStatus: OLO.State.Payment.PAYMENT_STEP_STATUS.INIT,
        paymentMethod: null,
        isPaying: true,
        hasSucceeded: false,
        hasFailed: false,
        data: null,
    })),
    on(actions.PaymentStepValidateGuestData, (state, action) => ({
        ...state,
        paymentStepStatus: OLO.State.Payment.PAYMENT_STEP_STATUS.VALIDATE_GUEST,
    })),
    on(actions.PaymentStepValidateGuestCardToken, actions.PaymentStepValidateMemberCardToken, (state, action) => ({
        ...state,
        paymentStepStatus: OLO.State.Payment.PAYMENT_STEP_STATUS.VALIDATE_CARD_TOKEN,
    })),
    on(actions.PaymentStepCreateOrder, (state, action) => ({
        ...state,
        paymentStepStatus: OLO.State.Payment.PAYMENT_STEP_STATUS.CREATE_ORDER,
    })),
    on(actions.PaymentStepRedirect, (state, action) => ({
        ...state,
        paymentStepStatus: OLO.State.Payment.PAYMENT_STEP_STATUS.REDIRECT,
    })),
    on(actions.PaymentStepPay, (state, action) => ({
        ...state,
        paymentStepStatus: OLO.State.Payment.PAYMENT_STEP_STATUS.PAYING,
    })),
    on(actions.PaymentStepPaymentStatusCheck, (state, action) => ({
        ...state,
        orderId: action.OrderId,
        paymentStepStatus: OLO.State.Payment.PAYMENT_STEP_STATUS.PAYMENT_STATUS_CHECK,
        data: {
            TransactionId: action.TransactionId,
        },
    })),

    on(actions.PaymentStepComplete, (state, action) => ({
        ...state,
        orderId: action.OrderId,
        paymentStepStatus: OLO.State.Payment.PAYMENT_STEP_STATUS.COMPLETE,
        errors: [],
        redirect: {
            vendorPayment: false,
        },
        data: {
            ...action.payload,
        },
        paymentMethod: null,
        isPaying: false,
        hasSucceeded: true,
        hasFailed: false,
    })),
    on(actions.PaymentStepFailed, (state, action) => ({
        ...state,
        paymentStepStatus: OLO.State.Payment.PAYMENT_STEP_STATUS.FAILED,
        errors: [
            ...state.errors,
            {
                Id: action.Id,
                Message: action.Message,
            },
        ],
        isPaying: false,
        hasSucceeded: false,
        hasFailed: true,
    })),
    on(actions.LocationRedemptionRateRequest, (state) => ({
        ...state,
        redemptionRate: {
            rate: null,
            isDownloading: true,
            hasSucceeded: false,
            hasFailed: false,
        },
    })),
    on(actions.LocationRedemptionRateSuccessRequest, (state, action) => ({
        ...state,
        redemptionRate: {
            rate: action.redemptionRate,
            isDownloading: false,
            hasSucceeded: true,
            hasFailed: false,
        },
    })),
    on(actions.LocationRedemptionRateErrorRequest, (state) => ({
        ...state,
        redemptionRate: {
            rate: null,
            isDownloading: false,
            hasSucceeded: false,
            hasFailed: true,
        },
    })),
    on(actions.PaymentStepSkipForZeroPricedOrder, (state, action) => ({
        ...state,
    })),
    on(actions.PaymentReset, (state, action) => ({
        ...initialState,
        redemptionRate: { ...state.redemptionRate },
    })),
);

export function paymentsReducer(state: OLO.State.Payment | undefined, action: Action) {
    return paymentsReducerFn(state, action);
}
