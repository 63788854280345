import { GAMenuFlowItem, GASimpleItem } from '../models/googleAnalytics/google-analytics.model';

import * as Utils from '@shared/core/utils';

export class GoogleAnalyticsMapper {
    public static mapMenuFlowItems(
        items: OLO.State.Cart.CartMenuFlowExtended[],
        onlineMenuPages: OLO.DTO.OnlineMenuPageResponseModel[],
        currency: OLO.Types.CURRENCY_SYMBOL,
    ): GA.CommonItem[] {
        return items.map((item) => new GAMenuFlowItem(item, onlineMenuPages, currency));
    }

    public static mapSimpleItems(
        items: (OLO.State.Cart.CartSimpleItem & OLO.State.Cart.CartSimpleItemExtended)[],
        onlineMenuPages: OLO.DTO.OnlineMenuPageResponseModel[],
        currency: OLO.Types.CURRENCY_SYMBOL,
    ): GA.CommonItem[] {
        return items.map((item) => new GASimpleItem(item, onlineMenuPages, currency));
    }

    public static mapMenuFlowItemsForSelectItemEvent(
        product: OLO.State.Cart.CartMenuFlowExtended,
        onlineMenuPages: OLO.DTO.OnlineMenuPageResponseModel[],
        currency: OLO.Types.CURRENCY_SYMBOL,
    ): GA.CartItem[] {
        return product.Pages.reduce(
            (acc, page) => [
                ...acc,
                ...page.Products.map((pageProduct) => {
                    const onlineMenuPage = Utils.Products.getOnlineMenuPageContaingProduct(pageProduct, onlineMenuPages, true);

                    return {
                        item_id: pageProduct?.Plu?.toString() || null,
                        item_name: pageProduct.ProductName,
                        quantity: pageProduct.Quantity,
                        price: pageProduct.UnitPrice,
                        currency: currency,
                        index: Utils.Products.getProductIndexInOnlineMenuPages(pageProduct, onlineMenuPages, true),
                        item_list_name: onlineMenuPage?.Name || null,
                        item_list_id: onlineMenuPage?.Id || null,
                    };
                }),
            ],
            [],
        );
    }
}
