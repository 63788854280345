import { createSelector } from '@ngrx/store';

import { membersState } from '../root';

export const passwordChangeError = createSelector(membersState, (members) => {
    if (!members.changePassword.hasFailed) return null;
    if (members.changePassword.error && members.changePassword.error.Message) return members.changePassword.error;

    return {
        changePasswordError: 'Password change error',
    };
});
