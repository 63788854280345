import * as io from 'io-ts';
import { boolean, validString } from '@shared/core/types/common';

const validationDefinition: io.MixedObject<OLO.Config.Validation> = {
    isEnabled: boolean,
    defaultText: validString,
};

const rootDefinition: io.MixedObject<OLO.Config.Translation> = {
    validation: io.type(validationDefinition),
};

export const translationSchema = io.type(rootDefinition);
