import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { MemberSignupPolicyEffects } from './member-signup-policy.effects';
import { memberSignupPolicyReducer } from './member-signup-policy.reducer';

@NgModule({
    declarations: [],
    imports: [CommonModule, StoreModule.forFeature('memberSignupPolicy', memberSignupPolicyReducer), EffectsModule.forFeature([MemberSignupPolicyEffects])],
    exports: [],
    providers: [],
})
export class MemberSignupPolicyStateModule {}
