import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './online-menu-virtual-locations.actions';

const initialState: OLO.State.OnlineMenuVirtualLocation[] = [];

export const onlineMenuVirtualLocationsReducerFn = createReducer(
    initialState,
    on(actions.OnlineMenuPagesVirtualLocationRequest, (state, action) => {
        const foundObj = state.find((obj) => obj.locationNo === action.locationNo && obj.virtualLocationNo === action.virtualLocationNo);

        if (!foundObj) {
            return [
                ...state,
                {
                    isDownloading: true,
                    hasSucceeded: false,
                    hasFailed: false,
                    downloadedDate: null,
                    pickupDate: action.pickupDate,
                    locationNo: action.locationNo,
                    virtualLocationNo: action.virtualLocationNo,
                    orderTypeId: action.orderTypeId,
                    accentColor: action.accentColor,
                    displayIndex: action.displayIndex,
                    name: action.name,
                    data: null,
                },
            ];
        }

        return state.map((obj) => {
            if (obj.locationNo === action.locationNo && obj.virtualLocationNo === action.virtualLocationNo) {
                return {
                    ...obj,
                    isDownloading: true,
                    hasFailed: false,
                    hasSucceeded: false,
                    orderTypeId: action.orderTypeId,
                };
            }

            return obj;
        });
    }),
    on(actions.OnlineMenuPagesVirtualLocationSuccessRequest, (state, action) =>
        state.map((obj) => {
            if (obj.locationNo === action.locationNo && obj.virtualLocationNo === action.virtualLocationNo) {
                return {
                    ...obj,
                    isDownloading: false,
                    hasSucceeded: true,
                    hasFailed: false,
                    data: action.payload,
                    downloadedDate: new Date().getTime(),
                };
            }

            return obj;
        }),
    ),
    on(actions.OnlineMenuPagesVirtualLocationErrorRequest, (state, action) =>
        state.map((obj) => {
            if (obj.locationNo === action.locationNo && obj.virtualLocationNo === action.virtualLocationNo) {
                return {
                    ...obj,
                    isDownloading: false,
                    hasSucceeded: false,
                    hasFailed: true,
                    data: null,
                };
            }

            return obj;
        }),
    ),
    on(actions.OnlineMenuPagesVirtualLocationReset, () => []),
);

export function onlineMenuVirtualLocationsReducer(state: OLO.State.OnlineMenuVirtualLocation[] | undefined, action: Action) {
    return onlineMenuVirtualLocationsReducerFn(state, action);
}
