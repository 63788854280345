import { Inject, Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';

import * as actions from './member-signup-policy.actions';
import * as Services from '@shared/core/services';
import * as Tokens from '@shared/core/tokens';

import { Observable, of } from 'rxjs';
import { mergeMap, catchError, map, tap } from 'rxjs/operators';

@Injectable()
export class MemberSignupPolicyEffects {
    public requestMemberSignupPolicy$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberSignupPolicyRequest),
            mergeMap((_) =>
                this._memberSignupPolicyService.getMemberSignupPolicy().pipe(
                    map((payload) => actions.MemberSignupPolicySuccessRequest({ payload })),
                    tap((response) => {
                        if (!response.payload.PasswordPolicy) {
                            console.error('Unable to fetch PasswordPolicy rules. Please configure GET "/loyaltyapp/membersignuppolicy" endpoint to enforce password requirements');
                        }

                        if (!response.payload.AllowOverrideForMemberCardNumber && this._config.signUpRewardsAccountLinking?.enabled) {
                            console.warn(
                                `requestMemberSignupPolicy$ - "signUpRewardsAccountLinking" is currently not supported.
                                It will be re-added in the future. See more under TOLO-2767`,
                            );

                            // TODO: uncomment below logic once signUpRewardsAccountLinking is supported. See more under https://vmobglobal.atlassian.net/browse/TOLO-2739?focusedCommentId=550977.
                            // console.error('Setup issue - signUpRewardsAccountLinking.enabled is set to true and AllowOverrideForMemberCardNumber is set to false');
                        }
                    }),
                    catchError((ex) => {
                        console.error('requestMemberSignupPolicy$', ex);

                        return of(actions.MemberSignupPolicyErrorRequest(ex));
                    }),
                ),
            ),
        ),
    );

    constructor(@Inject(Tokens.CONFIG_TOKEN) private _config: OLO.Config, private _actions$: Actions, private _memberSignupPolicyService: Services.MemberSignupPolicyService) {}
}
