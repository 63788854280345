import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { ReorderEffects } from './reorder.effects';
import { reorderReducer } from './reorder.reducer';

@NgModule({
    declarations: [],
    imports: [CommonModule, StoreModule.forFeature('reorder', reorderReducer), EffectsModule.forFeature([ReorderEffects])],
    exports: [],
    providers: [],
})
export class ReorderStateModule {}
