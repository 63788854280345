import { createSelector } from '@ngrx/store';
import { appSettingsState } from '../root';

export const getAppSettingsLinks = createSelector(appSettingsState, (appSettings) => {
    const links: OLO.Config.ConfigLink[] = [];

    if (!appSettings?.apiSettings?.data?.ExternalUrls) return links;

    const a = appSettings?.apiSettings.data.ExternalUrls;

    a.forEach((link) => {
        links.push({
            id: link.Id,
            name: link.Name,
            urlType: link.UrlType,
            url: link.Url,
        });
    });

    return links;
});
