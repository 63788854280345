import { createSelector } from '@ngrx/store';
import { Pricing } from '@shared/core/utils';
import { cartState } from '../root';

export const getCartTotalValue = createSelector(cartState, (cart) => {
    let totalValue: number = 0;

    for (let i = 0, j = cart.itemsSimple.length; i < j; i++) {
        totalValue += cart.itemsSimple[i].Quantity * cart.itemsSimple[i].UnitPrice;
    }

    for (let i = 0, j = cart.itemsMenuFlow.length; i < j; i++) {
        const extraPrice = Pricing.calculateExtraPriceForIngredients(cart.itemsMenuFlow[i]);

        totalValue += (cart.itemsMenuFlow[i].UnitTotalValue + extraPrice) * cart.itemsMenuFlow[i].Quantity;
    }

    return totalValue;
});
