import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { GeolocationEffects } from './geolocation.effects';
import { geolocationReducer } from './geolocation.reducer';

@NgModule({
    declarations: [],
    imports: [CommonModule, StoreModule.forFeature('geolocation', geolocationReducer), EffectsModule.forFeature([GeolocationEffects])],
    exports: [],
    providers: [],
})
export class GeolocationStateModule {}
