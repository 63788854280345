import { createSelector } from '@ngrx/store';
import * as Utils from '@shared/core/utils';
import * as Statics from '@shared/core/statics';

import { getOrderTypeIdFromDefaultOrCart } from '../../orderTypes/getOrderTypeIdFromDefaultOrCart';

export const getCollectionTypeFromDefaultOrCart = createSelector(getOrderTypeIdFromDefaultOrCart, (orderTypeIdFromDefaultOrCart) => {
    const config = new Statics.ConfigStatic().current;

    return new Utils.CollectionTypeHelper(config.collectionTypes).getCollectionTypeByOrderTypeId(orderTypeIdFromDefaultOrCart);
});
