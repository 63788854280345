import { Injectable, Inject } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';

import * as actions from '../actions';
import * as selectors from '../selectors';

import * as Services from '@shared/core/services';
import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';

import { Observable, of, iif, timer } from 'rxjs';
import { switchMap, audit, withLatestFrom, map, take, combineLatest, filter, auditTime } from 'rxjs/operators';

@Injectable()
export class PickupsEffects {
    private _isScheduleOrderingEnabled = this._config.onlineOrders.scheduledOrders === true;

    /**
     * Calculates default pickup time if not set for current location
     */
    public setDefaultPickupTimeForCurrentLocation$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.CurrentLocationSet, actions.SetCollectionType),
            /** Prevent from retriggering */
            auditTime(0),
            withLatestFrom(
                this._store.pipe(select(selectors.getCurrentLocationDetails)),
                this._store.pipe(select(selectors.getCurrentPickupTime)),
                this._store.pipe(select(selectors.getOrderTypeId)),
                this._store.pipe(select(selectors.getCartPickupTime)),
            ),
            filter(([location, currentPickupTime]) => (!location && currentPickupTime === null) || currentPickupTime === undefined),
            switchMap(([, location, currentPickupTime, orderTypeId, cartPickupTime]) => {
                const list = Utils.LocationPickups.getAvailablePickupTimesWithFutureForLocation({ location, orderTypeId, futureOrders: this._isScheduleOrderingEnabled });

                if (list?.length > 0) {
                    let nextPickup = list.find((obj) => currentPickupTime?.Id === obj.Id) ?? list[0];

                    const boundle: Array<Action> = [actions.LocationsFiltersSyncPickupTime(nextPickup.IsAsap ? null : nextPickup)];

                    if (nextPickup.Id !== currentPickupTime.Id) {
                        boundle.unshift(actions.CurrentLocationPickupTimeSet(nextPickup));
                    }

                    return boundle;
                }

                return [];
            }),
        ),
    );

    /**
     * This will validate cart itself comparing to current date.
     * - check cart.pickupTime orderTimeout and pickupTime if user hasn't spent too much time ordering,
     * - check cart.onlineMenu dates and compare it to current date,
     * - check if it's TODAY if there was anything in the cart from other days,
     *
     * Check it only if there is anything in the cart.
     */
    public validateCartPickupTimeAndOnlineMenuTime$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(
                actions.ROUTER_NAVIGATED,
                actions.WizzardValidate,
                actions.CartLoad,
                actions.CartSetLocationNo,
                actions.CartSetOnlineMenu,
                actions.CartEditItem,
                actions.CartMenuFlowAdd,
                actions.CartMenuFlowDecrement,
                actions.CartMenuFlowIncrement,
                actions.CartMenuFlowChangeQuantity,
                actions.CartMenuFlowRemove,
                actions.CartMenuFlowUpdate,
                actions.CartSimpleItemAdd,
                actions.CartSimpleItemDecrement,
                actions.CartSimpleItemIncrement,
                actions.CartSimpleItemChangeQuantity,
                actions.CartSimpleItemRemove,
                actions.CartSimpleItemUpdate,
            ),
            audit(() => timer(10)),
            withLatestFrom(this._store.pipe(select(selectors.getCart)), this._store.pipe(select(selectors.isCollectionTypeDineIn))),
            switchMap(([, cartObj, isDineIn]) =>
                iif(
                    () => cartObj.itemsMenuFlow.length === 0 && cartObj.itemsSimple.length === 0,
                    [],
                    this.isPickupOrderTimeValid$().pipe(
                        switchMap((isValid) =>
                            iif(
                                () => isValid === true || (isValid === false && cartObj.pickupTime?.IsAsap === true && isDineIn === true),
                                [],
                                of(cartObj).pipe(
                                    withLatestFrom(this._store.pipe(select(selectors.getAllModals))),
                                    switchMap(() => {
                                        console.error('Cart pickup time is not valid any more!');

                                        this._pickupsService.exitLocationWithPickupPrompt();

                                        return [];
                                    }),
                                ),
                            ),
                        ),
                    ),
                ),
            ),
        ),
    );

    public isPickupOrderTimeValid$(date: Date = new Date()): Observable<boolean> {
        return this._store.pipe(
            select(selectors.getCart),
            combineLatest(
                this._store.pipe(
                    select(selectors.getOrderingTimeInfoByCartLocationAndOrderType),
                    filter((obj) => obj !== undefined && obj !== null),
                    take(1),
                ),
            ),
            take(1),
            map(([cart, orderingTimeInfo]) => {
                const relevantOnlineMenu = cart.onlineMenu || cart.onlineMenuVirtualLocations?.[0];

                return this._pickupsService.validateSelectedPickupTimeObjForOnlineMenu(date, cart.pickupTime, relevantOnlineMenu, orderingTimeInfo);
            }),
        );
    }

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: OLO.Config,
        private _actions$: Actions,
        private _store: Store<OLO.State>,
        private _pickupsService: Services.PickupsService,
    ) {}
}
