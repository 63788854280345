import { createSelector } from '@ngrx/store';
import { getLocationFilters } from '@shared/state/locationsFilters/selectors';
import { getLandingPageSearchMode } from '../../dynamicPage/getLandingPageSearchMode';

export const getLocationFiltersSearchInputWithDelivery = () =>
    createSelector(
        getLandingPageSearchMode,
        getLocationFilters,
        (searchMode, filters) =>
            (searchMode === OLO.Enums.SEARCH_MODE.DELIVERY_ADDRESS
                ? filters?.address?.details?.formatted_address || filters?.address?.structured_formatting?.main_text || filters?.address?.description
                : filters?.search) || null,
    );
