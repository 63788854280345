import { Inject, Injectable } from '@angular/core';
import { Store, select, Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';

import * as Services from '@shared/core/services';
import * as Tokens from '@shared/core/tokens';

import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, filter } from 'rxjs/operators';

@Injectable()
export class HistoryOrdersEffects {
    public onSuccessfulOrderCancelUpdateOrderDetails$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.OnlineOrderCancelSuccessRequest),
            switchMap(({ orderId, updateHistoryOrder }) =>
                updateHistoryOrder
                    ? [
                          actions.HistoryOrderRequest({
                              orderId,
                          }),
                      ]
                    : [],
            ),
        ),
    );

    public requestHistoryOrderStatus$: Observable<Action> = createEffect(() =>
        this._actions$
        .pipe(
            ofType(
                actions.HistoryOrderStatusRequest,
            ),
            switchMap(action => this._onlineOrdersService.getOnlineOrderStatus(action.orderId, action.includeFinalized)
                .pipe(
                    map(payload => actions.HistoryOrderStatusSuccessRequest({ orderId: action.orderId, payload })),
                    catchError(ex => of(actions.HistoryOrderStatusErrorRequest({ orderId: action.orderId, ex }))),
                ))
            )
    );

    public requestSingleHistoryOrder$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.HistoryOrderRequest),
            switchMap((action) =>
                this._onlineOrdersService.getOnlineOrder(action.orderId).pipe(
                    map((payload) => actions.HistoryOrderSuccessRequest({ orderId: action.orderId, payload })),
                    catchError((ex) => of(actions.HistoryOrderErrorRequest({ orderId: action.orderId, ex }))),
                ),
            ),
        ),
    );

    public onRequestHistoryOrders$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.HistoryOrdersRequest),
            switchMap((action) =>
                this._store.pipe(
                    select(selectors.getCurrentMember),
                    filter((member) => member !== null && this._config.appMode !== OLO.Enums.APP_MODE.LOYALTY_ONLY),
                    take(1),
                    switchMap((member) =>
                        this._onlineOrdersService
                            .getOnlineOrders({
                                ...action.params,
                            })
                            .pipe(
                                map((response) => actions.HistoryOrdersSuccessRequest({ params: action.params, payload: response.Items })),
                                catchError((ex) => of(actions.HistoryOrdersErrorRequest({ params: action.params, ex }))),
                            ),
                    ),
                ),
            ),
        ),
    );

    public onRequestHistoryOrdersLoadMore$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.HistoryOrdersLoadMoreRequest),
            switchMap((action) =>
                this._store.pipe(
                    select(selectors.getCurrentMember),
                    filter((member) => member !== null),
                    take(1),
                    switchMap((member) =>
                        this._onlineOrdersService
                            .getOnlineOrders({
                                ...action.params,
                            })
                            .pipe(
                                map((response) => actions.HistoryOrdersLoadMoreSuccessRequest({ params: action.params, payload: response.Items })),
                                catchError((ex) => of(actions.HistoryOrdersLoadMoreErrorRequest({ params: action.params, ex }))),
                            ),
                    ),
                ),
            ),
        ),
    );

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: OLO.Config,
        private _actions$: Actions,
        private _onlineOrdersService: Services.OnlineOrdersService,
        private _store: Store<OLO.State>,
    ) {}
}
