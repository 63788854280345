import { Injectable } from '@angular/core';
import { Action, Store, select } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';

import * as Utils from '@shared/core/utils';
import * as Services from '@shared/core/services';

import { Observable, of, timer } from 'rxjs';
import { map, catchError, switchMap, withLatestFrom, auditTime, take, filter, skipWhile, tap, pairwise } from 'rxjs/operators';

@Injectable()
export class ProductRestrictionsEffects {
    public requestRestrictionsForLocation$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.CurrentLocationPickupTimeSet, actions.CartSetPickupTime),
            /* wait for current pickupt time set */
            auditTime(50),
            withLatestFrom(
                this._store.pipe(select(selectors.getCurrentLocationDetails)),
                this._store.pipe(select(selectors.getCart)),
                this._store.pipe(select(selectors.getCurrentPickupTime)),
            ),
            switchMap(([action, currentLocation, cart, currentPickupTime]) => {
                if (!currentLocation) return [];

                const pickupTime: string = action.type === actions.CurrentLocationPickupTimeSet.type ? currentPickupTime?.DateLocalISO : cart?.pickupTime?.DateLocalISO;

                const dateToCheck = Utils.Dates.getLocalISOFormatDate(pickupTime ? Utils.Dates.createDate(pickupTime) : Utils.Dates.createDate(), false);

                const params = {
                    locationNo: currentLocation.LocationNo,
                    dateToCheck,
                };

                return [actions.ProductRestrictionsRequest({ params })];
            }),
        ),
    );

    public onRequestProductRestrictions$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.ProductRestrictionsRequest),
            switchMap(({ params }) =>
                this._productService.getProductRestrictionsForLocation(params).pipe(
                    map((payload) => actions.ProductRestrictionsSuccessRequest({ payload })),
                    catchError((ex) => {
                        console.error('Product restrictions error:', ex);

                        return of(actions.ProductRestrictionsErrorRequest());
                    }),
                ),
            ),
        ),
    );

    public onRequestProductRestrictionsSuccess$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.ProductRestrictionsSuccessRequest),
            switchMap(({ payload }) =>
                this._store.pipe(
                    select(selectors.getOnlineMenuVirtualLocations),
                    withLatestFrom(
                        this._store.pipe(select(selectors.getCurrentLocationDetails)),
                        this._store.pipe(select(selectors.getOnlineMenu)),
                        this._store.pipe(select(selectors.getMenuFlows)),
                    ),
                    switchMap(([onlineMenuVirtualLocations, currentLocation, onlineMenu, menuflows]) => {
                        const onlineMenuDataAndVirtualLocationsNotExist = !onlineMenu.data && !onlineMenuVirtualLocations;
                        const virtualLocationsExistAndDownloaded =
                            !!onlineMenuVirtualLocations && !onlineMenuVirtualLocations.every((virtualLocationOnlineMenu) => virtualLocationOnlineMenu.hasSucceeded);

                        if (onlineMenuDataAndVirtualLocationsNotExist || virtualLocationsExistAndDownloaded) return [];

                        const onlineMenuPages = onlineMenu.data?.Pages;
                        const virtualLocationOnlineMenusPages = onlineMenuVirtualLocations.map((virtualLocationOnlineMenu) => virtualLocationOnlineMenu.data.Pages);
                        const allOnlineMenuPages = onlineMenuPages
                            ? [onlineMenuPages, ...virtualLocationOnlineMenusPages].reduce((acc, menu) => [...acc, ...menu], [])
                            : virtualLocationOnlineMenusPages.reduce((acc, menu) => [...acc, ...menu], []);
                        const onlineMenuMenuFlowIds: number[] = allOnlineMenuPages.reduce(
                            (MenuFlowIds: number[], pages: OLO.DTO.OnlineMenuPageResponseModel) =>
                                MenuFlowIds.concat(pages.Products.map((p) => p.MenuFlowId).filter((MFIds) => MFIds !== null)),
                            [] as number[],
                        );
                        const products: OLO.DTO.OnlineMenuProductResponseModel[] = allOnlineMenuPages.reduce(
                            (Prod: OLO.DTO.OnlineMenuProductResponseModel[], pages: OLO.DTO.OnlineMenuPageResponseModel) => Prod.concat(pages.Products),
                            [] as OLO.DTO.OnlineMenuProductResponseModel[],
                        );

                        const resArr: OLO.DTO.OnlineMenuProductResponseModel[] = [];

                        if (payload && products && payload.length && products.length) {
                            payload.forEach((restriction) => {
                                products.forEach((product) => {
                                    if (
                                        restriction.ProductIds.includes(product.ProductId) ||
                                        restriction.CategoryIds.includes(product.ProductCategoryId) ||
                                        product.ProductFamilyIds.some((family) => restriction.FamilyIds.includes(family))
                                    ) {
                                        resArr.push(product);
                                    }
                                });
                            });

                            /**
                             * Little optimization to prevent from redownloading menuflow details.
                             * Also do not download menu flow details if it was not on restrictions list
                             */
                            let requiredMenuFlows = payload.reduce((acc, productRestr) => {
                                const mfIds = [];

                                onlineMenuMenuFlowIds.forEach((id) => {
                                    if (productRestr.CategoryIds.includes(id) || productRestr.FamilyIds.includes(id) || productRestr.ProductIds.includes(id)) {
                                        mfIds.push(id);
                                    }
                                });

                                acc.concat(...mfIds);

                                return acc;
                            }, [] as number[]);
                            requiredMenuFlows = Array.from(new Set(requiredMenuFlows));
                            const menuflowsNotDownloadedYet = requiredMenuFlows.filter(
                                (menuFlowId) => !menuflows.find((obj) => obj.LocationNo === currentLocation.LocationNo && obj.MenuFlowId === menuFlowId && obj.hasFailed === false),
                            );

                            menuflowsNotDownloadedYet.forEach((menuFlowId) =>
                                this._store.dispatch(
                                    actions.MenuFlowsDetailsRequest({
                                        menuFlowId,
                                        locationNo: currentLocation.LocationNo,
                                    }),
                                ),
                            );
                        }

                        return this._store.pipe(
                            select(selectors.isDownloadingAnyMenuFlows),
                            filter((isDownloading) => !isDownloading),
                            take(1),
                            withLatestFrom(this._store.pipe(select(selectors.getMenuFlows))),
                            switchMap(([, menuFlowsState]) => {
                                const menuFlows = menuFlowsState.map((obj) => obj.data);

                                const menuFlowProducts = menuFlows.reduce(
                                    (arr: { Product: OLO.DTO.MenuFlowProduct; MenuFlowId: number }[], menuFlow: OLO.DTO.MenuFlowDetailsModel) =>
                                        arr.concat(
                                            ...menuFlow.Pages.map((page) =>
                                                page.Products.map((prod) => ({
                                                    Product: prod,
                                                    MenuFlowId: menuFlow.MenuFlowId,
                                                })),
                                            ),
                                        ),
                                    [] as { Product: OLO.DTO.MenuFlowProduct; MenuFlowId: number }[],
                                );

                                if (payload && products && payload.length && menuFlowProducts.length) {
                                    payload.forEach((restriction) => {
                                        menuFlowProducts.forEach((menuFlowProduct) => {
                                            if (
                                                restriction.ProductIds.includes(menuFlowProduct.Product.ProductId) ||
                                                restriction.CategoryIds.includes(menuFlowProduct.Product.ProductCategoryId) ||
                                                menuFlowProduct.Product.ProductFamilyIds.some((family) => restriction.FamilyIds.includes(family))
                                            ) {
                                                const foundProduct = products.find((prod) => prod.MenuFlowId === menuFlowProduct.MenuFlowId);
                                                if (foundProduct) {
                                                    resArr.push(foundProduct);
                                                }
                                            }
                                        });
                                    });
                                }

                                return [actions.ProductAddRestrictions({ payload: resArr })];
                            }),
                        );
                    }),
                ),
            ),
        ),
    );

    private _isIncomingUpsell: boolean = false;
    public onProductQuantityChange$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(
                actions.CartMenuFlowIncrement,
                actions.CartMenuFlowDecrement,
                actions.CartMenuFlowChangeQuantity,
                actions.CartMenuFlowAdd,
                actions.CartMenuFlowUpdate,
                actions.CartMenuFlowRemove,
                actions.CartSimpleItemIncrement,
                actions.CartSimpleItemDecrement,
                actions.CartSimpleItemChangeQuantity,
                actions.CartSimpleItemAdd,
                actions.CartSimpleItemUpdate,
                actions.CartSimpleItemRemove,
                actions.ProductAddRestrictions,
                actions.CartRemoveAllItems,
                actions.CartTransferItemRequest,
            ),
            withLatestFrom(
                this._store.pipe(select(selectors.getMenuFlowDetailsByWizzard)),
                this._store.pipe(select(selectors.getCart)),
                this._store.pipe(select(selectors.getProductsRestrictionsData)),
                this._store.pipe(
                    select(selectors.getCurrentLocationNo),
                    switchMap((locationNo) => this._store.pipe(select(selectors.restrictionsLimit(locationNo)))),
                    pairwise(),
                ),
            ),
            switchMap((args) => {
                const [action, wizzardMenuFlow, cart] = args;

                if (
                    action.type === actions.CartTransferItemRequest.type &&
                    (!action.item._Id || ![...cart.itemsMenuFlow, ...cart.itemsSimple].some((item) => item._Id === action.item._Id)) &&
                    Boolean(wizzardMenuFlow?.UpsellMenuFlowId)
                ) {
                    this._isIncomingUpsell = true;
                }

                const isLongDelayedAction = action.type === actions.CartMenuFlowAdd.type || action.type === actions.CartSimpleItemAdd.type;

                return timer(isLongDelayedAction ? 1000 : 50).pipe(map(() => args));
            }),
            filter(([{ type }]) => type !== actions.CartTransferItemRequest.type),
            switchMap(([action, , , restrictions, productsWithRestrictions]) => {
                const [previousProductsWithRestrictions, actualProductsWithRestrictions] = productsWithRestrictions;
                const actualProductsQtyWithRestric = actualProductsWithRestrictions?.[0] ? actualProductsWithRestrictions[0].totalQty : 0;
                const actualLimit = actualProductsWithRestrictions?.[0] ? actualProductsWithRestrictions[0].limit : 0;
                const previousProductsQtyWithRestric = previousProductsWithRestrictions?.[0] ? previousProductsWithRestrictions[0].totalQty : 0;

                const returnedActions: Action[] = [];
                if (actualLimit !== null && actualLimit <= actualProductsQtyWithRestric && actualProductsQtyWithRestric !== previousProductsQtyWithRestric && restrictions?.[0]) {
                    switch (action.type) {
                        case actions.CartMenuFlowIncrement.type:
                        case actions.CartMenuFlowUpdate.type:
                        case actions.CartSimpleItemUpdate.type:
                        case actions.CartMenuFlowAdd.type:
                        case actions.CartSimpleItemAdd.type:
                        case actions.ProductAddRestrictions.type:
                            if (action.type === actions.CartMenuFlowIncrement.type && action.context === OLO.Enums.CART_ACTION_CONTEXT.INSIDE_CART) {
                                // Do not show restriction modal for cart, checkout plus/minus button
                                break;
                            }
                            if (actualProductsQtyWithRestric === restrictions[0].MaxQty) returnedActions.push(actions.ProductRestrictionsLimit(restrictions[0]));
                            if (actualProductsQtyWithRestric > restrictions[0].MaxQty) returnedActions.push(actions.ProductRestrictionsOverLimit(restrictions[0]));
                            break;
                    }
                }

                return returnedActions;
            }),
        ),
    );

    public productRestrictionsLimit$: Observable<void> = createEffect(
        () =>
            this._actions$.pipe(
                ofType(actions.ProductRestrictionsLimit, actions.ProductRestrictionsOverLimit),
                withLatestFrom(this._store.pipe(select(selectors.getAllModals))),
                switchMap(([{ type, restriction, modalId }, modals]) => {
                    const _modalTrigger = () => {
                        const modalParams: OLO.State.Modals.Modal = {
                            type: type === actions.ProductRestrictionsOverLimit.type ? 'restrictions-overlimit' : 'restrictions-limit',
                            isLoading: false,
                            restriction: restriction,
                        };

                        if (modalId && modals.find((obj) => obj.id === modalId)) {
                            return this._modalsService.swap(modalId, modalParams);
                        }

                        modalParams.id = modalId;
                        delete modalParams.isLoading;

                        this._modalsService.show(modalParams);
                    };

                    // This maybe can be simplify to just code with checking is any modal is visible
                    if (this._isIncomingUpsell) {
                        return this._actions$.pipe(
                            ofType(actions.ModalRequestClose),
                            take(1),
                            switchMap(() =>
                                timer(1000).pipe(
                                    switchMap(() => {
                                        this._isIncomingUpsell = false;
                                        _modalTrigger();

                                        return [];
                                    }),
                                ),
                            ),
                        );
                    }

                    return this._modalsService.isAnyModalVisible$.pipe(
                        skipWhile((isVisible) => isVisible),
                        take(1),
                        tap(() => _modalTrigger()),
                    );
                }),
            ),
        { dispatch: false },
    );

    constructor(private _actions$: Actions, private _productService: Services.ProductsService, private _modalsService: Services.ModalsService, private _store: Store<OLO.State>) {}
}
