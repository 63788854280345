import { Injectable } from '@angular/core';

import { HTML } from '@shared/core/utils/html.utils';

import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { auditTime } from 'rxjs/operators';

interface IRegistryEntry {
    target: HTMLElement;
    source: HTMLElement;
    offsetElements: HTMLElement[];
    offsetAdditional: number[];
    skipRecalculation: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class HeightAdjustService {
    public registry: Map<string, IRegistryEntry> = new Map();
    public targetHeightsRegistry: Map<string, BehaviorSubject<number>> = new Map<string, BehaviorSubject<number>>();

    constructor() {
        fromEvent(window, 'resize')
            .pipe(auditTime(200))
            .subscribe(() => this.recalculateAll());
    }

    private _createEntry(targetId: string): IRegistryEntry {
        if (!this.registry.has(targetId)) {
            this.registry.set(targetId, {
                target: null,
                source: null,
                offsetElements: [],
                offsetAdditional: [],
                skipRecalculation: false,
            });
        }

        if (!this.targetHeightsRegistry.has(targetId)) {
            this.targetHeightsRegistry.set(targetId, new BehaviorSubject<number>(0));
        }

        return this.registry.get(targetId);
    }

    private _setKey(targetId: string, key: string, value: any): IRegistryEntry {
        const entry: IRegistryEntry = this._createEntry(targetId);

        this.registry.set(targetId, {
            ...entry,
            [key]: value,
        });

        return this.registry.get(targetId);
    }

    private _addToArray(targetId: string, key: string, value: any): IRegistryEntry {
        const entry: IRegistryEntry = this._createEntry(targetId);

        this.registry.set(targetId, {
            ...entry,
            [key]: [...entry[key], value],
        });

        return this.registry.get(targetId);
    }

    private _removeFromArray(targetId: string, key: string, value: any): IRegistryEntry {
        const entry: IRegistryEntry = this._createEntry(targetId);

        this.registry.set(targetId, {
            ...entry,
            [key]: entry[key].filter((obj) => obj === value),
        });

        return this.registry.get(targetId);
    }

    public getEntry(id: string): IRegistryEntry {
        return this.registry.get(id);
    }

    public removeEntry(targetId: string): void {
        this.registry.delete(targetId);
    }

    public setSource(targetId: string, elem: HTMLElement): IRegistryEntry {
        return this._setKey(targetId, 'source', elem);
    }

    public setTarget(targetId: string, elem: HTMLElement): IRegistryEntry {
        return this._setKey(targetId, 'target', elem);
    }

    public addOffsetElement(targetId: string, elem: HTMLElement): IRegistryEntry {
        return this._addToArray(targetId, 'offsetElements', elem);
    }

    public removeOffsetElement(targetId: string, elem: HTMLElement): IRegistryEntry {
        return this._removeFromArray(targetId, 'offsetElements', elem);
    }

    public setSkipRecalculation(targetId: string, value: boolean): IRegistryEntry {
        return this._setKey(targetId, 'skipRecalculation', value);
    }

    public shouldIgnoreHeightRecalculation(targetId: string): boolean {
        if (!this.registry.has(targetId)) {
            return true;
        }

        const { source, target, skipRecalculation } = this.registry.get(targetId);

        return !source || !target || skipRecalculation;
    }

    public recalculateHeight(targetId: string, initialOffset: number = 0): void {
        if (this.shouldIgnoreHeightRecalculation(targetId)) {
            return;
        }

        const entry: IRegistryEntry = this.registry.get(targetId);

        let totalHeight: number = initialOffset;

        entry.offsetElements.forEach((elem) => (totalHeight += HTML.getOuterHeight(elem)));
        entry.offsetAdditional.forEach((no) => (totalHeight += no));
        totalHeight += HTML.getOuterHeight(entry.source);

        this.targetHeightsRegistry.get(targetId).next(totalHeight);
    }

    public recalculateAll(): void {
        setTimeout(() => {
            for (let [key, value] of this.registry) {
                this.recalculateHeight(key);
            }
        }, 0);
    }

    public getHeightUpdate$(targetId: string): Observable<number> {
        if (this.targetHeightsRegistry.has(targetId)) {
            return this.targetHeightsRegistry.get(targetId);
        }
    }
}
