import { Injectable, Inject } from '@angular/core';
import * as Tokens from '@shared/core/tokens';

@Injectable({
    providedIn: 'root',
})

/* eslint-disable @typescript-eslint/naming-convention, quote-props*/
export class GoogleTagManagerService {
    private isLoaded = false;

    private browserGlobals = {
        windowRef(): any {
            return window;
        },
        documentRef(): any {
            return document;
        },
    };

    constructor(@Inject(Tokens.CONFIG_TOKEN) public config: OLO.Config) {}

    private _applyGtmQueryParams(url: string, containerID: string): string {
        if (url.indexOf('?') === -1) {
            url += '?';
        }

        return url + `id=${containerID}`;
    }

    private _pushOnDataLayer(obj: any): void {
        const dataLayer = this.getDataLayer();
        dataLayer.push(obj);
    }

    public getDataLayer(): any[] {
        const window = this.browserGlobals.windowRef();
        window.dataLayer = window.dataLayer || [];

        return window.dataLayer;
    }

    public addGtmToDom(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            const ScriptElementID = 'GTMscript';
            this.isLoaded = this.isLoaded || this.browserGlobals.documentRef().getElementById(ScriptElementID);

            if (!this.isLoaded) {
                this.config.ecommerceTracking.googleTags.containerIDs.forEach((gtmID, id) => {
                    const doc = this.browserGlobals.documentRef();
                    this._pushOnDataLayer({
                        'gtm.start': new Date().getTime(),
                        event: 'gtm.js',
                    });

                    const gtmScript = doc.createElement('script');
                    gtmScript.id = ScriptElementID + (id + 1);
                    gtmScript.async = true;
                    gtmScript.src = this._applyGtmQueryParams('https://www.googletagmanager.com/gtm.js', gtmID);
                    gtmScript.addEventListener('load', () => {
                        resolve((this.isLoaded = true));
                    });

                    gtmScript.addEventListener('error', () => reject(false));
                    doc.head.insertBefore(gtmScript, doc.head.firstChild);
                });
            }

            return resolve(this.isLoaded);
        });
    }

    public pushTag(item: GA.GTagEvent): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (this.isLoaded) {
                this._pushOnDataLayer(item);

                return resolve();
            } else {
                this.addGtmToDom()
                    .then(() => {
                        this._pushOnDataLayer(item);

                        return resolve();
                    })
                    .catch(() => reject());
            }
        });
    }
}
/* eslint-enable @typescript-eslint/naming-convention, quote-props*/
