import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './top-bar.actions';

const initialState: OLO.State.TopBar = {
    isSticky: false,
    isCartVisible: true,
    isBackToLandingVisible: false,
    isBackToStoreVisible: false,
};

export const topBarReducerFn = createReducer(
    initialState,
    on(actions.TopBarSetStickyFlag, (state, action) => ({
        ...state,
        isSticky: action.isSticky,
    })),
    on(actions.TopBarSetCartIconVisibility, (state, action) => ({
        ...state,
        isCartVisible: action.isVisible,
    })),
    on(actions.TopBarSetBackToStoreIconVisibility, (state, action) => ({
        ...state,
        isBackToStoreVisible: action.isVisible,
    })),
    on(actions.TopBarSetBackToLandingIconVisibility, (state, action) => ({
        ...state,
        isBackToLandingVisible: action.isVisible,
    })),
);

export function topBarReducer(state: OLO.State.TopBar | undefined, action: Action) {
    return topBarReducerFn(state, action);
}
