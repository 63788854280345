import { createSelector } from '@ngrx/store';
import { getOrderSummary } from '../../../onlineOrder/selectors';
import { getMemberRedeemableePoints } from '../../../members/selectors';
import { getActiveCardId, isPayByPointsSelected } from '../../../creditCards/selectors';
import { getLocationRedemptionRate } from '../../../payment/selectors';
import { canFulfillPaymentWithPointsOnly } from '../canFulfillPaymentWithPointsOnly';

export const isPaymentDisabledForPayByPoints = createSelector(
    getOrderSummary,
    isPayByPointsSelected,
    getMemberRedeemableePoints,
    getLocationRedemptionRate,
    getActiveCardId,
    canFulfillPaymentWithPointsOnly,
    (orderSummary, payByPointsSelected, points, locationRedemptionRate, activeCardId, canFulfillWithPointsOnly) => {
        const total = Number(orderSummary?.Total);
        const availableBalance = points / locationRedemptionRate?.rate;
        const differentActiveCardId = activeCardId !== OLO.Enums.PAYMENT_VENDOR_SERVICE.PAY_BY_POINTS;
        const noSufficientFunds = availableBalance < total && !differentActiveCardId;
        const noAlternativePaymentMethod = !canFulfillWithPointsOnly && !differentActiveCardId;

        return payByPointsSelected && (noSufficientFunds || noAlternativePaymentMethod);
    },
);
