import { createAction, props } from '@ngrx/store';

export const MenuFlowDefaultActivationRequest = createAction(
    '[Menu Flow Default Activation] Request default activation for menu flow by menuFlowId',
    props<{ menuFlowId: number }>(),
);

export const MenuFlowDefaultActivationSuccessRequest = createAction(
    '[Menu Flow Default Activation] Success request default activation for menu flow by menuFlowId',
    props<{ menuFlowId: number; payload: OLO.DTO.MenuFlowDefaultActivation }>(),
);

export const MenuFlowDefaultActivationErrorRequest = createAction(
    '[Menu Flow Default Activation] Error request default activation for menu flow by menuFlowId',
    props<{ menuFlowId: number; ex?: any }>(),
);
