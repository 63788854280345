import { Inject, Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';
import * as Tokens from '@shared/core/tokens';
import * as Services from '@shared/core/services';

import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, switchMap, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class TaggingAnalyticsEffects {
    public onAddToCartSimpleItem$: Observable<void> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.CartSimpleItemAdd, actions.CartSimpleItemIncrement),
            switchMap((action) => {
                this._ecommerceTrackingService.recordAddToCartSimpleItem(action.item);

                return [];
            }),
        ),
    );

    public onAddToCartMenuFlow$: Observable<void> = createEffect(
        () =>
            this._actions$.pipe(
                ofType(actions.CartMenuFlowAdd, actions.CartMenuFlowIncrement),
                switchMap((action) => {
                    this._ecommerceTrackingService.recordAddToCartMenuFlowItem(action.item);

                    return [];
                }),
            ),
        { dispatch: false },
    );

    public onRemoveFromCartSimpleItem$: Observable<void> = createEffect(
        () =>
            this._actions$.pipe(
                ofType(actions.CartSimpleItemRemove, actions.CartSimpleItemDecrement),
                switchMap((action) => {
                    this._ecommerceTrackingService.recordRemoveFromCart(
                        {
                            item_id: action.item.Plu.toString(),
                            item_name: action.item.PosDisplay,
                            price: action.item.UnitPrice,
                            currency: this._config.localization.currency,
                            index: null,
                        },
                        1,
                    );

                    return [];
                }),
            ),
        { dispatch: false },
    );

    public onRemoveFromCartMenuFlow$: Observable<void> = createEffect(
        () =>
            this._actions$.pipe(
                ofType(actions.CartMenuFlowRemove, actions.CartMenuFlowDecrement),
                switchMap((action) => {
                    this._ecommerceTrackingService.recordRemoveFromCart(
                        {
                            item_id: action.item.MenuFlowId.toString(),
                            item_name: action.item.MenuFlowName,
                            price: action.item.UnitTotalValue,
                            currency: this._config.localization.currency,
                            item_list_name: null,
                            item_list_id: null,
                        },
                        1,
                    );

                    return [];
                }),
            ),
        { dispatch: false },
    );

    public onSelectCreditCards$: Observable<void> = createEffect(
        () =>
            this._actions$.pipe(
                ofType(actions.SelectActiveCreditCardId),
                withLatestFrom(this._store.pipe(select(selectors.getCartTotalValue))),
                switchMap(([action, totalCartValue]) => {
                    switch (action?.cardId) {
                        case OLO.Enums.PAYMENT_VENDOR_SERVICE.ACCOUNT_CHARGE:
                            this._ecommerceTrackingService.recordAddPaymentInfo(totalCartValue, GA.PAYMENT_METHOD.ACOUNT_CHARGE);
                            break;
                        case OLO.Enums.PAYMENT_VENDOR_SERVICE.PAY_BY_POINTS:
                            this._ecommerceTrackingService.recordAddPaymentInfo(totalCartValue, GA.PAYMENT_METHOD.PAY_BY_POINTS);
                            break;
                        case OLO.Enums.PAYMENT_VENDOR_SERVICE.GOOGLE_PAY:
                            this._ecommerceTrackingService.recordAddPaymentInfo(totalCartValue, GA.PAYMENT_METHOD.GOOGLE_PAY);
                            break;
                        case OLO.Enums.PAYMENT_VENDOR_SERVICE.APPLE_PAY:
                            this._ecommerceTrackingService.recordAddPaymentInfo(totalCartValue, GA.PAYMENT_METHOD.APPLE_PAY);
                            break;
                        default:
                            this._ecommerceTrackingService.recordAddPaymentInfo(totalCartValue, GA.PAYMENT_METHOD.CREDIT_CARD);
                            break;
                    }

                    return [];
                }),
            ),
        { dispatch: false },
    );

    public onStepPaymentComplete$: Observable<void> = createEffect(
        () =>
            this._actions$.pipe(
                ofType(actions.PaymentStepComplete),
                withLatestFrom(
                    // these data is required to pass to google ecommerce purchase event before payment init
                    this._store.pipe(select(selectors.getOnlineOrderRecalcData)),
                    this._store.pipe(select(selectors.getCartSimpleItems)),
                    this._store.pipe(select(selectors.getCartMenuFlows)),
                    this._store.pipe(select(selectors.getOnlineMenuPages)),
                    this._store.pipe(select(selectors.isAccountChargeSelected)),
                ),
                switchMap(([step, recalcOrder, simpleItems, menuFlowItems, onlineMenuPages, isAccountChargeSelected]) => {
                    if (isAccountChargeSelected) {
                        this._ecommerceTrackingService.recordSpendVirtualCurrency(recalcOrder.data.TotalGrossValue);
                    }
                    this._ecommerceTrackingService.recordPurchase({
                        orderId: step.OrderId,
                        order: recalcOrder.data,
                        simpleItems,
                        menuFlowItems,
                        onlineMenuPages,
                    });

                    return [];
                }),
            ),
        { dispatch: false },
    );

    public onCartPopupVisible$: Observable<void> = createEffect(
        () =>
            this._actions$.pipe(
                ofType(actions.SetCartPopup),
                switchMap(() => this._store.pipe(select(selectors.isCartPopupVisible))),
                distinctUntilChanged(),
                filter((isCartVisible) => isCartVisible),
                switchMap(() => {
                    this._ecommerceTrackingService.recordViewCart();

                    return [];
                }),
            ),
        { dispatch: false },
    );

    public onCartSetupForMenuFlow$: Observable<void> = createEffect(
        () =>
            this._actions$.pipe(
                ofType(actions.CartSetup),
                filter((action) => action.type === actions.CartSetup.type && !!action.item?.MenuFlowId),
                switchMap((action) => {
                    this._ecommerceTrackingService.recordSelectItemMenuFlow(action.item as OLO.State.Cart.CartMenuFlow);

                    return [];
                }),
            ),
        { dispatch: false },
    );

    public onSearchOnlineMenuOrLocations$: Observable<void> = createEffect(
        () =>
            this._actions$.pipe(
                ofType(actions.OnlineMenuFiltersSetSearch, actions.LocationsFiltersSetSearch),
                filter((action) => !!action.search?.length),
                switchMap((action) => {
                    this._ecommerceTrackingService.recordSearch(action.search);

                    return [];
                }),
            ),
        { dispatch: false },
    );

    //  TODO in future
    // @Effect() public onCollectionTypeSetGoogleEcommerce$: Observable<Action> = this._actions$
    //     .pipe(
    //         ofType(
    //             actions.SetCollectionType,
    //             actions.PatchOrderTypeIdCollectionTypeValue,
    //             actions.CartSetCollectionTypeId
    //         ),
    //         tap(() => this._ecommerceTrackingServiceService.recordAddShippingInfo(null)),
    //         switchMap(() => [])
    //     );

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: OLO.Config,
        private _actions$: Actions,
        private _store: Store<OLO.State>,
        private _ecommerceTrackingService: Services.EcommerceTrackingService,
    ) {}
}
