import { createSelector } from '@ngrx/store';
import { collectionTypeState } from '../root';

import * as Models from '@shared/core/models';
import * as Utils from '@shared/core/utils';
import * as Statics from '@shared/core/statics';

export const getCollectionTypesList = createSelector(collectionTypeState, (collectionType) => {
    const list: Models.CollectionTypeItem[] = [];
    const config = new Statics.ConfigStatic().current;

    const collectionTypePickupConfig = new Utils.CollectionTypeHelper(config.collectionTypes).getPickupCollectionTypeConfig();
    const collectionTypeDeliveryConfig = new Utils.CollectionTypeHelper(config.collectionTypes).getDeliveryCollectionTypeConfig();
    const collectionTypeDineInConfig = new Utils.CollectionTypeHelper(config.collectionTypes).getDineInCollectionTypeConfig();
    const collectionTypeCateringConfig = new Utils.CollectionTypeHelper(config.collectionTypes).getCateringCollectionTypeConfig();

    if (collectionTypePickupConfig?.enabled === true) {
        list.push(
            new Models.CollectionTypeItem(
                1,
                collectionTypePickupConfig.displayName,
                collectionTypePickupConfig.orderTypeIds,
                collectionTypePickupConfig.orderTypeIds.includes(collectionType?.orderTypeId),
                OLO.Enums.COLLECTION_TYPE.PICKUP,
                collectionTypePickupConfig.displayIndex,
            ),
        );
    }

    if (collectionTypeDeliveryConfig?.enabled === true) {
        list.push(
            new Models.CollectionTypeItem(
                2,
                collectionTypeDeliveryConfig.displayName,
                collectionTypeDeliveryConfig.orderTypeIds,
                collectionTypeDeliveryConfig.orderTypeIds.includes(collectionType?.orderTypeId),
                OLO.Enums.COLLECTION_TYPE.DELIVERY,
                collectionTypeDeliveryConfig.displayIndex,
            ),
        );
    }

    if (collectionTypeDineInConfig?.enabled === true) {
        list.push(
            new Models.CollectionTypeItem(
                3,
                collectionTypeDineInConfig.displayName,
                collectionTypeDineInConfig.orderTypeIds,
                collectionTypeDineInConfig.orderTypeIds.includes(collectionType?.orderTypeId),
                OLO.Enums.COLLECTION_TYPE.DINE_IN,
                collectionTypeDineInConfig.displayIndex,
            ),
        );
    }

    if (collectionTypeCateringConfig?.enabled === true) {
        list.push(
            new Models.CollectionTypeItem(
                4,
                collectionTypeCateringConfig.displayName,
                collectionTypeCateringConfig.orderTypeIds,
                collectionTypeCateringConfig.orderTypeIds.includes(collectionType?.orderTypeId),
                OLO.Enums.COLLECTION_TYPE.CATERING,
                collectionTypeCateringConfig.displayIndex,
            ),
        );
    }

    return list.sort((a, b) => a.DispayIndex - b.DispayIndex);
});
