import { createSelector } from '@ngrx/store';
import { isMemberAuthorizedJWT } from '../isMemberAuthorizedJWT';
import { getMemberFormFields } from '../getMemberFormFields';

/**
 * Gets member signup/update fields depending on isAuthorized flag
 */
export const getFieldsDetails = createSelector(isMemberAuthorizedJWT, getMemberFormFields, (isAuthorized, fields) =>
    isAuthorized ? fields.updatePersonalDetails.data : fields?.signup.data,
);
