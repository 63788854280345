import { NgModuleFactory, Type } from '@angular/core';

export type ModulesNames = 'modals' | 'cart' | 'dev';

export const lazyModulesRegistry: { name: ModulesNames; loadChildren: () => Promise<NgModuleFactory<any> | Type<any>> }[] = [
    {
        name: 'modals',
        loadChildren: () => import('../../modules/modals/modals.shared.module').then((m) => m.ModalsModule),
    },
    {
        name: 'cart',
        loadChildren: () => import('../../modules/cart/cart.shared.module').then((m) => m.CartModule),
    },
    {
        name: 'dev',
        loadChildren: () => import('../../components/dev/dev.module').then((m) => m.DevModule),
    },
];

export function lazyModulesToObj() {
    const result = {};
    for (const w of lazyModulesRegistry) {
        result[w.name] = w.loadChildren;
    }

    return result;
}
