import * as io from 'io-ts';

/** Checks if value is in range */
export const numberInRange = (from: number, to?: number) => {
    const isInRange = (input: unknown): input is number => {
        const isNumber = Number.isNaN(Number(input)) ? false : true;
        if (!isNumber) {
            return false;
        }

        if (to == null) {
            return (input as number) >= from;
        }

        return (input as number) >= from && (input as number) <= to;
    };

    return new io.Type(
        'isInRange',
        isInRange,
        (input, context) => (isInRange(input) ? io.success(input) : io.failure(input, context, `Provided value ${input} not in range ${from} - ${to}`)),
        io.identity,
    );
};

export const nnumberInRange: typeof numberInRange = (from, to) => io.union([io.null, numberInRange(from, to)]);
