import { OrderPaymentMethod, OrderPaymentDetails } from './order-payment-method';

export class OrderPaymentWithGooglePay extends OrderPaymentMethod {
    public async generatePaymentDetails(details: OrderPaymentDetails) {
        const { paymentMethod } = details;

        if (paymentMethod?.vendorService === OLO.Enums.PAYMENT_VENDOR_SERVICE.GOOGLE_PAY && Boolean(paymentMethod?.googlePaymentData?.PaymentData)) {
            this._extendExecutePaymentModel(details);

            this._executePaymentModel.GooglePayToken = {
                PaymentData: {
                    ...paymentMethod.googlePaymentData.PaymentData,
                },
            };

            this._executePaymentModel.PaymentAccountId = null;

            return this._executePaymentModel;
        }

        if (this._next) {
            return this._next.generatePaymentDetails(details);
        }

        return this._executePaymentModel;
    }
}
