import { createSelector } from '@ngrx/store';

import { getCartTotalQuantity } from '../getCartTotalQuantity';
import { getOnlineOrderState } from '@shared/state/onlineOrder/selectors';
import { getCartTotalValueAfterDiscounts } from '../getCartTotalValueAfterDiscounts';

export const canShowPaymentSection = createSelector(
    getCartTotalQuantity,
    getCartTotalValueAfterDiscounts,
    getOnlineOrderState,
    (cartTotalQuantity, cartTotalValueAfterDiscounts, getOnlineOrder) => cartTotalQuantity > 0 && (cartTotalValueAfterDiscounts > 0 || getOnlineOrder.data?.TotalLeftToPay > 0),
);
