import { createSelector } from '@ngrx/store';

import * as Utils from '@shared/core/utils';

import { getOnlineMenuPages } from '@shared/state/onlineMenu/selectors';
import { getWizzardSimpleItem, getWizzardMenuFlow } from '@shared/state/wizzard/selectors';
import { getDietaryTagImages } from '@shared/state/dietaryTagsImages/selectors';
import { getOnlineMenuVirtualLocationsPagesFiltered } from '../../onlineMenuVirtualLocations';

export const getDietaryTagImagesForWizzardItem = (isMenuFlow: boolean) =>
    createSelector(
        getOnlineMenuPages,
        getOnlineMenuVirtualLocationsPagesFiltered,
        getWizzardSimpleItem,
        getWizzardMenuFlow,
        getDietaryTagImages,
        (onlineMenuPages, onlineMenuVirtualLocationsPagesFiltered, singleProduct, menuFlow, images) => {
            if ((!onlineMenuPages && !onlineMenuVirtualLocationsPagesFiltered) || (isMenuFlow && !menuFlow) || (!isMenuFlow && !singleProduct) || !images) return null;

            const allOnlineMenuPages = Utils.Arrays.safeConcat(onlineMenuPages, onlineMenuVirtualLocationsPagesFiltered);

            let dietaryTags: OLO.DTO.TagModel[] = allOnlineMenuPages.reduce((tags, page) => {
                if (tags.length > 0) return tags;

                const productWithDietaryTag = page.Products.find((product) => {
                    if (isMenuFlow) {
                        return product.MenuFlowId === menuFlow.MenuFlowId && product.DietaryTags;
                    } else {
                        return product.ProductId === singleProduct.ProductId && product.DietaryTags;
                    }
                });

                return productWithDietaryTag ? [...productWithDietaryTag.DietaryTags] : tags;
            }, []);

            if (!dietaryTags?.length) return null;

            return images.reduce((acc, tagImg) => {
                const tag = dietaryTags.find((obj) => obj.Id === tagImg.Id);
                if (tag) {
                    return [...acc, tagImg];
                }

                return acc;
            }, []);
        },
    );
