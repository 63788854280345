import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './member-signup-policy.actions';

const initialState: OLO.State.MemberSignupPolicy = {
    isDownloading: false,
    hasSucceeded: false,
    hasFailed: false,
    data: null,
};

export const memberSignupPolicyReducerFn = createReducer(
    initialState,
    on(actions.MemberSignupPolicyRequest, (state) => ({
        ...state,
        isDownloading: true,
        hasSucceeded: false,
        hasFailed: false,
    })),
    on(actions.MemberSignupPolicySuccessRequest, (state, action) => ({
        ...state,
        isDownloading: false,
        hasSucceeded: true,
        hasFailed: false,
        data: action.payload,
    })),
    on(actions.MemberSignupPolicyErrorRequest, (state) => ({
        ...state,
        isDownloading: false,
        hasSucceeded: false,
        hasFailed: true,
    })),
);

export function memberSignupPolicyReducer(state: OLO.State.MemberSignupPolicy | undefined, action: Action) {
    return memberSignupPolicyReducerFn(state, action);
}
