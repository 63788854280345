import { createAction } from '@ngrx/store';

export const OnlineMenuPagesRequest = createAction(
    '[Online menu] Request menu pages',
    (locationNo: number, orderTypeId: number, pickupDate: string = new Date().toISOString()) => ({ locationNo, orderTypeId, pickupDate }),
);

export const OnlineMenuPagesSuccessRequest = createAction(
    '[Online menu] Success request menu pages',
    (locationNo: number, orderTypeId: number, pickupDate: string, payload: OLO.DTO.OnlineMenuResponseModel) => ({ locationNo, orderTypeId, pickupDate, payload }),
);

export const OnlineMenuPageErrorRequest = createAction('[Online menu] Error request menu pages', (locationNo: number, orderTypeId: number, pickupDate: string, ex: any = null) => ({
    locationNo,
    orderTypeId,
    pickupDate,
    ex,
}));
