import { createSelector } from '@ngrx/store';
import { getCardsState } from '../getCardsState';
import * as Utils from '@shared/core/utils';

export const getCardsErrorsMapped = createSelector(getCardsState, (cardsState) => {
    const collectedErrors = [
        cardsState.add.hasFailed,
        cardsState.adyen.locationConfig.hasFailed,
        cardsState.stripe.locationConfig.hasFailed,
        cardsState.cardConnect.locationConfig.hasFailed,
        cardsState.remove.hasFailed,
        cardsState.token.hasFailed,
        cardsState.validation.hasFailed,
        cardsState.download.hasFailed,
    ];

    return collectedErrors.includes(true) ? Utils.Messages.mapErrors({ creditCardProcessing: "We're having trouble processing your card. Please try again" })[0] : null;
});
