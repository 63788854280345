import { createSelector } from '@ngrx/store';
import { cartState } from '../root';

export const getCartPickupTimeName = createSelector(cartState, (cart) => {
    const p = cart.pickupTime;
    if (!p) return null;

    if (!p.IsAsap) return p.Name;

    return p.MinutesFromNow ? `ASAP - ${p.MinutesFromNow} mins` : 'ASAP';
});
