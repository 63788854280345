import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { LocationsEffects } from './locations.effects';
import { locationsReducer } from './locations.reducer';

@NgModule({
    declarations: [],
    imports: [CommonModule, StoreModule.forFeature('locations', locationsReducer), EffectsModule.forFeature([LocationsEffects])],
    exports: [],
    providers: [],
})
export class LocationsStateModule {}
