import { createSelector } from '@ngrx/store';

import * as Utils from '@shared/core/utils';

import { onlineMenuPagesImagesState } from '../root';

export const getOnlineMenuPageImage = (pageId: number, imageType: OLO.Enums.IMAGE_TYPE = OLO.Enums.IMAGE_TYPE.ForWeb) =>
    createSelector(onlineMenuPagesImagesState, (onlineMenuPageImage) => {
        let imagesType = Utils.Images.toImageTypeString(imageType);

        return onlineMenuPageImage[imagesType].find((obj) => obj.pageId === pageId) || null;
    });
