import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { RouterEffects } from './router.effects';
import { routeReducer } from './router.reducer';

@NgModule({
    imports: [
        StoreModule.forFeature('router', routeReducer, {
            metaReducers: [],
        }),
        EffectsModule.forFeature([RouterEffects]),
    ],
})
export class RouterStateModule {}
