import { Component, ViewChild, ViewContainerRef, Inject, AfterViewInit, OnInit } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { Title } from '@angular/platform-browser';
import { fadeInOut } from '@shared/core/animations';
import { InitService } from '@shared/core/services';
import { LazyLoaderService } from '@shared/core/services/lazyLoader/lazy-loader.service';

import { ENV_TOKEN } from '@shared/core/tokens';
import { GoogleTagManagerService } from '@shared/core/services/google/google-tag-manager.shared.service';
import * as Tokens from '@shared/core/tokens';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [trigger('routeAnimations', [transition('* <=> *', [useAnimation(fadeInOut)])])],
})
export class AppComponent implements AfterViewInit, OnInit {
    @ViewChild('dev', { read: ViewContainerRef, static: false }) public dev: ViewContainerRef;

    constructor(
        @Inject(ENV_TOKEN) private _env: Environment,
        @Inject(Tokens.CONFIG_TOKEN) private _config: OLO.Config,
        @Inject(Tokens.STATIC_TEXT_TOKEN) public readonly t: T.StaticTexts,
        private _initService: InitService,
        private _lazyLoaderService: LazyLoaderService,
        private _gtmService: GoogleTagManagerService,
        private _titleService: Title,
    ) {}

    ngOnInit() {
        this._setTitle();

        if (this._config.ecommerceTracking.googleTags) {
            this._gtmTagInit();
        }
    }

    ngAfterViewInit(): void {
        this._initServ();
    }

    private _gtmTagInit(): void {
        this._gtmService.pushTag({ ecommerce: null });
    }

    private _initServ(): void {
        this._initService.init().then(() => {
            if (!this._env.production) {
                this._lazyLoaderService.loadModuleWithComponent({
                    viewRefContainer: this.dev,
                    name: 'dev',
                });
            }
        });
    }

    private _setTitle(): void {
        this._titleService.setTitle(this.t.pageTitle.genericTitle);
    }
}
