import * as io from 'io-ts';
import { logger } from './logger';
import { excess } from './excess.type';

type Params = {
    throw: boolean;
    logType: 'warn' | 'log' | 'error';
    message: Nullable<string>;
};

/**
 * Validates model against provided types definition
 * @param {io.Type<T>} typesDefinition model types definition from `io` library
 * @param {T} model to check
 * @param {Params} params - log or throw an error
 * @returns {boolean | never} bool or just throw...
 */
export const isValid = <T>(typesDefinition: io.Type<T>, model: T, params: Partial<Params> = {}): boolean | never => {
    const opts: Params = {
        throw: false,
        logType: 'error',
        message: null,
        ...params,
    };
    const result = excess(typesDefinition).decode(model);

    if (result._tag === 'Right') {
        return true;
    }

    if (opts.logType) {
        // eslint-disable-next-line no-console
        console[opts.logType](opts.message, result);

        logger(result.left, { logType: opts.logType });
    }

    if (opts.throw) {
        throw new Error(opts.message || 'Data validation error');
    }

    return false;
};
