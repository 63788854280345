import { Injectable } from '@angular/core';

import { Subject, BehaviorSubject } from 'rxjs';

import * as Utils from '@shared/core/utils';

@Injectable({
    providedIn: 'root',
})
export class MenuFlowPagesService {
    private _isLocked: boolean = false;
    private readonly _registry: Map<number, HTMLElement> = new Map();

    public collapsedElement$: Subject<{ pageIdentifier: number; elem: HTMLElement }> = new Subject();
    public currentActivePageIdentifier$: BehaviorSubject<number> = new BehaviorSubject(null);

    private _lock(): void {
        this._isLocked = true;
        setTimeout(() => {
            this._isLocked = false;
        }, 500);
    }

    public register(pageIdentifier: number, elem: HTMLElement): void {
        this._registry.set(pageIdentifier, elem);
    }

    public unregister(pageIdentifier: number): void {
        this._registry.delete(pageIdentifier);
    }

    public getElement(pageIdentifier: number): HTMLElement {
        const elem = this._registry.get(pageIdentifier);

        return elem || null;
    }

    public emitCollapse(pageIdentifier: number, elem: HTMLElement): void {
        setTimeout(() => {
            this.collapsedElement$.next({ pageIdentifier, elem });
        }, 0);
    }

    public unsetCurrentScrolledPage(): void {
        this.currentActivePageIdentifier$.next(null);
    }

    public calculateCurrentScrolledPage(wrapperContainer: HTMLElement, detectThreshold: number = 30): void {
        if (this._isLocked) return;

        let foundActivePageIdentifier: Nullable<number> = null;
        for (let [pageIdentifier, page] of this._registry) {
            if (foundActivePageIdentifier) break;

            const targetY: number = page.offsetTop - detectThreshold;
            const targetYBottomEdge: number = targetY + page.clientHeight;
            const scrollPosY: number = wrapperContainer.scrollTop;

            const isAboveArea: boolean = scrollPosY >= targetY && scrollPosY < targetYBottomEdge;
            if (isAboveArea) {
                foundActivePageIdentifier = pageIdentifier;
            }
        }
        if (!foundActivePageIdentifier || foundActivePageIdentifier < 0) return;
        this.currentActivePageIdentifier$.next(foundActivePageIdentifier);
    }

    public scrollToMenuFlowPage(wrapperContainer: HTMLElement, pageIdentifier: number, scrollDuration: number = 200, compensation: number = -30): void {
        if (!pageIdentifier || this._isLocked) return;

        const targetElem: HTMLElement = this._registry.get(pageIdentifier);
        if (!targetElem) return;
        this._lock();
        this.currentActivePageIdentifier$.next(pageIdentifier);
        Utils.Scroll.smoothScrollInnerElement(wrapperContainer, targetElem, compensation, scrollDuration);
    }
}
