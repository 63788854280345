import * as io from 'io-ts';
import { arrayOfStrings, boolean, nullableString, number, validString } from '@shared/core/types/common';
import { orderTypeIdsArray, onlineOrderStatusEnum } from '@shared/core/types/consts';

const orderConfirmationPageHeaderDefinition: io.MixedObject<OLO.Config.OrderConfirmationPage.PageHeader> = {
    preTitle: validString,
    note: validString,
    title: arrayOfStrings,
};

const orderDetailsHeaderDefinition: io.MixedObject<OLO.Config.OrderConfirmationPage.DetailsHeader> = {
    preHeader: nullableString,
    description: nullableString,
    title: arrayOfStrings,
};

const orderDetailsSectionDefinition: io.MixedObject<OLO.Config.OrderConfirmationPage.DetailsSection> = {
    orderDetailsHeader: io.type(orderDetailsHeaderDefinition),
};

const orderStatusHeaderDefinition: io.MixedObject<OLO.Config.OrderConfirmationPage.StatusHeader> = {
    preHeader: validString,
    title: validString,
    description: validString,
};

const orderStatusHeaderSectionDefinition: io.MixedObject<OLO.Config.OrderConfirmationPage.StatusHeaderSection> = {
    success: io.type(orderStatusHeaderDefinition),
    failed: io.type(orderStatusHeaderDefinition),
};

const liveViewSectionDefinition: io.MixedObject<OLO.Config.OrderConfirmationPage.LiveViewItem> = {
    title: validString,
    description: validString,
    pickupDateFilter: boolean,
    isFinal: boolean,
    isSuccess: boolean,
    statusFilter: io.array(onlineOrderStatusEnum),
    displayIndex: number,
};

const orderStatusSectionDefinition: io.MixedObject<OLO.Config.OrderConfirmationPage.StatusSection> = {
    isLiveViewEnabled: boolean,
    orderStatusHeader: io.type(orderStatusHeaderSectionDefinition),
    liveViewSection: io.array(io.type(liveViewSectionDefinition)),
};

const rootDefinition: io.MixedObject<OLO.Config.OrderConfirmationPage> = {
    orderTypeIds: orderTypeIdsArray,
    pageHeader: io.type(orderConfirmationPageHeaderDefinition),
    orderDetailsSection: io.type(orderDetailsSectionDefinition),
    orderStatusSection: io.type(orderStatusSectionDefinition),
};

export const orderConfirmationPageSchema = io.array(io.type(rootDefinition));
