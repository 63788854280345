import { createSelector } from '@ngrx/store';
import { cartState } from '../root';
import * as Utils from '@shared/core/utils';
import { ConfigStatic } from '@shared/core/statics';

export const isCartCollectionTypeDineIn = createSelector(cartState, (cart) => {
    if (!cart?.orderTypeId) return false;

    const config = new ConfigStatic().current;

    const collectionTypeDineInConfig = new Utils.CollectionTypeHelper(config.collectionTypes).getDineInCollectionTypeConfig();

    return cart?.orderTypeId === collectionTypeDineInConfig?.dineInBuzzer?.orderTypeId || cart?.orderTypeId === collectionTypeDineInConfig?.dineInTable?.orderTypeId;
});
