export class MemberPhotoChecker {
    constructor(private _member: OLO.DTO.MemberModel, private _memberFields: Nullable<OLO.State.Members.MemberFormFieldModel[]>) {}
    protected get _isPictureSet(): boolean {
        return Boolean(this._member.ProfilePictureUrl || this._member.ProfilePicture);
    }

    public get isRequired(): boolean {
        return Boolean(this._memberFields?.find((obj) => obj.Type === OLO.Enums.MEMBER_FORM_FIELDS_TYPE.PROFILE_PICTURE)?.IsRequired);
    }

    public get isValid(): boolean {
        return !this.isRequired || this._isPictureSet;
    }
}
