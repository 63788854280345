import { PaymentMethod } from '@stripe/stripe-js';

export class StripePaymentProviderMapper {
    public static mapStripePaymentGetSettingsForLocation(settingsForLocation: APIv3.StripeSettingsResponse): OLO.DTO.StripeSettingsResponse {
        return settingsForLocation
            ? {
                  ApiKey: settingsForLocation?.ApiKey,
                  CurrencyCode: settingsForLocation?.CurrencyCode,
              }
            : null;
    }

    public static mapStripePaymentMethodResponse(response: PaymentMethod): OLO.DTO.StripeResponsePaymentMethod {
        return (
            (response && {
                billing_details: response.billing_details,
                card: response.card,
                card_present: response.card_present,
                created: response.created,
                customer: response.customer,
                fpx: response.fpx,
                id: response.id,
                ideal: response.ideal,
                livemode: response.livemode,
                metadata: response.metadata,
                object: response.object,
                sepa_debit: response.sepa_debit,
                type: response.type,
            }) ||
            null
        );
    }
}
