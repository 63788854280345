import { createAction, props } from '@ngrx/store';

export const MemberCardBarcodesImagesRequest = createAction('[Member Card Barcodes Images] Request image', props<{ memberId: number; barcodeType: OLO.Enums.BARCODE_SYMBOLOGY }>());

export const MemberCardBarcodesImagesSuccessRequest = createAction(
    '[Member Card Barcodes Images] Success request image',
    props<{ memberId: number; barcodeType: OLO.Enums.BARCODE_SYMBOLOGY; payload: string }>(),
);

export const MemberCardBarcodesImagesErrorRequest = createAction(
    '[Member Card Barcodes Images] Error request image',
    props<{ memberId: number; barcodeType: OLO.Enums.BARCODE_SYMBOLOGY; ex?: any }>(),
);
