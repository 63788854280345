import { createSelector } from '@ngrx/store';
import { getWizzardMenuFlow } from '@shared/state/wizzard/selectors';
import { getProductsWithRestrictions } from '@shared/state/productRestrictions/selectors';

export const hasWizzardMenuFlowRestriction = createSelector(getWizzardMenuFlow, getProductsWithRestrictions, (wizzardMenuFlow, productRestrictions) => {
    const wizzardMenuFlowProductsIds: number[] = wizzardMenuFlow?.Pages?.reduce((productsIds, page) => [...productsIds, ...page.Products.map((product) => product.ProductId)], []);
    const productRestrictionsIds: number[] = productRestrictions.map((productRestriction) => productRestriction.ProductId);

    if (!wizzardMenuFlowProductsIds) return null;

    return wizzardMenuFlowProductsIds.some((wizzardMenuFlowProductId) => productRestrictionsIds.includes(wizzardMenuFlowProductId));
});
