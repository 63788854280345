import { createAction, props } from '@ngrx/store';

export const CurrentLocationValidationRequest = createAction('[Current location] Request validation');
export const CurrentLocationValidationSuccessRequest = createAction('[Current location] Success request validation');
export const CurrentLocationValidationErrorRequest = createAction('[Current location] Error request validation');
export const CurrentLocationValidationReset = createAction('[Current location] Request validation reset');

export const CurrentLocationReset = createAction('[Current location] Reset');

export const CurrentLocationSet = createAction(
    '[Current location] Set locationNo',
    props<{ locationNo: number; previousLocationNo?: number; deliveryAddress?: APICommon.GooglePlaceDetails }>(),
);

export const CurrentLocationPickupTimeSet = createAction('[Current location] Set pickup time', (pickupTime: OLO.Ordering.PickupTime = null) => ({ pickupTime }));

export const CurrentLocationFiltersPickupTimeSync = createAction(
    '[Current location] Sync current location pickup time with filters',
    (pickupTime: OLO.Ordering.PickupTime = null) => ({ pickupTime }),
);

export const CurrentLocationPickupTimeRestore = createAction('[Current location] Restore current pickup time from local storage', (pickupTime: OLO.Ordering.PickupTime = null) => ({
    pickupTime,
}));
