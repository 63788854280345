import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as actions from '../actions';

import * as Services from '@shared/core/services';

import { Observable, of } from 'rxjs';
import { switchMap, catchError, map, mergeMap } from 'rxjs/operators';

@Injectable()
export class LoyaltyIntroductionPagesImagesEffects {
    public onLoyaltyIntroductionPagesSuccessRequestGetImages$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.LoyaltyIntroductionPagesSuccessRequest),
            switchMap((pages) => pages.payload.map((page) => actions.LoyaltyIntroductionPageImageRequest({ pageId: page.Id }))),
        ),
    );

    public requestIntroductionPagesImages$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.LoyaltyIntroductionPageImageRequest),
            mergeMap(({ pageId }) => {
                const params = {
                    width: window.innerWidth,
                    height: window.innerHeight,
                };

                if (window.innerWidth > 768) {
                    params.width = Math.floor(window.innerWidth / 3);
                    params.height = Math.floor(window.innerHeight / 3);
                }

                return this._imagesService.getImageForLoyaltyIntroductionPage(pageId, params).pipe(
                    map((payload) => actions.LoyaltyIntroductionPageImageSuccessRequest({ pageId, payload })),
                    catchError((ex) => {
                        console.error(ex);

                        return of(actions.LoyaltyIntroductionPageImageErrorRequest({ pageId, ex }));
                    }),
                );
            }),
        ),
    );

    constructor(private _actions$: Actions, private _imagesService: Services.ImagesService) {}
}
