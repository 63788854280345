/* https://www.yearofmoo.com/2017/06/new-wave-of-animation-features.html#8-1-getting-router-outlet-to-animate */
import { animation, animateChild, animate, style, query } from '@angular/animations';
/* https://alligator.io/angular/animating-route-changes/ */
export const fadeInOut = animation([
    style({
        display: 'block',
        position: 'relative',
    }),
    query(':enter', style({ opacity: 0 }), { optional: true }),
    query(':leave', animateChild(), { optional: true }),
    query(':leave', [style({ opacity: 1, position: 'absolute', top: '0px', left: '0px', width: '100%' })], { optional: true }),
    query(':leave', [animate(300, style({ opacity: 0 }))], { optional: true }),
    query(':enter', [style({ opacity: 0 }), animate('300ms 300ms', style({ opacity: 1 }))], { optional: true }),
    query(':enter', animateChild(), { optional: true }),
]);
