import { createSelector } from '@ngrx/store';
import { getOnlineMenuVirtualLocations } from '@shared/state/onlineMenuVirtualLocations/selectors';
import { getCurrentLocationNo, getCurrentLocationValidationState } from '@shared/state/currentLocation/selectors';

export const canShowOnlineMenuVirtualLocationForCurrentLocation = createSelector(
    getCurrentLocationNo,
    getCurrentLocationValidationState,
    getOnlineMenuVirtualLocations,
    (locationNo, validation, onlineMenuVirtualLocations) =>
        locationNo !== null &&
        Boolean(onlineMenuVirtualLocations?.length) &&
        onlineMenuVirtualLocations.every(
            (onlineMenuVirtualLocation) => onlineMenuVirtualLocation?.locationNo === locationNo && Boolean(!onlineMenuVirtualLocation?.isDownloading),
        ) &&
        validation?.hasSucceeded === true &&
        validation?.hasFailed !== true &&
        validation?.isValidating !== true,
);
