import { Injectable, Inject } from '@angular/core';
import { Action, Store, select } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';

import * as Tokens from '@shared/core/tokens';

import { Observable } from 'rxjs';
import { switchMap, withLatestFrom, skipWhile } from 'rxjs/operators';

@Injectable()
export class TopBarEffects {
    public onRouteChange$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.ROUTER_NAVIGATION),
            withLatestFrom(
                this._store.pipe(
                    select(selectors.getCurrentRoute),
                    skipWhile((route) => route === null),
                ),
                (action, route) => route,
            ),
            withLatestFrom(this._store.pipe(select(selectors.routeIsLocationDetailsPage()))),
            switchMap(([route, isOnLocationPage]) => {
                let showOrderMore: boolean = /(sign-in|sign-up|account\/profile|checkout)/gi.test(route.url);
                let showCartIcon: boolean = !(/(checkout)/gi.test(route.url) || route.urlAfterRedirects === '/checkout/summary');

                return [
                    actions.TopBarSetBackToStoreIconVisibility({ isVisible: showOrderMore }),
                    actions.TopBarSetBackToLandingIconVisibility({ isVisible: isOnLocationPage }),
                    actions.TopBarSetCartIconVisibility({ isVisible: showCartIcon }),
                ];
            }),
        ),
    );

    constructor(@Inject(Tokens.CONFIG_TOKEN) private _config: OLO.Config, private _actions$: Actions, private _store: Store<OLO.State>) {}
}
