import { createSelector } from '@ngrx/store';

import * as Utils from '@shared/core/utils';

import { getAppSettingsTags } from '../../../appSettings/selectors';
import { getCartMenuFlowByTempId } from '../getCartMenuFlowByTempId';

export const getCartMenuFlowDescription = (tempId: number) =>
    createSelector(getCartMenuFlowByTempId(tempId), getAppSettingsTags, (menuFlow, appSettingsTags) => {
        if (!menuFlow) return null;

        return Utils.Items.generateCartMenuFlowDescription(menuFlow, appSettingsTags);
    });
