import { Injectable, Inject } from '@angular/core';
import { Action, Store, select } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';

import * as Services from '@shared/core/services';
import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';

import { Observable, throwError, of, iif, combineLatest } from 'rxjs';
import { catchError, switchMap, map, tap, withLatestFrom, filter, take, delay, auditTime } from 'rxjs/operators';
import { ReturningMemberService } from '@shared/core/services/returning-member.shared.service';

@Injectable()
export class MembersEffects {
    /**
     * Once the session has been set, we want to get proper fields for member. Some might be required when placing an order, i.e. ProfilePicture
     */
    public onMemberInit$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(
                actions.MemberSessionErrorRequest,
                actions.MemberSessionSuccessRequest,
                actions.MemberGuestModeSet,
                actions.MemberJwtDataResponseSuccess,
                actions.MemberSignInDataResponseSuccess,
            ),
            auditTime(0),

            switchMap(() =>
                this._store.pipe(
                    select(selectors.isMemberAuthorizedJWT),
                    filter((isAuthorized) => isAuthorized != null),
                    take(1),
                    withLatestFrom(this._store.pipe(select(selectors.getMemberState))),
                    switchMap(([isAuthorized, membersState]) => {
                        /** Prevent from redownloading same stuff */
                        const fieldsState = isAuthorized ? membersState.MemberFormFieldsModel.updatePersonalDetails : membersState.MemberFormFieldsModel.signup;

                        if (fieldsState.isDownloading || fieldsState.hasSucceeded) {
                            return [];
                        }

                        return [isAuthorized ? actions.MemberGetUpdateMemberFormFieldsRequest() : actions.MemberGetSignupFormFieldsRequest()];
                    }),
                ),
            ),
        ),
    );

    public verifyLinkRewardsAccount$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberLinkRewardsAccountVerifyRequest),
            switchMap(({ params }) =>
                this._authService.validateEmailWithMemberCardNumber(params.email, params.memberCardNo).pipe(
                    map((member) => {
                        if (!member) throw new Error('Member not found by params: ' + params);

                        return actions.MemberLinkRewardsAccountVerifySuccessRequest({ params, payload: member });
                    }),
                    catchError(() => of(actions.MemberLinkRewardsAccountVerifyErrorRequest({ params }))),
                ),
            ),
        ),
    );

    public onVerifyLinkRequestMemberDataToUpdateStep$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberLinkRewardsAccountVerifySuccessRequest),
            switchMap(() => [actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.ACCOUNT_LINKING_REWARDS_COMPLETE_SIGN_UP })]),
        ),
    );

    public onSuccessUserData$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberDataSuccessRequest),
            withLatestFrom(this._store.pipe(select(selectors.getAuthorizationStep))),
            map(([, currentStep]) => actions.MemberAuthorizationCalculateNextStep({ currentStep })),
        ),
    );

    public calculateNextAuthStep$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberAuthorizationCalculateNextStep),
            withLatestFrom(
                this._store.pipe(select(selectors.getAuthorizationStep)),
                this._store.pipe(select(selectors.getMemberState)),
                this._store.pipe(select(selectors.isMemberAuthorizedJWT)),
                this._store.pipe(select(selectors.getMobilePhoneCountryId)),
            ),
            switchMap(([action, authStep, memberState, isAuthorized, mobilePhoneCountryId]) => {
                const currentAuthStep = action.currentStep ?? authStep;
                const member = memberState.data;

                const isLinkingRewardsAccount =
                    memberState.authorizationStep === OLO.Enums.AUTH_STEP.ACCOUNT_LINKING_REWARDS_SIGN_UP && memberState.verifyLinkRewardsAccount.data !== null;

                if (isLinkingRewardsAccount) {
                    return [actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.ACCOUNT_LINKING_REWARDS_COMPLETE_SIGN_UP })];
                }

                const requireUpdate: boolean = member && member.IsOnlineRegistered === false;
                if (!isAuthorized && requireUpdate) {
                    return [actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.REGISTER })];
                }
                const memberRequiresRevalidation: boolean = member.IsEmailValidated === false || member.IsMobileValidated === false;
                const isVeryfiying: boolean = memberState.authorizationStep === OLO.Enums.AUTH_STEP.ON_LOGIN_DATA_VALIDATION;
                const shouldCheckValidation: boolean =
                    isVeryfiying === true ||
                    ((memberState.authorizationStep === OLO.Enums.AUTH_STEP.VERIFY_EMAIL || memberState.authorizationStep === OLO.Enums.AUTH_STEP.VERIFY_PHONE) &&
                        memberRequiresRevalidation);

                if (shouldCheckValidation) {
                    if (!member.IsMobileValidated) {
                        return [
                            actions.MemberSendPhoneVerificationCodeDataRequest({ phoneNo: memberState.data.MobilePhone, mobilePhoneCountryId }),
                            actions.MemberAuthorizationSetStep({
                                step: isAuthorized ? OLO.Enums.AUTH_STEP.BEFORE_UPDATE_VERIFY_PHONE : OLO.Enums.AUTH_STEP.VERIFY_PHONE,
                            }),
                        ];
                    }

                    if (!member.IsEmailValidated) {
                        const isSendingEmailVerificationRequest = memberState.tokenForEmail.isSending;

                        return [
                            actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.VERIFY_EMAIL }),
                            actions.MemberJwtDataResponseSuccess({ email: null, password: null }),
                            ...(isSendingEmailVerificationRequest ? [] : [actions.MemberSendEmailVeryficationRequest({ email: member.Email })]),
                        ];
                    }

                    /**
                     * Show profile picture form for not authorized members.
                     * Don't want to show this modal during sign in process since this might get messy with our sign in flow and other modals that
                     * need to show up. There is also problem with 'back' button. If user gets signed in, profile pic auth step will show in the context
                     * of current modal and there will be back arrow visible but it won't work.
                     * Automatic popup will be triggered during checkout from checkout-type component and user won't be able to close it if mandatory
                     */
                    const isPhotoValid = new Utils.MemberPhotoChecker(
                        member,
                        isAuthorized ? memberState.MemberFormFieldsModel.updatePersonalDetails.data : memberState.MemberFormFieldsModel.signup.data,
                    ).isValid;
                    const showProfilePictureStep = !isAuthorized && (!isPhotoValid || currentAuthStep !== OLO.Enums.AUTH_STEP.PROFILE_PICTURE);
                    if (showProfilePictureStep) {
                        return [actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.PROFILE_PICTURE })];
                    }

                    return [actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.LOGIN_SUCCESS }), actions.MemberJwtDataResponseSuccess({ email: null, password: null })];
                }

                if (isAuthorized && memberState.update.updateHasSucceeded) {
                    return [actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.UPDATE_SUCCESS })];
                }

                if (
                    !isAuthorized &&
                    memberState.data &&
                    memberState.data.IsOnlineRegistered &&
                    memberState.data.IsEmailValidated &&
                    memberState.data.IsMobileValidated &&
                    memberState.authorizationStep > OLO.Enums.AUTH_STEP.REGISTER
                ) {
                    return [actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.REGISTER_SUCCESS })];
                }

                return [actions.MemberAuthorizationSetStep({ step: null })];
            }),
        ),
    );

    public onMemberAuthorizationSetStep$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberAuthorizationSetStep),
            withLatestFrom(this._store.pipe(select(selectors.getAllModals))),
            switchMap(([{ step }, modals]) => {
                if (step == null) {
                    return modals.filter(({ type }) => type === 'auth').map(({ id }) => actions.ModalClose({ id }));
                }

                return [];
            }),
        ),
    );

    public simulateEmailVerifyRequests$ = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberVerifyEmailRequest),
            switchMap(() =>
                this._store.pipe(
                    select(selectors.getMemberState),
                    filter((state) => state.isDownloading === false),
                    take(1),
                    switchMap((state) => {
                        /* This dummy action is required to control proper button behavior. We don't have valid endpoint that will check email validation flag */
                        if (state.hasSucceeded && state.data && state.data.IsEmailValidated) {
                            return of(actions.MemberVerifyEmailSuccessRequest());
                        }

                        return this._membersService.validateMemberByProperty(state.data.Email, state.data.MobilePhoneCountryId, OLO.Enums.LOGIN_TYPE.EMAIL_BASED_LOGIN).pipe(
                            switchMap((response) => {
                                if (response.isValid) {
                                    return of(actions.MemberVerifyEmailErrorRequest());
                                } else {
                                    return [
                                        actions.MemberVerifyEmailSuccessRequest(),
                                        actions.MemberAuthorizationSetStep({
                                            step: state.isAuthorized && state.accountId ? OLO.Enums.AUTH_STEP.UPDATE_SUCCESS : OLO.Enums.AUTH_STEP.REGISTER_SUCCESS,
                                        }),
                                    ];
                                }
                            }),
                        );
                    }),
                ),
            ),
        ),
    );

    public checkGuestMemberToCompleteAccountSetup$ = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberCompleteAccountSetupGuestCheck),
            withLatestFrom(this._store.pipe(select(selectors.isMemberAuthorizedJWT)), this._store.pipe(select(selectors.getGuestData))),
            switchMap(([{ orderId }, isAuthorized, guest]) => {
                if (isAuthorized) return [];

                return this._store.pipe(
                    select(selectors.getHistoryOrderByOrderId(orderId)),
                    filter((order) => order !== undefined && order !== null && order.data !== null),
                    take(1),
                    switchMap(() => {
                        if (!guest) return [];

                        return of(
                            actions.MemberValidateLoginRequest({
                                login: `${guest.MobilePhoneCountryId}:${guest.MobileNumber}`,
                                loginType: OLO.Enums.LOGIN_TYPE.MOBILE_PHONE_BASED_LOGIN,
                            }),
                        );
                    }),
                    catchError((ex) => {
                        console.error("Unable to delete member's account", ex);

                        return of(actions.MemberDeleteProfileErrorRequest({ ex }));
                    }),
                );
            }),
        ),
    );

    public revalidateAndSetGuestData$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberGuestDataRevalidateAndSet),
            switchMap((action) => [
                actions.MemberGuestDataReset(),
                actions.MemberDataReset(),
                actions.MemberGuestDataSet({ guestData: action.guestData as APICommon.OnlineOrderPartialMember }),
                actions.MemberVerifyEmailRestoreFlags(),
                actions.MemberVerifyPhoneRestoreFlags(),
                actions.MemberValidateEmailDataRequest({ email: action.guestData.Email, memberPhoneIdPriority: true }),
                actions.MemberValidatePhoneRequest({ phone: action.guestData.MobileNumber, memberPhoneIdPriority: true }),
            ]),
        ),
    );

    public deleteAccountRequest$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberDeleteProfileRequest),
            switchMap(() => {
                if (this._config.membership.membersCanDeleteAccounts !== true) {
                    throw new Error('Application is not configured to allow members to delete their accounts');
                }

                return this._authService.deleteMemberAccount().pipe(
                    map((hasDeleted) => {
                        if (!hasDeleted) throw new Error('Unable to delete account');

                        return actions.MemberDeleteProfileSuccessRequest();
                    }),
                    catchError((ex) => {
                        console.error("Unable to delete member's account", ex);

                        return of(actions.MemberDeleteProfileErrorRequest({ ex }));
                    }),
                );
            }),
            catchError((ex) => {
                console.error("Unable to delete member's account", ex);

                return of(actions.MemberDeleteProfileErrorRequest({ ex }));
            }),
        ),
    );

    public onDeleteAccountSuccessSignOut$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberDeleteProfileSuccessRequest),
            switchMap(() => {
                this._cleanService.clearStorageOnUserDelete();

                return [actions.MemberSignOut({ redirect: true })];
            }),
        ),
    );

    public memberSignOut$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberSignOut),
            switchMap((action) => {
                this._authService.signOut(action.redirect);

                return [];
            }),
        ),
    );

    public initUpdateMemberProfile$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberProfileUpdateInit),
            switchMap(({ userModel, modalId }) =>
                this._store.pipe(
                    select(selectors.getMemberState),
                    take(1),
                    switchMap((memberState) => {
                        if (!memberState.data) {
                            console.error('Members data is not available', memberState.data);

                            return [actions.MemberProfileUpdateErrorRequest({ userModel, modalId, ex: 'Members data is not available' })];
                        }

                        if (
                            userModel.ProfilePicture ||
                            userModel.hasOwnProperty('Birthday') ||
                            (!userModel.hasOwnProperty('Email') &&
                                memberState.data.IsEmailValidated &&
                                !userModel.hasOwnProperty('MobilePhone') &&
                                memberState.data.IsMobileValidated)
                        ) {
                            return [
                                actions.MemberProfileUpdateRequest(
                                    {
                                        ...memberState.data,
                                        ...userModel,
                                    },
                                    modalId,
                                ),
                            ];
                        }

                        const IsEmailValidated: boolean = memberState.data.IsEmailValidated === true && memberState.data.Email === userModel.Email;

                        if (memberState.data.MobilePhone === userModel.MobilePhone) {
                            if (!IsEmailValidated && modalId) {
                                this._modalsService.show({
                                    type: 'auth',
                                    id: modalId,
                                });
                            }

                            return [
                                actions.MemberProfileUpdateRequest({
                                    ...memberState.data,
                                    ...userModel,
                                    IsEmailValidated,
                                }),
                                ...(IsEmailValidated
                                    ? []
                                    : [
                                          actions.MemberSendEmailVeryficationRequest({ email: userModel.Email }),
                                          actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.VERIFY_EMAIL }),
                                      ]),
                            ];
                        }

                        if (modalId) {
                            this._modalsService.show({
                                type: 'auth',
                                id: modalId,
                            });
                        }

                        return [
                            actions.MemberSendPhoneVerificationCodeDataRequest({ phoneNo: userModel.MobilePhone, mobilePhoneCountryId: userModel.MobilePhoneCountryId }),
                            actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.BEFORE_UPDATE_VERIFY_PHONE }),
                        ];
                    }),
                ),
            ),
        ),
    );

    public updateMemberProfileData$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberProfileUpdateRequest),
            switchMap(({ userModel, modalId }) =>
                this._membersService.validateUserNewProfileData(userModel).pipe(
                    switchMap((response) => {
                        if (response) return of(actions.MemberProfileUpdateErrorRequest({ userModel, ex: new Error('User with provied details already exists') }));

                        return this._membersService.updateUser(userModel).pipe(
                            map(() => actions.MemberProfileUpdateSuccessRequest({ userModel, payload: userModel, modalId })),
                            catchError((ex) => of(actions.MemberProfileUpdateErrorRequest({ userModel, ex }))),
                        );
                    }),
                ),
            ),
        ),
    );

    public revalidateEmailAndCellAfterProfileUpdate$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberProfileUpdateSuccessRequest),
            withLatestFrom(
                this._store.pipe(select(selectors.getAllModals)),
                this._store.pipe(select(selectors.getMobilePhoneCountryId)),
                this._store.pipe(select(selectors.getAuthorizationStep)),
            ),
            switchMap(([{ payload, modalId }, modals, mobilePhoneCountryId, step]) => {
                if (!modalId) return [];

                const isCellValidated = payload.IsMobileValidated === true;
                const isEmailValidated = payload.IsEmailValidated === true;

                if (!modals.find((obj) => obj.id === modalId)) {
                    this._modalsService.show({
                        type: 'auth',
                        id: modalId,
                    });
                }

                const modalAlreadyExist = modals.some((obj) => obj.id === modalId);
                const hasProfilePicture = payload.ProfilePicture;
                const isProfilePictureStep = step === OLO.Enums.AUTH_STEP.PROFILE_PICTURE;

                if (modalAlreadyExist && hasProfilePicture && isProfilePictureStep) {
                    this._modalsService.swap(modalId, {
                        type: 'auth',
                        preventManualClose: false,
                    });
                }

                if (!isCellValidated) {
                    return [
                        actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.VERIFY_PHONE }),
                        actions.MemberSendPhoneVerificationCodeDataRequest({ phoneNo: payload.MobilePhone, mobilePhoneCountryId }),
                    ];
                }

                if (!isEmailValidated) {
                    return [actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.VERIFY_EMAIL }), actions.MemberSendEmailVeryficationRequest({ email: payload.Email })];
                }

                return [actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.UPDATE_SUCCESS })];
            }),
        ),
    );

    public signOutIfUserWontValidateDataAfterUpdate$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.ModalClose, actions.ModalCloseAll),
            withLatestFrom(this._store.pipe(select(selectors.getCurrentMember)), this._store.pipe(select(selectors.getGuestData))),
            switchMap(([action, memberData, guestData]) => {
                if (!memberData || !!guestData) return [];

                const isValid: boolean = memberData.IsMobileValidated;
                if (isValid) return [];

                if ((action.type === actions.ModalClose.type && action.id === -100) || action.type === actions.ModalCloseAll.type) {
                    return of(actions.MemberSignOut({ redirect: true }));
                }

                return [];
            }),
        ),
    );

    public validateConfirmEmailToken$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberConfirmEmailTokenRequest),
            switchMap(({ token }) =>
                this._membersService.validateMemberConfirmEmailToken(token).pipe(
                    map((payload) => actions.MemberConfirmEmailTokenSuccessRequest({ token, payload })),
                    catchError((ex) => of(actions.MemberConfirmEmailTokenErrorRequest({ token, ex }))),
                ),
            ),
        ),
    );

    public memberConfirmEmailTokenSuccessRequest$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberConfirmEmailTokenSuccessRequest),
            map(({ token }) => actions.MemberConfirmEmailRequest({ token })),
        ),
    );

    public confirmEmailAddress$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberConfirmEmailRequest),
            switchMap(({ token }) =>
                this._membersService.confirmEmailAddress(token).pipe(
                    map((payload) => actions.MemberConfirmEmailSuccessRequest({ token, payload })),
                    catchError((ex) => of(actions.MemberConfirmEmailErrorRequest({ token, ex }))),
                ),
            ),
        ),
    );

    public validateResetPasswordToken$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberValidatePasswordResetTokenRequest),
            switchMap(({ token }) =>
                this._membersService.validateMemberPasswordResetToken(token).pipe(
                    map((payload) => actions.MemberValidatePasswordResetTokenSuccessRequest({ token, payload })),
                    catchError((ex) => of(actions.MemberValidatePasswordResetTokenErrorRequest({ token, ex }))),
                ),
            ),
        ),
    );

    public resetForgottenPassword$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberForgottenPasswordResetRequest),
            switchMap(({ model }) =>
                this._membersService.resetForgottenPassword({ Token: model.Token, NewPassword: model.NewPassword }).pipe(
                    map((payload) => actions.MemberForgottenPasswordResetSuccessRequest({ model, payload })),
                    catchError((ex) => of(actions.MemberForgottenPasswordResetErrorRequest({ model, ex }))),
                ),
            ),
        ),
    );

    public requestUserData$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberDataRequest),
            switchMap(() =>
                this._membersService.getUserData().pipe(
                    map((response) => actions.MemberDataSuccessRequest({ memberId: response.UserId, payload: response })),
                    catchError((ex) => throwError(actions.MemberDataErrorRequest(ex))),
                ),
            ),
        ),
    );

    // TODO: Check in future where it is used, because there is no place in code where MemberQuickLoginRequest is dispatched
    public quickLoginRequest$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberQuickLoginRequest),
            switchMap((action) =>
                this._authService
                    .signIn({
                        Login: action.login,
                        Password: action.password,
                        LoginType: action.authorizationType,
                    })
                    .pipe(
                        withLatestFrom(this._store.select(selectors.getMemberState)),
                        switchMap(([data]) =>
                            of(
                                actions.MemberQuickLoginSuccessRequest({
                                    login: action.login,
                                    password: action.password,
                                    authorizationType: action.authorizationType,
                                    sessionKey: data.sessionKey,
                                }),
                            ),
                        ),
                        catchError((ex) =>
                            of(
                                actions.MemberQuickLoginErrorRequest({
                                    login: action.login,
                                    password: action.password,
                                    authorizationType: action.authorizationType,
                                    ex,
                                }),
                            ),
                        ),
                    ),
            ),
        ),
    );

    public quickLoginOnSuccessSetup$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberQuickLoginSuccessRequest),
            withLatestFrom(this._store.select(selectors.getMemberState)),
            switchMap(([{ sessionKey, authorizationType }, memberState]) => [
                actions.CreateMemberSession({ sessionKey, accountId: String(memberState.data.MemberId), authorizationType: authorizationType }),
                actions.MemberAuthorizationSetFlag({ flag: true }),
                actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.LOGIN_SUCCESS }),
                actions.MemberJwtDataResponseSuccess({ email: null, password: null }),
            ]),
        ),
    );

    public linkRewardsAccountInit$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberLinkRewardsAccountRequest),
            withLatestFrom(this._store.pipe(select(selectors.getMemberState)), this._store.pipe(select(selectors.getMobilePhoneCountryId))),
            switchMap(([, state, mobilePhoneCountryId]) => [
                actions.MemberSendPhoneVerificationCodeDataRequest({ phoneNo: state.data.MobilePhone, mobilePhoneCountryId }),
                actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.VERIFY_PHONE }),
            ]),
        ),
    );

    public requestUpdateUserData$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberUpdateRequest),
            switchMap(({ userModel }) =>
                this._membersService.updateUser(userModel).pipe(
                    map(() => actions.MemberUpdateSuccessRequest({ userModel, payload: userModel })),
                    catchError((ex) => of(actions.MemberUpdateErrorRequest({ userModel, ex }))),
                ),
            ),
        ),
    );

    public onSuccessfulMemberUpdateShowOk$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberUpdateSuccessRequest),
            withLatestFrom(this._store.pipe(select(selectors.getMemberState))),
            switchMap(([action, state]) => {
                if (state.authorizationStep === OLO.Enums.AUTH_STEP.BEFORE_UPDATE_VERIFY_PHONE) {
                    if (action.payload.IsEmailValidated) {
                        return [actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.UPDATE_SUCCESS })];
                    } else {
                        return [actions.MemberProfileUpdateInit({ userModel: action.payload, modalId: null }), actions.MemberDataRequest()];
                    }
                }

                return [];
            }),
        ),
    );

    public requestPasswordChange$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberPasswordChangeRequest),
            switchMap(({ NewPassword, OldPassword, MemberId }) =>
                this._membersService.updatePasswordRequest({ OldPassword, NewPassword }).pipe(
                    map((response) =>
                        response
                            ? actions.MemberPasswordChangeSuccessRequest({
                                  OldPassword,
                                  NewPassword,
                                  MemberId,
                              })
                            : actions.MemberPasswordChangeErrorRequest({
                                  OldPassword,
                                  NewPassword,
                                  MemberId,
                              }),
                    ),
                    catchError((ex) =>
                        of(
                            actions.MemberPasswordChangeErrorRequest({
                                OldPassword,
                                NewPassword,
                                MemberId,
                                ex: ex.error && ex.error.Message && typeof ex.error.Message === 'string' ? ex.error : null,
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    public initAuthorization$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberAuthorizationInit),
            switchMap(({ authorizationType, accountLogin }) => {
                switch (authorizationType) {
                    case OLO.Enums.LOGIN_TYPE.MEMBER_CARD_NUMBER_BASED_LOGIN:
                    case OLO.Enums.LOGIN_TYPE.MOBILE_PHONE_BASED_LOGIN:
                        return of(actions.MemberValidateLoginRequest({ login: accountLogin, loginType: authorizationType }));
                }
            }),
        ),
    );

    public requestLoginValidation$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberValidateLoginRequest),
            switchMap(({ login, loginType }) =>
                this._membersService.validateLogin(login, loginType).pipe(
                    map((payload) => actions.MemberValidateLoginSuccessRequest({ login, loginType, payload })),
                    catchError((ex) => of(actions.MemberValidateLoginErrorRequest({ login, loginType, ex }))),
                ),
            ),
        ),
    );

    public loginValidationSuccess$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberValidateLoginSuccessRequest),
            withLatestFrom(this._store.pipe(select(selectors.isPaymentComplete))),
            switchMap(([action, isPaymentComplete]) => {
                if (isPaymentComplete) {
                    return [];
                }

                if (action.payload !== null && action.payload.MemberId && action.payload.IsOnlineRegistered) {
                    return of(actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.PASSWORD }));
                } else {
                    if (action.payload?.MemberId === null && this._config.signUpRewardsAccountLinking?.enabled === true) {
                        console.warn(
                            'loginValidationSuccess$ - "signUpRewardsAccountLinking" is currently not supported. It will be re-added in the future. See more under TOLO-2767',
                        );

                        // TODO: uncomment below logic once signUpRewardsAccountLinking is supported. See more under https://vmobglobal.atlassian.net/browse/TOLO-2739?focusedCommentId=550977.
                        // return of(actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.ACCOUNT_LINKING_REWARDS_INFO }));
                    }

                    if (action.payload?.MemberId && !action?.payload?.IsOnlineRegistered && action.payload?.Email) {
                        return [];
                    }

                    const returnedActions: Action[] = [actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.REGISTER })];

                    if (action.payload && action.payload.MemberId) {
                        returnedActions.push(actions.MemberDataSuccessRequest({ memberId: action.payload.MemberId, payload: action.payload }));
                    }

                    return returnedActions;
                }
            }),
        ),
    );

    public requestMemberLogin$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberSignInDataRequest),
            withLatestFrom(this._store.select(selectors.getMemberState)),
            switchMap(([action, memberState]) => {
                const password = action.password;
                const login = action.login || memberState.accountLogin;

                let MobilePhoneCountryId = memberState.data?.MobilePhoneCountryId;

                const countryId = localStorage.getItem('user.countryId');
                if (!MobilePhoneCountryId && !isNaN(+countryId) && +countryId && !this._returningMemberService.hasModalBeenDisplayed) {
                    MobilePhoneCountryId = +countryId;
                }

                const credentials: OLO.DTO.LoginMemberRequest = {
                    Login: login,
                    Password: password,
                    LoginType: memberState.authorizationType,
                    MobilePhoneCountryId,
                };

                return this._authService.signIn(credentials).pipe(
                    map((response) =>
                        response !== null
                            ? actions.MemberSignInDataResponseSuccess({ password, data: response, withChangeState: true })
                            : actions.MemberSignInDataResponseError({ password }),
                    ),
                    catchError((ex) => of(actions.MemberSignInDataResponseError({ password, ex }))),
                );
            }),
        ),
    );

    public requestJWTLogin$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberJwtDataRequest),
            switchMap(({ email, password }) =>
                this._jwtService.reqestJWToken({ Email: email, Password: password }).pipe(
                    map((response) => (response ? actions.MemberJwtDataResponseSuccess({ email, password }) : actions.MemberJwtDataResponseError({ email, password }))),
                    catchError(() => of(actions.MemberJwtDataResponseError({ email, password }))),
                ),
            ),
        ),
    );

    public requestMemberLoginSuccess$: Observable<any> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberSignInDataResponseSuccess),
            withLatestFrom(this._store.select(selectors.getMemberState), this._store.pipe(select(selectors.isInRecalculation))),
            switchMap(([action, memberState, isInRecalculation]) => {
                const sessionKey = action.data.sessionKey;
                const bundleActions: any[] = [actions.MemberJwtSetData({ jwt: action.data.jwt }), actions.MemberAuthorizationSetFlag({ flag: true })];

                if (action.withChangeState) {
                    bundleActions.push(actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.ON_LOGIN_DATA_VALIDATION }));
                }

                bundleActions.push(actions.MemberDataRequest());

                if (sessionKey && isInRecalculation) {
                    bundleActions.push(actions.OnlineOrderRecalculateRequest());
                }

                // TODO remove IsEmailValidated
                if (memberState.data && memberState.accountId && sessionKey) {
                    bundleActions.push(actions.CreateMemberSession({ sessionKey, accountId: memberState.accountId as string, authorizationType: memberState.authorizationType }));
                }

                if (this._config.membership.enableQuickSignInForReturningMembers && memberState.data) {
                    localStorage.setItem(OLO.Enums.USER_STORAGE.PHONE, `${memberState.data?.MobilePhone}`);
                    localStorage.setItem(OLO.Enums.USER_STORAGE.COUNTRY_ID, `${memberState.data?.MobilePhoneCountryId}`);
                }

                return bundleActions;
            }),
        ),
    );

    public validatePhone$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberValidatePhoneRequest),
            switchMap(({ phone, memberPhoneIdPriority }) =>
                this._store.pipe(
                    select(selectors.getMemberState),
                    filter((memberState) => memberState.isDownloading === false),
                    take(1),
                    switchMap((memberState) => {
                        const MobilePhoneCountryId =
                            memberState?.addData?.MobilePhoneCountryId ||
                            memberState?.guestData?.MobileNumberCountryID ||
                            memberState?.guestData?.MobilePhoneCountryId ||
                            memberState?.data?.MobilePhoneCountryId;

                        return this._membersService.validateLogin(`${MobilePhoneCountryId}:${phone}`, OLO.Enums.LOGIN_TYPE.MOBILE_PHONE_BASED_LOGIN).pipe(
                            map((response) =>
                                !response.IsOnlineRegistered
                                    ? actions.MemberValidatePhoneSuccessRequest({
                                          phone,
                                          memberId: memberPhoneIdPriority && response.MemberId ? response.MemberId : memberState.data?.MemberId,
                                      })
                                    : actions.MemberValidatePhoneDataResponseError({ phone }),
                            ),
                            catchError((ex) => {
                                console.error('Phone check validation error:', ex);

                                return of(actions.MemberValidatePhoneDataResponseError({ phone, ex }));
                            }),
                        );
                    }),
                ),
            ),
        ),
    );

    public validateEmail$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberValidateEmailDataRequest),
            switchMap(({ email, memberPhoneIdPriority }) =>
                this._store.pipe(
                    select(selectors.getMemberState),
                    take(1),
                    switchMap((memberState) =>
                        this._membersService.validateLogin(`${email}`, OLO.Enums.LOGIN_TYPE.EMAIL_BASED_LOGIN).pipe(
                            map((response) =>
                                !response.IsOnlineRegistered
                                    ? actions.MemberValidateEmailDataResponseSuccess({
                                          email,
                                          memberId: memberPhoneIdPriority && memberState.data?.MemberId ? memberState.data?.MemberId : response.MemberId,
                                      })
                                    : actions.MemberValidateEmailDataResponseError({ email }),
                            ),
                            catchError((ex) => {
                                console.error('Email check validation error:', ex);

                                return of(actions.MemberValidateEmailDataResponseError({ email, ex }));
                            }),
                        ),
                    ),
                ),
            ),
        ),
    );

    public requestForgotPassword$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberForgotPasswordDataRequest),
            switchMap(({ email }) =>
                this._membersService.resendForgotPasswordConfirmation(email).pipe(
                    switchMap(() =>
                        //
                        //          We don't care here if response is false due to security reasons.
                        //          We navigate to success step.
                        //
                        [actions.MemberForgotPasswordDataResponseSuccess({ email }), actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.FORGOT_PASSWORD_SENT })],
                    ),
                    catchError((ex) => {
                        console.error('EXEPTION', ex, typeof ex);

                        return of(actions.MemberForgotPasswordDataResponseError({ email, ex }));
                    }),
                ),
            ),
        ),
    );

    public setMemberNewPassword$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberPasswordSetRequest),
            switchMap(({ memberId, password }) =>
                this._membersService.changeMemberPassword(password).pipe(
                    map((response) => {
                        if (response) return actions.MemberPasswordSetSuccessRequest({ memberId, password });

                        return actions.MemberPasswordSetErrorRequest({ memberId, password });
                    }),
                    catchError((ex) => {
                        console.error('Unable to set user password', ex);

                        return of(actions.MemberPasswordSetErrorRequest({ memberId, password }));
                    }),
                ),
            ),
        ),
    );

    public initializePartialMemberSignUpProcess$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberPartialSignUpRequest),
            withLatestFrom(this._store.pipe(select(selectors.getMemberState))),
            map(([actionPayload, memberState]) => {
                const showProfilePictureStep = !new Utils.MemberPhotoChecker(memberState.addData, memberState.MemberFormFieldsModel.signup.data).isValid;

                if (showProfilePictureStep) {
                    return actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.PROFILE_PICTURE });
                }

                return actions.MemberSignUpProcessInit({
                    memberData: { ...memberState.addData, ...memberState.data, MobilePhone: memberState.data.MobileNumber },
                });
            }),
        ),
    );

    public initializeSignUpProcess$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberSignUpProcessInit),
            withLatestFrom(this._store.pipe(select(selectors.getMemberState))),
            tap(([{ memberData }, initialState]) => {
                if (initialState.uniqueCode.hasSucceeded === false && !initialState.uniqueCode.data) {
                    this._store.dispatch(actions.MemberValidatePhoneRequest({ phone: memberData.MobilePhone, memberPhoneIdPriority: false }));
                    this._store.dispatch(actions.MemberValidateEmailDataRequest({ email: memberData.Email, memberPhoneIdPriority: false }));
                }
            }),
            switchMap(([{ memberData }]) =>
                this._store.pipe(
                    select(selectors.getMemberState),
                    filter(
                        (state) =>
                            (state.uniqueCode.hasSucceeded === true && state.uniqueCode.data !== null) ||
                            (state.validatePhone.isValidating === false &&
                                (state.validatePhone.hasSucceeded === true || state.validatePhone.hasFailed === true) &&
                                state.validateEmail.isValidating === false &&
                                (state.validateEmail.hasSucceeded === true || state.validateEmail.hasFailed === true)),
                    ),
                    take(1),
                    switchMap((state) => {
                        if (state.validatePhone.hasFailed || state.validateEmail.hasFailed) return [];

                        if (state.uniqueCode.hasSucceeded === true && state.uniqueCode.data !== null) {
                            return [
                                actions.MemberSignUpRequest({
                                    memberData: {
                                        ...memberData,
                                        IsMobileValidated: true,
                                    },
                                    existingMember: state.uniqueCode.data,
                                }),
                            ];
                        }

                        return [
                            actions.MemberSendPhoneVerificationCodeDataRequest({ phoneNo: memberData.MobilePhone, mobilePhoneCountryId: memberData.MobilePhoneCountryId }),
                            actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.VERIFY_PHONE }),
                        ];
                    }),
                ),
            ),
        ),
    );

    public registerMemberWithFlowDetached$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberDetachedFlowSignUpRequest),
            switchMap(({ memberData }) =>
                this._authService.signUp(memberData, { IsMobileValidated: true }).pipe(
                    switchMap((payload) => [
                        actions.MemberSendEmailVeryficationRequest({ email: memberData.Email }),
                        actions.MemberDetachedFlowSignUpSuccessRequest({ memberData: payload }),
                        actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.REGISTER_SUCCESS }),
                    ]),
                    catchError((err) => {
                        console.warn('Detached flow member sign up error', err);

                        return [actions.MemberDetachedFlowSignUpErrorRequest({ memberData })];
                    }),
                ),
            ),
        ),
    );

    public cleanupAfterDetachedSignUpProcess$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberDetachedFlowSignUpSuccessRequest),
            switchMap(() => [actions.MemberGuestModeSet({ flag: false }), actions.MemberUnsetAddData()]),
        ),
    );

    public requestRegisterMember$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberSignUpRequest),
            switchMap(({ memberData, existingMember }) =>
                /* There might be user without phone number due to new linking account cases - make sure it's not there */
                iif(
                    () => Boolean(existingMember),
                    of(existingMember),
                    this._membersService
                        .getMembers({
                            memberEmail: memberData?.Email || existingMember?.Email || null,
                        })
                        .pipe(map((member) => member || null)),
                ).pipe(
                    switchMap((existingMemberChecked) => {
                        if (existingMemberChecked.IsOnlineRegistered) {
                            const userModel: OLO.DTO.MemberModel = {
                                ...existingMemberChecked,
                                IsMobileValidated: true,
                            };

                            return this._membersService.updateUser(userModel).pipe(
                                switchMap(() => {
                                    const bundleActions: Action[] = [actions.MemberUpdateSuccessRequest({ userModel, payload: userModel })];

                                    if (userModel.IsEmailValidated) {
                                        bundleActions.push(actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.UPDATE_SUCCESS }));
                                    } else {
                                        bundleActions.push(actions.MemberSendEmailVeryficationRequest({ email: userModel.Email }));
                                        bundleActions.push(actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.VERIFY_EMAIL }));
                                    }

                                    return bundleActions;
                                }),
                                catchError((ex) => of(actions.MemberUpdateErrorRequest({ userModel, ex }))),
                            );
                        }

                        return this._authService.signUp(memberData, existingMemberChecked).pipe(
                            switchMap((payload) => [
                                actions.MemberSendEmailVeryficationRequest({ email: payload.Email }),
                                actions.MemberSignUpSuccessRequest({ memberData: payload }),
                            ]),
                            catchError((ex) => of(actions.MemberSignUpErrorRequest({ memberData, existingMember: existingMemberChecked, ex }))),
                        );
                    }),
                ),
            ),
        ),
    );

    public requestRegisterMemberSuccess$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberSignUpSuccessRequest),
            withLatestFrom(this._store.pipe(select(selectors.getMemberState)), this._store.pipe(select(selectors.isMemberAuthorizedJWT))),
            switchMap(([, memberState, isAuthorized]) => {
                const bundleActions: Action[] = [];

                if (!isAuthorized) {
                    if (memberState.addData || memberState.data.IsEmailValidated === false) {
                        bundleActions.push(
                            actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.VERIFY_EMAIL }),
                            actions.MemberSendEmailVeryficationRequest({ email: memberState.addData ? memberState.addData.Email : memberState.data.Email }),
                        );
                    } else {
                        bundleActions.push(actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.LOGIN }));
                    }
                } else {
                    bundleActions.push(actions.MemberDataRequest());
                }

                return bundleActions;
            }),
        ),
    );

    public requestSendPhoneVeryficationCode$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberSendPhoneVerificationCodeDataRequest),
            switchMap(({ phoneNo, mobilePhoneCountryId }) =>
                this._authService.verifyPhoneNumber(phoneNo, mobilePhoneCountryId).pipe(
                    map((response) =>
                        response ? actions.MemberSendPhoneVerificationCodeDataResponseSuccess({ phoneNo }) : actions.MemberSendPhoneVerificationCodeDataResponseError({ phoneNo }),
                    ),
                    catchError((ex) => {
                        console.error('VerifyPhoneNoError', ex);

                        return of(actions.MemberSendPhoneVerificationCodeDataResponseError({ phoneNo, ex }));
                    }),
                ),
            ),
        ),
    );

    public requestVerifyPhoneCode$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberVerifyPhoneDataRequest),
            switchMap(({ phoneNo, token, countryPrefix }) =>
                this._authService.verifyPhoneNumberToken(phoneNo, token, countryPrefix).pipe(
                    map((response) => (response ? actions.MemberVerifyPhoneDataSuccessRequest({ phoneNo, token }) : actions.MemberVerifyPhoneDataErrorRequest({ phoneNo, token }))),
                    catchError((ex) => {
                        console.error('VerifyPhoneNumberToken', ex);

                        return of(actions.MemberVerifyPhoneDataErrorRequest({ phoneNo, token, ex }));
                    }),
                ),
            ),
        ),
    );

    public requestPhoneVeryficationSuccess$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberVerifyPhoneDataSuccessRequest),
            withLatestFrom(this._store.pipe(select(selectors.getMemberState)), this._store.pipe(select(selectors.hasRequestedLoyaltyProductPrograms))),
            switchMap(([{ token }, memberState, hasRequestedPrograms]) => {
                if (memberState.authorizationStep === OLO.Enums.AUTH_STEP.BEFORE_UPDATE_VERIFY_PHONE && memberState.data !== null) {
                    const IsEmailValidated: boolean = memberState.data.Email === memberState.update?.data?.Email || memberState.data.IsEmailValidated;
                    const userModel: OLO.DTO.MemberModel = {
                        ...memberState.data,
                        ...memberState.update.data,
                        IsMobileValidated: true,
                        IsEmailValidated,
                    };

                    const actionsToExecute: Action[] = [actions.MemberUpdateRequest({ userModel })];

                    if (!memberState.data.IsEmailValidated) {
                        actionsToExecute.push(actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.VERIFY_EMAIL }));
                        actionsToExecute.push(actions.MemberSendEmailVeryficationRequest({ email: memberState.data.Email }));
                    } else {
                        actionsToExecute.push(actions.MemberSignUpSuccessRequest({ memberData: userModel }));
                        actionsToExecute.push(actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.UPDATE_SUCCESS }));
                        actionsToExecute.push(actions.LoyaltyProgramsRequest());

                        if (this._config.appMode !== OLO.Enums.APP_MODE.ORDERING_ONLY && !hasRequestedPrograms) {
                            actionsToExecute.push(actions.LoyaltyProductProgramsRequest({ requestParams: {} }));
                        }
                    }

                    return actionsToExecute;
                }

                if (memberState.linkRewardsAccount.isLinking) {
                    const newMember: OLO.DTO.MemberModel = {
                        ...memberState.linkRewardsAccount.data,
                        IsEmailValidated: false,
                        IsMobileValidated: true,
                        IsOnlineRegistered: true,
                        MobilePhoneVerificationCode: token,
                        PartialMemberId: memberState.verifyLinkRewardsAccount.data.Id,
                    };

                    return this._authService.signUpSimple(newMember).pipe(
                        switchMap(() => [
                            actions.MemberLinkRewardsAccountSuccessRequest({ payload: newMember }),
                            actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.VERIFY_EMAIL }),
                            actions.MemberSendEmailVeryficationRequest({ email: newMember.Email }),
                        ]),
                        catchError((ex) => {
                            console.error('Error linking account', ex);

                            return [actions.MemberLinkRewardsAccountErrorRequest({ ex })];
                        }),
                    );
                }

                return [actions.MemberSignUpRequest({ memberData: memberState.addData, existingMember: memberState.data })];
            }),
        ),
    );

    public requestVerifyEmail$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberSendEmailVeryficationRequest),
            switchMap(({ email }) =>
                this._store.pipe(
                    select(selectors.getMemberState),
                    auditTime(100),
                    filter((state) => state.update.isUpdating !== true),
                    take(1),
                    switchMap(() => {
                        const model: OLO.DTO.SendMemberActivationEmailCommand = {
                            MemberEmail: email,
                        };

                        return this._membersService.resendEmailConfirmation(model).pipe(
                            map((response) =>
                                response ? actions.MemberSendEmailVeryficationSuccessRequest({ email }) : actions.MemberSendEmailVeryficationDataResponseError({ email }),
                            ),
                            catchError((ex) => {
                                console.error('ResendEmailConfirmation', ex);

                                return of(actions.MemberSendEmailVeryficationDataResponseError({ email, ex }));
                            }),
                        );
                    }),
                ),
            ),
        ),
    );

    public createSession$: Observable<Action> = createEffect(
        () =>
            this._actions$.pipe(
                ofType(actions.CreateMemberSession),
                switchMap((member) => {
                    this._clearPasswordForQuickLogin();
                    this._sessionService.createSession(member.sessionKey, member.accountId, member.authorizationType);

                    return [];
                }),
            ),
        { dispatch: false },
    );

    public checkUserSessionOnAppInit$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberSessionRequest),
            switchMap(() =>
                this._sessionService.getSession().pipe(
                    switchMap((response) => {
                        if (!response) {
                            return of(actions.MemberSessionErrorRequest({ sessionKey: null, accountId: null, authorizationType: null }));
                        }

                        return this._authService.validateSession().pipe(
                            switchMap((isValid) => {
                                if (!isValid) {
                                    return of(actions.MemberSessionErrorRequest({ sessionKey: null, accountId: null, authorizationType: null }));
                                }

                                return of(
                                    actions.MemberSessionSuccessRequest({
                                        sessionKey: response.SessionKey,
                                        accountId: response.AccountId as string,
                                        authorizationType: response.AuthorizationType,
                                    }),
                                );
                            }),
                        );
                    }),
                ),
            ),
        ),
    );

    public setSessionFlags: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberSessionSuccessRequest),
            withLatestFrom(this._store.pipe(select(selectors.getMemberState))),
            switchMap(([, { isDownloading }]) => {
                const bundle = [actions.MemberAuthorizationSetFlag({ flag: true }), actions.MemberJwtDataResponseSuccess({ email: null, password: null })];

                if (!isDownloading) {
                    (<any>bundle).push(actions.MemberDataRequest());
                }

                return bundle;
            }),
        ),
    );

    public removeSessionDataOnError$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberSessionErrorRequest),
            withLatestFrom(this._sessionService.getSession()),
            switchMap(([, session]) => {
                /* Sign out only if there was session detected */
                if (session) {
                    this._sessionService.removeSession();

                    return of(actions.MemberSignOut({ redirect: false }));
                }

                return [];
            }),
        ),
    );

    public onLoyaltyProductSuccessRequestGetInfoAboutMemberProducts$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.LoyaltyProductProgramsSuccessRequest),
            switchMap(() =>
                this._store.pipe(
                    select(selectors.getMemberState),
                    filter((state) => state.data !== null),
                    take(1),
                    switchMap((state) => {
                        const actionsBundle: Action[] = [];

                        if (!state.freeProducts.data && !state.freeProducts.isDownloading) {
                            actionsBundle.push(actions.MemberFreeProductsRequest({ memberId: state.data.MemberId }));
                        }

                        if (!state.loyaltyProducts.data && !state.loyaltyProducts.isDownloading) {
                            actionsBundle.push(actions.MemberLoyaltyProductsRequest({ memberId: state.data.MemberId }));
                        }

                        return actionsBundle;
                    }),
                ),
            ),
        ),
    );

    public requestFeeProducts$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberFreeProductsRequest),
            switchMap(({ memberId }) =>
                this._membersService.apiGetFreeProductsForMemberRequest().pipe(
                    map((payload) => actions.MemberFreeProductsSuccessRequest({ payload })),
                    catchError((ex) => {
                        console.error('MemberFreeProducts request error for member', memberId, ex);

                        return of(actions.MemberFreeProductsErrorRequest({ memberId, ex }));
                    }),
                ),
            ),
        ),
    );

    public requestFeeProductsVisited$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberFreeProductsRequestVisited),
            switchMap(({ freeProductId }) =>
                this._membersService.apiPostFreeProductsVisitedRequest(freeProductId).pipe(
                    map((productId: number) => actions.MemberFreeProductsVisitedSuccessRequest({ freeProductId: productId })),
                    catchError((ex) => of(actions.MemberFreeProductsVisitedErrorRequest({ ex }))),
                ),
            ),
        ),
    );

    public requestLoyaltyProducts$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberLoyaltyProductsRequest),
            switchMap(({ memberId }) =>
                this._membersService.apiGetLoyaltyProductsForMemberRequest().pipe(
                    map((payload) => actions.MemberLoyaltyProductsSuccessRequest({ memberId, payload })),
                    catchError((ex) => {
                        console.error('MemberLoyaltyProducts request error for member', memberId, ex);

                        return of(actions.MemberLoyaltyProductsErrorRequest({ memberId, ex }));
                    }),
                ),
            ),
        ),
    );

    public requestMemberCodeCheck$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberUniqueCodeCheckRequest),
            switchMap(({ memberCode }) =>
                this._membersService.checkMemberUniqueCode(memberCode).pipe(
                    map((payload) => actions.MemberUniqueCodeCheckSuccessRequest({ memberCode, payload })),
                    catchError((ex) => {
                        console.error('Check member code error', ex);

                        return [actions.MemberUniqueCodeCheckErrorRequest({ memberCode, ex }), actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.UNIQUE_CODE_ERROR })];
                    }),
                ),
            ),
        ),
    );

    public setStepAfterUniqueCodeRequest$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberUniqueCodeCheckSuccessRequest),
            switchMap(({ payload }) => {
                if (payload && payload.IsOnlineRegistered === true) {
                    return [actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.UNIQUE_CODE_ERROR })];
                }

                return [];
            }),
        ),
    );

    public showBirthdayPopupOnSignIn$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberJwtDataResponseSuccess),
            delay(3000),
            withLatestFrom(this._store.pipe(select(selectors.getMemberState)), this._store.pipe(select(selectors.isMemberAuthorizedJWT))),
            switchMap(([, state, isAuthorized]) => {
                if (
                    !isAuthorized ||
                    this._config.membership.birthdayRewards !== true ||
                    this._config.appMode === OLO.Enums.APP_MODE.ORDERING_ONLY ||
                    Utils.Storage.getItem(OLO.Enums.USER_STORAGE.BIRTHDAY_REWARDS) === 'skip' ||
                    !state.data ||
                    state.data.Birthday
                )
                    return [];

                return combineLatest(this._store.pipe(select(selectors.isMemberLoading)), this._store.pipe(select(selectors.getAllModals))).pipe(
                    filter(([isLoading, modals]) => isLoading === false && modals.length === 0),
                    take(1),
                    filter(() => {
                        const pathsWithoutAuthModal = ['reset-password', 'email-confirmation', 'order-confirmation'];

                        return !pathsWithoutAuthModal.some((path) => window.location.pathname.includes(path));
                    }),
                    delay(100), // Auth modal component has 100ms delay before call height update
                    switchMap(() => {
                        this._modalsService.show({
                            type: 'auth',
                        });

                        return [actions.MemberAuthorizationSetStep({ step: OLO.Enums.AUTH_STEP.BIRTHDAY_REWARDS })];
                    }),
                );
            }),
        ),
    );

    public triggerRequestAccountBalanceForMemberAfterSuccessTransaction$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.PaymentStepComplete),
            filter(() => this._config.payments.accountCharge?.enabled === true),
            withLatestFrom(this._store.pipe(select(selectors.isMemberAuthorizedJWT)), this._store.pipe(select(selectors.isAccountChargeSelected))),
            switchMap(([, isMemberAuthorized, hasPaidWithAccountCharge]) => {
                if (isMemberAuthorized && hasPaidWithAccountCharge) return of(actions.MemberAccountBalanceRequest({}));

                return [];
            }),
        ),
    );

    public triggerRequestAccountBalanceAndPointsForMemberWhenAuthorized$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberDataSuccessRequest, actions.MemberJwtDataResponseSuccess),
            filter(() => this._config.payments.accountCharge?.enabled === true),
            auditTime(100),
            withLatestFrom(this._store.pipe(select(selectors.isMemberAuthorizedJWT)), this._store.pipe(select(selectors.getMemberState))),
            switchMap(([, isAuthorized, state]) => {
                const actionsToReturn = [];
                if (!state.data?.HasAccount || state.accountBalance.hasSucceeded === true || !isAuthorized) {
                    return actionsToReturn;
                }
                actionsToReturn.push(actions.MemberAccountBalanceRequest({ memberId: state.data.MemberId }), actions.MemberGetPointsRequest());

                return actionsToReturn;
            }),
        ),
    );

    public triggerRequestMemberRedeemablePointsWhenAuthorized$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberDataSuccessRequest, actions.MemberJwtDataResponseSuccess),
            filter(() => this._config.payments.payByPoints?.enabled === true),
            auditTime(100),
            withLatestFrom(this._store.pipe(select(selectors.isMemberAuthorizedJWT)), this._store.pipe(select(selectors.getMemberState))),
            switchMap(([, isAuthorized, state]) => {
                if (!state.data?.HasAccount || state.redeemablePoints.hasSucceeded || !isAuthorized) {
                    return [];
                }

                return [actions.MemberGetRedeemablePointsRequest()];
            }),
        ),
    );

    public requestAccountBalance$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberAccountBalanceRequest),
            switchMap(() =>
                this._membersService.apiGetMemberAccountBalance().pipe(
                    map((payload) => actions.MemberAccountBalanceSuccessRequest({ payload })),
                    catchError((ex) => {
                        console.error('Request account balance error', ex);

                        return of(actions.MemberAccountBalanceErrorRequest({ ex }));
                    }),
                ),
            ),
        ),
    );

    public requestMemberRedeemablePoints$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberAccountBalanceSuccessRequest, actions.MemberGetRedeemablePointsRequest, actions.PaymentStepComplete),
            switchMap(() => this._store.pipe(select(selectors.isMemberAuthorizedJWT))),
            switchMap((isAuthorized) => {
                if (isAuthorized) {
                    return this._membersService.apiGetMemberRedeemablePoints().pipe(
                        map((payload) => actions.MemberGetRedeemablePointsRequestSuccess({ payload })),
                        catchError((ex) => {
                            console.error('Request redeemable points error', ex);

                            return [actions.MemberGetRedeemablePointsRequestError({ ex })];
                        }),
                    );
                }

                return [];
            }),
        ),
    );

    public jwtSetData$: Observable<boolean> = createEffect(
        () =>
            this._actions$.pipe(
                ofType(actions.MemberJwtSetData),
                switchMap((state) => this._jwtService.setJWToken(state.jwt)),
            ),
        { dispatch: false },
    );

    public validateGuestDetails$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberGuestDataRequest),
            switchMap(({ login, loginType }) =>
                this._membersService.validateLogin(login, loginType).pipe(
                    map((payload) => actions.MemberGuestDataSuccessRequest({ login, loginType, payload })),
                    catchError((ex) => {
                        console.error('Unable to validate guest details', ex);

                        return [actions.MemberGuestDataErrorRequest({ login, loginType, ex })];
                    }),
                ),
            ),
        ),
    );

    public getMemberPoints$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberGetPointsRequest),
            switchMap(() =>
                this._membersService.apiGetPoints().pipe(
                    map((payload) => actions.MemberGetPointsRequestSuccessRequest({ points: payload })),
                    catchError((ex) => {
                        console.error('Unable to validate guest details', ex);

                        return [actions.MemberGetPointsRequestErrorRequest({ ex })];
                    }),
                ),
            ),
        ),
    );

    public getSignupFormFields$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberGetSignupFormFieldsRequest),
            switchMap(() =>
                this._membersService.apiGetMemberFormFields(0).pipe(
                    map((payload) => actions.MemberGetSignupFormFieldsSuccessRequest({ formFields: payload })),
                    catchError((ex) => {
                        console.error('Request signup member form error', ex);

                        return [actions.MemberGetSignupFormFieldsErrorRequest({ ex })];
                    }),
                ),
            ),
        ),
    );

    public getUpdateMemberFormFields$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberGetUpdateMemberFormFieldsRequest),
            switchMap(() =>
                this._membersService.apiGetMemberFormFields(1).pipe(
                    map((payload) => actions.MemberGetUpdateMemberFormFieldsSuccessRequest({ formFields: payload })),
                    catchError((ex) => {
                        console.error('Request update member form error', ex);

                        return [actions.MemberGetUpdateMemberFormFieldsErrorRequest({ ex })];
                    }),
                ),
            ),
        ),
    );

    public onGetMemberInterface$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberGetMemberInterfaceRequest),
            switchMap((action) =>
                this._loyaltyAppService.apiGetMemberInterface(action.locationNo).pipe(
                    switchMap((payload) => [
                        actions.MemberGetMemberInterfaceSuccessRequest({ locationNo: action.locationNo, payload }),
                        actions.MemberGetMemberInterfaceUserDataRequest(),
                    ]),
                    catchError((ex) => {
                        console.error('Request get member interface error', ex);

                        return [actions.MemberGetMemberInterfaceErrorRequest({ locationNo: action.locationNo, ex })];
                    }),
                ),
            ),
        ),
    );

    public apiGetMemberInterfaceUserData$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberGetMemberInterfaceUserDataRequest),
            switchMap((action) =>
                this._membersService.apiGetMemberInterfaceUserData().pipe(
                    map((payload) => actions.MemberGetMemberInterfaceUserDataSuccessRequest({ payload })),
                    catchError((ex) => {
                        console.error('Request get member interface error', ex);

                        return [actions.MemberGetMemberInterfaceUserDataErrorRequest({ ex })];
                    }),
                ),
            ),
        ),
    );

    public onValidateMemberInterface$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberValidateMemberInterfaceRequest),
            switchMap((action) =>
                this._membersService.apiValidateMemberInterface(action.data).pipe(
                    map((payload) => actions.MemberValidateMemberInterfaceSuccessRequest({ status: payload.VerificationStatus })),
                    catchError((ex) => {
                        console.error('Request validate member interface error', ex);

                        return [actions.MemberValidateMemberInterfaceErrorRequest({ ex })];
                    }),
                ),
            ),
        ),
    );

    private _clearPasswordForQuickLogin(): void {
        Utils.Storage.remove(OLO.Enums.USER_STORAGE.PASSWORD, 'sessionStorage');
    }

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: OLO.Config,
        private _actions$: Actions,
        private _store: Store<OLO.State>,
        private _membersService: Services.MembersService,
        private _authService: Services.AuthService,
        private _sessionService: Services.SessionService,
        private _jwtService: Services.JWTService,
        private _modalsService: Services.ModalsService,
        private _returningMemberService: ReturningMemberService,
        private _cleanService: Services.CleanService,
        private _loyaltyAppService: Services.LoyaltyAppService,
    ) {}
}
