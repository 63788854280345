import { Injectable, Inject } from '@angular/core';
import { Store } from '@ngrx/store';

import * as actions from '@shared/state/actions';

import * as Tokens from '@shared/core/tokens';
import * as Utils from '@shared/core/utils';

import { CryptoService } from './crypto.shared.service';
import { LocationsService } from './locations.shared.service';
import { AppSettingsService } from './app-settings.shared.service';
import { VenueImagesService } from './venue-images.shared.service';
import { PaymentsService } from './payments.shared.service';
import { GoogleApiService } from './google';
import { OrderTypeService } from './order-type.shared.service';
import { VirtualLocationsService } from './virtual-locations.shared.service';
import { MemberSignupPolicyService } from './member-signup-policy.shared.service';

@Injectable({
    providedIn: 'root',
})
export class InitService {
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) protected _config: OLO.Config,
        @Inject(Tokens.STATIC_TEXT_TOKEN) public readonly t: T.StaticTexts,
        protected _store: Store<OLO.State>,
        protected _cryptoService: CryptoService,
        protected _locationsService: LocationsService,
        protected _appSettingsService: AppSettingsService,
        protected _venueImagesService: VenueImagesService,
        protected _googleApiService: GoogleApiService,
        protected _paymentsService: PaymentsService,
        protected _orderTypeService: OrderTypeService,
        protected _virtualLocationsService: VirtualLocationsService,
        protected _memberSignupPolicyService: MemberSignupPolicyService,
    ) {}

    public async unsetSplashLoader(): Promise<boolean> {
        return true;
    }

    public async init(cleanSessionRedirect: boolean = false): Promise<boolean> {
        return new Promise((resolve) => {
            if (this._config.payments.googlePay.enabled) {
                this._paymentsService.googlePayPaymentProviderService.addHtmlElementsToDOM();
            }
            /**
             * Add Adyen Script & Link elements to DOM
             */
            if (this._config.payments.baseProvider === OLO.Enums.PAYMENT_PROVIDER.ADYEN) {
                this._paymentsService.adyenPaymentProviderService.addHtmlElementsToDOM();
            }

            /**
             * Add Stripe Script
             */
            if (this._config.payments.baseProvider === OLO.Enums.PAYMENT_PROVIDER.STRIPE) {
                this._paymentsService.stripePaymentProviderService.addHtmlElementsToDOM();
            }

            /**
             * Add FatZebra 3DS Script
             */
            if (this._config.payments.baseProvider === OLO.Enums.PAYMENT_PROVIDER.FAT_ZEBRA) {
                this._paymentsService.fatZebra3DSPaymentProviderService.addHtmlElementsToDOM();
            }

            /**
             * Set base title for whole app getting data from config - base title is set in config file
             * This is slow solution. Base title should be set directly in index.html file.
             */
            if (this._config.title) {
                Utils.Meta.setTags({ title: this._config.title });
            }

            /**
             * Some payment methods require redirecting and restoring state
             */
            if (Utils.Redirect.isRedirecting() && !cleanSessionRedirect) {
                return resolve(false);
            }
            Utils.Redirect.unsetRedirectAsync();
            Utils.Storage.remove(OLO.Enums.SESSION_STORAGE.STATE, 'sessionStorage');

            /**
             * Check for session data
             */
            this._store.dispatch(actions.MemberSessionRequest());

            /**
             * Set app mode and device type
             */
            this._appSettingsService.init();

            /** Get venue image if neede */
            if (this._config.venue && this._config.venue.id) {
                this._venueImagesService.requestVenueImage();
            }

            /**
             * Restore current pickup time
             */
            try {
                const pickupTime: string = Utils.Storage.getItem(OLO.Enums.USER_STORAGE.CURRENT_PICKUP_TIME);

                if (pickupTime) {
                    let json: OLO.Ordering.PickupTime = JSON.parse(pickupTime as unknown as string);
                    json.PlaceOrderTimeout = new Date(json.PlaceOrderTimeout);
                    json.Date = new Date(json.Date);

                    if (json.TimeSpan) {
                        json.TimeSpan = {
                            startDate: new Date(json.TimeSpan.startDate),
                            endDate: new Date(json.TimeSpan.endDate),
                        };
                    }

                    if (json.Date < new Date()) {
                        Utils.Storage.remove(OLO.Enums.USER_STORAGE.CURRENT_PICKUP_TIME);
                    } else {
                        this._store.dispatch(actions.CurrentLocationPickupTimeRestore(json));
                    }
                }
            } catch (ex) {
                console.error('Unable to restore current pickup time');
            }

            /**
             * Load saved cart contents
             */
            try {
                const cartData: string = Utils.Storage.getItem(OLO.Enums.CART_STORAGE.DATA);

                if (cartData) {
                    let json: OLO.State.Cart = JSON.parse(this._cryptoService.decrypt(cartData as string));
                    if (!json) throw new Error('Bad cart data');
                    this._store.dispatch(actions.CartLoad({ cart: json }));
                }
            } catch (ex) {
                Utils.Storage.remove(OLO.Enums.CART_STORAGE.DATA as unknown as string);
                console.error("Unable to restore cart's contents");
            }

            /**
             * Set previously saved locationNo
             */
            const currentLocationNo: string = Utils.Storage.getItem(OLO.Enums.USER_STORAGE.CURRENT_LOCATION as unknown as string);
            if (currentLocationNo && !Number.isNaN(+currentLocationNo)) {
                this._store.dispatch(actions.CurrentLocationSet({ locationNo: +currentLocationNo }));
            }

            /**
             * Request virtual locations
             */
            if (this._config.virtualLocations.enabled) {
                this._store.dispatch(
                    actions.LocationsFiltersSetBrand({
                        Id: -1,
                        Name: this.t.common.all,
                    }),
                );
                this._virtualLocationsService.requestVirtualLocations();
            }

            /**
             * Request locations
             */
            this._locationsService.requestLocations();

            /* Init saved history orders */
            const historyOrdersData: string = Utils.Storage.getItem(OLO.Enums.HISTORY_ORDERS_STORAGE.DATA);
            const setDefaultStringToStorage = () => Utils.Storage.set(OLO.Enums.HISTORY_ORDERS_STORAGE.DATA, this._cryptoService.encrypt('[]'));

            if (!historyOrdersData) {
                setDefaultStringToStorage();
            } else {
                try {
                    JSON.parse(this._cryptoService.decrypt(historyOrdersData));
                } catch {
                    setDefaultStringToStorage();
                }
            }
            /**
             * Request hiddenLocations
             */
            if (this._config.hiddenLocations.enabled) {
                this._locationsService.requestHiddenLocations();
            }

            /**
             * Request Member signup policy
             */
            this._memberSignupPolicyService.requestMemberSignupPolicy();

            /**
             * Set collection type from localstorage if it exist
             */
            this._orderTypeService.setCollectionTypeFromLocalStorage();

            /**
             * Confirm application initializations
             */
            setTimeout(() => {
                this._appSettingsService.setInitAppInitFlag();
                resolve(true);
            }, 0);

            /**
             * Warn if "signUpRewardsAccountLinking" is enabled
             */
            if (this._config.signUpRewardsAccountLinking.enabled)
                console.warn('WARNING! "signUpRewardsAccountLinking" is currently not supported. It will be re-added in the future. See more under TOLO-2767');
        });
    }
}
