import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './reorder.actions';

const initialState: OLO.State.Reorder[] = [];

export const reorderReducerFn = createReducer(
    initialState,
    on(actions.ReorderUnmount, (state, action) => {
        if (action.orderId) return state.filter((obj) => obj.OrderId !== action.orderId);

        return [];
    }),
    on(actions.ReorderDeselectAll, (state, action) =>
        state.map((obj) => {
            if (obj.OrderId === action.orderId) {
                return {
                    ...obj,
                    data: !obj.data
                        ? null
                        : {
                              ...obj.data,
                              cart: !obj.data.cart
                                  ? null
                                  : {
                                        ...obj.data.cart,
                                        itemsMenuFlow: obj.data.cart.itemsMenuFlow.map((item) => ({
                                            ...item,
                                            _IsSelected: false,
                                        })),
                                        itemsSimple: obj.data.cart.itemsSimple.map((item) => ({
                                            ...item,
                                            _IsSelected: false,
                                        })),
                                    },
                          },
                };
            }

            return obj;
        }),
    ),
    on(actions.ReorderSelectItem, (state, action) =>
        state.map((obj) => {
            if (obj.OrderId === action.orderId) {
                let type = 'itemsMenuFlow';
                if (!action.item.MenuFlowId) {
                    type = 'itemsSimple';
                }

                let id = action.item._Id;

                return {
                    ...obj,
                    data: {
                        ...obj.data,
                        cart: {
                            ...obj.data.cart,
                            [type]: obj.data.cart[type].map((objItem) => {
                                if (objItem._Id === id) {
                                    return {
                                        ...objItem,
                                        _IsSelected: true,
                                        UnitPrice: action.item.UnitPrice,
                                    };
                                }

                                return objItem;
                            }),
                        },
                    },
                };
            }

            return obj;
        }),
    ),
    on(actions.ReorderDeselectItem, (state, action) =>
        state.map((obj) => {
            if (obj.OrderId === action.orderId) {
                let type = 'itemsMenuFlow';
                if (!action.item.MenuFlowId) {
                    type = 'itemsSimple';
                }

                let id = action.item._Id;

                return {
                    ...obj,
                    data: {
                        ...obj.data,
                        cart: {
                            ...obj.data.cart,
                            [type]: obj.data.cart[type].map((objItem) => {
                                if (objItem._Id === id) {
                                    return {
                                        ...objItem,
                                        _IsSelected: false,
                                        UnitPrice: action.item.UnitPrice,
                                    };
                                }

                                return objItem;
                            }),
                        },
                    },
                };
            }

            return obj;
        }),
    ),
    on(actions.ReorderCalculateRequest, (state, action) => {
        const foundReorder = state.find((obj) => obj.LocationNo === action.locationNo && obj.OrderId === action.orderId && obj.PickupTime.Id === action.pickupTime.Id);
        if (foundReorder) {
            return state.map((obj) => {
                if (obj.LocationNo === action.locationNo && obj.OrderId === action.orderId && obj.PickupTime.Id === action.pickupTime.Id) {
                    return {
                        ...obj,
                        isDownloading: true,
                        hasFailed: false,
                        hasSucceeded: false,
                    };
                }

                return obj;
            });
        }

        return [
            ...state,
            {
                LocationNo: action.locationNo,
                OrderId: action.orderId,
                PickupTime: action.pickupTime,
                isDownloading: true,
                hasFailed: false,
                hasSucceeded: false,
                data: {
                    virtualLocationOnlineMenus: null,
                    onlineMenu: null,
                    menuFlowsDetails: [],
                    ingredients: [],

                    errors: null,
                    cart: null,
                },
            },
        ];
    }),
    on(actions.ReorderCalculateSuccessRequest, (state, action) =>
        state.map((obj) => {
            if (obj.LocationNo === action.locationNo && obj.OrderId === action.orderId && obj.PickupTime.Id === action.pickupTime.Id) {
                return {
                    ...obj,
                    isDownloading: false,
                    hasSucceeded: true,
                    hasFailed: false,
                    data: { ...action.payload },
                };
            }

            return obj;
        }),
    ),
    on(actions.ReorderCalculateErrorRequest, (state, action) =>
        state.map((obj) => {
            if (obj.LocationNo === action.locationNo && obj.OrderId === action.orderId && obj.PickupTime.Id === action.pickupTime.Id) {
                return {
                    ...obj,
                    isDownloading: false,
                    hasSucceeded: false,
                    hasFailed: true,
                };
            }

            return obj;
        }),
    ),
);

export function reorderReducer(state: OLO.State.Reorder[] | undefined, action: Action) {
    return reorderReducerFn(state, action);
}
