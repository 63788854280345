import { Injectable } from '@angular/core';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as actions from '../actions';

import * as Services from '@shared/core/services';

import { Observable, of } from 'rxjs';
import { switchMap, catchError, map } from 'rxjs/operators';

@Injectable()
export class MemberCardBarcodesImagesEffects {
    public requestMemberBarcodeImage$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberCardBarcodesImagesRequest),
            switchMap(({ memberId, barcodeType }) =>
                this._imagesService.getMemberCardBarcode(memberId, { width: window.innerWidth, height: window.innerHeight }, barcodeType).pipe(
                    map((payload) => actions.MemberCardBarcodesImagesSuccessRequest({ memberId, barcodeType, payload })),
                    catchError((ex) => {
                        console.error('getMemberCardBarcode error', ex);

                        return of(actions.MemberCardBarcodesImagesErrorRequest({ memberId, barcodeType, ex }));
                    }),
                ),
            ),
        ),
    );

    constructor(private _actions$: Actions, private _imagesService: Services.ImagesService) {}
}
