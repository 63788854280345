import { createSelector } from '@ngrx/store';
import * as Utils from '@shared/core/utils';
import { getOnlineMenuVirtualLocationsDownloaded } from '@shared/state/onlineMenuVirtualLocations/selectors';
import { getOnlineMenuFiltersSearchInput } from '@shared/state/onlineMenuFilters/selectors';

export const getOnlineMenuVirtualLocationsFiltered = createSelector(
    getOnlineMenuVirtualLocationsDownloaded,
    getOnlineMenuFiltersSearchInput,
    (onlineMenuVirtualLocations, searchInput) => {
        const filteredOnlineMenuVirtualLocations = onlineMenuVirtualLocations?.map((virtualLocation) => {
            const pages = virtualLocation?.data?.Pages || null;
            if (!searchInput || !pages) {
                return virtualLocation;
            }

            // TODO: consinder to move these logic to separate file, because it share logic with online menu pages filtered selector
            return {
                ...virtualLocation,
                data: {
                    ...virtualLocation.data,
                    Pages: virtualLocation.data.Pages.reduce((pagesAcc, page) => {
                        if (page.Products.length === 0) return pagesAcc;

                        /* Page check */
                        const pageMatches = Utils.Strings.searchValueStringInObject(searchInput, page, 'Name', 'Description');
                        if (pageMatches) {
                            if (page.Products.length) {
                                pagesAcc.push(page);
                            }

                            return pagesAcc;
                        }

                        /* Check products */
                        const productsFiltered = page.Products.filter((product) => {
                            const productMatches = Utils.Strings.searchValueStringInObject(searchInput, product, 'PosDisplay', 'PosDescription');
                            if (productMatches) return true;

                            /* Check tags if no match in prop values */
                            return !product.Tags ? false : product.Tags.some((t) => Utils.Strings.searchValueStringInObject(searchInput, t, 'Name'));
                        });

                        if (productsFiltered.length) {
                            pagesAcc.push({
                                ...page,
                                Products: productsFiltered,
                            });
                        }

                        return pagesAcc;
                    }, [] as OLO.DTO.OnlineMenuPageResponseModel[]),
                },
            };
        });

        return filteredOnlineMenuVirtualLocations?.sort((a, b) => (a.displayIndex > b.displayIndex ? 1 : -1)) || null;
    },
);
