import { CryptoService } from '../services/crypto.shared.service';
import { Strings } from './strings.utils';

export class Storage {
    private static _cryptoService = new CryptoService();

    public static set(name: string, value: number | null | undefined | string | { [key: string]: any } | any[], storageType: string = 'localStorage'): boolean {
        try {
            window[storageType].setItem(name, Strings.toString(value));

            return true;
        } catch (ex) {
            console.error(ex);

            return false;
        }
    }

    public static getItem(name: string, storageType: string = 'localStorage'): string | null {
        try {
            return window[storageType].getItem(name);
        } catch (ex) {
            console.error(ex);

            return null;
        }
    }

    public static getEncryptedItem(name: string, storageType: string = 'localStorage'): string | object | null {
        let item = this.getItem(name, storageType);
        let encryptedItem = this._decryptAndParseItem(item);

        return encryptedItem;
    }

    private static _decryptAndParseItem(item: string): string | null {
        try {
            if (item) {
                const decryptedItem = this._cryptoService.decrypt(item);
                if (decryptedItem) {
                    return JSON.parse(decryptedItem);
                }
            }

            return null;
        } catch (e) {
            console.error(e);

            return null;
        }
    }

    public static remove(name: string, storageType: string = 'localStorage'): boolean {
        try {
            window[storageType].removeItem(name);

            return true;
        } catch (ex) {
            console.error(ex);

            return false;
        }
    }

    public static clear(storageType: string = 'localStorage'): boolean {
        try {
            window[storageType].clear();

            return true;
        } catch (ex) {
            console.error(ex);

            return false;
        }
    }

    public static clearByCategory(category: string, storageType: string = 'localStorage'): boolean {
        try {
            const keys = Object.keys(window[storageType]);
            keys.forEach((key: string) => {
                if (key.startsWith(category)) {
                    window[storageType].removeItem(key);
                }
            });

            return true;
        } catch (ex) {
            console.error(ex);

            return false;
        }
    }
}
