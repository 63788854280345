import { createSelector } from '@ngrx/store';

import { getCartTotalQuantity } from '../getCartTotalQuantity';
import { getCartTotalValueAfterDiscounts } from '../getCartTotalValueAfterDiscounts';
import { getOnlineOrderState } from '@shared/state/onlineOrder/selectors';

export const isCartZeroPrice = createSelector(
    getCartTotalQuantity,
    getCartTotalValueAfterDiscounts,
    getOnlineOrderState,
    (cartTotalQuantity, cartTotalValueAfterDiscounts, getOnlineOrder) => Boolean(cartTotalQuantity) && !cartTotalValueAfterDiscounts && getOnlineOrder.data?.TotalLeftToPay === 0,
);
