export * from './commonSelectors';
export * from './alertMessages/alert-messages.selectors';
export * from './appSettings/selectors';
export * from './availablePickups/selectors';
export * from './cart/selectors';
export * from './cartPopup/selectors';
export * from './collectionType/selectors';
export * from './creditCards/selectors';
export * from './currentLocation/selectors';
export * from './dietaryTagsImages/selectors';
export * from './freeProductsImages/selectors';
export * from './geolocation/selectors';
export * from './google/selectors';
export * from './historyOrders/selectors';
export * from './ingredients/selectors';
export * from './latestTransactions/selectors';
export * from './loader/loader.selectors';
export * from './locations/selectors';
export * from './locationsFilters/selectors';
export * from './locationsImages/selectors';
export * from './loyaltyIntroductionPages/selectors';
export * from './loyaltyIntroductionPagesImages/selectors';
export * from './loyaltyMessages/selectors';
export * from './loyaltyProductPrograms/selectors';
export * from './loyaltyPrograms/selectors';
export * from './memberCardBarcodesImages/selectors';
export * from './members/selectors';
export * from './menuFlowDefaultActivations/selectors';
export * from './menuFlowImages/selectors';
export * from './menuFlows/selectors';
export * from './modals/selectors';
export * from './onlineMenu/selectors';
export * from './onlineMenuFilters/selectors';
export * from './onlineMenuPagesImages/selectors';
export * from './onlineMenuProductsImages/selectors';
export * from './onlineMenuVirtualLocations/selectors';
export * from './onlineOrder/selectors';
export * from './orderTypes/selectors';
export * from './orderTypesImages/selectors';
export * from './payment/selectors';
export * from './productImages/selectors';
export * from './productRestrictions/selectors';
export * from './reorder/selectors';
export * from './router/selectors';
export * from './venuesImages/selectors';
export * from './wizzard/selectors';
export * from './virutalLocations/selectors';
export * from './memberSignupPolicy/selectors';

export const getState = (state: OLO.State): OLO.State => state;
