import { Injectable } from '@angular/core';
import { Action, Store, select } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';
import * as Services from '@shared/core/services';

import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError, switchMap, withLatestFrom, first } from 'rxjs/operators';

@Injectable()
export class MenuFlowImagesEffects {
    public onOnlineMenuSuccessRequest$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.OnlineMenuPagesSuccessRequest),
            withLatestFrom(this._store.pipe(select(selectors.getMenuFlowImagesState), first())),
            switchMap(([action, images]) => {
                /* Get regular images for menu flow */
                const Pages: OLO.DTO.OnlineMenuPageResponseModel[] = action.payload.Pages;
                const menuFlowIds: number[] = Pages.reduce((acc, page) => {
                    const filterMenuFlowsIds: number[] = page.Products.filter((item) => item.MenuFlowId && !item.ProductId).map((item) => item.MenuFlowId);

                    return acc.concat(filterMenuFlowsIds);
                }, []);
                const filtered: number[] = menuFlowIds.filter(
                    (menuFlowId) => !images.find((image) => image.MenuFlowId === menuFlowId && (image.isDownloading || image.hasSucceeded)),
                );

                return of(actions.MenuFlowImagesRequest({ params: { width: 150, height: 150 }, menuFlowIds: filtered }));
            }),
        ),
    );

    /* Move this to wizzard */
    public onRequestMenuFlowImages$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MenuFlowImagesRequest),
            mergeMap(({ params, menuFlowIds }) => {
                if (menuFlowIds.length === 0) return [actions.MenuFlowImagesSuccessRequest({ payload: [], menuFlowIds: [] })];

                return this._imagesService.getMenuFlowImages(params, ...menuFlowIds).pipe(
                    map((payload: OLO.DTO.ImageUrlModel[]) => actions.MenuFlowImagesSuccessRequest({ payload, menuFlowIds })),
                    catchError((ex) => of(actions.MenuFlowImagesErrorRequest({ ex, menuFlowIds }))),
                );
            }),
        ),
    );

    constructor(private _actions$: Actions, private _imagesService: Services.ImagesService, private _store: Store<OLO.State>) {}
}
