import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './online-menu-filters.actions';

const initialState: OLO.State.OnlineMenuFilters = {
    search: null,
    locationNo: null,
};

export const onlineMenuFiltersReducerFn = createReducer(
    initialState,
    on(actions.OnlineMenuFiltersReset, (state, action) => JSON.parse(JSON.stringify(initialState))),
    on(actions.OnlineMenuFiltersSetSearch, (state, action) => ({
        ...state,
        search: action.search,
    })),
    on(actions.OnlineMenuFiltersSetLocationNo, (state, action) => ({
        ...state,
        locationNo: action.locationNo,
    })),
);

export function onlineMenuFiltersReducer(state: OLO.State.OnlineMenuFilters | undefined, action: Action) {
    return onlineMenuFiltersReducerFn(state, action);
}
