import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { MembersEffects } from './members.effects';
import { ReturningMemberModalEffects } from './returning-member-modal.effects';
import { memberReducer } from './members.reducer';

@NgModule({
    declarations: [],
    imports: [CommonModule, StoreModule.forFeature('members', memberReducer), EffectsModule.forFeature([MembersEffects, ReturningMemberModalEffects])],
})
export class MembersStateModule {}
