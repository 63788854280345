/**
 * Helps to create OnlineOrderDeliveryDetailsBusinessModel and get parts of address
 */
export class DeliveryAddressModel {
    constructor(private _address: APICommon.GooglePlaceDetails) {
        if (!_address || typeof _address === 'string') return;
    }

    public getDeliveryDetailsBusinessModel(pickupDate: string, nextTick: number): OLO.DTO.OnlineOrderDeliveryDetailsBusinessModel {
        return {
            Street: this.getStreet(),
            Building: this.getBuildingNo(),
            Unit: this.getAptNo(),
            City: this.getCity(),
            PostCode: this.getPostCode(),
            Latitude: this.getLatitude(),
            Longitude: this.getLongitude(),
            ExtraInstructions: this.getExtraInstructions(),
            DesiredDeliveryTime: pickupDate,
            EstimatedDeliveryTime: nextTick,
        };
    }

    public generateAddressString(include: { street: boolean; buildingNo: boolean; aptNo: boolean }): string {
        let formattedAddress = '';
        const street = this.getStreet();
        const buildingNo = this.getBuildingNo();
        const aptNo = this.getAptNo();
        const city = this.getCity();

        if (buildingNo && include?.buildingNo) formattedAddress += `${buildingNo}, `;
        if (aptNo && include?.aptNo) formattedAddress += `${aptNo}, `;
        if (street && include?.street) formattedAddress += `${street}, `;
        if (city) formattedAddress += `${city}`;

        return formattedAddress;
    }

    public getStreet(): string {
        return this._address?.details?.address_components?.find((comp) => comp.types.includes('route'))?.long_name;
    }

    public getBuildingNo(): string {
        return this._address?.details?.address_components?.find((comp) => comp.types.includes('street_number'))?.long_name;
    }

    public getAptNo(): string {
        return this._address?._ApartmentNo?.toString();
    }

    public getCity(): string {
        return this._address?.details?.address_components?.find((comp) => comp.types.includes('locality'))?.long_name;
    }

    public getPostCode(): string {
        return this._address?.details?.address_components?.find((comp) => comp.types.includes('postal_code'))?.long_name;
    }

    public getLatitude(): number {
        return this._address?.geometry?.lat;
    }

    public getLongitude(): number {
        return this._address?.geometry?.lng;
    }

    public getExtraInstructions(): string {
        return this._address?._Instructions;
    }
}
