import { Injectable } from '@angular/core';

import { RouteService } from './route.shared.service';

import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { filter, auditTime } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class WizzardService {
    private _stepThroughState$: BehaviorSubject<Nullable<OLO.Components.WizzardStepThroughNextStateDetails>> = new BehaviorSubject<
        Nullable<OLO.Components.WizzardStepThroughNextStateDetails>
    >(null);
    private _onContentScrollInit$: Subject<HTMLDivElement> = new Subject();
    private _onContentScroll$: Subject<Event> = new Subject();
    public _navClickListener$: BehaviorSubject<Nullable<number>> = new BehaviorSubject<Nullable<number>>(null);

    constructor(public routeService: RouteService) {}

    public get navClickListener$(): Observable<Nullable<number>> {
        return this._navClickListener$.asObservable();
    }

    public set navClickListener(index: number) {
        this._navClickListener$.next(index);
    }

    /* When scroll initialize - move this to separate service */
    public onWizzardScrollInit($event: HTMLDivElement): void {
        this._onContentScrollInit$.next($event);
    }

    public get contentScrollInitListener(): Observable<HTMLDivElement> {
        return this._onContentScrollInit$;
    }

    /* When scrolling TODO - move this to separate service */
    public onWizzardScrollContent($event: Event): void {
        this._onContentScroll$.next($event);
    }

    public get contentScrollListener$(): Subject<Event> {
        return this._onContentScroll$;
    }

    public validateWizzardItems(menuFlow: Nullable<OLO.DTO.MenuFlowDetailsModel>, wizzardMenuFlow: Nullable<OLO.State.Wizzard.WizzardMenuFlow>): OLO.State.Wizzard.WizzardError[] {
        //
        //  Validate current wizzardMenuFlow
        //  It is used in different contexts and ways so it's just a method - not effect
        //
        const errorId = (): number => new Date().getTime() + Math.floor(Math.random() * 10000);
        const errors: OLO.State.Wizzard.WizzardError[] = [];

        wizzardMenuFlow?.Pages?.forEach((wizzardPage) => {
            const page = menuFlow?.Pages?.find((p) => p.PageIdentifier === wizzardPage.PageIdentifier);
            if (!page) {
                return;
            }
            const { PageMinQuantity, PageMaxQuantity } = page;
            const wizzardPageTotalQuantity = wizzardPage!.Products?.reduce((acc, product) => (acc += product.Quantity || 0), 0) || 0;

            if (PageMinQuantity) {
                if (wizzardPageTotalQuantity < PageMinQuantity) {
                    errors.push({
                        id: errorId(),
                        pageIdentifier: wizzardPage.PageIdentifier || null,
                        error: OLO.State.Wizzard.WIZZARD_ERROR.REQUIRED,
                    });
                }
            }

            if (PageMaxQuantity) {
                if (wizzardPageTotalQuantity > PageMaxQuantity) {
                    console.warn('Warning! Menu Flow setup issue - Menu Page Maximum Items exceeded. Please check Maximum Items and Kiosk Qty fields and their correctness.');

                    errors.push({
                        id: errorId(),
                        pageIdentifier: wizzardPage.PageIdentifier || null,
                        error: OLO.State.Wizzard.WIZZARD_ERROR.OVERFLOW,
                    });
                }
            }
        });

        return errors;
    }

    public registerStepThroughWizzardChange(nextState: OLO.Components.WizzardStepThroughNextStateDetails): void {
        this._stepThroughState$.next(nextState);
    }

    /**
     * Shows next step through state detailed information
     * @returns {OLO.Components.WizzardStepThroughRegDataObj} OLO.Components.IWizzardStepThroughRegDataObj
     */
    public get nextStepThroughState$(): Observable<OLO.Components.WizzardStepThroughNextStateDetails> {
        return this._stepThroughState$.pipe(
            filter((obj) => obj !== null),
            auditTime(10),
        );
    }
}
