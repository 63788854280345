import { createSelector } from '@ngrx/store';

import { onlineMenuVirtualLocationsState } from '../root';

export const getOnlineMenuVirtualLocationsDownloaded = createSelector(onlineMenuVirtualLocationsState, (onlineMenuVirtualLocations) => {
    if (onlineMenuVirtualLocations?.length && onlineMenuVirtualLocations.every((virtualLocation) => virtualLocation.hasSucceeded)) {
        return onlineMenuVirtualLocations;
    }

    return null;
});
