import { v4 } from 'uuid';

export class ProductsMapper {
    public static mapIngredientsGETResponse(data: APIv3.PaginatedListProductRecipeIngredientModel): OLO.DTO.PaginatedListProductRecipeIngredientModel {
        return data
            ? {
                  PageNo: data.PageNo,
                  PageSize: data.PageSize,
                  Items: data.Items
                      ? data.Items.map((item: APIv3.ProductRecipeIngredientModel) => ({
                            RecipeId: item.RecipeId,
                            ProductId: item.ProductId,
                            ProductPLU: item.ProductPLU,
                            IngredientId: item.IngredientId,
                            IngredientPLU: item.IngredientPLU,
                            Description: item.Description,
                            Quantity: item.Quantity,
                            POSDisplay: item.POSDisplay,
                            OnlineName: item.OnlineName,
                            CategoryId: item.CategoryId,
                            IsActive: item.IsActive,
                            Modifiers: item.Modifiers
                                ? item.Modifiers.map((modifier: APIv3.ProductModifier) => ({
                                      ProductId: modifier.ProductId,
                                      ModifierId: modifier.ModifierId,
                                      Modifier: modifier.Modifier,
                                  }))
                                : null,
                            DisplayIndex: item.DisplayIndex,
                            IsOptional: item.IsOptional,
                        }))
                      : null,
              }
            : null;
    }

    public static mapIngredientsForLocationGETResponse(data: APIv3.ProductWithIngredientsModel[], locationNo: number): OLO.DTO.ProductWithIngredientsModel[] {
        return (
            data?.map((product: APIv3.ProductWithIngredientsModel) => ({
                Ingredients: product.Ingredients
                    ? product.Ingredients.map((ingredient: APIv3.ProductIngredient) => ({
                          Modifiers: ingredient.Modifiers
                              ? ingredient.Modifiers.map((modifier: APIv3.IngredientModifier) => ({
                                    ModifierID: modifier.ModifierID,
                                    ModifierName: modifier.ModifierName,
                                    ExtraPrice: modifier.ExtraPrice,
                                }))
                              : null,
                          Swaps: ingredient.Swaps
                              ? ingredient.Swaps.map((swap: APIv3.IngredientSwap) => ({
                                    Modifiers: swap.Modifiers
                                        ? swap.Modifiers.map((modifier: APIv3.IngredientModifier) => ({
                                              ModifierID: modifier.ModifierID,
                                              ModifierName: modifier.ModifierName,
                                              ExtraPrice: modifier.ExtraPrice,
                                          }))
                                        : null,
                                    NewPLU: swap.NewPLU,
                                    Name: swap.Name,
                                    ExtraPrice: swap.ExtraPrice,
                                }))
                              : null,
                          IngredientPLU: ingredient.IngredientPLU,
                          IngredientName: ingredient.IngredientName,
                          IngredientMode: ingredient.IngredientMode,
                          ExtraPrice: ingredient.ExtraPrice,
                      }))
                    : null,
                ProductID: product.ProductID,
                PLU: product.PLU,
                ProductName: product.ProductName,
                Price: product.Price,
                _LocationNo: locationNo,
            })) || null
        );
    }

    public static mapProductRestrictionsForLocationGETResponse(data: APIv3.ProductRestrictionsResponse): OLO.DTO.ProductRestriction[] {
        return (
            data?.Restrictions?.map((restriction: APIv3.ProductRestriction) => ({
                _Id: v4(),
                CategoryIds: [...restriction.CategoryIds],
                FamilyIds: [...restriction.FamilyIds],
                ProductIds: [...restriction.ProductIds],
                MinQty: restriction.MinQty,
                MaxQty: restriction.MaxQty,
                CustomerFriendlyName: restriction.CustomerFriendlyName,
                CustomerFriendlyDescription: restriction.CustomerFriendlyDescription,
            })) || null
        );
    }
}
