import { Injectable } from '@angular/core';
import { Action, Store } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';

import * as Services from '@shared/core/services';
import * as Utils from '@shared/core/utils';

import { Observable, of, combineLatest } from 'rxjs';
import { mergeMap, map, catchError, switchMap, filter, take } from 'rxjs/operators';

@Injectable()
export class MenuFlowDefaultActivationsEffects {
    public requestDefaultActivationOnMenuFlowDetailsForCartEditItem$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.CartEditItem),
            switchMap((action) => {
                if (!action.item.MenuFlowId) return [];

                return combineLatest(
                    this._store.select(selectors.getCartOnlineMenuItemById(action.item.MenuFlowId, action.item.VirtualLocationNo)),
                    this._store.select(selectors.getAppSettingsTags),
                ).pipe(
                    filter((cartOnlineMenu) => !!cartOnlineMenu),
                    take(1),
                    switchMap(([cartOnlineMenu, appSettingsTags]) => {
                        const requireDefaultActivation: boolean = cartOnlineMenu.Tags ? Utils.Items.requireDefaultActivation(cartOnlineMenu, appSettingsTags) : true;
                        if (!requireDefaultActivation) return [];

                        return of(actions.MenuFlowDefaultActivationRequest({ menuFlowId: action.item.MenuFlowId }));
                    }),
                );
            }),
        ),
    );

    public requestDefaultActivationOnMenuFlowDetailsSuccessRequest$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MenuFlowsDetailsSuccessRequest),
            switchMap((action) =>
                combineLatest(
                    this._store.select(selectors.getOnlineMenuItemById(action.payload.MenuFlowId)),
                    this._store.select(selectors.getOnlineMenuVirtualLocationsItemById(action.virtualLocationNo, action.payload.MenuFlowId)),
                    this._store.select(selectors.getAppSettingsTags),
                ).pipe(
                    filter(([onlineMenuItem, onlineMenuVirtualLocationItem]) => onlineMenuItem !== null || onlineMenuVirtualLocationItem !== null),
                    take(1),
                    switchMap(([onlineMenuItem, onlineMenuVirtualLocationItem, appSettingsTags]) => {
                        const onlineMenu = onlineMenuItem ?? onlineMenuVirtualLocationItem;
                        const requireDefaultActivation: boolean = onlineMenu.Tags ? Utils.Items.requireDefaultActivation(onlineMenu, appSettingsTags) : true;
                        if (!requireDefaultActivation) return [];

                        return of(actions.MenuFlowDefaultActivationRequest({ menuFlowId: action.menuFlowId }));
                    }),
                ),
            ),
        ),
    );

    public onMenuFlowDefaultActivationsRequest$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MenuFlowDefaultActivationRequest),
            mergeMap(({ menuFlowId }) =>
                this._menuFlowsService.getDefaultActivation(menuFlowId).pipe(
                    map((payload) => actions.MenuFlowDefaultActivationSuccessRequest({ menuFlowId, payload })),
                    catchError((ex) => of(actions.MenuFlowDefaultActivationErrorRequest({ menuFlowId, ex }))),
                ),
            ),
        ),
    );

    constructor(private _actions$: Actions, private _menuFlowsService: Services.MenuFlowsService, private _store: Store<OLO.State>) {}
}
