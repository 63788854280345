export class ImagesMapper {
    public static mapGetImageForOrderType(imagesForOrderType: APIv3.ImageGetOrderTypeImages.Responses.$200): OLO.DTO.ImageUrlModel[] {
        return imagesForOrderType
            ? imagesForOrderType.map((img: APIv3.ImageUrlModel) => ({
                  ImageId: img.ImageId,
                  ParentId: img.ParentId,
                  ImageUrl: img.ImageUrl,
              }))
            : null;
    }

    public static mapGetImageForLocation(imageForLocation: APIv3.ImageGetLocationImage.Responses.$200): string {
        return imageForLocation ? imageForLocation : null;
    }

    public static mapGetImagesForLocationsByType(imagesForLocationsByType: APIv3.ImageGetLocationsImages.Responses.$200): OLO.DTO.ImageUrlModel[] {
        return imagesForLocationsByType
            ? imagesForLocationsByType.map((img: APIv3.ImageUrlModel) => ({
                  ImageId: img.ImageId,
                  ParentId: img.ParentId,
                  ImageUrl: img.ImageUrl,
              }))
            : null;
    }

    public static mapGetProductImages(productImages: APIv3.ImageGetProductImages.Responses.$200): OLO.DTO.ImageUrlModel[] {
        return productImages?.Items
            ? productImages?.Items.map((img: APIv3.ImageUrlModel) => ({
                  ImageId: img.ImageId,
                  ParentId: img.ParentId,
                  ImageUrl: img.ImageUrl,
              }))
            : null;
    }

    public static mapGetImagesForOnlineMenuPages(imagesForOnlineMenuPages: APIv3.ImageGetOnlineMenuPageImages.Responses.$200): OLO.DTO.ImageUrlModel[] {
        return imagesForOnlineMenuPages
            ? imagesForOnlineMenuPages.map((img: APIv3.ImageUrlModel) => ({
                  ImageId: img.ImageId,
                  ParentId: img.ParentId,
                  ImageUrl: img.ImageUrl,
              }))
            : null;
    }

    public static mapGetImagesForOnlineMenuPageProducts(imagesForOnlineMenuPageProducts: APIv3.ImageGetOnlineMenuPageProductsImages.Responses.$200): OLO.DTO.ImageUrlModel[] {
        return imagesForOnlineMenuPageProducts?.Items
            ? imagesForOnlineMenuPageProducts?.Items.map((img: APIv3.ImageUrlModel) => ({
                  ImageId: img.ImageId,
                  ParentId: img.ParentId,
                  ImageUrl: img.ImageUrl,
              }))
            : null;
    }

    public static mapGetMenuFlowImages(imagesForOnlineMenuPageProducts: APIv3.ImageGetMenuFlowsImages.Responses.$200): OLO.DTO.ImageUrlModel[] {
        return imagesForOnlineMenuPageProducts?.Items
            ? imagesForOnlineMenuPageProducts?.Items.map((img: APIv3.ImageUrlModel) => ({
                  ImageId: img.ImageId,
                  ParentId: img.ParentId,
                  ImageUrl: img.ImageUrl,
              }))
            : null;
    }

    public static mapVenueImagesByType(imagesForOnlineMenuPageProducts: APIv3.ImageGetVenuesImages.Responses.$200): OLO.DTO.ImageUrlModel[] {
        return imagesForOnlineMenuPageProducts
            ? imagesForOnlineMenuPageProducts.map((img: APIv3.ImageUrlModel) => ({
                  ImageId: img.ImageId,
                  ParentId: img.ParentId,
                  ImageUrl: img.ImageUrl,
              }))
            : null;
    }

    public static mapGetDietaryTagsImages(imageGetDietaryTagsImages: APIv3.ImageGetDietaryTagsImages.Responses.$200): OLO.DTO.ImageUrlModel[] {
        return imageGetDietaryTagsImages
            ? imageGetDietaryTagsImages.map((img: APIv3.ImageUrlModel) => ({
                  ImageId: img.ImageId,
                  ParentId: img.ParentId,
                  ImageUrl: img.ImageUrl,
              }))
            : null;
    }

    public static mapGetInteracts(imageGetInteractImages: APIv3.ImageGetInteractImages.Responses.$200): OLO.DTO.InteractImageUrlModel[] {
        return imageGetInteractImages
            ? imageGetInteractImages.map((img: APIv3.InteractImageUrlModel) => ({
                  ImageUrl: img.ImageUrl,
                  ProductId: img.ProductId,
                  DisplayIndex: img.DisplayIndex,
                  OffsetY: img.OffsetY,
                  Rotation: img.Rotation,
                  OriginalWidth: img.OriginalWidth,
                  OriginalHeight: img.OriginalHeight,
              }))
            : null;
    }

    public static mapGetImageForLoyaltyIntroductionPage(introductionPageImages: APIv3.ImageGetIntroductionPageImages.Responses.$200): string {
        return introductionPageImages?.Items ? introductionPageImages?.Items[0] : null;
    }

    public static mapGetImageForMemberFreeProduct(introductionPageImages: APIv3.ImageGetMemberFreeProductImage.Responses.$200): string {
        return introductionPageImages ? introductionPageImages : null;
    }

    public static mapGetMemberCardBarcode(introductionPageImages: APIv3.ImageGetMemberCardBarcodeUrl.Responses.$200): string {
        return introductionPageImages ? introductionPageImages : null;
    }
}
