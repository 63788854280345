import * as io from 'io-ts';
import { boolean, nullableString, string, minimumArraySize } from '@shared/core/types/common';
import { appModeEnum } from '@shared/core/types/consts';
import * as schemas from './partials';

const rootDefinition: io.MixedObject<OLO.Config> = {
    appMode: appModeEnum,
    demoMode: boolean,
    geolocation: boolean,
    title: nullableString,
    cart: schemas.cartSchema,
    signUpRewardsAccountLinking: schemas.signUpRewardsAccountLinkingSchema,
    loyaltyPoints: schemas.loyaltyPointsSchema,
    google: schemas.googleSchema,
    links: schemas.linksSchema,
    localization: schemas.localizationSchema,
    payments: schemas.paymentsSchema,
    api: schemas.apiSchema,
    collectionTypes: schemas.collectionTypesSchema,
    images: schemas.imagesSchema,
    modals: schemas.modalsSchema,
    onlineMenu: schemas.onlineMenuSchema,
    venue: schemas.venueSchema,
    onlineOrders: schemas.onlineOrdersSchema,
    predownloadImages: schemas.predownloadImagesSchema,
    membership: schemas.membershipSchema,
    landingPage: minimumArraySize(schemas.landingPageSchema),
    checkoutPage: minimumArraySize(schemas.checkoutPageSchema),
    orderConfirmationPage: minimumArraySize(schemas.orderConfirmationPageSchema),
    locationListPage: minimumArraySize(schemas.locationListPageSchema),
    locationDetailsPage: minimumArraySize(schemas.locationDetailsPageSchema),
    locationMoreInfoModal: schemas.locationMoreInfoModalSchema,
    topBar: minimumArraySize(schemas.topBarSchema),
    loyalty: schemas.loyaltySchema,
    memberVerification: schemas.memberVerificationSchema,
    forgotPassword: schemas.forgotPasswordSchema,
    historyOrdersPage: schemas.historyOrdersPageSchema,
    imagesScaleFactor: string,
    virtualLocations: schemas.virtualLocationsSchema,
    hiddenLocations: schemas.hiddenLocationsSchema,
    translation: schemas.translationSchema,
    ecommerceTracking: schemas.ecommerceTrackingSchema,
};

export const applicationConfigSchema = io.type(rootDefinition);
export const applicationConfigPartialSchema = io.partial(rootDefinition);
