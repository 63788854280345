import { createSelector } from '@ngrx/store';
import { collectionTypeState } from '../root';

import * as Utils from '@shared/core/utils';
import * as Statics from '@shared/core/statics';

export const isCollectionTypeDelivery = createSelector(collectionTypeState, (collectionType) => {
    const config = new Statics.ConfigStatic().current;

    const collectionTypeDeliveryConfig = new Utils.CollectionTypeHelper(config.collectionTypes).getDeliveryCollectionTypeConfig();

    return (collectionType?.orderTypeId && collectionTypeDeliveryConfig?.orderTypeIds?.includes(collectionType?.orderTypeId)) || false;
});
