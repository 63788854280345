import { createAction, props } from '@ngrx/store';

export const DietaryTagsImagesRequest = createAction('[Dietary tags images] Request', props<{ params: APICommon.ImagesGetParams; dietaryTags: OLO.DTO.TagModel[] }>());

export const DietaryTagsImagesSuccessRequest = createAction(
    '[Dietary tags images] Success request',
    props<{ payload: OLO.DTO.ImageUrlModel[]; dietaryTags: OLO.DTO.TagModel[] }>(),
);

export const DietaryTagsImagesErrorRequest = createAction('[Dietary tags images] Error request', props<{ ex?: any; dietaryTags: OLO.DTO.TagModel[] }>());

export const LoadDietaryImagesForLocation = createAction(
    '[Dietary tags images] Load dietary images for location',
    (locationNo: number, orderTypeId: number, pickupDate: string, payload: OLO.DTO.OnlineMenuResponseModel) => ({ locationNo, orderTypeId, pickupDate, payload }),
);
