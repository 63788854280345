import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { IngredientsEffects } from './ingredients.effects';
import { ingredientsReducer } from './ingredients.reducer';

@NgModule({
    declarations: [],
    imports: [CommonModule, StoreModule.forFeature('ingredients', ingredientsReducer), EffectsModule.forFeature([IngredientsEffects])],
    exports: [],
    providers: [],
})
export class IngredientsStateModule {}
