import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './dietary-tags-images.actions';

const initialState: OLO.State.DietaryTagImage[] = [];

export const dietaryTagsImagesReducerFn = createReducer(
    initialState,
    on(actions.DietaryTagsImagesRequest, (state, action) => {
        const newToConcat: OLO.State.DietaryTagImage[] = [];

        action.dietaryTags.forEach((tag) => {
            if (state.find((image) => image.Id === tag.Id)) {
                return;
            }

            newToConcat.push({
                Id: tag.Id,
                Name: tag.Name,
                isDownloading: true,
                hasSucceeded: false,
                hasFailed: false,
                data: null,
            });
        });

        return [
            ...state.map((image: OLO.State.DietaryTagImage) => {
                if (action.dietaryTags.find((tag) => tag.Id === image.Id)) {
                    return {
                        ...image,
                        isDownloading: true,
                        hasSucceeded: false,
                        hasFailed: false,
                    };
                }

                return image;
            }),
            ...newToConcat,
        ];
    }),
    on(actions.DietaryTagsImagesSuccessRequest, (state, action) =>
        state.map((image: OLO.State.DietaryTagImage) => {
            const foundItem: OLO.DTO.ImageUrlModel = action.payload.find((newImage) => newImage.ParentId === image.Id);

            if (foundItem) {
                return {
                    ...image,
                    data: { ...foundItem },
                    isDownloading: false,
                    hasSucceeded: true,
                    hasFailed: false,
                };
            }

            return {
                ...image,
                isDownloading: false,
                hasSucceeded: true,
                hasFailed: false,
            };
        }),
    ),
    on(actions.DietaryTagsImagesErrorRequest, (state, action) =>
        state.map((image: OLO.State.DietaryTagImage) => {
            if (action.dietaryTags.find((tag) => tag.Id === image.Id)) {
                return {
                    ...image,
                    isDownloading: false,
                    hasSucceeded: false,
                    hasFailed: true,
                };
            }

            return image;
        }),
    ),
);

export function dietaryTagsImagesReducer(state: OLO.State.DietaryTagImage[] | undefined, action: Action) {
    return dietaryTagsImagesReducerFn(state, action);
}
