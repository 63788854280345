import { createSelector } from '@ngrx/store';

import { isWizzardPageOneAllowed } from '../isWizzardPageOneAllowed';
import { isWizzardPageLimitReached } from '../isWizzardPageLimitReached';
import { getWizzardMenuFlowProductTotalQuantity } from '@shared/state/wizzard/selectors';

export const isMenuFlowProductSelectable = (pageIdentifier: number, productId: number, productState: Nullable<number> = null) =>
    createSelector(
        isWizzardPageOneAllowed(pageIdentifier),
        isWizzardPageLimitReached(pageIdentifier),
        getWizzardMenuFlowProductTotalQuantity(pageIdentifier, productId),
        (isPageOneAllowed, pageLimitReached, productQty) => {
            if (isPageOneAllowed) return true;
            const isClickable: boolean = productQty === 0 && pageLimitReached === false;

            return productState === null ? isClickable : isClickable && productState === 0;
        },
    );
