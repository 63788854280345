import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { HistoryOrdersEffects } from './history-orders.effects';
import { historyOrdersReducer } from './history-orders.reducer';

@NgModule({
    declarations: [],
    imports: [CommonModule, StoreModule.forFeature('historyOrders', historyOrdersReducer), EffectsModule.forFeature([HistoryOrdersEffects])],
    exports: [],
    providers: [],
})
export class HistoryOrdersStateModule {}
