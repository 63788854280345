import { Injectable } from '@angular/core';
import { Action, Store, select } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';

import * as Services from '@shared/core/services';
import * as Utils from '@shared/core/utils';

import { Observable } from 'rxjs';
import { switchMap, withLatestFrom, filter, take, delay } from 'rxjs/operators';

@Injectable()
export class ReturningMemberModalEffects {
    private _quickSignInHasBeenDisplayed: boolean = false;

    private _showLoginModal(session: Nullable<OLO.Authorization.SessionData>): void {
        if (session) {
            this._quickSignInHasBeenDisplayed = true;
        }

        if (!this._quickSignInHasBeenDisplayed) {
            this._quickSignInHasBeenDisplayed = true;
            this._modalsService.closeAll();
            this._returningMemberService.openLoginModalForReturningMember();
        }
    }

    public showModalForReturningMember$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.ROUTER_NAVIGATED),
            filter(() => this._quickSignInHasBeenDisplayed === false && !Utils.Storage.getItem(OLO.Enums.SESSION_STORAGE.STATE, 'sessionStorage')),
            withLatestFrom(
                this._sessionService.getSession(),
                this._store.pipe(select(selectors.routeIsLocationDetailsPage())),
                this._store.pipe(select(selectors.getCurrentRouteQueryParams)),
            ),
            switchMap(([, session, isLocationDetailsPage, queryParams]) => {
                const queryParamsDetails = Object.entries(queryParams);
                if (queryParamsDetails.length && isLocationDetailsPage) {
                    return this._actions$.pipe(
                        ofType(actions.OnlineMenuPagesSuccessRequest),
                        take(1),
                        delay(1000),
                        withLatestFrom(this._store.pipe(select(selectors.getAllModals))),
                        switchMap(([, modals]) => {
                            /**
                             * This is not bulletproof solution, but it might be just enough without overengineering.
                             * If not, than we would need to extract logic from wizzard.effect 'openWizzardByUrlParams$'
                             */
                            if (!modals.length) {
                                this._showLoginModal(session);
                            }

                            return [];
                        }),
                    );
                }

                this._showLoginModal(session);

                return [];
            }),
        ),
    );

    constructor(
        private _actions$: Actions,
        private _store: Store<OLO.State>,
        private _sessionService: Services.SessionService,
        private _modalsService: Services.ModalsService,
        private _returningMemberService: Services.ReturningMemberService,
    ) {}
}
