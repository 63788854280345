import * as io from 'io-ts';
import { arrayOfStrings, boolean } from '@shared/core/types/common';

const mapsRequiredDefinition: io.MixedObject<OLO.Config.GoogleTagsConfig> = {
    enabled: boolean,
    containerIDs: arrayOfStrings,
};

const rootDefinition: io.MixedObject<OLO.Config.EcommerceTracking> = {
    googleTags: io.type(mapsRequiredDefinition),
};

export const ecommerceTrackingSchema = io.type(rootDefinition);
