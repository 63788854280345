import { createSelector } from '@ngrx/store';
import { getOnlineMenuVirtualLocation } from '../getOnlineMenuVirtualLocation';

export const getOnlineMenuVirtualLocationsItemById = (virtualLocationNo: number, itemId: number) =>
    createSelector(getOnlineMenuVirtualLocation(virtualLocationNo), (onlineMenu) => {
        // TODO: consider to refactor, common code with getOnlineMenuItemById
        if (!onlineMenu?.data) return null;

        let product: Nullable<OLO.DTO.OnlineMenuProductResponseModel> = null;

        for (let i = 0, j = onlineMenu.data.Pages.length; i < j; i++) {
            if (product) break;

            const page = onlineMenu.data.Pages[i];

            for (let k = 0, m = page.Products.length; k < m; k++) {
                if (page.Products[k].ProductId === itemId || page.Products[k].MenuFlowId === itemId) {
                    product = page.Products[k];
                    break;
                }
            }
        }

        return product;
    });
