import * as Utils from '@shared/core/utils';
import { createSelector } from '@ngrx/store';
import { getAppSettingsTags } from '../../../appSettings/selectors';
import { getWizzardMenuFlow } from '@shared/state/wizzard/selectors';

export const isWizzardMenuFlowFeatured = createSelector(getWizzardMenuFlow, getAppSettingsTags, (wizzardMenuFlow, appTagSettings) => {
    const tags = wizzardMenuFlow?.Tags || [];

    return new Utils.TagSettingsUtils(tags, appTagSettings).isFeaturedMenuItem;
});
