import { ActionReducer, Action } from '@ngrx/store';

/** This meta reducer should be attached directly into root Store */
export function stateMetaReducer(reducer: ActionReducer<OLO.State>): ActionReducer<OLO.State> {
    return function newReducer(state: OLO.State, action: Action): OLO.State {
        switch (action.type) {
            case '[State] Restored':
                return { ...(action as any).state };

            default: {
                return reducer(state, action);
            }
        }
    };
}
