import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { LocationsImagesEffects } from './locations-images.effects';
import { locationImagesReducer } from './locations-images.reducer';

@NgModule({
    declarations: [],
    imports: [CommonModule, StoreModule.forFeature('locationsImages', locationImagesReducer), EffectsModule.forFeature([LocationsImagesEffects])],
    exports: [],
    providers: [],
})
export class LocationsImagesStateModule {}
