import { createSelector } from '@ngrx/store';

import { getWizzardMenuFlowPageItemsTotalQuantity } from '../getWizzardMenuFlowPageItemsTotalQuantity';

export const isWizzardPageQuantityValid = (pageDetails: OLO.DTO.MenuFlowPageModel) =>
    createSelector(getWizzardMenuFlowPageItemsTotalQuantity(pageDetails.PageIdentifier), (count) => {
        if (count === null) return null;

        return count >= pageDetails.PageMinQuantity && count <= pageDetails.PageMaxQuantity;
    });
