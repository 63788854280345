import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './collection-type.actions';

const initialState: OLO.State.CollectionType = {
    orderTypeId: null,
    address: null,
    tableNo: null,
};

export const collectionTypesReducerFn = createReducer<OLO.State.CollectionType>(
    initialState,
    on(actions.SetCollectionType, (state, action) => ({
        ...state,
        orderTypeId: action.orderTypeId,
        address: action.address,
        tableNo: action.tableNo,
    })),
    on(actions.PatchOrderTypeIdCollectionTypeValue, (state, action) => ({
        ...state,
        orderTypeId: action.orderTypeId,
    })),
    on(actions.PatchAddressCollectionTypeValue, (state, action) => ({
        ...state,
        address: action.address,
    })),
    on(actions.PatchTableNoCollectionTypeValue, (state, action) => ({
        ...state,
        tableNo: action.tableNo,
    })),
);

export function collectionTypesReducer(state: OLO.State.CollectionType | undefined, action: Action) {
    return collectionTypesReducerFn(state, action);
}
