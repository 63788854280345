import { Injectable, Inject } from '@angular/core';
import { Store, select, Action } from '@ngrx/store';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as selectors from '../selectors';
import * as actions from '../actions';

import * as Utils from '@shared/core/utils';
import * as Services from '@shared/core/services';
import * as Tokens from '@shared/core/tokens';

import { Observable, of, forkJoin } from 'rxjs';
import { catchError, exhaustMap, map, withLatestFrom, switchMap } from 'rxjs/operators';

@Injectable()
export class VenueImagesEffects {
    public requestImagesForVenues$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.VenueImagesRequest),
            withLatestFrom(this._store.pipe(select(selectors.getAllVenueImages))),
            exhaustMap(([action, images]) => {
                const { imageType, height, width, ids } = action;
                let targetImageType: string = Utils.Images.toImageTypeString(imageType);
                /*
                    Prevent from redownloading same image
                */
                const imagesFiltered = {
                    downloaded: [],
                    required: [],
                };

                ids.forEach((id) => {
                    const img = images[targetImageType].find((obj) => obj.Id === id);

                    if (img.data) {
                        imagesFiltered.downloaded.push(img);
                    } else {
                        imagesFiltered.required.push(img.Id);
                    }
                });

                if (imagesFiltered.downloaded.length) {
                    this._store.dispatch(actions.VenueImagesSuccessRequest({ imageType, ids: imagesFiltered.downloaded.map((img) => img.Id), payload: imagesFiltered.downloaded }));
                }

                return this._imagesService.getVenueImagesByType({ imageType, height, width }, ...imagesFiltered.required).pipe(
                    switchMap((payload) => {
                        if (this._config.predownloadImages && this._config.predownloadImages.forVenue) {
                            return forkJoin(...payload.map((img) => this._imagesService.preloadImageInMemory(img.ImageUrl))).pipe(
                                map(() => actions.VenueImagesSuccessRequest({ imageType, ids: imagesFiltered.required, payload })),
                            );
                        }

                        return of(actions.VenueImagesSuccessRequest({ imageType, ids: imagesFiltered.required, payload }));
                    }),
                    catchError((ex) => of(actions.VenueImagesErrorRequest({ imageType, ids: imagesFiltered.required, ex }))),
                );
            }),
        ),
    );

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: OLO.Config,
        private _store: Store<OLO.State>,
        private _actions$: Actions,
        private _imagesService: Services.ImagesService,
    ) {}
}
