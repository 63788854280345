import { Injectable } from '@angular/core';
import { Store, Action, select } from '@ngrx/store';
import { Actions, ofType, createEffect } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';

import * as Services from '@shared/core/services';

import { Observable, of } from 'rxjs';
import { switchMap, map, mergeMap, catchError, withLatestFrom } from 'rxjs/operators';

@Injectable()
export class OrderTypesEffects {
    public requestOrderTypeForLocation$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.CurrentLocationSet, actions.CartSetup),
            withLatestFrom(this._store.pipe(select(selectors.getOrderTypesForAllLocations))),
            switchMap(([action, orderTypes]) => {
                const foundDownloaded = orderTypes.find((obj) => obj.locationNo === action.locationNo && obj.hasSucceeded === true);
                if (foundDownloaded) {
                    return [];
                }

                return [actions.OrderTypesRequest({ locationNo: action.locationNo })];
            }),
        ),
    );

    public requestOrderTypes$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.OrderTypesRequest),
            mergeMap(({ locationNo }) =>
                this._loylatyAppService.apiGetOrderTypes(locationNo).pipe(
                    map((payload) => actions.OrderTypesSuccessRequest({ locationNo, payload })),
                    catchError((ex) => {
                        console.error('OrderTypes request error', ex);

                        return of(actions.OrderTypesErrorRequest({ locationNo, ex }));
                    }),
                ),
            ),
        ),
    );

    public resetOnlineOrderTypeOnRequest$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.CurrentLocationSet, actions.OrderTypesRequest),
            switchMap(() => [actions.OnlineOrderTypeSelect({ orderType: null })]),
        ),
    );

    constructor(private _actions$: Actions, private _store: Store<OLO.State>, private _loylatyAppService: Services.LoyaltyAppService) {}
}
