import { Injectable } from '@angular/core';
import { Action, Store, select } from '@ngrx/store';
import { createEffect, Actions, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';
import * as Services from '@shared/core/services';

import { Observable, of } from 'rxjs';
import { map, catchError, switchMap, mergeMap, withLatestFrom, filter, take } from 'rxjs/operators';

@Injectable()
export class OnlineMenuProductsImagesEffects {
    private _imgParams: APICommon.ImagesGetParams = { width: 150, height: 150 };

    public requestProductImageOnDirectEnter$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.WizzardStepSimpleItemMount, actions.WizzardStepMenuFlowMount),
            switchMap((action) => {
                const isMenuFlow = !!action.item.MenuFlowId;
                const id = isMenuFlow ? action.item.MenuFlowId : (action.item as OLO.State.Wizzard.WizzardSimpleItem).ProductId;

                return this._store.pipe(
                    select(selectors.getImageForProduct(id)),
                    take(1),
                    filter((image) => Boolean(image) === false),
                    withLatestFrom(
                        this._store.pipe(select(selectors.getOnlineMenu)),
                        this._store.pipe(select(selectors.getOnlineMenuVirtualLocation(action.item?.VirtualLocationNo))),
                    ),
                    switchMap(([, onlineMenu, onlineMenuVirtualLocation]) => {
                        const _onlineMenu = action.item?.VirtualLocationNo ? onlineMenuVirtualLocation : onlineMenu;
                        const ProductIdPage = _onlineMenu.data?.Pages.find((page) =>
                            page.Products.find((product) => (isMenuFlow ? product.MenuFlowId === id : product.ProductId === id)),
                        );

                        if (!ProductIdPage) {
                            return [];
                        }

                        return [
                            actions.OnlineMenuProductsImagesRequest({
                                params: this._imgParams,
                                pageId: ProductIdPage.Id,
                            }),
                        ];
                    }),
                );
            }),
        ),
    );

    public onCartItemEditRequestImageForItem$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.CartEditItem),
            withLatestFrom(this._store.pipe(select(selectors.getCart))),
            switchMap(([, cart]) => {
                const relevantOnlineMenu = cart.onlineMenu || cart.onlineMenuVirtualLocations?.[0];

                if (!relevantOnlineMenu || !relevantOnlineMenu.Pages) return [];

                return relevantOnlineMenu.Pages.map((page) => actions.OnlineMenuProductsImagesRequest({ params: this._imgParams, pageId: page.Id }));
            }),
        ),
    );

    public onPageImagesRequest$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.OnlineMenuProductsImagesRequest),
            mergeMap(({ params, pageId }) =>
                this._imagesService.getImagesForOnlineMenuPageProducts(params, pageId).pipe(
                    map((payload) => actions.OnlineMenuProductsImagesSuccessRequest({ pageId, payload })),
                    catchError((ex) => of(actions.OnlineMenuProductsImagesErrorRequest({ pageId, ex }))),
                ),
            ),
        ),
    );

    constructor(private _actions$: Actions, private _imagesService: Services.ImagesService, private _store: Store<OLO.State>) {}
}
