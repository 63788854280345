import { createSelector } from '@ngrx/store';
import * as Utils from '@shared/core/utils';
import { getFieldsDetails } from '../getFieldsDetails';
/**
 * Checks if user's profile picture is required by policies for sign up new user and for update member details.
 */
export const isMemberProfilePictureRequired = createSelector(getFieldsDetails, (fields) => {
    if (!fields?.length) {
        return false;
    }
    const profilePic = new Utils.MemberPhotoChecker(null, fields);

    return profilePic.isRequired;
});
