export class Geolocation {
    public static convertCoords(googleCoords: Partial<APICommon.SimpleCoords> = null): OLO.Maps.MapSimpleCoords {
        return {
            latitude: googleCoords?.lat ?? null,
            longitude: googleCoords?.lng ?? null,
        };
    }

    public static extractCoords(formBrowser: Partial<GeolocationCoordinates>, fromSettings: Partial<APICommon.SimpleCoords> = null): OLO.Maps.MapSimpleCoords {
        const targetCoords: OLO.Maps.MapSimpleCoords = Geolocation.convertCoords(fromSettings);

        if (!!formBrowser && formBrowser?.latitude !== null && formBrowser?.longitude !== null) {
            targetCoords.latitude = formBrowser.latitude;
            targetCoords.longitude = formBrowser.longitude;
        }

        let isValid = true;
        for (let key in targetCoords) {
            if (targetCoords[key] === null) {
                isValid = false;
            }
        }

        return (isValid && targetCoords) || null;
    }

    public static deg2rad(deg: number): number {
        return deg * (Math.PI / 180);
    }

    public static getDistanceFromTo(coordsFrom: OLO.Maps.MapSimpleCoords, coordsTo: OLO.Maps.MapSimpleCoords): number {
        //
        //  https://stackoverflow.com/questions/21279559/geolocation-closest-locationlat-long-from-my-position
        //  In kilometers
        //
        const earthRadiusKm: number = 6371;
        const dLat: number = Geolocation.deg2rad(coordsTo.latitude - coordsFrom.latitude);
        const dLng: number = Geolocation.deg2rad(coordsTo.longitude - coordsFrom.longitude);

        /* No idea... It feel's like higher deegre math. To analyse */
        const a: number =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(Geolocation.deg2rad(coordsFrom.latitude)) * Math.cos(Geolocation.deg2rad(coordsTo.latitude)) * Math.sin(dLng / 2) * Math.sin(dLng / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        return earthRadiusKm * c;
    }

    public static findNearestLocation(
        coords: OLO.Maps.MapSimpleCoords,
        locations: OLO.DTO.OnlineOrderingLocationBusinessModel[],
    ): { distance: number; location: OLO.DTO.OnlineOrderingLocationBusinessModel } {
        if (!coords) {
            return {
                distance: null,
                location: locations[0],
            };
        }

        let closestLocation: { index: number; distance: number; location: OLO.DTO.OnlineOrderingLocationBusinessModel } = null;

        locations.forEach((location, index) => {
            const distance = Geolocation.getDistanceFromTo(
                { latitude: coords.latitude, longitude: coords.longitude },
                { latitude: location.Latitude, longitude: location.Longitude },
            );
            const firstOrCloserThanPrevious: boolean = !closestLocation || (closestLocation.distance && distance < closestLocation.distance);

            if (firstOrCloserThanPrevious) {
                closestLocation = { index, distance, location };
            }
        });

        return closestLocation ? closestLocation : { distance: null, location: locations[0] };
    }
}
