import { Model } from './model';

interface Params {
    label?: Nullable<string>;
    price?: number;
    kilojoules?: string | number;
    displayMinimumPrice?: Nullable<boolean>;
}

export class Stats extends Model<OLO.Components.StatsComponentInput> {
    constructor(private _params: Params) {
        super();

        this._model.label = this._params.label ?? null;
        this._model.kilojoules = this._params.kilojoules ?? null;
        this._model.price = this._params.price ?? null;
        this._model.cals = this._kilojoulesToCals(this._params.kilojoules);
        this._model.displayMinimumPrice = this._params.displayMinimumPrice;
    }

    private _kilojoulesToCals(kilojoules: Nullable<string | number>): Nullable<number> {
        if (kilojoules == null || kilojoules === '') {
            return null;
        }
        const regularRound = +kilojoules / 4.184;
        if (Number.isNaN(regularRound)) {
            return null;
        }
        const initFloor = Math.floor(regularRound);

        const rest = regularRound - initFloor;
        let roundFunc = Math.floor;
        if (rest >= 0.5) {
            roundFunc = Math.ceil;
        }

        return roundFunc(regularRound);
    }

    public set label(label: Nullable<string>) {
        this._model.label = label ?? null;
    }

    public set price(price: Nullable<number>) {
        this._model.price = price ?? null;
    }

    public set kilojoules(kilojoules: Nullable<number | string>) {
        this._model.kilojoules = kilojoules ?? null;
        if (this._model.kilojoules) {
            this._model.cals = this._kilojoulesToCals(this._model.kilojoules);
        }
    }

    public set displayMinimumPrice(displayMinimumPrice: Nullable<boolean>) {
        this._model.displayMinimumPrice = displayMinimumPrice ?? null;
    }
}
