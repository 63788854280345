export class Strings {
    /**
     * Returns base64 size in kb
     * @param str base64 string
     * @returns {number} size in kb
     */
    public static base64Size(str: string): number {
        const base64 = str.substring(str.indexOf(',') + 1);
        const bits = base64.length * 6;
        const bytes = bits / 8;
        const kb = Math.ceil(bytes / 1000);

        return kb;
    }

    public static toString(value: any): string | boolean {
        if (typeof value === 'string') {
            return value;
        }
        try {
            return JSON.stringify(value);
        } catch (ex) {
            console.error(ex);

            return false;
        }
    }

    public static removeQuotesChars(value: string): string {
        return value.replace(/("|')/gi, '');
    }

    public static obfuscateEmail(email: string): string {
        const arr: string[] = email.split('@');
        const prefix: string = arr[0].substr(0, arr[0].length > 3 ? 3 : 1);

        return `${prefix}***@${arr[1]}`;
    }

    public static obfuscateMobilePhone(phoneNo: string, prefix: string = '+XXXXXXX'): string {
        return `${prefix}${phoneNo.substr(7, 10)}`;
    }

    public static extractMemberFullName(fullName: string): { name: string; surname: string } {
        const nameIndex = fullName.indexOf(' ');
        const name: string = fullName.substr(0, nameIndex);
        const surname: string = fullName.substr(nameIndex + 1);

        return {
            name,
            surname,
        };
    }

    public static capitalizeFirstChar(string: string): string {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    public static stringContains(targetStr: string, term: string, toLowercaseCompare: boolean = true): boolean {
        if (typeof targetStr !== 'string') return false;
        if (!term || typeof term !== 'string') return true;

        const searchIn: string = toLowercaseCompare ? targetStr.toLowerCase() : targetStr;
        const termInput: string = toLowercaseCompare ? term.toLowerCase() : term;

        return searchIn.includes(termInput);
    }

    public static searchValueStringInObject<T, K extends keyof T>(term: string, obj: T, ...propsToSearch: Array<K>): boolean {
        let found: boolean = false;

        for (let i: number = 0, j: number = propsToSearch.length; i < j; i++) {
            if (found) break;
            const currProp = propsToSearch[i];
            found = Strings.stringContains(obj[currProp] as any, term);
        }

        return found;
    }

    public static truncate<T extends string>(value: T, startAt: number = 0, limit: number = 82, ellipsis: string = '...', completeWords: boolean = false): string {
        if (typeof value !== 'string') return '';

        if (!Number.isInteger(startAt) || startAt < 0) {
            startAt = 0;
        }

        if (value.length <= limit || !limit) return value;

        if (completeWords === true) {
            limit = value.substr(startAt, limit).lastIndexOf(' ');
        }

        let newValue: string = value.substr(startAt, limit);
        const notAllowedLastChars: string[] = [',', ' ', '.'];
        while (notAllowedLastChars.includes(newValue[newValue.length - 1])) {
            newValue = newValue.substr(0, newValue.length - 1);
        }

        return `${newValue}${value.length > limit && ellipsis ? ellipsis : ''}`;
    }
}
