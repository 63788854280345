import { createSelector } from '@ngrx/store';
import { getOnlineOrderRecalcData } from '../getOnlineOrderRecalcData';

export const areAllProductsUnredeemable = createSelector(getOnlineOrderRecalcData, (recalculate) => {
    const singleProductsUnredeemable = recalculate?.data?.Items?.every((item: { PointsCost: null }) => item.PointsCost === null);
    const menuFlowProductsUnredeemable = recalculate?.data?.MenuFlowActivations?.every((menuFlow) =>
        menuFlow.MenuFlowItems?.every((item: { PointsCost: null }) => item.PointsCost === null),
    );

    return singleProductsUnredeemable && menuFlowProductsUnredeemable;
});
