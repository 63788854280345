import { Inject, Injectable } from '@angular/core';
import { take, filter, combineLatest, withLatestFrom, tap } from 'rxjs/operators';
import { Router, NavigationEnd } from '@angular/router';
import { Store } from '@ngrx/store';

import * as Tokens from '@shared/core/tokens';
import { AuthService } from './auth.shared.service';
import { ModalsService } from './modals.shared.service';
import { SessionService } from './session.shared.service';
import * as selectors from '@shared/state/selectors';

@Injectable({
    providedIn: 'root',
})
export class ReturningMemberService {
    public hasModalBeenDisplayed = false;
    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: OLO.Config,
        private _store: Store<OLO.State>,
        private _router: Router,
        private _authService: AuthService,
        private _modalService: ModalsService,
        private _sessionService: SessionService,
    ) {}

    public openLoginModalForReturningMember(): void {
        if (!this._config.membership.enableQuickSignInForReturningMembers) return;

        let sessionExists = false;
        this._sessionService
            .getSession()
            .pipe(take(1))
            .subscribe((res) => {
                if (res) {
                    sessionExists = true;
                }
            });

        if (sessionExists) return;

        const cellNumber = localStorage.getItem(OLO.Enums.USER_STORAGE.PHONE);
        const countryId = localStorage.getItem(OLO.Enums.USER_STORAGE.COUNTRY_ID);

        if (!cellNumber || !countryId) return;

        if (!isNaN(+cellNumber) && !isNaN(+countryId)) {
            this._showAuthModal(countryId, cellNumber);
        }
    }

    private _shouldShowModal(url: string): boolean {
        const pathsWithoutAuthModal = ['reset-password', 'email-confirmation', 'order-confirmation'];

        return !pathsWithoutAuthModal.some((path) => url.includes(path));
    }

    private _initSignInAndShowAuthModal(countryId: string, cellNumber: string): void {
        this._authService.initSignIn(`${countryId}:${cellNumber}`);
        this._modalService.show({
            type: 'auth',
        });
        this.hasModalBeenDisplayed = true;
    }

    private _showAuthModal(countryId: string, cellNumber: string): void {
        const currentUrl = this._router.url;
        if (this._shouldShowModal(currentUrl)) {
            this._initSignInAndShowAuthModal(countryId, cellNumber);
        } else {
            this._router.events
                .pipe(
                    filter((event) => event instanceof NavigationEnd),
                    withLatestFrom(this._store.select(selectors.getAllModals)),
                    filter(([_, modals]) => modals.length === 0),
                    take(1),
                )
                .subscribe(([event]) => {
                    if (this._shouldShowModal((event as NavigationEnd).url)) {
                        this._initSignInAndShowAuthModal(countryId, cellNumber);
                    }
                });
        }
    }
}
