type Errors = Array<OLO.Common.OrderTypeIdsValidatorError>;

export abstract class Validators {
    protected static _errors: Errors = [];

    public static readonly ERROR_TYPES = {};

    public static logErrors(errors: Errors): void {
        errors.forEach((error) => {
            const key = Object.keys(error)[0];
            const body = error[key];

            console.error(`${Validators.ERROR_TYPES[key] || key} in ${body.path} : Provided value: `, body.value, [error]);
        });
    }

    protected static _registerErrorTypes(errorTypes: { [key: string]: string }): void {
        Object.entries(errorTypes).forEach(([key, value]) => {
            Validators.ERROR_TYPES[key] = value;
        });
    }
}
