import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { LoyaltyMessagesEffects } from './loyalty-messages.effects';
import { loyaltyMessagesReducer } from './loyalty-messages.reducer';

@NgModule({
    imports: [StoreModule.forFeature('loyaltyMessages', loyaltyMessagesReducer), EffectsModule.forFeature([LoyaltyMessagesEffects])],
})
export class LoyaltyMessagesStateModule {}
