import { createSelector } from '@ngrx/store';

import { getOrderSummary } from '../getOrderSummary';
import { isPayByPointsSelected } from '@shared/state/creditCards/selectors/isPayByPointsSelected';
import { getActiveCardId } from '@shared/state/creditCards/selectors/getActiveCardId';
import { canFulfillPaymentWithPointsOnly } from '@shared/state/commonSelectors/payments';

export const shouldRequireTwoPaymentOptions = createSelector(
    isPayByPointsSelected,
    canFulfillPaymentWithPointsOnly,
    getActiveCardId,
    getOrderSummary,
    (payByPointsSelected, canFulfillPaymentWithPoints, cardId, orderSummary) => {
        const arePointsOnlyMethodWithLowBalance = !canFulfillPaymentWithPoints && cardId === OLO.Enums.PAYMENT_VENDOR_SERVICE.PAY_BY_POINTS;
        const isEverythingRedeemableButHasSurcharges = canFulfillPaymentWithPoints && orderSummary.Surcharges.length > 0;

        return payByPointsSelected && (arePointsOnlyMethodWithLowBalance || isEverythingRedeemableButHasSurcharges);
    },
);
