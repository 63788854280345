import * as moment from 'moment';

import { TextsStatic } from '@shared/core/statics';

import { LocationOpenStatus } from './location-open-status.utils';
import { WeekCount } from './week-count';
import { Dates } from './dates.utils';
import { ShortHours } from './short-hours.utils';
import { LocationOrderingTimeInfo } from './location-ordering-time-info.utils';
import { ReplacePlaceholderVariables } from './replace-variable-with-value.utils';

export class LocationOpenStatusMessage {
    private _hasInit: boolean = false;
    private _mode: OLO.Enums.LOCATION_AVAILABILITY = OLO.Enums.LOCATION_AVAILABILITY.BY_OPERATING_HOURS;
    private _message: string = '';
    private _status!: OLO.Enums.LOCATION_OPEN_STATUS;
    private _dateTimeString!: string;
    private _index: number;
    private _currentOrderTimeInfo: OLO.DTO.LocationOrderingTimeInfoModel;
    private _nextAvailableTimeInfo: OLO.DTO.LocationOrderingTimeInfoModel;
    private _openingTimeInfo: OLO.DTO.LocationOrderingTimeInfoModel[];
    private _text: T.StaticTexts;

    constructor(
        private _locationOpenStatusConstructor: typeof LocationOpenStatus,
        private _location: OLO.DTO.OnlineOrderingLocationBusinessModel,
        private _orderTypeId: number,
        private _dateToCheck: Date,
    ) {
        if (!this._location) {
            return;
        }

        if (!this._locationOpenStatusConstructor || typeof _locationOpenStatusConstructor !== 'function') throw new Error('LocationOpenStatus function is required');

        this._text = new TextsStatic().current;

        this._init();
    }

    private _init(): void {
        if (this._hasInit) {
            return;
        }

        this._openingTimeInfo = this._location?.OperatingTimeInfo;
        if (this._mode === OLO.Enums.LOCATION_AVAILABILITY.BY_ORDERING_TIME_INFO) {
            this._openingTimeInfo = new LocationOrderingTimeInfo(this._location, this._orderTypeId).getOrderingTimeInfo();
        }

        if (!this._openingTimeInfo || !this._dateToCheck || !(this._dateToCheck instanceof Date)) return;

        this._hasInit = true;
        this._dateTimeString = this._extractDateToISOString(this._dateToCheck);
        this._setCurrentOrderTimeInfoByProvidedDateAndGetIndex();
        this._setNextAvailableOpenOrderTimeInfo();
        this._setMessageAndStatus();
    }

    private _extractDateToISOString(date: Date): string {
        return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000).toISOString();
    }

    private _setCurrentOrderTimeInfoByProvidedDateAndGetIndex(): void {
        this._currentOrderTimeInfo = this._openingTimeInfo.find((orderTimeInfo, index) => {
            this._index = index;

            return orderTimeInfo.Date.split('T')[0] === this._dateTimeString.split('T')[0];
        });
    }

    private _setNextAvailableOpenOrderTimeInfo(): void {
        this._nextAvailableTimeInfo = this._openingTimeInfo.find((orderTimeInfo) => {
            const currDate = new Date(this._dateTimeString.replace('Z', ''));
            const nextDate = new Date(orderTimeInfo.Date.split('T')[0] + `T${orderTimeInfo.OpeningTime}`);
            if (nextDate < currDate) return false;

            return this._checkOrderInfoIsOpen(orderTimeInfo);
        });
    }

    private _setMessageAndStatus(): void {
        let shortHour: string = this._setShorHourString();

        if (this._isOpenNow()) {
            this._message = ReplacePlaceholderVariables.replaceVariableWithValue(this._text.locations.openTodayUntil, 'hour', `${shortHour}`);
            this._status = OLO.Enums.LOCATION_OPEN_STATUS.OPEN;
        }
        if (this._isClosingSoon()) {
            this._message = ReplacePlaceholderVariables.replaceVariableWithValue(this._text.locations.closesSoonAt, 'hour', `${shortHour}`);
            this._status = OLO.Enums.LOCATION_OPEN_STATUS.CLOSE_SOON;
        }
        if (this._isOpenTomorrow()) {
            this._message = ReplacePlaceholderVariables.replaceVariableWithValue(this._text.locations.closedOpensTomorrowAt, 'hour', `${shortHour}`);
            this._status = OLO.Enums.LOCATION_OPEN_STATUS.CLOSED;
        }
        if (this._isOpeningSoon()) {
            this._message = ReplacePlaceholderVariables.replaceVariableWithValue(this._text.locations.openingSoonAt, 'hour', `${shortHour}`);
            this._status = OLO.Enums.LOCATION_OPEN_STATUS.OPEN_SOON;
        }
        if (this._isClosedNowButOpensLaterToday()) {
            this._message = ReplacePlaceholderVariables.replaceVariableWithValue(this._text.locations.closedOpensAt, 'hour', `${shortHour}`);
            this._status = OLO.Enums.LOCATION_OPEN_STATUS.CLOSED;
        }
        if (this._isOpenThisWeek()) {
            this._message = ReplacePlaceholderVariables.replaceVariablesWithValues(this._text.locations.closedOpensDateAt, [
                { variable: 'hour', value: `${shortHour}` },
                { variable: 'date', value: `${moment(this._nextAvailableTimeInfo.Date).format('dddd')}` },
            ]);
            this._status = OLO.Enums.LOCATION_OPEN_STATUS.CLOSED;
        }
        if (this._isOpenNextWeekOrLater()) {
            this._message = ReplacePlaceholderVariables.replaceVariablesWithValues(this._text.locations.closedOpensDateAt, [
                { variable: 'hour', value: `${shortHour}` },
                { variable: 'date', value: `${moment(this._nextAvailableTimeInfo.Date).format('ddd, D MMMM')}` },
            ]);
            this._status = OLO.Enums.LOCATION_OPEN_STATUS.CLOSED;
        }
        if (!this._message) {
            this._message = this._text.locations.closed;
            this._status = OLO.Enums.LOCATION_OPEN_STATUS.CLOSED;
        }
    }

    private _setShorHourString(): string {
        let shortHour: string = this._shortHour(this._currentOrderTimeInfo?.ClosingTime || null);
        if (!this._isOpenNow()) {
            shortHour = this._shortHour(this._nextAvailableTimeInfo?.OpeningTime || null);
        }

        return shortHour;
    }

    private _isOpenNow(): boolean {
        if (!this._currentOrderTimeInfo) return false;

        const openingDate = new Date(this._currentOrderTimeInfo.Date.split('T')[0] + `T${this._currentOrderTimeInfo.OpeningTime}.000`);
        const closingDate = new Date(this._currentOrderTimeInfo.Date.split('T')[0] + `T${this._currentOrderTimeInfo.ClosingTime}.000`);
        const dateToCheck = new Date(this._dateTimeString.replace('Z', ''));

        const isInTimeRange = dateToCheck >= openingDate && dateToCheck < closingDate;

        return isInTimeRange;
    }

    private _isClosingSoon(): boolean {
        if (!this._currentOrderTimeInfo) return false;

        const currentDate = new Date(this._dateTimeString.replace('Z', ''));
        const closeDate = new Date(this._currentOrderTimeInfo.Date.split('T')[0] + 'T' + this._currentOrderTimeInfo.ClosingTime + '.000');
        const differenceInMinutes = Math.ceil((closeDate.getTime() - currentDate.getTime()) / 60 / 1000);

        return differenceInMinutes <= 60 && this._isOpenNow();
    }

    private _isOpeningSoon(): boolean {
        if (!this._nextAvailableTimeInfo) return false;

        const currentDate = new Date(this._dateTimeString.replace('Z', ''));
        const openDate = new Date(this._nextAvailableTimeInfo.Date.split('T')[0] + 'T' + this._nextAvailableTimeInfo.OpeningTime + '.000');
        const differenceInMinutes = Math.abs(Math.ceil((openDate.getTime() - currentDate.getTime()) / 60 / 1000));

        return differenceInMinutes <= 60 && !this._isOpenNow();
    }

    private _isClosedNowButOpensLaterToday(): boolean {
        if (!this._nextAvailableTimeInfo) return false;

        const currentDate = new Date(this._dateTimeString.replace('Z', ''));
        const openDate = new Date(this._nextAvailableTimeInfo.Date.split('T')[0] + 'T' + this._nextAvailableTimeInfo.OpeningTime + '.000');
        const differenceInMinutes = Math.ceil((openDate.getTime() - currentDate.getTime()) / 60 / 1000);

        return differenceInMinutes > 60 && this._dateTimeString.split('T')[0] === this._nextAvailableTimeInfo.Date.split('T')[0];
    }

    private _isOpenTomorrow(): boolean {
        if (this._index === -1) return false;
        const tomorrowOrderTimeInfo = this._openingTimeInfo.find((orderingTimeInfo, index) => {
            if (index <= this._index) return false;

            return Dates.datesDiffInDays(this._currentOrderTimeInfo?.Date || this._dateTimeString.replace('Z', ''), orderingTimeInfo.Date) === 1;
        });

        return (tomorrowOrderTimeInfo ? this._checkOrderInfoIsOpen(tomorrowOrderTimeInfo) : false) && !this._isOpenNow();
    }

    private _isOpenNextWeekOrLater(): boolean {
        if (!this._nextAvailableTimeInfo) return false;
        const currentWeekNo = new WeekCount(this._currentOrderTimeInfo?.Date || this._dateTimeString.replace('Z', '')).getWeekNo();
        const nextWeekNo = new WeekCount(this._nextAvailableTimeInfo.Date).getWeekNo();

        return nextWeekNo > currentWeekNo && !this._isOpenNow() && !this._isOpeningSoon();
    }

    private _isOpenThisWeek(): boolean {
        if (!this._nextAvailableTimeInfo) return false;
        const currentWeekNo = new WeekCount(this._currentOrderTimeInfo?.Date || this._dateTimeString.replace('Z', '')).getWeekNo();
        const nextWeekNo = new WeekCount(this._nextAvailableTimeInfo.Date).getWeekNo();

        return nextWeekNo === currentWeekNo && !this._isOpenTomorrow() && !this._isOpenNow() && !this._isClosedNowButOpensLaterToday() && !this._isOpeningSoon();
    }

    private _checkOrderInfoIsOpen(orderTimeInfo: OLO.DTO.LocationOrderingTimeInfoModel): boolean {
        if (!orderTimeInfo) return false;

        return new this._locationOpenStatusConstructor(orderTimeInfo).isOpen();
    }

    private _shortHour(longHourString: string): string {
        if (!longHourString) return null;
        const shortHour = new ShortHours(longHourString, this._text).getTransformedHour();
        const withoutMinutesIfPossible = shortHour.replace(':00', '');

        return withoutMinutesIfPossible;
    }

    /**
     * Change the base for calculating open status. Default is OLO.Enums.LOCATION_AVAILABILITY.BY_OPERATING_HOURS
     */
    public setMode(mode: OLO.Enums.LOCATION_AVAILABILITY): LocationOpenStatusMessage {
        if (this._mode !== mode) {
            this._mode = mode;
        }
        this._hasInit = false;
        this._init();

        return this;
    }

    public getMessageForDate(): string {
        this._init();

        return this._message;
    }

    public getStatus(): OLO.Enums.LOCATION_OPEN_STATUS {
        this._init();

        return this._status;
    }

    public getIsOpen(): boolean {
        this._init();

        return this._isOpenNow() || this._isClosingSoon();
    }

    public getOpenStatusObj(): OLO.Common.LocationOpenStatus {
        this._init();

        const obj: OLO.Common.LocationOpenStatus = {
            locationNo: this._location?.LocationNo || null,
            status: null,
            isOpen: null,
            message: '',
        };

        if (!this._location) {
            return obj;
        }

        return {
            ...obj,
            status: this.getStatus(),
            message: this.getMessageForDate(),
            isOpen: this.getIsOpen(),
        };
    }
}
