export class PaymentExpressPaymentProviderMapper {
    public static mapGetCardDetails(cardDetails: APIv3.PaymentExpressCardIdResponse): OLO.DTO.PaymentExpressCardIdResponse {
        return cardDetails
            ? {
                  CardId: cardDetails.CardId,
                  NiceName: cardDetails.NiceName,
              }
            : null;
    }

    public static mapGetSettingsForLocation(settingsForLocation: APIv3.PaymentExpressSettingsResponse): OLO.DTO.PaymentExpressSettingsResponse {
        return settingsForLocation
            ? {
                  Url: settingsForLocation.Url,
                  SessionToken: settingsForLocation.SessionToken,
              }
            : null;
    }
}
