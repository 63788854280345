import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './order-types-images.actions';

const initialState: OLO.State.OrderTypesImages[] = [];

export const orderTypesImagesReducerFn = createReducer(
    initialState,
    on(actions.OrderTypesImagesRequest, (state, action) => {
        const newToConcat: OLO.State.OrderTypesImages[] = [];

        action.orderTypesIds.forEach((id) => {
            if (state.find((image) => image.Id === id)) {
                return;
            }

            newToConcat.push({
                Id: id,
                isDownloading: true,
                hasSucceeded: false,
                hasFailed: false,
                data: null,
            });
        });

        return [
            ...state.map((image: OLO.State.OrderTypesImages) => {
                if (action.orderTypesIds.find((productId) => productId === image.Id)) {
                    return {
                        ...image,
                        isDownloading: true,
                        hasSucceeded: false,
                        hasFailed: false,
                    };
                }

                return image;
            }),
            ...newToConcat,
        ];
    }),
    on(actions.OrderTypesImagesSuccessRequest, (state, action) =>
        state.map((image: OLO.State.OrderTypesImages) => {
            const foundItem: OLO.DTO.ImageUrlModel = action.payload.find((newImage) => newImage.ParentId === image.Id);

            if (foundItem) {
                return {
                    ...image,
                    data: { ...foundItem },
                    isDownloading: false,
                    hasSucceeded: true,
                    hasFailed: false,
                };
            }

            return {
                ...image,
                isDownloading: false,
                hasSucceeded: true,
                hasFailed: false,
            };
        }),
    ),
    on(actions.OrderTypesImagesErrorRequest, (state, action) =>
        state.map((image: OLO.State.OrderTypesImages) => {
            if (action.orderTypesIds.find((productId) => productId === image.Id)) {
                return {
                    ...image,
                    isDownloading: false,
                    hasSucceeded: false,
                    hasFailed: true,
                };
            }

            return image;
        }),
    ),
);

export function orderTypesImagesReducer(state: OLO.State.OrderTypesImages[] | undefined, action: Action) {
    return orderTypesImagesReducerFn(state, action);
}
