import { createSelector } from '@ngrx/store';
import { getFilteredLocations } from '../../filteredLocations/getFilteredLocations';

export const getLocationsListBy = (sortTag: 'Popular' | 'Distance' = null, filterParams: OLO.Common.LocationFilterParams = {}, limit: number = 3) =>
    createSelector(getFilteredLocations(sortTag, filterParams), (locations) => {
        if (limit) {
            if (locations.length > limit) {
                locations.length = limit;
            }
        }

        return locations;
    });
