import { Injectable, Inject } from '@angular/core';
import { Store, select } from '@ngrx/store';

import * as actions from '@shared/state/actions';
import * as selectors from '@shared/state/selectors';

import * as Tokens from '@shared/core/tokens';

import { take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class CartService {
    public popupTimeout: number = 300;

    constructor(@Inject(Tokens.CONFIG_TOKEN) public config: OLO.Config, public store: Store<OLO.State>) {}

    public async showPopup(timeout: number = this.popupTimeout): Promise<boolean> {
        return new Promise((resolve) => {
            this.store.pipe(select(selectors.isCartPopupVisible), take(1)).subscribe((isVisible) => {
                if (isVisible) return resolve(true);
                this.store.dispatch(actions.SetCartPopup({ options: { isVisible: true, animation: 'in' } }));
                setTimeout(() => {
                    this.store.dispatch(actions.SetCartPopup({ options: { isVisible: true, animation: null } }));
                    resolve(true);
                }, timeout);
            });
        });
    }

    public async hidePopup(timeout: number = this.popupTimeout): Promise<boolean> {
        return new Promise((resolve) => {
            this.store.pipe(select(selectors.isCartPopupVisible), take(1)).subscribe((cartPopup) => {
                if (!cartPopup) return resolve(true);

                this.store.dispatch(actions.SetCartPopup({ options: { isVisible: true, animation: 'out' } }));
                setTimeout(() => {
                    this.store.dispatch(actions.SetCartPopup({ options: { isVisible: false, animation: null } }));
                    resolve(true);
                }, timeout);
            });
        });
    }
}
