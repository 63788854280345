import { createAction, props } from '@ngrx/store';

export const ProductRestrictionsRequest = createAction('[Product restrictions] Request', props<{ params: OLO.DTO.ProductRestrictionsRequest }>());

export const ProductRestrictionsSuccessRequest = createAction('[Product restrictions] Success request', props<{ payload: OLO.DTO.ProductRestriction[] }>());

export const ProductRestrictionsErrorRequest = createAction('[Product restrictions] Error request');

export const ProductAddRestrictions = createAction('[Product restrictions] Add Restrictions', props<{ payload: OLO.DTO.OnlineMenuProductResponseModel[] }>());

export const ProductRestrictionsLimit = createAction('[Cart] Product restrictions limit ', (restriction: OLO.DTO.ProductRestriction, modalId: Nullable<number> = null) => ({
    restriction,
    modalId,
}));

export const ProductRestrictionsOverLimit = createAction('[Cart] Product restrictions overlimit', (restriction: OLO.DTO.ProductRestriction, modalId: Nullable<number> = null) => ({
    restriction,
    modalId,
}));
