import { createSelector } from '@ngrx/store';

import { getOnlineOrderState } from '../getOnlineOrderState';
import { getOnlineOrderTotalTax } from '../getOnlineOrderTotalTax';
import { getOrderPointsCost } from '../getOrderPointsCost';

export const getOrderSummary = createSelector(getOnlineOrderState, getOnlineOrderTotalTax, getOrderPointsCost, ({ recalculateRequest }, Tax, TotalOrderPointCost) => {
    if (!recalculateRequest.data || Tax === null) return null;

    return {
        Subtotal: recalculateRequest.data?.TotalNettValue,
        Tax,
        TotalOrderPointCost,
        Total: recalculateRequest.data?.TotalLeftToPay,
        Surcharges: recalculateRequest.data?.Surcharges?.sort((a, b) => (a.DisplayIndex || 0) - (b.DisplayIndex || 0)) || [],
        Medias: recalculateRequest.data?.Medias,
    } as OLO.Ordering.OrderSummary;
});
