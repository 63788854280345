import { createAction, props } from '@ngrx/store';

export const LocationImagesRequest = createAction(
    '[Location images] Request',
    (ids: number[] = [], imageType: OLO.Enums.IMAGE_TYPE, width: number = 1000, height: number = 1000) => ({ ids, imageType, width, height }),
);

export const LocationImagesSuccessRequest = createAction(
    '[Location images] Success request',
    props<{ imageType: OLO.Enums.IMAGE_TYPE; ids: number[]; payload: OLO.DTO.ImageUrlModel[] }>(),
);

export const LocationImagesErrorRequest = createAction('[Location images] Error request', props<{ imageType: OLO.Enums.IMAGE_TYPE; ids: number[]; ex?: any }>());
