import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { FreeProductsImagesEffects } from './free-products-images.effects';
import { freeProductsImagesReducer } from './free-products-images.reducer';

@NgModule({
    declarations: [],
    imports: [CommonModule, StoreModule.forFeature('freeProductsImages', freeProductsImagesReducer), EffectsModule.forFeature([FreeProductsImagesEffects])],
    exports: [],
    providers: [],
})
export class FreeProductsImagesStateModule {}
