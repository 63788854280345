import { createSelector } from '@ngrx/store';
import { collectionTypeState } from '../root';

import * as Utils from '@shared/core/utils';
import * as Statics from '@shared/core/statics';

export const isCollectionTypePickup = createSelector(collectionTypeState, (collectionType) => {
    const config = new Statics.ConfigStatic().current;

    const collectionTypePickupConfig = new Utils.CollectionTypeHelper(config.collectionTypes).getPickupCollectionTypeConfig();

    return (collectionType?.orderTypeId && collectionTypePickupConfig?.orderTypeIds?.includes(collectionType?.orderTypeId)) || false;
});
