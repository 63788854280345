import { createReducer, on, Action } from '@ngrx/store';
import * as actions from './router.actions';

const initialState: OLO.State.Router = {
    previous: null,
    current: null,
    history: [],
};

export const routeReducerFn = createReducer(
    initialState,
    on(actions.RouterNavigation, (state, action) => {
        /* Run serialization */
        const payload: OLO.State.Router.RouterNavigationPayload = action.payload;
        const { id, url, urlAfterRedirects } = payload.event;

        if (!payload.routerState) return state;

        let route = payload.routerState.root;
        while (route.firstChild) {
            route = route.firstChild;
        }
        const { params, queryParams, data, fragment /* Hash */ } = route;

        const current = {
            id,
            url,
            urlAfterRedirects,
            params,
            queryParams,
            data,
            fragment,
            timestamp: new Date().getTime(),
        };

        const previous = state?.current ? { ...state?.current } : null;

        return {
            ...state,
            previous: {
                ...previous,
            },

            current,
            //
            //  TODO:
            //  Currently history state doesn't give a ... about browser 'GO BACK' action.
            //  This way we will have preview of full REAL history.
            //  Previous route is REAL previous route of ANGULAR state, not browser! It's not duplication.
            //  These might be different
            //
            history: previous ? [...state.history, previous] : state.history,
        };
    }),
    on(actions.RouterCancel, (state, action) => action.payload.storeState.router),
);

export function routeReducer(state: OLO.State.Router | undefined, action: Action) {
    return routeReducerFn(state, action);
}
