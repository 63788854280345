import { createSelector } from '@ngrx/store';

import * as Utils from '@shared/core/utils';

import { getMenuFlowDetailsByWizzard } from '../getMenuFlowDetailsByWizzard';
import { getWizzardMenuFlowAllPageProducts } from '@shared/state/wizzard/selectors';

export const getNextPriceForMenuFlowProduct = (productId: number, pageIdentifier: number) =>
    createSelector(getMenuFlowDetailsByWizzard, getWizzardMenuFlowAllPageProducts(pageIdentifier), (menuFlow, wizzardPageProducts) => {
        /* TODO: move this logic to utils Pricing */
        if (!menuFlow) return null;

        const pageDetails: OLO.DTO.MenuFlowPageModel = menuFlow.Pages.find((Page) => Page.PageIdentifier === pageIdentifier);
        if (!pageDetails) return null;

        const product: OLO.DTO.MenuFlowProduct = pageDetails.Products.find((productItem) => productItem.ProductId === productId);
        const ingredients = wizzardPageProducts?.find((productItem) => productItem.ProductId === product.ProductId)?.IngredientsChanges;
        const extra: APICommon.IngredientModifierExtended = ingredients?.IngredientsModified[0] ?? ingredients?.IngredientsAdded[0];

        const addExtra = (price) => (extra && extra.ExtraPrice ? price + extra.ExtraPrice : price);

        if (!product) return null;

        if (product.ExcludeFromSpecialPricing) {
            return product['OverridedPrice'] !== null ? addExtra(product['OverridedPrice']) : addExtra(product['OriginalPrice']);
        }

        const sortDetails = Utils.Pricing.sortDetails(pageDetails);
        if (sortDetails.property === 'default') {
            if (menuFlow.OverridePrice !== null) {
                return addExtra(product.OverridedPrice) || 0;
            } else {
                return product.OverridedPrice !== null ? addExtra(product.OverridedPrice) : addExtra(product.OriginalPrice);
            }
        }

        const isPageSpecialPricingLimitReached: boolean = Utils.Pricing.isPageSpecialPricingLimitReached(pageDetails, wizzardPageProducts);

        if (isPageSpecialPricingLimitReached) {
            return product[sortDetails.property] !== null ? addExtra(product[sortDetails.property]) : addExtra(product['OriginalPrice']);
        }

        return addExtra(pageDetails.SpecialPrice);
    });
