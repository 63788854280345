import { createAction, props } from '@ngrx/store';

export const LocationsFiltersReset = createAction('[Locations filters] Reset');

export const LocationsFiltersSetPickupMode = createAction(
    '[Locations filters] Set pickup time mode ASAP/LATER TODAY',
    (mode: OLO.Ordering.FilterForPickupMode = { Id: 1, Name: 'ASAP' }) => ({ mode }),
);

export const LocationsFiltersSetPickupTime = createAction('[Locations filters] Set pickup time', (pickupTime: OLO.Ordering.PickupTime) => ({ pickupTime }));

export const LocationsFiltersRestorePickupTime = createAction('[Locations filters] Restore to ASAP pickup time');

export const LocationsFiltersSyncPickupTime = createAction(
    '[Locations filters] Request adjust pickup time to when CURRENT TIME is changing without triggering EFFECTS!',
    (pickupTime: OLO.Ordering.PickupTime = null) => ({ pickupTime }),
);

export const LocationsFiltersSetSearch = createAction('[Locations filter] Set search string', (search: Nullable<string> = null, geocoder: APICommon.GeocoderResult = null) => ({
    search,
    geocoder,
}));

export const LocationsFiltersSetAddress = createAction('[Locations filter] Set address', props<{ address: APICommon.GooglePlaceDetails }>());

export const LocationsFiltersSetBrand = createAction('[Locations filter] Set brand', (brand: OLO.Components.DropDown.DropDownOption) => ({ brand }));

export const InitGeocoder = createAction('[Locations filter] Init geocoder', (geocoder: APICommon.GeocoderResult) => ({ geocoder }));
