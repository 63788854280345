import { matchValues } from '@shared/core/types/common';

const locale: OLO.Enums.URL_TYPES[] = [
    OLO.Enums.URL_TYPES.USER_AGREEMENT,
    OLO.Enums.URL_TYPES.PRIVACY_POLICY,
    OLO.Enums.URL_TYPES.TERMS_AND_CONDITIONS,
    OLO.Enums.URL_TYPES.CONTACT_US,
    OLO.Enums.URL_TYPES.SOCIAL_MEDIA_FACEBOOK,
    OLO.Enums.URL_TYPES.SOCIAL_MEDIA_INSTAGRAM,
    OLO.Enums.URL_TYPES.FAQ,
    OLO.Enums.URL_TYPES.ABOUT_US,
    OLO.Enums.URL_TYPES.FEEDBACK,
];

export const linkType = matchValues(...locale);
