/* Will remove data from storage */
import { Injectable } from '@angular/core';
import { CookieService } from './cookie.shared.service';

import * as Utils from '@shared/core/utils';

@Injectable({
    providedIn: 'root',
})
export class CleanService {
    constructor(public cookiesService: CookieService) {}

    public cookiesStorage(): void {
        this.cookiesService.removeAll();
    }

    public localStorage(): void {
        Utils.Storage.clear();
    }

    public localStorageByCategory(category: string): void {
        Utils.Storage.clearByCategory(category);
    }

    public sessionStorage(): void {
        Utils.Storage.clear('sessionStorage');
    }

    public clearAllStorageTypes(): void {
        this.cookiesStorage();
        this.localStorage();
        this.sessionStorage();
    }

    public clearStorageOnUserDelete(): void {
        this.cookiesStorage();
        this.localStorageByCategory(OLO.Enums.USER_STORAGE.USER_CATEGORY);
        this.localStorageByCategory(OLO.Enums.JWT.JWT_CATEGORY);
        this.localStorageByCategory(OLO.Enums.SESSION_STORAGE.ACCOUNT_CATEGORY);
        this.localStorageByCategory(OLO.Enums.SESSION_STORAGE.AUTH_CATEGORY);
        this.localStorageByCategory(OLO.Enums.SESSION_STORAGE.SESSION_CATEGORY);
        this.localStorageByCategory(OLO.Enums.CART_STORAGE.CART_CATEGORY);
        this.sessionStorage();
    }

    public clearForSignIn(): void {
        /* Is registering or logging in - gathering data in sessionStorage */
        this.cookiesStorage();
        this.localStorage();
    }
}
