import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { TopBarEffects } from './top-bar.effects';
import { topBarReducer } from './top-bar.reducer';

@NgModule({
    declarations: [],
    imports: [CommonModule, StoreModule.forFeature('topBar', topBarReducer), EffectsModule.forFeature([TopBarEffects])],
    exports: [],
    providers: [],
})
export class TopBarStateModule {}
