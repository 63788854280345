import { createSelector } from '@ngrx/store';

import { orderTypesState } from '../root';
import { getSelectedOrderType } from '@shared/state/onlineOrder/selectors/getSelectedOrderType';
import { getCurrentLocationNo } from '@shared/state/currentLocation/selectors/getCurrentLocationNo';

export const isSpecialInstructionForOrderEnabled = createSelector(
    orderTypesState,
    getSelectedOrderType,
    getCurrentLocationNo,
    (orderTypes: OLO.State.OrderTypes[], selectedOrderType: APICommon.OrderTypeExtended, currentLocationNo) => {
        if (!orderTypes || !selectedOrderType || !currentLocationNo) return false;

        const currentOrderCollection = orderTypes.find((obj) => obj.locationNo === currentLocationNo);
        const currentOrderType = currentOrderCollection?.data.find((obj) => obj.Id === selectedOrderType.Id);

        return currentOrderType?.IsExtraInstructionsForOrdersEnabled;
    },
);
