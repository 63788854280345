import { Injectable, Inject } from '@angular/core';
import { Store, select, Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import * as actions from '../actions';
import * as selectors from '../selectors';

import * as Services from '@shared/core/services';
import * as Tokens from '@shared/core/tokens';

import { Observable, of } from 'rxjs';
import { switchMap, catchError, map, withLatestFrom, mergeMap } from 'rxjs/operators';

@Injectable()
export class LoyaltyProgramsEffects {
    public resetLoyaltyProgramOnSignOut$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.MemberSignOut),
            switchMap(() => of(actions.LoyaltyProgramsReset())),
        ),
    );

    public requestLoyaltyInfoForCurrentMemberOnAppInit$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.AppSettingsSuccessRequest, actions.MemberJwtDataResponseSuccess),
            withLatestFrom(this._store.pipe(select(selectors.isMemberAuthorizedJWT))),
            switchMap(([, isAuthorized]) => {
                if (this._config.appMode !== OLO.Enums.APP_MODE.ORDERING_ONLY && isAuthorized) {
                    return [actions.LoyaltyProgramsRequest()];
                }

                return [];
            }),
        ),
    );

    public requestLoyaltyPrograms$: Observable<Action> = createEffect(() =>
        this._actions$.pipe(
            ofType(actions.LoyaltyProgramsRequest),
            mergeMap(() =>
                this._loyaltyAppService.apiGetLoyaltyPrograms().pipe(
                    map((payload) => actions.LoyaltyProgramsSuccessRequest({ payload })),
                    catchError((ex) => {
                        console.error('apiGetLoyaltyPrograms', ex);

                        return of(actions.LoyaltyProgramsErrorRequest({ ex }));
                    }),
                ),
            ),
        ),
    );

    constructor(
        @Inject(Tokens.CONFIG_TOKEN) private _config: OLO.Config,
        private _actions$: Actions,
        private _store: Store<OLO.State>,
        private _loyaltyAppService: Services.LoyaltyAppService,
    ) {}
}
