import { CollectionTypeHelper } from './collection-type-helper.utils';

/**
 * Helps to detect to which group provided orderTypeId belongs
 */
export class CollectionTypeGroupDetector {
    private _collectionType: OLO.Enums.COLLECTION_TYPE = null;

    constructor(private _orderTypeId: number, private _config: OLO.Config) {
        if (!_orderTypeId || !_config) return;

        this._init();
    }

    private _init(): void {
        const c = new CollectionTypeHelper(this._config.collectionTypes);

        const deliveryIds = c.getDeliveryCollectionTypeConfig()?.orderTypeIds || [];
        const dineInBuzzer = c.getDineInCollectionTypeConfig()?.dineInBuzzer?.orderTypeId || null;
        const dineInTable = c.getDineInCollectionTypeConfig()?.dineInTable?.orderTypeId || null;
        const pickupsIds = c.getPickupCollectionTypeConfig()?.orderTypeIds || [];
        const cateringIds = c.getCateringCollectionTypeConfig()?.orderTypeIds || [];

        const oid = this._orderTypeId;
        switch (true) {
            case deliveryIds.includes(oid) || oid === OLO.Enums.COLLECTION_TYPE.DELIVERY:
                this._collectionType = OLO.Enums.COLLECTION_TYPE.DELIVERY;
                break;

            case oid === dineInBuzzer || oid === OLO.Enums.COLLECTION_TYPE.DINE_IN:
            case oid === dineInTable || oid === OLO.Enums.COLLECTION_TYPE.DINE_IN:
                this._collectionType = OLO.Enums.COLLECTION_TYPE.DINE_IN;
                break;

            case pickupsIds.includes(oid) || oid === OLO.Enums.COLLECTION_TYPE.PICKUP:
                this._collectionType = OLO.Enums.COLLECTION_TYPE.PICKUP;
                break;

            case cateringIds.includes(oid) || oid === OLO.Enums.COLLECTION_TYPE.CATERING:
                this._collectionType = OLO.Enums.COLLECTION_TYPE.CATERING;
                break;
        }
    }

    public getCollectionType(): OLO.Enums.COLLECTION_TYPE {
        return this._collectionType;
    }

    public isDineIn(): boolean {
        return this.getCollectionType() === OLO.Enums.COLLECTION_TYPE.DINE_IN;
    }

    public isDelivery(): boolean {
        return this.getCollectionType() === OLO.Enums.COLLECTION_TYPE.DELIVERY;
    }

    public isPickup(): boolean {
        return this.getCollectionType() === OLO.Enums.COLLECTION_TYPE.PICKUP;
    }

    public isCatering(): boolean {
        return this.getCollectionType() === OLO.Enums.COLLECTION_TYPE.CATERING;
    }

    public collectionTypeAllowedPickupTimeASAP(): boolean {
        return this._collectionType === OLO.Enums.COLLECTION_TYPE.PICKUP || this._collectionType === OLO.Enums.COLLECTION_TYPE.DINE_IN;
    }

    /**
     * Checks if providerd orderTypeId matches collectionType
     * @param toCheckOrderTypeId to check
     * @returns boolean
     */
    public matchOrderTypeToCollectionType(toCheckOrderTypeId?: number): boolean {
        const toCheckObj = new CollectionTypeGroupDetector(toCheckOrderTypeId, this._config);

        return toCheckObj.getCollectionType() === this.getCollectionType();
    }
}
